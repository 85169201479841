import * as React from 'react';
import { Translatable, useI18n } from '../i18n';

type TableProps = {
    header?: React.ReactNode;
    footer?: React.ReactNode;
};

export const Table: React.FunctionComponent<TableProps> = ({
    header,
    children,
    footer,
}) => {
    return (
        <div className="flex flex-col">
            <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                        {header}
                        <tbody className="bg-white">{children}</tbody>
                    </table>
                    {footer}
                </div>
            </div>
        </div>
    );
};

export const TableFooter: React.FunctionComponent = ({ children }) => {
    return (
        <div className="bg-white px-4 py-3 flex items-center justify-between sm:px-6">
            {children}
        </div>
    );
};

type TableHeaderProps = {
    labels: Translatable[];
};

export const TableHeader: React.FunctionComponent<TableHeaderProps> = ({
    labels,
}) => {
    const { t } = useI18n();

    return (
        <thead>
            <tr>
                {labels.map((label, i) => (
                    <th
                        key={i}
                        className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
                    >
                        {t(label)}
                    </th>
                ))}
            </tr>
        </thead>
    );
};

type TableRowProps = {
    contents: React.ReactNode[];
};

export const TableRow: React.FunctionComponent<TableRowProps> = ({
    contents,
}) => {
    return (
        <tr>
            {contents.map((c, i) => {
                return (
                    <td
                        key={i}
                        className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                    >
                        {c}
                    </td>
                );
            })}
        </tr>
    );
};

type TextTableCellProps = {
    primary: React.ReactNode;
    secondary?: React.ReactNode;
};

export const TextTableCell: React.FunctionComponent<TextTableCellProps> = ({
    primary,
    secondary,
}) => {
    return (
        <>
            <div className="text-sm leading-5 text-gray-900">{primary}</div>
            {!!secondary && (
                <div className="text-sm leading-5 text-gray-500">
                    {secondary}
                </div>
            )}
        </>
    );
};
