import { useState } from 'react';
import * as React from 'react';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { useHtmlId } from '../../hooks';
import { Translatable, useI18n } from '../../i18n';

type Props = {
    label: Translatable;
    placeholder: Translatable;
    value: Date | undefined;
    defaultValue: Date | undefined;
    onChanged: (v: Date | undefined) => void;
};

const DateInput: React.FunctionComponent<Props> = ({
    label,
    placeholder,
    defaultValue,
    value,
    onChanged,
}) => {
    const id = useHtmlId();
    const { t } = useI18n();

    const [focused, setFocused] = useState<boolean>(false);

    return (
        <div className="flex flex-col space-y-1">
            <label htmlFor={id} className="block pl-3 form-label">
                {t(label)}
            </label>
            <SingleDatePicker
                block
                id={id}
                date={
                    !!value
                        ? moment(value)
                        : !!defaultValue
                        ? moment(defaultValue)
                        : null
                }
                focused={focused}
                onFocusChange={({ focused }) => setFocused(focused)}
                onDateChange={(momentDate) => onChanged(momentDate?.toDate())}
                firstDayOfWeek={1}
                hideKeyboardShortcutsPanel
                placeholder={t(placeholder)}
                displayFormat="DD.MM.YYYY"
            />
        </div>
    );
};

export default DateInput;
