import * as React from 'react';
import { Button } from '../common/Button';
import { partnerActions } from './store';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { authSelectors } from '../auth/store';
import { accountSelectors } from '../account/store';

const ResendInvitationButton: React.FunctionComponent<{ userId: string }> = ({
    userId,
}) => {
    const account = useAppSelector((state) =>
        accountSelectors.getById(state, { id: userId }),
    );

    const dispatch = useAppDispatch();
    const partnerId = useAppSelector(authSelectors.getPartnerId)!;

    const resendInvite = React.useCallback(async () => {
        if (!account) {
            return;
        }
        await dispatch(
            partnerActions.invitePartnerManager({
                partnerId,
                email: account.email,
            }),
        );
    }, [dispatch, partnerId, account]);

    return (
        <Button
            label="manager.invite.resendButton"
            secondary
            onClick={resendInvite}
        />
    );
};

export default ResendInvitationButton;
