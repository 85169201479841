import {
    Redirect,
    Route,
    RouteComponentProps,
    Switch,
    useRouteMatch,
    withRouter,
} from 'react-router-dom';
import { Button } from '../common/Button';
import DashboardHeader from '../common/DashboardHeader';
import RewardOverview from './overview/RewardOverview';
import RewardForm from './RewardForm';
import RewardView from './RewardView';
import Dropdown from '../common/Dropdown';

type Props = {} & RouteComponentProps;

const Rewards = (props: Props) => {
    return (
        <Switch>
            <Route exact path="/rewards">
                <DashboardHeader title="module.rewards.name">
                    <Dropdown
                        button={
                            <Button
                                primary
                                label="reward.addReward"
                                onClick={() => void 0}
                            />
                        }
                        items={[
                            {
                                label: 'reward.addRewardPaid',
                                onClick: () =>
                                    props.history.push('/rewards/add/paid'),
                            },
                            {
                                label: 'reward.addRewardDiscount',
                                onClick: () =>
                                    props.history.push('/rewards/add/discount'),
                            },
                        ]}
                    />
                </DashboardHeader>
                <RewardOverview />
            </Route>
            <Route path="/rewards/add" exact>
                <Redirect to="/rewards/add/discount" />
            </Route>
            <Route path="/rewards/add/:billingType">
                <RewardFormHeader add />
                <RewardForm />
            </Route>
            <Route path="/rewards/live/:rewardId">
                <RewardView />
            </Route>
            <Route path="/rewards/draft/:rewardId/:billingType">
                <RewardFormHeader edit />
                <RewardForm />
            </Route>
        </Switch>
    );
};

export default withRouter(Rewards);

function RewardFormHeader(props: { edit: true } | { add: true }) {
    const { params } = useRouteMatch();
    const { billingType } = params as { billingType: string };

    const labelBase =
        'edit' in props ? 'module.rewards.detail.' : 'module.rewards.add.';

    return <DashboardHeader backButton title={labelBase + billingType} />;
}
