import * as React from 'react';
import { Button } from '../common/Button';
import { partnerActions } from './store';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { authSelectors } from '../auth/store';

const RemovePartnerManagerButton: React.FunctionComponent<{
    userId: string;
}> = ({ userId }) => {
    const dispatch = useAppDispatch();
    const partnerId = useAppSelector(authSelectors.getPartnerId)!;

    const ownUid = useAppSelector(authSelectors.getUid);

    const remove = React.useCallback(async () => {
        await dispatch(
            partnerActions.removePartnerManager({ partnerId, userId }),
        );
    }, [dispatch, partnerId, userId]);

    return (
        <Button
            label="manager.remove.label"
            secondary
            onClick={remove}
            disabled={ownUid === userId}
        />
    );
};

export default RemovePartnerManagerButton;
