import * as Sentry from '@sentry/react';

/**
 * Init Sentry
 * this should not be used in staging for now.
 */
export const init = () => {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN || undefined,
        release: process.env.REACT_APP_GIT_SHA,
    });
};
