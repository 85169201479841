import i18next, { ResourceLanguage, TFunction } from 'i18next';
import {
    initReactI18next,
    Namespace,
    useTranslation,
    UseTranslationOptions,
    Trans,
} from 'react-i18next';
import de from './de.json';

export type Translatable = string;

export const init = () => {
    return i18next.use(initReactI18next).init({
        resources: {
            de: de as ResourceLanguage,
        },
        keySeparator: '.',
        lng: 'de',
        fallbackLng: 'en',
    });
};

/*
We use a custom wrapper to allow us alter the i18n behaviour in the future without touching every file again
 */

type TFunc = TFunction;

type UseI18nResponse = {
    t: TFunc;
    Trans: typeof Trans;
};

export const useI18n = (
    ns?: Namespace,
    options?: UseTranslationOptions,
): UseI18nResponse => {
    const { t } = useTranslation(ns, options);

    return {
        t: t,
        Trans,
    };
};
