import { useHistory } from 'react-router-dom';
import { Button } from '../../common/Button';
import { useI18n } from '../../i18n';
import { IconGift } from '../../icons';
import Dropdown from '../../common/Dropdown';

export const NoDraftsBody = () => {
    const history = useHistory();
    const { t } = useI18n();
    return (
        <div className="flex flex-col space-y-4 h-full justify-center items-center">
            <div className="flex w-40 h-40 rounded-full bg-semantic-background justify-center items-center">
                <IconGift />
            </div>
            <div className="text-body2 text-gray-500 px-12 text-center">
                {t('reward.overview.noDrafts')}
            </div>
            <div>
                <Dropdown
                    button={
                        <Button
                            secondary
                            label="reward.addReward"
                            onClick={() => void 0}
                        />
                    }
                    items={[
                        {
                            label: 'reward.addRewardPaid',
                            onClick: () => history.push('/rewards/add/paid'),
                        },
                        {
                            label: 'reward.addRewardDiscount',
                            onClick: () =>
                                history.push('/rewards/add/discount'),
                        },
                    ]}
                />
            </div>
        </div>
    );
};
