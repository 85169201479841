import { useState } from 'react';
import * as React from 'react';

import TextInput from '../common/form/TextInput';
import FormSection from '../common/form/FormSection';
import { PostPartnerProfileDraftRequest_RichSocialLink } from '../api/model/b2b';
import { Button } from '../common/Button';
import { IconClose } from '../icons';
import { useI18n } from '../i18n';
import { ValidationResult } from '../hooks/useValidation';

type Props = {
    linksRich?: PostPartnerProfileDraftRequest_RichSocialLink[] | undefined;
    disabled?: boolean;
    onChanged: (v: PostPartnerProfileDraftRequest_RichSocialLink[]) => void;
    onBlur?: () => void;
    errorMessages?: ValidationResult<
        PostPartnerProfileDraftRequest_RichSocialLink[] | undefined
    >;
};

const SocialLinksInput: React.FunctionComponent<Props> = (props) => {
    const { t } = useI18n();
    const currentLinks = props.linksRich;

    const [links, setLinks] = useState<
        PostPartnerProfileDraftRequest_RichSocialLink[] | undefined
    >();

    const add = () => {
        setLinks([
            ...(links ?? currentLinks ?? []),
            {
                kind: 'website',
                url: '',
                label: '',
            },
        ]);
    };

    const remove = (index: number) => {
        const newLinks = [...(links ?? currentLinks ?? [])];
        newLinks.splice(index, 1);
        setLinks(newLinks);
    };

    const changed = (
        i: number,
        pairs: Array<
            [keyof PostPartnerProfileDraftRequest_RichSocialLink, string]
        >,
    ) => {
        let newLinks: PostPartnerProfileDraftRequest_RichSocialLink[] = [];
        if (currentLinks !== undefined) {
            newLinks = [...currentLinks];
        }
        if (links !== undefined) {
            newLinks = [...links];
        }

        newLinks[i] = {
            ...newLinks[i],
            ...Object.fromEntries(pairs),
        };
        setLinks(newLinks);
        props.onChanged(newLinks);
    };

    return (
        <FormSection
            label="profile.socialLinks.title"
            subtitle="profile.socialLinks.subtitle"
        >
            {(links ?? currentLinks)?.map((l, i) => (
                <div key={i} className="flex flex-row items-center space-x-2">
                    <select
                        value={l.kind}
                        disabled={props.disabled}
                        onChange={(e) => changed(i, [['kind', e.target.value]])}
                    >
                        {[
                            'website',
                            'instagram',
                            'facebook',
                            'tiktok',
                            'twitter',
                            'linkedin',
                            'youtube',
                            'spotify',
                        ].map((kind) => (
                            <option key={kind} value={kind}>
                                {t(`profile.socialLinks.${kind}`)}
                            </option>
                        ))}
                    </select>
                    <div className="flex-1">
                        <TextInput
                            label="profile.socialLinks.general"
                            defaultValue={at(currentLinks, i)?.url ?? ''}
                            value={l.url}
                            disabled={props.disabled}
                            onBlur={props.onBlur}
                            errorMessage={
                                (props.errorMessages ?? [])[i]?.url || null
                            }
                            onChanged={(value) =>
                                changed(i, [
                                    ['url', value],
                                    [
                                        'label',
                                        (() => {
                                            try {
                                                return new URL(value).host;
                                            } catch (e) {
                                                return value;
                                            }
                                        })(),
                                    ],
                                ])
                            }
                        />
                    </div>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            remove(i);
                        }}
                    >
                        <IconClose className="w-4 h-4" />
                    </button>
                </div>
            ))}
            <Button
                secondary
                label="profile.socialLinks.addButtonLabel"
                disabled={props.disabled}
                onClick={(e) => {
                    e.preventDefault();
                    add();
                }}
            />
        </FormSection>
    );
};

export default SocialLinksInput;

function at<T>(arr: Array<T> | undefined, idx: number): T | undefined {
    if (arr === undefined) {
        return undefined;
    }
    return arr[idx];
}
