// @generated by protobuf-ts 2.1.0 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "model/common.proto" (package "one.plan3t.core.model", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * Represents a whole or partial calendar date, such as a birthday. The time of
 * day and time zone are either specified elsewhere or are insignificant. The
 * date is relative to the Gregorian Calendar. This can represent one of the
 * following:
 *
 * * A full date, with non-zero year, month, and day values
 * * A month and day value, with a zero year, such as an anniversary
 * * A year on its own, with zero month and day values
 * * A year and month value, with a zero day, such as a credit card expiration
 * date
 *
 * A related type is `google.protobuf.Timestamp`.
 *
 * @generated from protobuf message one.plan3t.core.model.Date
 */
export interface Date {
    /**
     * Year of the date. Must be from 1 to 9999, or 0 to specify a date without
     * a year.
     *
     * @generated from protobuf field: int32 year = 1;
     */
    year: number;
    /**
     * Month of a year. Must be from 1 to 12, or 0 to specify a year without a
     * month and day.
     *
     * @generated from protobuf field: int32 month = 2;
     */
    month: number;
    /**
     * Day of a month. Must be from 1 to 31 and valid for the year and month, or 0
     * to specify a year by itself or a year and month where the day isn't
     * significant.
     *
     * @generated from protobuf field: int32 day = 3;
     */
    day: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class Date$Type extends MessageType<Date> {
    constructor() {
        super("one.plan3t.core.model.Date", [
            { no: 1, name: "year", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "month", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "day", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Date>): Date {
        const message = { year: 0, month: 0, day: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Date>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Date): Date {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 year */ 1:
                    message.year = reader.int32();
                    break;
                case /* int32 month */ 2:
                    message.month = reader.int32();
                    break;
                case /* int32 day */ 3:
                    message.day = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Date, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 year = 1; */
        if (message.year !== 0)
            writer.tag(1, WireType.Varint).int32(message.year);
        /* int32 month = 2; */
        if (message.month !== 0)
            writer.tag(2, WireType.Varint).int32(message.month);
        /* int32 day = 3; */
        if (message.day !== 0)
            writer.tag(3, WireType.Varint).int32(message.day);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.Date
 */
export const Date = new Date$Type();
