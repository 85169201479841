// @generated by protobuf-ts 2.1.0 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "model/core.proto" (package "one.plan3t.core.model", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Duration } from "../google/protobuf/duration";
import { Timestamp } from "../google/protobuf/timestamp";
/**
 * @generated from protobuf message one.plan3t.core.model.Plan3TPoint
 */
export interface Plan3TPoint {
    /**
     * this is a signed integer (32-bit) to ensure capability with javascript.
     *
     * @generated from protobuf field: int32 amount = 1;
     */
    amount: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PartnerProfile
 */
export interface PartnerProfile {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string tagLine = 3;
     */
    tagLine: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.CoreIndustry coreIndustry = 4;
     */
    coreIndustry: CoreIndustry;
    /**
     * @generated from protobuf field: string description = 5;
     */
    description: string;
    /**
     * @generated from protobuf field: repeated string socialLinks = 6;
     */
    socialLinks: string[];
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource coverImage = 7;
     */
    coverImage?: ImageResource;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource logo = 8;
     */
    logo?: ImageResource;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ImageResource mood_images = 9;
     */
    moodImages: ImageResource[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 10;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.PartnerProfile.RichSocialLink social_links_rich = 11;
     */
    socialLinksRich: PartnerProfile_RichSocialLink[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.PartnerProfile.RichSocialLink
 */
export interface PartnerProfile_RichSocialLink {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
    /**
     * @generated from protobuf field: string kind = 3;
     */
    kind: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPartnerProfilesResponse
 */
export interface GetPartnerProfilesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.PartnerProfile partner = 1;
     */
    partner: PartnerProfile[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.ChallengeEstimatedSavings
 */
export interface ChallengeEstimatedSavings {
    /**
     * @generated from protobuf field: int32 absoluteSavingsInKg = 1;
     */
    absoluteSavingsInKg: number;
    /**
     * the names map to the defined [CoreIndustry] s
     *
     * @generated from protobuf field: double housing = 2;
     */
    housing: number;
    /**
     * @generated from protobuf field: double mobility = 3;
     */
    mobility: number;
    /**
     * @generated from protobuf field: double nutrition = 4;
     */
    nutrition: number;
    /**
     * @generated from protobuf field: double consumption = 5;
     */
    consumption: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ChallengePreview
 */
export interface ChallengePreview {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string created_by = 3;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp modified_at = 4;
     */
    modifiedAt?: Timestamp;
    /**
     * @generated from protobuf field: string modified_by = 5;
     */
    modifiedBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp published_at = 6;
     */
    publishedAt?: Timestamp;
    /**
     * @generated from protobuf field: string published_by = 7;
     */
    publishedBy: string;
    /**
     * @generated from protobuf field: string name = 8;
     */
    name: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.CoreIndustry coreIndustry = 9;
     */
    coreIndustry: CoreIndustry;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ChallengeType type = 10;
     */
    type: ChallengeType;
    /**
     * @generated from protobuf field: string category = 11;
     */
    category: string;
    /**
     * @generated from protobuf field: string description = 12;
     */
    description: string;
    /**
     * @generated from protobuf field: string iconName = 13;
     */
    iconName: string;
    /**
     * @generated from protobuf field: repeated string keyIdeas = 14;
     */
    keyIdeas: string[];
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource image = 15;
     */
    image?: ImageResource;
    /**
     * @generated from protobuf field: string tagLine = 16;
     */
    tagLine: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint award = 17;
     */
    award?: Plan3TPoint;
    /**
     * @generated from protobuf field: repeated string partnerIds = 18;
     */
    partnerIds: string[];
    /**
     * @generated from protobuf field: one.plan3t.core.model.ChallengeEstimatedSavings estimatedSavings = 19;
     */
    estimatedSavings?: ChallengeEstimatedSavings;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.CO2SavingPotential recommendedPotentials = 20;
     */
    recommendedPotentials: CO2SavingPotential[];
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.CO2SavingPotential requiredPotentials = 21;
     */
    requiredPotentials: CO2SavingPotential[];
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.CO2SavingPotential forbiddenPotentials = 22;
     */
    forbiddenPotentials: CO2SavingPotential[];
    /**
     * @generated from protobuf field: bool active = 23;
     */
    active: boolean;
    /**
     * @generated from protobuf field: repeated string blogReferenceIds = 24;
     */
    blogReferenceIds: string[];
    /**
     * @generated from protobuf field: repeated string tipIds = 25;
     */
    tipIds: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.PartnerBase
 */
export interface PartnerBase {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string legalName = 2;
     */
    legalName: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string created_by = 4;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp modified_at = 5;
     */
    modifiedAt?: Timestamp;
    /**
     * @generated from protobuf field: string modified_by = 6;
     */
    modifiedBy: string;
    /**
     * @generated from protobuf field: string brandName = 7;
     */
    brandName: string;
    /**
     * @generated from protobuf field: string pipedrive_id = 8;
     */
    pipedriveId: string;
    /**
     * @generated from protobuf field: string datev_id = 9;
     */
    datevId: string;
    /**
     * @generated from protobuf field: bool affiliate_only = 10;
     */
    affiliateOnly: boolean;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PartnerManager
 */
export interface PartnerManager {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string created_by = 4;
     */
    createdBy: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.UserAccount
 */
export interface UserAccount {
    /**
     * @generated from protobuf field: string uid = 1;
     */
    uid: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string email = 3;
     */
    email: string;
    /**
     * @generated from protobuf field: string photoUrl = 4;
     */
    photoUrl: string;
    /**
     * @generated from protobuf field: bool email_verified = 5;
     */
    emailVerified: boolean;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetUserAccountRequest
 */
export interface GetUserAccountRequest {
    /**
     * @generated from protobuf field: repeated string user_ids = 1;
     */
    userIds: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetUserAccountResponse
 */
export interface GetUserAccountResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.UserAccount accounts = 1;
     */
    accounts: UserAccount[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.ImageResource
 */
export interface ImageResource {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string url = 2;
     */
    url: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PartnerProfileDraft
 */
export interface PartnerProfileDraft {
    /**
     * @generated from protobuf field: string partner_id = 1;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string partner_name = 2;
     */
    partnerName: string;
    /**
     * @generated from protobuf field: string tagLine = 3;
     */
    tagLine: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 4;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string created_by = 5;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp modified_at = 6;
     */
    modifiedAt?: Timestamp;
    /**
     * @generated from protobuf field: string modified_by = 7;
     */
    modifiedBy: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.DraftState state = 8;
     */
    state: DraftState;
    /**
     * @generated from protobuf field: one.plan3t.core.model.CoreIndustry coreIndustry = 9;
     */
    coreIndustry: CoreIndustry;
    /**
     * @generated from protobuf field: string description = 10;
     */
    description: string;
    /**
     * @generated from protobuf field: repeated string socialLinks = 11;
     */
    socialLinks: string[];
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource coverImage = 12;
     */
    coverImage?: ImageResource;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource logo = 13;
     */
    logo?: ImageResource;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ImageResource mood_images = 14;
     */
    moodImages: ImageResource[];
    /**
     * @generated from protobuf field: string rejectionReason = 15;
     */
    rejectionReason: string;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.PartnerProfileDraft.RichSocialLink social_links_rich = 16;
     */
    socialLinksRich: PartnerProfileDraft_RichSocialLink[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.PartnerProfileDraft.RichSocialLink
 */
export interface PartnerProfileDraft_RichSocialLink {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
    /**
     * @generated from protobuf field: string kind = 3;
     */
    kind: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.UploadedFile
 */
export interface UploadedFile {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string filename = 2;
     */
    filename: string;
    /**
     * @generated from protobuf field: string path = 3;
     */
    path: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.RewardDraft
 */
export interface RewardDraft {
    /**
     * @generated from protobuf field: string reward_id = 1;
     */
    rewardId: string;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string created_by = 4;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp modified_at = 5;
     */
    modifiedAt?: Timestamp;
    /**
     * @generated from protobuf field: string modified_by = 6;
     */
    modifiedBy: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.DraftState state = 7;
     */
    state: DraftState;
    /**
     * @generated from protobuf field: string rejectionReason = 8;
     */
    rejectionReason: string;
    /**
     * @generated from protobuf field: string title = 9;
     */
    title: string;
    /**
     * @generated from protobuf field: string description = 10;
     */
    description: string;
    /**
     * @generated from protobuf field: string terms = 11;
     */
    terms: string;
    /**
     * @generated from protobuf field: string directLink = 12;
     */
    directLink: string;
    /**
     * @generated from protobuf field: repeated string coupon_codes = 13;
     */
    couponCodes: string[];
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ImageResource images = 14;
     */
    images: ImageResource[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp valid_until = 15;
     */
    validUntil?: Timestamp;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint price = 16;
     */
    price?: Plan3TPoint;
    /**
     * @generated from protobuf field: string tag_line = 17;
     */
    tagLine: string;
    /**
     * @generated from protobuf field: int32 max_claims = 18;
     */
    maxClaims: number;
    /**
     * @generated from protobuf field: google.protobuf.Duration max_claims_cooldown = 19;
     */
    maxClaimsCooldown?: Duration;
    /**
     * @generated from protobuf field: one.plan3t.core.model.RewardBillingType billing_type = 20;
     */
    billingType: RewardBillingType;
    /**
     * @generated from protobuf field: int32 code_price_cents = 21;
     */
    codePriceCents: number;
    /**
     * @generated from protobuf field: one.plan3t.core.model.TaxableCodeType taxable_code_type = 22;
     */
    taxableCodeType: TaxableCodeType;
}
/**
 * @generated from protobuf message one.plan3t.core.model.Reward
 */
export interface Reward {
    /**
     * @generated from protobuf field: string reward_id = 1;
     */
    rewardId: string;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string title = 3;
     */
    title: string;
    /**
     * @generated from protobuf field: string description = 4;
     */
    description: string;
    /**
     * @generated from protobuf field: string terms = 5;
     */
    terms: string;
    /**
     * @generated from protobuf field: string directLink = 6;
     */
    directLink: string;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ImageResource images = 7;
     */
    images: ImageResource[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp valid_until = 8;
     */
    validUntil?: Timestamp;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint price = 9;
     */
    price?: Plan3TPoint;
    /**
     * @generated from protobuf field: string tag_line = 10;
     */
    tagLine: string;
    /**
     * @generated from protobuf field: int32 max_claims = 11;
     */
    maxClaims: number;
    /**
     * @generated from protobuf field: google.protobuf.Duration max_claims_cooldown = 12;
     */
    maxClaimsCooldown?: Duration;
    /**
     * @generated from protobuf field: one.plan3t.core.model.RewardBillingType billing_type = 13;
     */
    billingType: RewardBillingType;
}
/**
 * @generated from protobuf message one.plan3t.core.model.Asset
 */
export interface Asset {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
    /**
     * @generated from protobuf field: string rewardID = 2;
     */
    rewardID: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint pointsValue = 3;
     */
    pointsValue?: Plan3TPoint;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp acquired_at = 4;
     */
    acquiredAt?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.Balance
 */
export interface Balance {
    /**
     * @generated from protobuf field: string owner = 1;
     */
    owner: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint points = 2;
     */
    points?: Plan3TPoint;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.Asset assets = 3;
     */
    assets: Asset[];
    /**
     * @generated from protobuf field: string lastEventId = 4;
     */
    lastEventId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp changedAt = 5;
     */
    changedAt?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRewardLiveRequest
 */
export interface GetRewardLiveRequest {
    /**
     * @generated from protobuf field: string partner_id = 1;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: repeated string ids = 2;
     */
    ids: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRewardLiveResponse
 */
export interface GetRewardLiveResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.Reward rewards = 1;
     */
    rewards: Reward[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRewardResponse
 */
export interface GetRewardResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.Reward reward = 1;
     */
    reward?: Reward;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ErrorResponse
 */
export interface ErrorResponse {
    /**
     * @generated from protobuf field: repeated string errors = 1;
     */
    errors: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.AnalyticsEvent
 */
export interface AnalyticsEvent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.AnalyticsEventType eventType = 2;
     */
    eventType: AnalyticsEventType;
    /**
     * @generated from protobuf field: string partnerId = 3;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string appVersion = 4;
     */
    appVersion: string;
    /**
     * @generated from protobuf field: string sessionId = 5;
     */
    sessionId: string;
    /**
     * @generated from protobuf field: string anonymousUserId = 6;
     */
    anonymousUserId: string;
    /**
     * @generated from protobuf field: string screenName = 7;
     */
    screenName: string;
    /**
     * @generated from protobuf field: string operatingSystem = 8;
     */
    operatingSystem: string;
    /**
     * @generated from protobuf field: string operatingSystemVersion = 9;
     */
    operatingSystemVersion: string;
    /**
     * @generated from protobuf field: map<string, string> payload = 10;
     */
    payload: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: google.protobuf.Timestamp clientTime = 11;
     */
    clientTime?: Timestamp;
    /**
     * @generated from protobuf field: map<string, string> geo = 12;
     */
    geo: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostAnalyticsRequest
 */
export interface PostAnalyticsRequest {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.AnalyticsEvent events = 1;
     */
    events: AnalyticsEvent[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostAnalyticsResponse
 */
export interface PostAnalyticsResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.ReducedEvent
 */
export interface ReducedEvent {
    /**
     * @generated from protobuf field: one.plan3t.core.model.AnalyticsEventType eventType = 1;
     */
    eventType: AnalyticsEventType;
    /**
     * @generated from protobuf field: string screenName = 2;
     */
    screenName: string;
    /**
     * @generated from protobuf field: map<string, string> payload = 3;
     */
    payload: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: google.protobuf.Timestamp serverTime = 4;
     */
    serverTime?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.AnalyticsEvents
 */
export interface AnalyticsEvents {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.AnalyticsDataPoint events = 1;
     */
    events: AnalyticsDataPoint[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.AnalyticsReport
 */
export interface AnalyticsReport {
    /**
     * @generated from protobuf field: map<string, one.plan3t.core.model.AnalyticsEvents> events = 1;
     */
    events: {
        [key: string]: AnalyticsEvents;
    };
    /**
     * @generated from protobuf field: one.plan3t.core.model.AnalyticsEvents summary = 2;
     */
    summary?: AnalyticsEvents;
    /**
     * @generated from protobuf field: int32 TotalEvents = 3 [json_name = "TotalEvents"];
     */
    totalEvents: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.AnalyticsDataPoint
 */
export interface AnalyticsDataPoint {
    /**
     * @generated from protobuf field: int32 value = 1;
     */
    value: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp time = 2;
     */
    time?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetChallengesRequest
 */
export interface GetChallengesRequest {
    /**
     * @generated from protobuf field: repeated string ids = 1;
     */
    ids: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetChallengesResponse
 */
export interface GetChallengesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ChallengePreview challenges = 1;
     */
    challenges: ChallengePreview[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetChallengeResponse
 */
export interface GetChallengeResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.ChallengePreview challenge = 1;
     */
    challenge?: ChallengePreview;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CompensateProject
 */
export interface CompensateProject {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: string category = 4;
     */
    category: string;
    /**
     * @generated from protobuf field: repeated string keyIdeas = 5;
     */
    keyIdeas: string[];
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource image = 6;
     */
    image?: ImageResource;
    /**
     * @generated from protobuf field: int32 pricePerTonCo2 = 7;
     */
    pricePerTonCo2: number;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.UNGoal UNGoals = 8 [json_name = "UNGoals"];
     */
    uNGoals: UNGoal[];
    /**
     * @generated from protobuf field: string cooperationPartnerName = 9;
     */
    cooperationPartnerName: string;
    /**
     * @generated from protobuf field: string cooperationPartnerUrl = 10;
     */
    cooperationPartnerUrl: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource cooperationPartnerLogo = 11;
     */
    cooperationPartnerLogo?: ImageResource;
    /**
     * @generated from protobuf field: string titleShort = 12;
     */
    titleShort: string;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.CompensateStandard standards = 13;
     */
    standards: CompensateStandard[];
    /**
     * @generated from protobuf field: bool active = 14;
     */
    active: boolean;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ImageResource mood_images = 15;
     */
    moodImages: ImageResource[];
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource success_moment_image = 16;
     */
    successMomentImage?: ImageResource;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CompensateStandard
 */
export interface CompensateStandard {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource logo = 3;
     */
    logo?: ImageResource;
    /**
     * @generated from protobuf field: string url = 4;
     */
    url: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PlatformCompensationReport
 */
export interface PlatformCompensationReport {
    /**
     * @generated from protobuf field: int32 totalCompensated = 1;
     */
    totalCompensated: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPlatformCompensationReportRequest
 */
export interface GetPlatformCompensationReportRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPlatformCompensationReportResponse
 */
export interface GetPlatformCompensationReportResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.PlatformCompensationReport report = 1;
     */
    report?: PlatformCompensationReport;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRewardAssetsResponse
 */
export interface GetRewardAssetsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.Asset available = 1;
     */
    available: Asset[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.ConsentLegalText
 */
export interface ConsentLegalText {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string created_by = 3;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ConsentPurpose purpose = 4;
     */
    purpose: ConsentPurpose;
    /**
     * @generated from protobuf field: string markdown = 5;
     */
    markdown: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetLegalTextResponse
 */
export interface GetLegalTextResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.ConsentLegalText legalText = 1;
     */
    legalText?: ConsentLegalText;
}
/**
 * @generated from protobuf message one.plan3t.core.model.NotificationAction
 */
export interface NotificationAction {
    /**
     * @generated from protobuf field: string label = 1;
     */
    label: string;
    /**
     * @generated from protobuf field: string navigation = 2;
     */
    navigation: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.UploadImageResponse
 */
export interface UploadImageResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.UploadedFile upload = 1;
     */
    upload?: UploadedFile;
}
/**
 * @generated from protobuf message one.plan3t.core.model.LotteryTicket
 */
export interface LotteryTicket {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string drawingItemId = 2;
     */
    drawingItemId: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint pointsValue = 3;
     */
    pointsValue?: Plan3TPoint;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp createdAt = 4;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string ownerId = 5;
     */
    ownerId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.LotteryPriceDrawingItem
 */
export interface LotteryPriceDrawingItem {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp starts_at = 2;
     */
    startsAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp ends_at = 3;
     */
    endsAt?: Timestamp;
    /**
     * @generated from protobuf field: string title = 4;
     */
    title: string;
    /**
     * @generated from protobuf field: string description = 5;
     */
    description: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource image = 6;
     */
    image?: ImageResource;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint ticketPrice = 7;
     */
    ticketPrice?: Plan3TPoint;
    /**
     * @generated from protobuf field: string partnerId = 8;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: repeated string winnerTicketIds = 9;
     */
    winnerTicketIds: string[];
    /**
     * @generated from protobuf field: string extraBenefitExplanation = 10;
     */
    extraBenefitExplanation: string;
    /**
     * @generated from protobuf field: string cooperationProjectName = 11;
     */
    cooperationProjectName: string;
    /**
     * @generated from protobuf field: string cooperationProjectUrl = 12;
     */
    cooperationProjectUrl: string;
    /**
     * @generated from protobuf field: string cooperationProjectTagLine = 13;
     */
    cooperationProjectTagLine: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource cooperationProjectLogo = 14;
     */
    cooperationProjectLogo?: ImageResource;
    /**
     * @generated from protobuf field: string terms = 15;
     */
    terms: string;
    /**
     * @generated from protobuf field: repeated string associated_partner_ids = 16;
     */
    associatedPartnerIds: string[];
    /**
     * @generated from protobuf field: string compensate_project_id = 17;
     */
    compensateProjectId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetLotteryPriceDrawingsRequest
 */
export interface GetLotteryPriceDrawingsRequest {
    /**
     * @generated from protobuf field: repeated string ids = 1;
     */
    ids: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetLotteryPriceDrawingsResponse
 */
export interface GetLotteryPriceDrawingsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.LotteryPriceDrawingItem items = 1;
     */
    items: LotteryPriceDrawingItem[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.ChallengeFeaturedTopic
 */
export interface ChallengeFeaturedTopic {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp startsAt = 2;
     */
    startsAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp endsAt = 3;
     */
    endsAt?: Timestamp;
    /**
     * @generated from protobuf field: repeated string challengeIDs = 4;
     */
    challengeIDs: string[];
    /**
     * @generated from protobuf field: string title = 5;
     */
    title: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.CoreIndustry coreIndustry = 6;
     */
    coreIndustry: CoreIndustry;
    /**
     * @generated from protobuf field: string category = 7;
     */
    category: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource backgroundPattern = 8;
     */
    backgroundPattern?: ImageResource;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.BlogReference references = 9;
     */
    references: BlogReference[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.BlogReference
 */
export interface BlogReference {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string url = 2;
     */
    url: string;
    /**
     * @generated from protobuf field: string label = 3;
     */
    label: string;
    /**
     * @generated from protobuf field: string description = 4;
     */
    description: string;
    /**
     * @generated from protobuf field: string title = 5;
     */
    title: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource image = 6;
     */
    image?: ImageResource;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetChallengeFeaturedTopicsRequest
 */
export interface GetChallengeFeaturedTopicsRequest {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp reference = 1;
     */
    reference?: Timestamp;
    /**
     * @generated from protobuf field: repeated string ids = 2;
     */
    ids: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetChallengeFeaturedTopicsResponse
 */
export interface GetChallengeFeaturedTopicsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ChallengeFeaturedTopic topics = 1;
     */
    topics: ChallengeFeaturedTopic[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetBlogReferenceRequest
 */
export interface GetBlogReferenceRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetBlogReferenceResponse
 */
export interface GetBlogReferenceResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.BlogReference reference = 1;
     */
    reference?: BlogReference;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CashbackLogEntry
 */
export interface CashbackLogEntry {
    /**
     * @generated from protobuf field: string transaction_id = 1;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string rule_id = 4;
     */
    ruleId: string;
    /**
     * @generated from protobuf field: string rate_id = 5;
     */
    rateId: string;
    /**
     * @generated from protobuf field: int32 transaction_amount_unscaled = 6;
     */
    transactionAmountUnscaled: number;
    /**
     * @generated from protobuf field: int32 transaction_amount_scale = 7;
     */
    transactionAmountScale: number;
    /**
     * @generated from protobuf field: int32 issued_cashback_unscaled = 8;
     */
    issuedCashbackUnscaled: number;
    /**
     * @generated from protobuf field: int32 issued_cashback_scale = 9;
     */
    issuedCashbackScale: number;
    /**
     * @generated from protobuf field: int32 fee_unscaled = 10;
     */
    feeUnscaled: number;
    /**
     * @generated from protobuf field: int32 fee_scale = 11;
     */
    feeScale: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp transaction_date = 12;
     */
    transactionDate?: Timestamp;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint points = 13;
     */
    points?: Plan3TPoint;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCashbackLogRequest
 */
export interface GetCashbackLogRequest {
    /**
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
    /**
     * @generated from protobuf field: string partner_id = 3;
     */
    partnerId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCashbackLogResponse
 */
export interface GetCashbackLogResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.CashbackLogEntry items = 1;
     */
    items: CashbackLogEntry[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCodeIDsForRewardRequest
 */
export interface GetCodeIDsForRewardRequest {
    /**
     * @generated from protobuf field: string reward_id = 1;
     */
    rewardId: string;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCodeIDsForRewardResponse
 */
export interface GetCodeIDsForRewardResponse {
    /**
     * @generated from protobuf field: repeated string code_ids = 1;
     */
    codeIds: string[];
}
/**
 * @generated from protobuf enum one.plan3t.core.model.ChallengeType
 */
export enum ChallengeType {
    /**
     * @generated from protobuf enum value: ChallengeTypeUnknown = 0;
     */
    ChallengeTypeUnknown = 0,
    /**
     * @generated from protobuf enum value: ChallengeTypeHabit = 1;
     */
    ChallengeTypeHabit = 1,
    /**
     * @generated from protobuf enum value: ChallengeTypeOnce = 2;
     */
    ChallengeTypeOnce = 2,
    /**
     * @generated from protobuf enum value: ChallengeTypeRepeatable = 3;
     */
    ChallengeTypeRepeatable = 3
}
/**
 * @generated from protobuf enum one.plan3t.core.model.CO2SavingPotential
 */
export enum CO2SavingPotential {
    /**
     * @generated from protobuf enum value: CO2SavingPotentialUnknown = 0;
     */
    CO2SavingPotentialUnknown = 0,
    /**
     * @generated from protobuf enum value: CO2SavingPotentialSwitchPower = 1;
     */
    CO2SavingPotentialSwitchPower = 1,
    /**
     * @generated from protobuf enum value: CO2SavingPotentialReducePower = 2;
     */
    CO2SavingPotentialReducePower = 2,
    /**
     * @generated from protobuf enum value: CO2SavingPotentialSwitchHeating = 3;
     */
    CO2SavingPotentialSwitchHeating = 3,
    /**
     * @generated from protobuf enum value: CO2SavingPotentialUseBike = 11;
     */
    CO2SavingPotentialUseBike = 11,
    /**
     * @generated from protobuf enum value: CO2SavingPotentialAvoidContinentalFlights = 12;
     */
    CO2SavingPotentialAvoidContinentalFlights = 12,
    /**
     * @generated from protobuf enum value: CO2SavingPotentialAvoidIntercontinentalFlights = 13;
     */
    CO2SavingPotentialAvoidIntercontinentalFlights = 13,
    /**
     * @generated from protobuf enum value: CO2SavingPotentialAvoidCruises = 14;
     */
    CO2SavingPotentialAvoidCruises = 14,
    /**
     * @generated from protobuf enum value: CO2SavingPotentialReduceHotelStays = 15;
     */
    CO2SavingPotentialReduceHotelStays = 15,
    /**
     * @generated from protobuf enum value: CO2SavingPotentialImproveVeganNutrition = 21;
     */
    CO2SavingPotentialImproveVeganNutrition = 21,
    /**
     * @generated from protobuf enum value: CO2SavingPotentialVeganLife = 22;
     */
    CO2SavingPotentialVeganLife = 22,
    /**
     * @generated from protobuf enum value: CO2SavingPotentialVegetarianLife = 23;
     */
    CO2SavingPotentialVegetarianLife = 23,
    /**
     * @generated from protobuf enum value: CO2SavingPotentialLessMeat = 24;
     */
    CO2SavingPotentialLessMeat = 24,
    /**
     * @generated from protobuf enum value: CO2SavingPotentialLessFish = 25;
     */
    CO2SavingPotentialLessFish = 25
}
/**
 * @generated from protobuf enum one.plan3t.core.model.CoreIndustry
 */
export enum CoreIndustry {
    /**
     * @generated from protobuf enum value: UnknownIndustry = 0;
     */
    UnknownIndustry = 0,
    /**
     * @generated from protobuf enum value: Housing = 1;
     */
    Housing = 1,
    /**
     * @generated from protobuf enum value: Mobility = 2;
     */
    Mobility = 2,
    /**
     * @generated from protobuf enum value: Nutrition = 3;
     */
    Nutrition = 3,
    /**
     * @generated from protobuf enum value: Consumption = 4;
     */
    Consumption = 4
}
/**
 * @generated from protobuf enum one.plan3t.core.model.DraftState
 */
export enum DraftState {
    /**
     * @generated from protobuf enum value: Draft = 0;
     */
    Draft = 0,
    /**
     * @generated from protobuf enum value: Pending = 1;
     */
    Pending = 1,
    /**
     * @generated from protobuf enum value: Rejected = 2;
     */
    Rejected = 2,
    /**
     * @generated from protobuf enum value: Live = 3;
     */
    Live = 3,
    /**
     * @generated from protobuf enum value: Suspended = 4;
     */
    Suspended = 4
}
/**
 * @generated from protobuf enum one.plan3t.core.model.RewardBillingType
 */
export enum RewardBillingType {
    /**
     * @generated from protobuf enum value: REWARD_BILLING_TYPE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: REWARD_BILLING_TYPE_DISCOUNT = 1;
     */
    DISCOUNT = 1,
    /**
     * @generated from protobuf enum value: REWARD_BILLING_TYPE_PAID = 2;
     */
    PAID = 2
}
/**
 * @generated from protobuf enum one.plan3t.core.model.TaxableCodeType
 */
export enum TaxableCodeType {
    /**
     * @generated from protobuf enum value: TAXABLE_CODE_TYPE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: TAXABLE_CODE_TYPE_SINGLE_PURPOSE = 1;
     */
    SINGLE_PURPOSE = 1,
    /**
     * @generated from protobuf enum value: TAXABLE_CODE_TYPE_MULTI_PURPOSE = 2;
     */
    MULTI_PURPOSE = 2,
    /**
     * @generated from protobuf enum value: TAXABLE_CODE_TYPE_SINGLE_PURPOSE_REDUCED_RATE = 3;
     */
    SINGLE_PURPOSE_REDUCED_RATE = 3
}
/**
 * @generated from protobuf enum one.plan3t.core.model.AnalyticsEventType
 */
export enum AnalyticsEventType {
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeUnknown = 0;
     */
    AnalyticsEventTypeUnknown = 0,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeAppOpen = 1;
     */
    AnalyticsEventTypeAppOpen = 1,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypePageView = 2;
     */
    AnalyticsEventTypePageView = 2,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeImpression = 3;
     */
    AnalyticsEventTypeImpression = 3,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeClickOut = 4;
     */
    AnalyticsEventTypeClickOut = 4,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypePartnerContentView = 5;
     */
    AnalyticsEventTypePartnerContentView = 5,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeBeginCheckout = 6;
     */
    AnalyticsEventTypeBeginCheckout = 6,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeStartFeedbackFlow = 7;
     */
    AnalyticsEventTypeStartFeedbackFlow = 7,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeInteraction = 8;
     */
    AnalyticsEventTypeInteraction = 8,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeLogin = 9;
     */
    AnalyticsEventTypeLogin = 9,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeLogout = 10;
     */
    AnalyticsEventTypeLogout = 10,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeCompensationPurchase = 11;
     */
    AnalyticsEventTypeCompensationPurchase = 11,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeChallengeStarted = 12;
     */
    AnalyticsEventTypeChallengeStarted = 12,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeChallengeHabitTracked = 13;
     */
    AnalyticsEventTypeChallengeHabitTracked = 13,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeChallengeHabitClaimed = 14;
     */
    AnalyticsEventTypeChallengeHabitClaimed = 14,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeLotteryTicketBought = 15;
     */
    AnalyticsEventTypeLotteryTicketBought = 15,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeNotificationOpened = 16;
     */
    AnalyticsEventTypeNotificationOpened = 16,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeRewardBought = 17;
     */
    AnalyticsEventTypeRewardBought = 17,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeOpenBankingWaitingListSignup = 18;
     */
    AnalyticsEventTypeOpenBankingWaitingListSignup = 18,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeTipStarted = 19;
     */
    AnalyticsEventTypeTipStarted = 19,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeTipCancelled = 20;
     */
    AnalyticsEventTypeTipCancelled = 20,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeTipSlideInteraction = 21;
     */
    AnalyticsEventTypeTipSlideInteraction = 21,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeTipQuizStarted = 22;
     */
    AnalyticsEventTypeTipQuizStarted = 22,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeTipAnswerSubmitted = 23;
     */
    AnalyticsEventTypeTipAnswerSubmitted = 23,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeCompensateWheelSpin = 24;
     */
    AnalyticsEventTypeCompensateWheelSpin = 24,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeOpenBankingConnected = 25;
     */
    AnalyticsEventTypeOpenBankingConnected = 25,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeOpenBankingRefreshed = 26;
     */
    AnalyticsEventTypeOpenBankingRefreshed = 26,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeOpenBankingDisconnected = 27;
     */
    AnalyticsEventTypeOpenBankingDisconnected = 27,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeDeepLinkOpened = 28;
     */
    AnalyticsEventTypeDeepLinkOpened = 28,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeOpenBankingCategoryChanged = 29;
     */
    AnalyticsEventTypeOpenBankingCategoryChanged = 29,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeDonationCompleted = 30;
     */
    AnalyticsEventTypeDonationCompleted = 30,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeOpenBankingInitialFetchCompleted = 31;
     */
    AnalyticsEventTypeOpenBankingInitialFetchCompleted = 31,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeErrorDialogShown = 32;
     */
    AnalyticsEventTypeErrorDialogShown = 32,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeTiming = 33;
     */
    AnalyticsEventTypeTiming = 33,
    /**
     * @generated from protobuf enum value: AnalyticsEventTypeOpenBankingExternalFlowStarted = 34;
     */
    AnalyticsEventTypeOpenBankingExternalFlowStarted = 34
}
/**
 * @generated from protobuf enum one.plan3t.core.model.UNGoal
 */
export enum UNGoal {
    /**
     * @generated from protobuf enum value: UNGoalUnknown = 0;
     */
    UNGoalUnknown = 0,
    /**
     * @generated from protobuf enum value: UNGoalNoPoverty = 1;
     */
    UNGoalNoPoverty = 1,
    /**
     * @generated from protobuf enum value: UNGoalZeroHunger = 2;
     */
    UNGoalZeroHunger = 2,
    /**
     * @generated from protobuf enum value: UNGoalGoodHealth = 3;
     */
    UNGoalGoodHealth = 3,
    /**
     * @generated from protobuf enum value: UNGoalQualityEducation = 4;
     */
    UNGoalQualityEducation = 4,
    /**
     * @generated from protobuf enum value: UNGoalGenderEquality = 5;
     */
    UNGoalGenderEquality = 5,
    /**
     * @generated from protobuf enum value: UNGoalCleanWater = 6;
     */
    UNGoalCleanWater = 6,
    /**
     * @generated from protobuf enum value: UNGoalCleanEnergy = 7;
     */
    UNGoalCleanEnergy = 7,
    /**
     * @generated from protobuf enum value: UNGoalDecentWork = 8;
     */
    UNGoalDecentWork = 8,
    /**
     * @generated from protobuf enum value: UNGoalIndustry = 9;
     */
    UNGoalIndustry = 9,
    /**
     * @generated from protobuf enum value: UNGoalReducedInequality = 10;
     */
    UNGoalReducedInequality = 10,
    /**
     * @generated from protobuf enum value: UNGoalSustainableCities = 11;
     */
    UNGoalSustainableCities = 11,
    /**
     * @generated from protobuf enum value: UNGoalResponsibleConsumption = 12;
     */
    UNGoalResponsibleConsumption = 12,
    /**
     * @generated from protobuf enum value: UNGoalClimateAction = 13;
     */
    UNGoalClimateAction = 13,
    /**
     * @generated from protobuf enum value: UNGoalLifeBelowWater = 14;
     */
    UNGoalLifeBelowWater = 14,
    /**
     * @generated from protobuf enum value: UNGoalLifeOnLand = 15;
     */
    UNGoalLifeOnLand = 15,
    /**
     * @generated from protobuf enum value: UNGoalPeaceAndJustice = 16;
     */
    UNGoalPeaceAndJustice = 16,
    /**
     * @generated from protobuf enum value: UNGoalPartnerships = 17;
     */
    UNGoalPartnerships = 17
}
/**
 * @generated from protobuf enum one.plan3t.core.model.ConsentPurpose
 */
export enum ConsentPurpose {
    /**
     * @generated from protobuf enum value: ConsentPurposeUnknown = 0;
     */
    ConsentPurposeUnknown = 0,
    /**
     * @generated from protobuf enum value: ConsentPurposeB2CPrivacyPolicy = 1;
     */
    ConsentPurposeB2CPrivacyPolicy = 1,
    /**
     * @generated from protobuf enum value: ConsentPurposeB2CAGB = 2;
     */
    ConsentPurposeB2CAGB = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class Plan3TPoint$Type extends MessageType<Plan3TPoint> {
    constructor() {
        super("one.plan3t.core.model.Plan3TPoint", [
            { no: 1, name: "amount", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Plan3TPoint>): Plan3TPoint {
        const message = { amount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Plan3TPoint>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Plan3TPoint): Plan3TPoint {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 amount */ 1:
                    message.amount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Plan3TPoint, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 amount = 1; */
        if (message.amount !== 0)
            writer.tag(1, WireType.Varint).int32(message.amount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.Plan3TPoint
 */
export const Plan3TPoint = new Plan3TPoint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PartnerProfile$Type extends MessageType<PartnerProfile> {
    constructor() {
        super("one.plan3t.core.model.PartnerProfile", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tagLine", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "coreIndustry", kind: "enum", T: () => ["one.plan3t.core.model.CoreIndustry", CoreIndustry] },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "socialLinks", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "coverImage", kind: "message", T: () => ImageResource },
            { no: 8, name: "logo", kind: "message", T: () => ImageResource },
            { no: 9, name: "mood_images", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageResource },
            { no: 10, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 11, name: "social_links_rich", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PartnerProfile_RichSocialLink }
        ]);
    }
    create(value?: PartialMessage<PartnerProfile>): PartnerProfile {
        const message = { id: "", name: "", tagLine: "", coreIndustry: 0, description: "", socialLinks: [], moodImages: [], socialLinksRich: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PartnerProfile>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PartnerProfile): PartnerProfile {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string tagLine */ 3:
                    message.tagLine = reader.string();
                    break;
                case /* one.plan3t.core.model.CoreIndustry coreIndustry */ 4:
                    message.coreIndustry = reader.int32();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* repeated string socialLinks */ 6:
                    message.socialLinks.push(reader.string());
                    break;
                case /* one.plan3t.core.model.ImageResource coverImage */ 7:
                    message.coverImage = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.coverImage);
                    break;
                case /* one.plan3t.core.model.ImageResource logo */ 8:
                    message.logo = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.logo);
                    break;
                case /* repeated one.plan3t.core.model.ImageResource mood_images */ 9:
                    message.moodImages.push(ImageResource.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp created_at */ 10:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* repeated one.plan3t.core.model.PartnerProfile.RichSocialLink social_links_rich */ 11:
                    message.socialLinksRich.push(PartnerProfile_RichSocialLink.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PartnerProfile, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string tagLine = 3; */
        if (message.tagLine !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tagLine);
        /* one.plan3t.core.model.CoreIndustry coreIndustry = 4; */
        if (message.coreIndustry !== 0)
            writer.tag(4, WireType.Varint).int32(message.coreIndustry);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* repeated string socialLinks = 6; */
        for (let i = 0; i < message.socialLinks.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.socialLinks[i]);
        /* one.plan3t.core.model.ImageResource coverImage = 7; */
        if (message.coverImage)
            ImageResource.internalBinaryWrite(message.coverImage, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.ImageResource logo = 8; */
        if (message.logo)
            ImageResource.internalBinaryWrite(message.logo, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated one.plan3t.core.model.ImageResource mood_images = 9; */
        for (let i = 0; i < message.moodImages.length; i++)
            ImageResource.internalBinaryWrite(message.moodImages[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 10; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* repeated one.plan3t.core.model.PartnerProfile.RichSocialLink social_links_rich = 11; */
        for (let i = 0; i < message.socialLinksRich.length; i++)
            PartnerProfile_RichSocialLink.internalBinaryWrite(message.socialLinksRich[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PartnerProfile
 */
export const PartnerProfile = new PartnerProfile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PartnerProfile_RichSocialLink$Type extends MessageType<PartnerProfile_RichSocialLink> {
    constructor() {
        super("one.plan3t.core.model.PartnerProfile.RichSocialLink", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "kind", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PartnerProfile_RichSocialLink>): PartnerProfile_RichSocialLink {
        const message = { url: "", label: "", kind: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PartnerProfile_RichSocialLink>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PartnerProfile_RichSocialLink): PartnerProfile_RichSocialLink {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                case /* string kind */ 3:
                    message.kind = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PartnerProfile_RichSocialLink, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        /* string kind = 3; */
        if (message.kind !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PartnerProfile.RichSocialLink
 */
export const PartnerProfile_RichSocialLink = new PartnerProfile_RichSocialLink$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPartnerProfilesResponse$Type extends MessageType<GetPartnerProfilesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetPartnerProfilesResponse", [
            { no: 1, name: "partner", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PartnerProfile }
        ]);
    }
    create(value?: PartialMessage<GetPartnerProfilesResponse>): GetPartnerProfilesResponse {
        const message = { partner: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPartnerProfilesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPartnerProfilesResponse): GetPartnerProfilesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.PartnerProfile partner */ 1:
                    message.partner.push(PartnerProfile.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPartnerProfilesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.PartnerProfile partner = 1; */
        for (let i = 0; i < message.partner.length; i++)
            PartnerProfile.internalBinaryWrite(message.partner[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPartnerProfilesResponse
 */
export const GetPartnerProfilesResponse = new GetPartnerProfilesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChallengeEstimatedSavings$Type extends MessageType<ChallengeEstimatedSavings> {
    constructor() {
        super("one.plan3t.core.model.ChallengeEstimatedSavings", [
            { no: 1, name: "absoluteSavingsInKg", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "housing", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "mobility", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "nutrition", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "consumption", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<ChallengeEstimatedSavings>): ChallengeEstimatedSavings {
        const message = { absoluteSavingsInKg: 0, housing: 0, mobility: 0, nutrition: 0, consumption: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChallengeEstimatedSavings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChallengeEstimatedSavings): ChallengeEstimatedSavings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 absoluteSavingsInKg */ 1:
                    message.absoluteSavingsInKg = reader.int32();
                    break;
                case /* double housing */ 2:
                    message.housing = reader.double();
                    break;
                case /* double mobility */ 3:
                    message.mobility = reader.double();
                    break;
                case /* double nutrition */ 4:
                    message.nutrition = reader.double();
                    break;
                case /* double consumption */ 5:
                    message.consumption = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChallengeEstimatedSavings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 absoluteSavingsInKg = 1; */
        if (message.absoluteSavingsInKg !== 0)
            writer.tag(1, WireType.Varint).int32(message.absoluteSavingsInKg);
        /* double housing = 2; */
        if (message.housing !== 0)
            writer.tag(2, WireType.Bit64).double(message.housing);
        /* double mobility = 3; */
        if (message.mobility !== 0)
            writer.tag(3, WireType.Bit64).double(message.mobility);
        /* double nutrition = 4; */
        if (message.nutrition !== 0)
            writer.tag(4, WireType.Bit64).double(message.nutrition);
        /* double consumption = 5; */
        if (message.consumption !== 0)
            writer.tag(5, WireType.Bit64).double(message.consumption);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ChallengeEstimatedSavings
 */
export const ChallengeEstimatedSavings = new ChallengeEstimatedSavings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChallengePreview$Type extends MessageType<ChallengePreview> {
    constructor() {
        super("one.plan3t.core.model.ChallengePreview", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "modified_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "modified_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "published_at", kind: "message", T: () => Timestamp },
            { no: 7, name: "published_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "coreIndustry", kind: "enum", T: () => ["one.plan3t.core.model.CoreIndustry", CoreIndustry] },
            { no: 10, name: "type", kind: "enum", T: () => ["one.plan3t.core.model.ChallengeType", ChallengeType] },
            { no: 11, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "iconName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "keyIdeas", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "image", kind: "message", T: () => ImageResource },
            { no: 16, name: "tagLine", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "award", kind: "message", T: () => Plan3TPoint },
            { no: 18, name: "partnerIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "estimatedSavings", kind: "message", T: () => ChallengeEstimatedSavings },
            { no: 20, name: "recommendedPotentials", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["one.plan3t.core.model.CO2SavingPotential", CO2SavingPotential] },
            { no: 21, name: "requiredPotentials", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["one.plan3t.core.model.CO2SavingPotential", CO2SavingPotential] },
            { no: 22, name: "forbiddenPotentials", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["one.plan3t.core.model.CO2SavingPotential", CO2SavingPotential] },
            { no: 23, name: "active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 24, name: "blogReferenceIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "tipIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ChallengePreview>): ChallengePreview {
        const message = { id: "", createdBy: "", modifiedBy: "", publishedBy: "", name: "", coreIndustry: 0, type: 0, category: "", description: "", iconName: "", keyIdeas: [], tagLine: "", partnerIds: [], recommendedPotentials: [], requiredPotentials: [], forbiddenPotentials: [], active: false, blogReferenceIds: [], tipIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChallengePreview>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChallengePreview): ChallengePreview {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string created_by */ 3:
                    message.createdBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp modified_at */ 4:
                    message.modifiedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.modifiedAt);
                    break;
                case /* string modified_by */ 5:
                    message.modifiedBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp published_at */ 6:
                    message.publishedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.publishedAt);
                    break;
                case /* string published_by */ 7:
                    message.publishedBy = reader.string();
                    break;
                case /* string name */ 8:
                    message.name = reader.string();
                    break;
                case /* one.plan3t.core.model.CoreIndustry coreIndustry */ 9:
                    message.coreIndustry = reader.int32();
                    break;
                case /* one.plan3t.core.model.ChallengeType type */ 10:
                    message.type = reader.int32();
                    break;
                case /* string category */ 11:
                    message.category = reader.string();
                    break;
                case /* string description */ 12:
                    message.description = reader.string();
                    break;
                case /* string iconName */ 13:
                    message.iconName = reader.string();
                    break;
                case /* repeated string keyIdeas */ 14:
                    message.keyIdeas.push(reader.string());
                    break;
                case /* one.plan3t.core.model.ImageResource image */ 15:
                    message.image = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                case /* string tagLine */ 16:
                    message.tagLine = reader.string();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint award */ 17:
                    message.award = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.award);
                    break;
                case /* repeated string partnerIds */ 18:
                    message.partnerIds.push(reader.string());
                    break;
                case /* one.plan3t.core.model.ChallengeEstimatedSavings estimatedSavings */ 19:
                    message.estimatedSavings = ChallengeEstimatedSavings.internalBinaryRead(reader, reader.uint32(), options, message.estimatedSavings);
                    break;
                case /* repeated one.plan3t.core.model.CO2SavingPotential recommendedPotentials */ 20:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.recommendedPotentials.push(reader.int32());
                    else
                        message.recommendedPotentials.push(reader.int32());
                    break;
                case /* repeated one.plan3t.core.model.CO2SavingPotential requiredPotentials */ 21:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.requiredPotentials.push(reader.int32());
                    else
                        message.requiredPotentials.push(reader.int32());
                    break;
                case /* repeated one.plan3t.core.model.CO2SavingPotential forbiddenPotentials */ 22:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.forbiddenPotentials.push(reader.int32());
                    else
                        message.forbiddenPotentials.push(reader.int32());
                    break;
                case /* bool active */ 23:
                    message.active = reader.bool();
                    break;
                case /* repeated string blogReferenceIds */ 24:
                    message.blogReferenceIds.push(reader.string());
                    break;
                case /* repeated string tipIds */ 25:
                    message.tipIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChallengePreview, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string created_by = 3; */
        if (message.createdBy !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.createdBy);
        /* google.protobuf.Timestamp modified_at = 4; */
        if (message.modifiedAt)
            Timestamp.internalBinaryWrite(message.modifiedAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string modified_by = 5; */
        if (message.modifiedBy !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.modifiedBy);
        /* google.protobuf.Timestamp published_at = 6; */
        if (message.publishedAt)
            Timestamp.internalBinaryWrite(message.publishedAt, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string published_by = 7; */
        if (message.publishedBy !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.publishedBy);
        /* string name = 8; */
        if (message.name !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.name);
        /* one.plan3t.core.model.CoreIndustry coreIndustry = 9; */
        if (message.coreIndustry !== 0)
            writer.tag(9, WireType.Varint).int32(message.coreIndustry);
        /* one.plan3t.core.model.ChallengeType type = 10; */
        if (message.type !== 0)
            writer.tag(10, WireType.Varint).int32(message.type);
        /* string category = 11; */
        if (message.category !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.category);
        /* string description = 12; */
        if (message.description !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.description);
        /* string iconName = 13; */
        if (message.iconName !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.iconName);
        /* repeated string keyIdeas = 14; */
        for (let i = 0; i < message.keyIdeas.length; i++)
            writer.tag(14, WireType.LengthDelimited).string(message.keyIdeas[i]);
        /* one.plan3t.core.model.ImageResource image = 15; */
        if (message.image)
            ImageResource.internalBinaryWrite(message.image, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* string tagLine = 16; */
        if (message.tagLine !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.tagLine);
        /* one.plan3t.core.model.Plan3TPoint award = 17; */
        if (message.award)
            Plan3TPoint.internalBinaryWrite(message.award, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* repeated string partnerIds = 18; */
        for (let i = 0; i < message.partnerIds.length; i++)
            writer.tag(18, WireType.LengthDelimited).string(message.partnerIds[i]);
        /* one.plan3t.core.model.ChallengeEstimatedSavings estimatedSavings = 19; */
        if (message.estimatedSavings)
            ChallengeEstimatedSavings.internalBinaryWrite(message.estimatedSavings, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* repeated one.plan3t.core.model.CO2SavingPotential recommendedPotentials = 20; */
        if (message.recommendedPotentials.length) {
            writer.tag(20, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.recommendedPotentials.length; i++)
                writer.int32(message.recommendedPotentials[i]);
            writer.join();
        }
        /* repeated one.plan3t.core.model.CO2SavingPotential requiredPotentials = 21; */
        if (message.requiredPotentials.length) {
            writer.tag(21, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.requiredPotentials.length; i++)
                writer.int32(message.requiredPotentials[i]);
            writer.join();
        }
        /* repeated one.plan3t.core.model.CO2SavingPotential forbiddenPotentials = 22; */
        if (message.forbiddenPotentials.length) {
            writer.tag(22, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.forbiddenPotentials.length; i++)
                writer.int32(message.forbiddenPotentials[i]);
            writer.join();
        }
        /* bool active = 23; */
        if (message.active !== false)
            writer.tag(23, WireType.Varint).bool(message.active);
        /* repeated string blogReferenceIds = 24; */
        for (let i = 0; i < message.blogReferenceIds.length; i++)
            writer.tag(24, WireType.LengthDelimited).string(message.blogReferenceIds[i]);
        /* repeated string tipIds = 25; */
        for (let i = 0; i < message.tipIds.length; i++)
            writer.tag(25, WireType.LengthDelimited).string(message.tipIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ChallengePreview
 */
export const ChallengePreview = new ChallengePreview$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PartnerBase$Type extends MessageType<PartnerBase> {
    constructor() {
        super("one.plan3t.core.model.PartnerBase", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "legalName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "created_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "modified_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "modified_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "brandName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "pipedrive_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "datev_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "affiliate_only", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<PartnerBase>): PartnerBase {
        const message = { id: "", legalName: "", createdBy: "", modifiedBy: "", brandName: "", pipedriveId: "", datevId: "", affiliateOnly: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PartnerBase>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PartnerBase): PartnerBase {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string legalName */ 2:
                    message.legalName = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 3:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string created_by */ 4:
                    message.createdBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp modified_at */ 5:
                    message.modifiedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.modifiedAt);
                    break;
                case /* string modified_by */ 6:
                    message.modifiedBy = reader.string();
                    break;
                case /* string brandName */ 7:
                    message.brandName = reader.string();
                    break;
                case /* string pipedrive_id */ 8:
                    message.pipedriveId = reader.string();
                    break;
                case /* string datev_id */ 9:
                    message.datevId = reader.string();
                    break;
                case /* bool affiliate_only */ 10:
                    message.affiliateOnly = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PartnerBase, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string legalName = 2; */
        if (message.legalName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.legalName);
        /* google.protobuf.Timestamp created_at = 3; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string created_by = 4; */
        if (message.createdBy !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.createdBy);
        /* google.protobuf.Timestamp modified_at = 5; */
        if (message.modifiedAt)
            Timestamp.internalBinaryWrite(message.modifiedAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string modified_by = 6; */
        if (message.modifiedBy !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.modifiedBy);
        /* string brandName = 7; */
        if (message.brandName !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.brandName);
        /* string pipedrive_id = 8; */
        if (message.pipedriveId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.pipedriveId);
        /* string datev_id = 9; */
        if (message.datevId !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.datevId);
        /* bool affiliate_only = 10; */
        if (message.affiliateOnly !== false)
            writer.tag(10, WireType.Varint).bool(message.affiliateOnly);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PartnerBase
 */
export const PartnerBase = new PartnerBase$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PartnerManager$Type extends MessageType<PartnerManager> {
    constructor() {
        super("one.plan3t.core.model.PartnerManager", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "created_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PartnerManager>): PartnerManager {
        const message = { userId: "", partnerId: "", createdBy: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PartnerManager>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PartnerManager): PartnerManager {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 3:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string created_by */ 4:
                    message.createdBy = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PartnerManager, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        /* google.protobuf.Timestamp created_at = 3; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string created_by = 4; */
        if (message.createdBy !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.createdBy);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PartnerManager
 */
export const PartnerManager = new PartnerManager$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserAccount$Type extends MessageType<UserAccount> {
    constructor() {
        super("one.plan3t.core.model.UserAccount", [
            { no: 1, name: "uid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "photoUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "email_verified", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UserAccount>): UserAccount {
        const message = { uid: "", name: "", email: "", photoUrl: "", emailVerified: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserAccount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserAccount): UserAccount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uid */ 1:
                    message.uid = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string email */ 3:
                    message.email = reader.string();
                    break;
                case /* string photoUrl */ 4:
                    message.photoUrl = reader.string();
                    break;
                case /* bool email_verified */ 5:
                    message.emailVerified = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserAccount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uid = 1; */
        if (message.uid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uid);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string email = 3; */
        if (message.email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.email);
        /* string photoUrl = 4; */
        if (message.photoUrl !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.photoUrl);
        /* bool email_verified = 5; */
        if (message.emailVerified !== false)
            writer.tag(5, WireType.Varint).bool(message.emailVerified);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.UserAccount
 */
export const UserAccount = new UserAccount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserAccountRequest$Type extends MessageType<GetUserAccountRequest> {
    constructor() {
        super("one.plan3t.core.model.GetUserAccountRequest", [
            { no: 1, name: "user_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserAccountRequest>): GetUserAccountRequest {
        const message = { userIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetUserAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserAccountRequest): GetUserAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string user_ids */ 1:
                    message.userIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string user_ids = 1; */
        for (let i = 0; i < message.userIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.userIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetUserAccountRequest
 */
export const GetUserAccountRequest = new GetUserAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserAccountResponse$Type extends MessageType<GetUserAccountResponse> {
    constructor() {
        super("one.plan3t.core.model.GetUserAccountResponse", [
            { no: 1, name: "accounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserAccount }
        ]);
    }
    create(value?: PartialMessage<GetUserAccountResponse>): GetUserAccountResponse {
        const message = { accounts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetUserAccountResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserAccountResponse): GetUserAccountResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.UserAccount accounts */ 1:
                    message.accounts.push(UserAccount.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserAccountResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.UserAccount accounts = 1; */
        for (let i = 0; i < message.accounts.length; i++)
            UserAccount.internalBinaryWrite(message.accounts[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetUserAccountResponse
 */
export const GetUserAccountResponse = new GetUserAccountResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageResource$Type extends MessageType<ImageResource> {
    constructor() {
        super("one.plan3t.core.model.ImageResource", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ImageResource>): ImageResource {
        const message = { id: "", url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageResource>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageResource): ImageResource {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string url */ 2:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageResource, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string url = 2; */
        if (message.url !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ImageResource
 */
export const ImageResource = new ImageResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PartnerProfileDraft$Type extends MessageType<PartnerProfileDraft> {
    constructor() {
        super("one.plan3t.core.model.PartnerProfileDraft", [
            { no: 1, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "partner_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tagLine", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "created_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "modified_at", kind: "message", T: () => Timestamp },
            { no: 7, name: "modified_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "state", kind: "enum", T: () => ["one.plan3t.core.model.DraftState", DraftState] },
            { no: 9, name: "coreIndustry", kind: "enum", T: () => ["one.plan3t.core.model.CoreIndustry", CoreIndustry] },
            { no: 10, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "socialLinks", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "coverImage", kind: "message", T: () => ImageResource },
            { no: 13, name: "logo", kind: "message", T: () => ImageResource },
            { no: 14, name: "mood_images", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageResource },
            { no: 15, name: "rejectionReason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "social_links_rich", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PartnerProfileDraft_RichSocialLink }
        ]);
    }
    create(value?: PartialMessage<PartnerProfileDraft>): PartnerProfileDraft {
        const message = { partnerId: "", partnerName: "", tagLine: "", createdBy: "", modifiedBy: "", state: 0, coreIndustry: 0, description: "", socialLinks: [], moodImages: [], rejectionReason: "", socialLinksRich: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PartnerProfileDraft>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PartnerProfileDraft): PartnerProfileDraft {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string partner_id */ 1:
                    message.partnerId = reader.string();
                    break;
                case /* string partner_name */ 2:
                    message.partnerName = reader.string();
                    break;
                case /* string tagLine */ 3:
                    message.tagLine = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 4:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string created_by */ 5:
                    message.createdBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp modified_at */ 6:
                    message.modifiedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.modifiedAt);
                    break;
                case /* string modified_by */ 7:
                    message.modifiedBy = reader.string();
                    break;
                case /* one.plan3t.core.model.DraftState state */ 8:
                    message.state = reader.int32();
                    break;
                case /* one.plan3t.core.model.CoreIndustry coreIndustry */ 9:
                    message.coreIndustry = reader.int32();
                    break;
                case /* string description */ 10:
                    message.description = reader.string();
                    break;
                case /* repeated string socialLinks */ 11:
                    message.socialLinks.push(reader.string());
                    break;
                case /* one.plan3t.core.model.ImageResource coverImage */ 12:
                    message.coverImage = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.coverImage);
                    break;
                case /* one.plan3t.core.model.ImageResource logo */ 13:
                    message.logo = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.logo);
                    break;
                case /* repeated one.plan3t.core.model.ImageResource mood_images */ 14:
                    message.moodImages.push(ImageResource.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string rejectionReason */ 15:
                    message.rejectionReason = reader.string();
                    break;
                case /* repeated one.plan3t.core.model.PartnerProfileDraft.RichSocialLink social_links_rich */ 16:
                    message.socialLinksRich.push(PartnerProfileDraft_RichSocialLink.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PartnerProfileDraft, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string partner_id = 1; */
        if (message.partnerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.partnerId);
        /* string partner_name = 2; */
        if (message.partnerName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerName);
        /* string tagLine = 3; */
        if (message.tagLine !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tagLine);
        /* google.protobuf.Timestamp created_at = 4; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string created_by = 5; */
        if (message.createdBy !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.createdBy);
        /* google.protobuf.Timestamp modified_at = 6; */
        if (message.modifiedAt)
            Timestamp.internalBinaryWrite(message.modifiedAt, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string modified_by = 7; */
        if (message.modifiedBy !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.modifiedBy);
        /* one.plan3t.core.model.DraftState state = 8; */
        if (message.state !== 0)
            writer.tag(8, WireType.Varint).int32(message.state);
        /* one.plan3t.core.model.CoreIndustry coreIndustry = 9; */
        if (message.coreIndustry !== 0)
            writer.tag(9, WireType.Varint).int32(message.coreIndustry);
        /* string description = 10; */
        if (message.description !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.description);
        /* repeated string socialLinks = 11; */
        for (let i = 0; i < message.socialLinks.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.socialLinks[i]);
        /* one.plan3t.core.model.ImageResource coverImage = 12; */
        if (message.coverImage)
            ImageResource.internalBinaryWrite(message.coverImage, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.ImageResource logo = 13; */
        if (message.logo)
            ImageResource.internalBinaryWrite(message.logo, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* repeated one.plan3t.core.model.ImageResource mood_images = 14; */
        for (let i = 0; i < message.moodImages.length; i++)
            ImageResource.internalBinaryWrite(message.moodImages[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* string rejectionReason = 15; */
        if (message.rejectionReason !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.rejectionReason);
        /* repeated one.plan3t.core.model.PartnerProfileDraft.RichSocialLink social_links_rich = 16; */
        for (let i = 0; i < message.socialLinksRich.length; i++)
            PartnerProfileDraft_RichSocialLink.internalBinaryWrite(message.socialLinksRich[i], writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PartnerProfileDraft
 */
export const PartnerProfileDraft = new PartnerProfileDraft$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PartnerProfileDraft_RichSocialLink$Type extends MessageType<PartnerProfileDraft_RichSocialLink> {
    constructor() {
        super("one.plan3t.core.model.PartnerProfileDraft.RichSocialLink", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "kind", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PartnerProfileDraft_RichSocialLink>): PartnerProfileDraft_RichSocialLink {
        const message = { url: "", label: "", kind: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PartnerProfileDraft_RichSocialLink>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PartnerProfileDraft_RichSocialLink): PartnerProfileDraft_RichSocialLink {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                case /* string kind */ 3:
                    message.kind = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PartnerProfileDraft_RichSocialLink, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        /* string kind = 3; */
        if (message.kind !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PartnerProfileDraft.RichSocialLink
 */
export const PartnerProfileDraft_RichSocialLink = new PartnerProfileDraft_RichSocialLink$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadedFile$Type extends MessageType<UploadedFile> {
    constructor() {
        super("one.plan3t.core.model.UploadedFile", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "filename", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UploadedFile>): UploadedFile {
        const message = { id: "", filename: "", path: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UploadedFile>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadedFile): UploadedFile {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string filename */ 2:
                    message.filename = reader.string();
                    break;
                case /* string path */ 3:
                    message.path = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UploadedFile, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string filename = 2; */
        if (message.filename !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.filename);
        /* string path = 3; */
        if (message.path !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.path);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.UploadedFile
 */
export const UploadedFile = new UploadedFile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RewardDraft$Type extends MessageType<RewardDraft> {
    constructor() {
        super("one.plan3t.core.model.RewardDraft", [
            { no: 1, name: "reward_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "created_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "modified_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "modified_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "state", kind: "enum", T: () => ["one.plan3t.core.model.DraftState", DraftState] },
            { no: 8, name: "rejectionReason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "terms", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "directLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "coupon_codes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "images", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageResource },
            { no: 15, name: "valid_until", kind: "message", T: () => Timestamp },
            { no: 16, name: "price", kind: "message", T: () => Plan3TPoint },
            { no: 17, name: "tag_line", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "max_claims", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 19, name: "max_claims_cooldown", kind: "message", T: () => Duration },
            { no: 20, name: "billing_type", kind: "enum", T: () => ["one.plan3t.core.model.RewardBillingType", RewardBillingType, "REWARD_BILLING_TYPE_"] },
            { no: 21, name: "code_price_cents", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 22, name: "taxable_code_type", kind: "enum", T: () => ["one.plan3t.core.model.TaxableCodeType", TaxableCodeType, "TAXABLE_CODE_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<RewardDraft>): RewardDraft {
        const message = { rewardId: "", partnerId: "", createdBy: "", modifiedBy: "", state: 0, rejectionReason: "", title: "", description: "", terms: "", directLink: "", couponCodes: [], images: [], tagLine: "", maxClaims: 0, billingType: 0, codePriceCents: 0, taxableCodeType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RewardDraft>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RewardDraft): RewardDraft {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string reward_id */ 1:
                    message.rewardId = reader.string();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 3:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string created_by */ 4:
                    message.createdBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp modified_at */ 5:
                    message.modifiedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.modifiedAt);
                    break;
                case /* string modified_by */ 6:
                    message.modifiedBy = reader.string();
                    break;
                case /* one.plan3t.core.model.DraftState state */ 7:
                    message.state = reader.int32();
                    break;
                case /* string rejectionReason */ 8:
                    message.rejectionReason = reader.string();
                    break;
                case /* string title */ 9:
                    message.title = reader.string();
                    break;
                case /* string description */ 10:
                    message.description = reader.string();
                    break;
                case /* string terms */ 11:
                    message.terms = reader.string();
                    break;
                case /* string directLink */ 12:
                    message.directLink = reader.string();
                    break;
                case /* repeated string coupon_codes */ 13:
                    message.couponCodes.push(reader.string());
                    break;
                case /* repeated one.plan3t.core.model.ImageResource images */ 14:
                    message.images.push(ImageResource.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp valid_until */ 15:
                    message.validUntil = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.validUntil);
                    break;
                case /* one.plan3t.core.model.Plan3TPoint price */ 16:
                    message.price = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.price);
                    break;
                case /* string tag_line */ 17:
                    message.tagLine = reader.string();
                    break;
                case /* int32 max_claims */ 18:
                    message.maxClaims = reader.int32();
                    break;
                case /* google.protobuf.Duration max_claims_cooldown */ 19:
                    message.maxClaimsCooldown = Duration.internalBinaryRead(reader, reader.uint32(), options, message.maxClaimsCooldown);
                    break;
                case /* one.plan3t.core.model.RewardBillingType billing_type */ 20:
                    message.billingType = reader.int32();
                    break;
                case /* int32 code_price_cents */ 21:
                    message.codePriceCents = reader.int32();
                    break;
                case /* one.plan3t.core.model.TaxableCodeType taxable_code_type */ 22:
                    message.taxableCodeType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RewardDraft, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string reward_id = 1; */
        if (message.rewardId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.rewardId);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        /* google.protobuf.Timestamp created_at = 3; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string created_by = 4; */
        if (message.createdBy !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.createdBy);
        /* google.protobuf.Timestamp modified_at = 5; */
        if (message.modifiedAt)
            Timestamp.internalBinaryWrite(message.modifiedAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string modified_by = 6; */
        if (message.modifiedBy !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.modifiedBy);
        /* one.plan3t.core.model.DraftState state = 7; */
        if (message.state !== 0)
            writer.tag(7, WireType.Varint).int32(message.state);
        /* string rejectionReason = 8; */
        if (message.rejectionReason !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.rejectionReason);
        /* string title = 9; */
        if (message.title !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.title);
        /* string description = 10; */
        if (message.description !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.description);
        /* string terms = 11; */
        if (message.terms !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.terms);
        /* string directLink = 12; */
        if (message.directLink !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.directLink);
        /* repeated string coupon_codes = 13; */
        for (let i = 0; i < message.couponCodes.length; i++)
            writer.tag(13, WireType.LengthDelimited).string(message.couponCodes[i]);
        /* repeated one.plan3t.core.model.ImageResource images = 14; */
        for (let i = 0; i < message.images.length; i++)
            ImageResource.internalBinaryWrite(message.images[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp valid_until = 15; */
        if (message.validUntil)
            Timestamp.internalBinaryWrite(message.validUntil, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.Plan3TPoint price = 16; */
        if (message.price)
            Plan3TPoint.internalBinaryWrite(message.price, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* string tag_line = 17; */
        if (message.tagLine !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.tagLine);
        /* int32 max_claims = 18; */
        if (message.maxClaims !== 0)
            writer.tag(18, WireType.Varint).int32(message.maxClaims);
        /* google.protobuf.Duration max_claims_cooldown = 19; */
        if (message.maxClaimsCooldown)
            Duration.internalBinaryWrite(message.maxClaimsCooldown, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.RewardBillingType billing_type = 20; */
        if (message.billingType !== 0)
            writer.tag(20, WireType.Varint).int32(message.billingType);
        /* int32 code_price_cents = 21; */
        if (message.codePriceCents !== 0)
            writer.tag(21, WireType.Varint).int32(message.codePriceCents);
        /* one.plan3t.core.model.TaxableCodeType taxable_code_type = 22; */
        if (message.taxableCodeType !== 0)
            writer.tag(22, WireType.Varint).int32(message.taxableCodeType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RewardDraft
 */
export const RewardDraft = new RewardDraft$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Reward$Type extends MessageType<Reward> {
    constructor() {
        super("one.plan3t.core.model.Reward", [
            { no: 1, name: "reward_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "terms", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "directLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "images", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageResource },
            { no: 8, name: "valid_until", kind: "message", T: () => Timestamp },
            { no: 9, name: "price", kind: "message", T: () => Plan3TPoint },
            { no: 10, name: "tag_line", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "max_claims", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "max_claims_cooldown", kind: "message", T: () => Duration },
            { no: 13, name: "billing_type", kind: "enum", T: () => ["one.plan3t.core.model.RewardBillingType", RewardBillingType, "REWARD_BILLING_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<Reward>): Reward {
        const message = { rewardId: "", partnerId: "", title: "", description: "", terms: "", directLink: "", images: [], tagLine: "", maxClaims: 0, billingType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Reward>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Reward): Reward {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string reward_id */ 1:
                    message.rewardId = reader.string();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                case /* string title */ 3:
                    message.title = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* string terms */ 5:
                    message.terms = reader.string();
                    break;
                case /* string directLink */ 6:
                    message.directLink = reader.string();
                    break;
                case /* repeated one.plan3t.core.model.ImageResource images */ 7:
                    message.images.push(ImageResource.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp valid_until */ 8:
                    message.validUntil = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.validUntil);
                    break;
                case /* one.plan3t.core.model.Plan3TPoint price */ 9:
                    message.price = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.price);
                    break;
                case /* string tag_line */ 10:
                    message.tagLine = reader.string();
                    break;
                case /* int32 max_claims */ 11:
                    message.maxClaims = reader.int32();
                    break;
                case /* google.protobuf.Duration max_claims_cooldown */ 12:
                    message.maxClaimsCooldown = Duration.internalBinaryRead(reader, reader.uint32(), options, message.maxClaimsCooldown);
                    break;
                case /* one.plan3t.core.model.RewardBillingType billing_type */ 13:
                    message.billingType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Reward, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string reward_id = 1; */
        if (message.rewardId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.rewardId);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        /* string title = 3; */
        if (message.title !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.title);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* string terms = 5; */
        if (message.terms !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.terms);
        /* string directLink = 6; */
        if (message.directLink !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.directLink);
        /* repeated one.plan3t.core.model.ImageResource images = 7; */
        for (let i = 0; i < message.images.length; i++)
            ImageResource.internalBinaryWrite(message.images[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp valid_until = 8; */
        if (message.validUntil)
            Timestamp.internalBinaryWrite(message.validUntil, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.Plan3TPoint price = 9; */
        if (message.price)
            Plan3TPoint.internalBinaryWrite(message.price, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string tag_line = 10; */
        if (message.tagLine !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.tagLine);
        /* int32 max_claims = 11; */
        if (message.maxClaims !== 0)
            writer.tag(11, WireType.Varint).int32(message.maxClaims);
        /* google.protobuf.Duration max_claims_cooldown = 12; */
        if (message.maxClaimsCooldown)
            Duration.internalBinaryWrite(message.maxClaimsCooldown, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.RewardBillingType billing_type = 13; */
        if (message.billingType !== 0)
            writer.tag(13, WireType.Varint).int32(message.billingType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.Reward
 */
export const Reward = new Reward$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Asset$Type extends MessageType<Asset> {
    constructor() {
        super("one.plan3t.core.model.Asset", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "rewardID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "pointsValue", kind: "message", T: () => Plan3TPoint },
            { no: 4, name: "acquired_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<Asset>): Asset {
        const message = { code: "", rewardID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Asset>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Asset): Asset {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                case /* string rewardID */ 2:
                    message.rewardID = reader.string();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint pointsValue */ 3:
                    message.pointsValue = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.pointsValue);
                    break;
                case /* google.protobuf.Timestamp acquired_at */ 4:
                    message.acquiredAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.acquiredAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Asset, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        /* string rewardID = 2; */
        if (message.rewardID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.rewardID);
        /* one.plan3t.core.model.Plan3TPoint pointsValue = 3; */
        if (message.pointsValue)
            Plan3TPoint.internalBinaryWrite(message.pointsValue, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp acquired_at = 4; */
        if (message.acquiredAt)
            Timestamp.internalBinaryWrite(message.acquiredAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.Asset
 */
export const Asset = new Asset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Balance$Type extends MessageType<Balance> {
    constructor() {
        super("one.plan3t.core.model.Balance", [
            { no: 1, name: "owner", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "points", kind: "message", T: () => Plan3TPoint },
            { no: 3, name: "assets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Asset },
            { no: 4, name: "lastEventId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "changedAt", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<Balance>): Balance {
        const message = { owner: "", assets: [], lastEventId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Balance>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Balance): Balance {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string owner */ 1:
                    message.owner = reader.string();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint points */ 2:
                    message.points = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.points);
                    break;
                case /* repeated one.plan3t.core.model.Asset assets */ 3:
                    message.assets.push(Asset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string lastEventId */ 4:
                    message.lastEventId = reader.string();
                    break;
                case /* google.protobuf.Timestamp changedAt */ 5:
                    message.changedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.changedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Balance, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string owner = 1; */
        if (message.owner !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.owner);
        /* one.plan3t.core.model.Plan3TPoint points = 2; */
        if (message.points)
            Plan3TPoint.internalBinaryWrite(message.points, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated one.plan3t.core.model.Asset assets = 3; */
        for (let i = 0; i < message.assets.length; i++)
            Asset.internalBinaryWrite(message.assets[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string lastEventId = 4; */
        if (message.lastEventId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.lastEventId);
        /* google.protobuf.Timestamp changedAt = 5; */
        if (message.changedAt)
            Timestamp.internalBinaryWrite(message.changedAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.Balance
 */
export const Balance = new Balance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRewardLiveRequest$Type extends MessageType<GetRewardLiveRequest> {
    constructor() {
        super("one.plan3t.core.model.GetRewardLiveRequest", [
            { no: 1, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetRewardLiveRequest>): GetRewardLiveRequest {
        const message = { partnerId: "", ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRewardLiveRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRewardLiveRequest): GetRewardLiveRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string partner_id */ 1:
                    message.partnerId = reader.string();
                    break;
                case /* repeated string ids */ 2:
                    message.ids.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRewardLiveRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string partner_id = 1; */
        if (message.partnerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.partnerId);
        /* repeated string ids = 2; */
        for (let i = 0; i < message.ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.ids[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRewardLiveRequest
 */
export const GetRewardLiveRequest = new GetRewardLiveRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRewardLiveResponse$Type extends MessageType<GetRewardLiveResponse> {
    constructor() {
        super("one.plan3t.core.model.GetRewardLiveResponse", [
            { no: 1, name: "rewards", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Reward }
        ]);
    }
    create(value?: PartialMessage<GetRewardLiveResponse>): GetRewardLiveResponse {
        const message = { rewards: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRewardLiveResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRewardLiveResponse): GetRewardLiveResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.Reward rewards */ 1:
                    message.rewards.push(Reward.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRewardLiveResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.Reward rewards = 1; */
        for (let i = 0; i < message.rewards.length; i++)
            Reward.internalBinaryWrite(message.rewards[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRewardLiveResponse
 */
export const GetRewardLiveResponse = new GetRewardLiveResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRewardResponse$Type extends MessageType<GetRewardResponse> {
    constructor() {
        super("one.plan3t.core.model.GetRewardResponse", [
            { no: 1, name: "reward", kind: "message", T: () => Reward }
        ]);
    }
    create(value?: PartialMessage<GetRewardResponse>): GetRewardResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRewardResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRewardResponse): GetRewardResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.Reward reward */ 1:
                    message.reward = Reward.internalBinaryRead(reader, reader.uint32(), options, message.reward);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRewardResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.Reward reward = 1; */
        if (message.reward)
            Reward.internalBinaryWrite(message.reward, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRewardResponse
 */
export const GetRewardResponse = new GetRewardResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ErrorResponse$Type extends MessageType<ErrorResponse> {
    constructor() {
        super("one.plan3t.core.model.ErrorResponse", [
            { no: 1, name: "errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ErrorResponse>): ErrorResponse {
        const message = { errors: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ErrorResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ErrorResponse): ErrorResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string errors */ 1:
                    message.errors.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ErrorResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string errors = 1; */
        for (let i = 0; i < message.errors.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.errors[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ErrorResponse
 */
export const ErrorResponse = new ErrorResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnalyticsEvent$Type extends MessageType<AnalyticsEvent> {
    constructor() {
        super("one.plan3t.core.model.AnalyticsEvent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "eventType", kind: "enum", T: () => ["one.plan3t.core.model.AnalyticsEventType", AnalyticsEventType] },
            { no: 3, name: "partnerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "appVersion", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "sessionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "anonymousUserId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "screenName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "operatingSystem", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "operatingSystemVersion", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "payload", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 11, name: "clientTime", kind: "message", T: () => Timestamp },
            { no: 12, name: "geo", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<AnalyticsEvent>): AnalyticsEvent {
        const message = { id: "", eventType: 0, partnerId: "", appVersion: "", sessionId: "", anonymousUserId: "", screenName: "", operatingSystem: "", operatingSystemVersion: "", payload: {}, geo: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AnalyticsEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AnalyticsEvent): AnalyticsEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* one.plan3t.core.model.AnalyticsEventType eventType */ 2:
                    message.eventType = reader.int32();
                    break;
                case /* string partnerId */ 3:
                    message.partnerId = reader.string();
                    break;
                case /* string appVersion */ 4:
                    message.appVersion = reader.string();
                    break;
                case /* string sessionId */ 5:
                    message.sessionId = reader.string();
                    break;
                case /* string anonymousUserId */ 6:
                    message.anonymousUserId = reader.string();
                    break;
                case /* string screenName */ 7:
                    message.screenName = reader.string();
                    break;
                case /* string operatingSystem */ 8:
                    message.operatingSystem = reader.string();
                    break;
                case /* string operatingSystemVersion */ 9:
                    message.operatingSystemVersion = reader.string();
                    break;
                case /* map<string, string> payload */ 10:
                    this.binaryReadMap10(message.payload, reader, options);
                    break;
                case /* google.protobuf.Timestamp clientTime */ 11:
                    message.clientTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.clientTime);
                    break;
                case /* map<string, string> geo */ 12:
                    this.binaryReadMap12(message.geo, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap10(map: AnalyticsEvent["payload"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof AnalyticsEvent["payload"] | undefined, val: AnalyticsEvent["payload"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.AnalyticsEvent.payload");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    private binaryReadMap12(map: AnalyticsEvent["geo"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof AnalyticsEvent["geo"] | undefined, val: AnalyticsEvent["geo"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.AnalyticsEvent.geo");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: AnalyticsEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* one.plan3t.core.model.AnalyticsEventType eventType = 2; */
        if (message.eventType !== 0)
            writer.tag(2, WireType.Varint).int32(message.eventType);
        /* string partnerId = 3; */
        if (message.partnerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.partnerId);
        /* string appVersion = 4; */
        if (message.appVersion !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.appVersion);
        /* string sessionId = 5; */
        if (message.sessionId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.sessionId);
        /* string anonymousUserId = 6; */
        if (message.anonymousUserId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.anonymousUserId);
        /* string screenName = 7; */
        if (message.screenName !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.screenName);
        /* string operatingSystem = 8; */
        if (message.operatingSystem !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.operatingSystem);
        /* string operatingSystemVersion = 9; */
        if (message.operatingSystemVersion !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.operatingSystemVersion);
        /* map<string, string> payload = 10; */
        for (let k of Object.keys(message.payload))
            writer.tag(10, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.payload[k]).join();
        /* google.protobuf.Timestamp clientTime = 11; */
        if (message.clientTime)
            Timestamp.internalBinaryWrite(message.clientTime, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* map<string, string> geo = 12; */
        for (let k of Object.keys(message.geo))
            writer.tag(12, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.geo[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AnalyticsEvent
 */
export const AnalyticsEvent = new AnalyticsEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostAnalyticsRequest$Type extends MessageType<PostAnalyticsRequest> {
    constructor() {
        super("one.plan3t.core.model.PostAnalyticsRequest", [
            { no: 1, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AnalyticsEvent }
        ]);
    }
    create(value?: PartialMessage<PostAnalyticsRequest>): PostAnalyticsRequest {
        const message = { events: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostAnalyticsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostAnalyticsRequest): PostAnalyticsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.AnalyticsEvent events */ 1:
                    message.events.push(AnalyticsEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostAnalyticsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.AnalyticsEvent events = 1; */
        for (let i = 0; i < message.events.length; i++)
            AnalyticsEvent.internalBinaryWrite(message.events[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostAnalyticsRequest
 */
export const PostAnalyticsRequest = new PostAnalyticsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostAnalyticsResponse$Type extends MessageType<PostAnalyticsResponse> {
    constructor() {
        super("one.plan3t.core.model.PostAnalyticsResponse", []);
    }
    create(value?: PartialMessage<PostAnalyticsResponse>): PostAnalyticsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostAnalyticsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostAnalyticsResponse): PostAnalyticsResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PostAnalyticsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostAnalyticsResponse
 */
export const PostAnalyticsResponse = new PostAnalyticsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReducedEvent$Type extends MessageType<ReducedEvent> {
    constructor() {
        super("one.plan3t.core.model.ReducedEvent", [
            { no: 1, name: "eventType", kind: "enum", T: () => ["one.plan3t.core.model.AnalyticsEventType", AnalyticsEventType] },
            { no: 2, name: "screenName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "payload", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 4, name: "serverTime", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<ReducedEvent>): ReducedEvent {
        const message = { eventType: 0, screenName: "", payload: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReducedEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReducedEvent): ReducedEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.AnalyticsEventType eventType */ 1:
                    message.eventType = reader.int32();
                    break;
                case /* string screenName */ 2:
                    message.screenName = reader.string();
                    break;
                case /* map<string, string> payload */ 3:
                    this.binaryReadMap3(message.payload, reader, options);
                    break;
                case /* google.protobuf.Timestamp serverTime */ 4:
                    message.serverTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.serverTime);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: ReducedEvent["payload"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ReducedEvent["payload"] | undefined, val: ReducedEvent["payload"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.ReducedEvent.payload");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: ReducedEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.AnalyticsEventType eventType = 1; */
        if (message.eventType !== 0)
            writer.tag(1, WireType.Varint).int32(message.eventType);
        /* string screenName = 2; */
        if (message.screenName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.screenName);
        /* map<string, string> payload = 3; */
        for (let k of Object.keys(message.payload))
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.payload[k]).join();
        /* google.protobuf.Timestamp serverTime = 4; */
        if (message.serverTime)
            Timestamp.internalBinaryWrite(message.serverTime, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ReducedEvent
 */
export const ReducedEvent = new ReducedEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnalyticsEvents$Type extends MessageType<AnalyticsEvents> {
    constructor() {
        super("one.plan3t.core.model.AnalyticsEvents", [
            { no: 1, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AnalyticsDataPoint }
        ]);
    }
    create(value?: PartialMessage<AnalyticsEvents>): AnalyticsEvents {
        const message = { events: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AnalyticsEvents>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AnalyticsEvents): AnalyticsEvents {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.AnalyticsDataPoint events */ 1:
                    message.events.push(AnalyticsDataPoint.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AnalyticsEvents, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.AnalyticsDataPoint events = 1; */
        for (let i = 0; i < message.events.length; i++)
            AnalyticsDataPoint.internalBinaryWrite(message.events[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AnalyticsEvents
 */
export const AnalyticsEvents = new AnalyticsEvents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnalyticsReport$Type extends MessageType<AnalyticsReport> {
    constructor() {
        super("one.plan3t.core.model.AnalyticsReport", [
            { no: 1, name: "events", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => AnalyticsEvents } },
            { no: 2, name: "summary", kind: "message", T: () => AnalyticsEvents },
            { no: 3, name: "TotalEvents", kind: "scalar", jsonName: "TotalEvents", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<AnalyticsReport>): AnalyticsReport {
        const message = { events: {}, totalEvents: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AnalyticsReport>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AnalyticsReport): AnalyticsReport {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, one.plan3t.core.model.AnalyticsEvents> events */ 1:
                    this.binaryReadMap1(message.events, reader, options);
                    break;
                case /* one.plan3t.core.model.AnalyticsEvents summary */ 2:
                    message.summary = AnalyticsEvents.internalBinaryRead(reader, reader.uint32(), options, message.summary);
                    break;
                case /* int32 TotalEvents = 3 [json_name = "TotalEvents"];*/ 3:
                    message.totalEvents = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: AnalyticsReport["events"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof AnalyticsReport["events"] | undefined, val: AnalyticsReport["events"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = AnalyticsEvents.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.AnalyticsReport.events");
            }
        }
        map[key ?? ""] = val ?? AnalyticsEvents.create();
    }
    internalBinaryWrite(message: AnalyticsReport, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, one.plan3t.core.model.AnalyticsEvents> events = 1; */
        for (let k of Object.keys(message.events)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            AnalyticsEvents.internalBinaryWrite(message.events[k], writer, options);
            writer.join().join();
        }
        /* one.plan3t.core.model.AnalyticsEvents summary = 2; */
        if (message.summary)
            AnalyticsEvents.internalBinaryWrite(message.summary, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 TotalEvents = 3 [json_name = "TotalEvents"]; */
        if (message.totalEvents !== 0)
            writer.tag(3, WireType.Varint).int32(message.totalEvents);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AnalyticsReport
 */
export const AnalyticsReport = new AnalyticsReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnalyticsDataPoint$Type extends MessageType<AnalyticsDataPoint> {
    constructor() {
        super("one.plan3t.core.model.AnalyticsDataPoint", [
            { no: 1, name: "value", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "time", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<AnalyticsDataPoint>): AnalyticsDataPoint {
        const message = { value: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AnalyticsDataPoint>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AnalyticsDataPoint): AnalyticsDataPoint {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 value */ 1:
                    message.value = reader.int32();
                    break;
                case /* google.protobuf.Timestamp time */ 2:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AnalyticsDataPoint, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 value = 1; */
        if (message.value !== 0)
            writer.tag(1, WireType.Varint).int32(message.value);
        /* google.protobuf.Timestamp time = 2; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AnalyticsDataPoint
 */
export const AnalyticsDataPoint = new AnalyticsDataPoint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChallengesRequest$Type extends MessageType<GetChallengesRequest> {
    constructor() {
        super("one.plan3t.core.model.GetChallengesRequest", [
            { no: 1, name: "ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetChallengesRequest>): GetChallengesRequest {
        const message = { ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetChallengesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetChallengesRequest): GetChallengesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string ids */ 1:
                    message.ids.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetChallengesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string ids = 1; */
        for (let i = 0; i < message.ids.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.ids[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetChallengesRequest
 */
export const GetChallengesRequest = new GetChallengesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChallengesResponse$Type extends MessageType<GetChallengesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetChallengesResponse", [
            { no: 1, name: "challenges", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChallengePreview }
        ]);
    }
    create(value?: PartialMessage<GetChallengesResponse>): GetChallengesResponse {
        const message = { challenges: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetChallengesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetChallengesResponse): GetChallengesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.ChallengePreview challenges */ 1:
                    message.challenges.push(ChallengePreview.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetChallengesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.ChallengePreview challenges = 1; */
        for (let i = 0; i < message.challenges.length; i++)
            ChallengePreview.internalBinaryWrite(message.challenges[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetChallengesResponse
 */
export const GetChallengesResponse = new GetChallengesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChallengeResponse$Type extends MessageType<GetChallengeResponse> {
    constructor() {
        super("one.plan3t.core.model.GetChallengeResponse", [
            { no: 1, name: "challenge", kind: "message", T: () => ChallengePreview }
        ]);
    }
    create(value?: PartialMessage<GetChallengeResponse>): GetChallengeResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetChallengeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetChallengeResponse): GetChallengeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.ChallengePreview challenge */ 1:
                    message.challenge = ChallengePreview.internalBinaryRead(reader, reader.uint32(), options, message.challenge);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetChallengeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.ChallengePreview challenge = 1; */
        if (message.challenge)
            ChallengePreview.internalBinaryWrite(message.challenge, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetChallengeResponse
 */
export const GetChallengeResponse = new GetChallengeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompensateProject$Type extends MessageType<CompensateProject> {
    constructor() {
        super("one.plan3t.core.model.CompensateProject", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "keyIdeas", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "image", kind: "message", T: () => ImageResource },
            { no: 7, name: "pricePerTonCo2", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "UNGoals", kind: "enum", jsonName: "UNGoals", repeat: 1 /*RepeatType.PACKED*/, T: () => ["one.plan3t.core.model.UNGoal", UNGoal] },
            { no: 9, name: "cooperationPartnerName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "cooperationPartnerUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "cooperationPartnerLogo", kind: "message", T: () => ImageResource },
            { no: 12, name: "titleShort", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "standards", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CompensateStandard },
            { no: 14, name: "active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "mood_images", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageResource },
            { no: 16, name: "success_moment_image", kind: "message", T: () => ImageResource }
        ]);
    }
    create(value?: PartialMessage<CompensateProject>): CompensateProject {
        const message = { id: "", title: "", description: "", category: "", keyIdeas: [], pricePerTonCo2: 0, uNGoals: [], cooperationPartnerName: "", cooperationPartnerUrl: "", titleShort: "", standards: [], active: false, moodImages: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompensateProject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompensateProject): CompensateProject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string category */ 4:
                    message.category = reader.string();
                    break;
                case /* repeated string keyIdeas */ 5:
                    message.keyIdeas.push(reader.string());
                    break;
                case /* one.plan3t.core.model.ImageResource image */ 6:
                    message.image = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                case /* int32 pricePerTonCo2 */ 7:
                    message.pricePerTonCo2 = reader.int32();
                    break;
                case /* repeated one.plan3t.core.model.UNGoal UNGoals = 8 [json_name = "UNGoals"];*/ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uNGoals.push(reader.int32());
                    else
                        message.uNGoals.push(reader.int32());
                    break;
                case /* string cooperationPartnerName */ 9:
                    message.cooperationPartnerName = reader.string();
                    break;
                case /* string cooperationPartnerUrl */ 10:
                    message.cooperationPartnerUrl = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource cooperationPartnerLogo */ 11:
                    message.cooperationPartnerLogo = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.cooperationPartnerLogo);
                    break;
                case /* string titleShort */ 12:
                    message.titleShort = reader.string();
                    break;
                case /* repeated one.plan3t.core.model.CompensateStandard standards */ 13:
                    message.standards.push(CompensateStandard.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool active */ 14:
                    message.active = reader.bool();
                    break;
                case /* repeated one.plan3t.core.model.ImageResource mood_images */ 15:
                    message.moodImages.push(ImageResource.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* one.plan3t.core.model.ImageResource success_moment_image */ 16:
                    message.successMomentImage = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.successMomentImage);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompensateProject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string category = 4; */
        if (message.category !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.category);
        /* repeated string keyIdeas = 5; */
        for (let i = 0; i < message.keyIdeas.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.keyIdeas[i]);
        /* one.plan3t.core.model.ImageResource image = 6; */
        if (message.image)
            ImageResource.internalBinaryWrite(message.image, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* int32 pricePerTonCo2 = 7; */
        if (message.pricePerTonCo2 !== 0)
            writer.tag(7, WireType.Varint).int32(message.pricePerTonCo2);
        /* repeated one.plan3t.core.model.UNGoal UNGoals = 8 [json_name = "UNGoals"]; */
        if (message.uNGoals.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uNGoals.length; i++)
                writer.int32(message.uNGoals[i]);
            writer.join();
        }
        /* string cooperationPartnerName = 9; */
        if (message.cooperationPartnerName !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.cooperationPartnerName);
        /* string cooperationPartnerUrl = 10; */
        if (message.cooperationPartnerUrl !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.cooperationPartnerUrl);
        /* one.plan3t.core.model.ImageResource cooperationPartnerLogo = 11; */
        if (message.cooperationPartnerLogo)
            ImageResource.internalBinaryWrite(message.cooperationPartnerLogo, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* string titleShort = 12; */
        if (message.titleShort !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.titleShort);
        /* repeated one.plan3t.core.model.CompensateStandard standards = 13; */
        for (let i = 0; i < message.standards.length; i++)
            CompensateStandard.internalBinaryWrite(message.standards[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* bool active = 14; */
        if (message.active !== false)
            writer.tag(14, WireType.Varint).bool(message.active);
        /* repeated one.plan3t.core.model.ImageResource mood_images = 15; */
        for (let i = 0; i < message.moodImages.length; i++)
            ImageResource.internalBinaryWrite(message.moodImages[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.ImageResource success_moment_image = 16; */
        if (message.successMomentImage)
            ImageResource.internalBinaryWrite(message.successMomentImage, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CompensateProject
 */
export const CompensateProject = new CompensateProject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompensateStandard$Type extends MessageType<CompensateStandard> {
    constructor() {
        super("one.plan3t.core.model.CompensateStandard", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "logo", kind: "message", T: () => ImageResource },
            { no: 4, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompensateStandard>): CompensateStandard {
        const message = { id: "", name: "", url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompensateStandard>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompensateStandard): CompensateStandard {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource logo */ 3:
                    message.logo = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.logo);
                    break;
                case /* string url */ 4:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompensateStandard, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* one.plan3t.core.model.ImageResource logo = 3; */
        if (message.logo)
            ImageResource.internalBinaryWrite(message.logo, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string url = 4; */
        if (message.url !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CompensateStandard
 */
export const CompensateStandard = new CompensateStandard$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlatformCompensationReport$Type extends MessageType<PlatformCompensationReport> {
    constructor() {
        super("one.plan3t.core.model.PlatformCompensationReport", [
            { no: 1, name: "totalCompensated", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PlatformCompensationReport>): PlatformCompensationReport {
        const message = { totalCompensated: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PlatformCompensationReport>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlatformCompensationReport): PlatformCompensationReport {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 totalCompensated */ 1:
                    message.totalCompensated = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlatformCompensationReport, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 totalCompensated = 1; */
        if (message.totalCompensated !== 0)
            writer.tag(1, WireType.Varint).int32(message.totalCompensated);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PlatformCompensationReport
 */
export const PlatformCompensationReport = new PlatformCompensationReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPlatformCompensationReportRequest$Type extends MessageType<GetPlatformCompensationReportRequest> {
    constructor() {
        super("one.plan3t.core.model.GetPlatformCompensationReportRequest", []);
    }
    create(value?: PartialMessage<GetPlatformCompensationReportRequest>): GetPlatformCompensationReportRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPlatformCompensationReportRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPlatformCompensationReportRequest): GetPlatformCompensationReportRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetPlatformCompensationReportRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPlatformCompensationReportRequest
 */
export const GetPlatformCompensationReportRequest = new GetPlatformCompensationReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPlatformCompensationReportResponse$Type extends MessageType<GetPlatformCompensationReportResponse> {
    constructor() {
        super("one.plan3t.core.model.GetPlatformCompensationReportResponse", [
            { no: 1, name: "report", kind: "message", T: () => PlatformCompensationReport }
        ]);
    }
    create(value?: PartialMessage<GetPlatformCompensationReportResponse>): GetPlatformCompensationReportResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPlatformCompensationReportResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPlatformCompensationReportResponse): GetPlatformCompensationReportResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.PlatformCompensationReport report */ 1:
                    message.report = PlatformCompensationReport.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPlatformCompensationReportResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.PlatformCompensationReport report = 1; */
        if (message.report)
            PlatformCompensationReport.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPlatformCompensationReportResponse
 */
export const GetPlatformCompensationReportResponse = new GetPlatformCompensationReportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRewardAssetsResponse$Type extends MessageType<GetRewardAssetsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetRewardAssetsResponse", [
            { no: 1, name: "available", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Asset }
        ]);
    }
    create(value?: PartialMessage<GetRewardAssetsResponse>): GetRewardAssetsResponse {
        const message = { available: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRewardAssetsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRewardAssetsResponse): GetRewardAssetsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.Asset available */ 1:
                    message.available.push(Asset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRewardAssetsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.Asset available = 1; */
        for (let i = 0; i < message.available.length; i++)
            Asset.internalBinaryWrite(message.available[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRewardAssetsResponse
 */
export const GetRewardAssetsResponse = new GetRewardAssetsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConsentLegalText$Type extends MessageType<ConsentLegalText> {
    constructor() {
        super("one.plan3t.core.model.ConsentLegalText", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "purpose", kind: "enum", T: () => ["one.plan3t.core.model.ConsentPurpose", ConsentPurpose] },
            { no: 5, name: "markdown", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ConsentLegalText>): ConsentLegalText {
        const message = { id: "", createdBy: "", purpose: 0, markdown: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConsentLegalText>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConsentLegalText): ConsentLegalText {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string created_by */ 3:
                    message.createdBy = reader.string();
                    break;
                case /* one.plan3t.core.model.ConsentPurpose purpose */ 4:
                    message.purpose = reader.int32();
                    break;
                case /* string markdown */ 5:
                    message.markdown = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConsentLegalText, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string created_by = 3; */
        if (message.createdBy !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.createdBy);
        /* one.plan3t.core.model.ConsentPurpose purpose = 4; */
        if (message.purpose !== 0)
            writer.tag(4, WireType.Varint).int32(message.purpose);
        /* string markdown = 5; */
        if (message.markdown !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.markdown);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ConsentLegalText
 */
export const ConsentLegalText = new ConsentLegalText$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLegalTextResponse$Type extends MessageType<GetLegalTextResponse> {
    constructor() {
        super("one.plan3t.core.model.GetLegalTextResponse", [
            { no: 1, name: "legalText", kind: "message", T: () => ConsentLegalText }
        ]);
    }
    create(value?: PartialMessage<GetLegalTextResponse>): GetLegalTextResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetLegalTextResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLegalTextResponse): GetLegalTextResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.ConsentLegalText legalText */ 1:
                    message.legalText = ConsentLegalText.internalBinaryRead(reader, reader.uint32(), options, message.legalText);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLegalTextResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.ConsentLegalText legalText = 1; */
        if (message.legalText)
            ConsentLegalText.internalBinaryWrite(message.legalText, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetLegalTextResponse
 */
export const GetLegalTextResponse = new GetLegalTextResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationAction$Type extends MessageType<NotificationAction> {
    constructor() {
        super("one.plan3t.core.model.NotificationAction", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "navigation", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NotificationAction>): NotificationAction {
        const message = { label: "", navigation: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NotificationAction>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NotificationAction): NotificationAction {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* string navigation */ 2:
                    message.navigation = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NotificationAction, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* string navigation = 2; */
        if (message.navigation !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.navigation);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.NotificationAction
 */
export const NotificationAction = new NotificationAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadImageResponse$Type extends MessageType<UploadImageResponse> {
    constructor() {
        super("one.plan3t.core.model.UploadImageResponse", [
            { no: 1, name: "upload", kind: "message", T: () => UploadedFile }
        ]);
    }
    create(value?: PartialMessage<UploadImageResponse>): UploadImageResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UploadImageResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadImageResponse): UploadImageResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.UploadedFile upload */ 1:
                    message.upload = UploadedFile.internalBinaryRead(reader, reader.uint32(), options, message.upload);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UploadImageResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.UploadedFile upload = 1; */
        if (message.upload)
            UploadedFile.internalBinaryWrite(message.upload, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.UploadImageResponse
 */
export const UploadImageResponse = new UploadImageResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LotteryTicket$Type extends MessageType<LotteryTicket> {
    constructor() {
        super("one.plan3t.core.model.LotteryTicket", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "drawingItemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "pointsValue", kind: "message", T: () => Plan3TPoint },
            { no: 4, name: "createdAt", kind: "message", T: () => Timestamp },
            { no: 5, name: "ownerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LotteryTicket>): LotteryTicket {
        const message = { id: "", drawingItemId: "", ownerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LotteryTicket>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LotteryTicket): LotteryTicket {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string drawingItemId */ 2:
                    message.drawingItemId = reader.string();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint pointsValue */ 3:
                    message.pointsValue = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.pointsValue);
                    break;
                case /* google.protobuf.Timestamp createdAt */ 4:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string ownerId */ 5:
                    message.ownerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LotteryTicket, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string drawingItemId = 2; */
        if (message.drawingItemId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.drawingItemId);
        /* one.plan3t.core.model.Plan3TPoint pointsValue = 3; */
        if (message.pointsValue)
            Plan3TPoint.internalBinaryWrite(message.pointsValue, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp createdAt = 4; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string ownerId = 5; */
        if (message.ownerId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.ownerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.LotteryTicket
 */
export const LotteryTicket = new LotteryTicket$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LotteryPriceDrawingItem$Type extends MessageType<LotteryPriceDrawingItem> {
    constructor() {
        super("one.plan3t.core.model.LotteryPriceDrawingItem", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "starts_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "ends_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "image", kind: "message", T: () => ImageResource },
            { no: 7, name: "ticketPrice", kind: "message", T: () => Plan3TPoint },
            { no: 8, name: "partnerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "winnerTicketIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "extraBenefitExplanation", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "cooperationProjectName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "cooperationProjectUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "cooperationProjectTagLine", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "cooperationProjectLogo", kind: "message", T: () => ImageResource },
            { no: 15, name: "terms", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "associated_partner_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "compensate_project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LotteryPriceDrawingItem>): LotteryPriceDrawingItem {
        const message = { id: "", title: "", description: "", partnerId: "", winnerTicketIds: [], extraBenefitExplanation: "", cooperationProjectName: "", cooperationProjectUrl: "", cooperationProjectTagLine: "", terms: "", associatedPartnerIds: [], compensateProjectId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LotteryPriceDrawingItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LotteryPriceDrawingItem): LotteryPriceDrawingItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* google.protobuf.Timestamp starts_at */ 2:
                    message.startsAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.startsAt);
                    break;
                case /* google.protobuf.Timestamp ends_at */ 3:
                    message.endsAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.endsAt);
                    break;
                case /* string title */ 4:
                    message.title = reader.string();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource image */ 6:
                    message.image = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                case /* one.plan3t.core.model.Plan3TPoint ticketPrice */ 7:
                    message.ticketPrice = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.ticketPrice);
                    break;
                case /* string partnerId */ 8:
                    message.partnerId = reader.string();
                    break;
                case /* repeated string winnerTicketIds */ 9:
                    message.winnerTicketIds.push(reader.string());
                    break;
                case /* string extraBenefitExplanation */ 10:
                    message.extraBenefitExplanation = reader.string();
                    break;
                case /* string cooperationProjectName */ 11:
                    message.cooperationProjectName = reader.string();
                    break;
                case /* string cooperationProjectUrl */ 12:
                    message.cooperationProjectUrl = reader.string();
                    break;
                case /* string cooperationProjectTagLine */ 13:
                    message.cooperationProjectTagLine = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource cooperationProjectLogo */ 14:
                    message.cooperationProjectLogo = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.cooperationProjectLogo);
                    break;
                case /* string terms */ 15:
                    message.terms = reader.string();
                    break;
                case /* repeated string associated_partner_ids */ 16:
                    message.associatedPartnerIds.push(reader.string());
                    break;
                case /* string compensate_project_id */ 17:
                    message.compensateProjectId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LotteryPriceDrawingItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* google.protobuf.Timestamp starts_at = 2; */
        if (message.startsAt)
            Timestamp.internalBinaryWrite(message.startsAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp ends_at = 3; */
        if (message.endsAt)
            Timestamp.internalBinaryWrite(message.endsAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string title = 4; */
        if (message.title !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.title);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* one.plan3t.core.model.ImageResource image = 6; */
        if (message.image)
            ImageResource.internalBinaryWrite(message.image, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.Plan3TPoint ticketPrice = 7; */
        if (message.ticketPrice)
            Plan3TPoint.internalBinaryWrite(message.ticketPrice, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string partnerId = 8; */
        if (message.partnerId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.partnerId);
        /* repeated string winnerTicketIds = 9; */
        for (let i = 0; i < message.winnerTicketIds.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.winnerTicketIds[i]);
        /* string extraBenefitExplanation = 10; */
        if (message.extraBenefitExplanation !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.extraBenefitExplanation);
        /* string cooperationProjectName = 11; */
        if (message.cooperationProjectName !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.cooperationProjectName);
        /* string cooperationProjectUrl = 12; */
        if (message.cooperationProjectUrl !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.cooperationProjectUrl);
        /* string cooperationProjectTagLine = 13; */
        if (message.cooperationProjectTagLine !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.cooperationProjectTagLine);
        /* one.plan3t.core.model.ImageResource cooperationProjectLogo = 14; */
        if (message.cooperationProjectLogo)
            ImageResource.internalBinaryWrite(message.cooperationProjectLogo, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* string terms = 15; */
        if (message.terms !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.terms);
        /* repeated string associated_partner_ids = 16; */
        for (let i = 0; i < message.associatedPartnerIds.length; i++)
            writer.tag(16, WireType.LengthDelimited).string(message.associatedPartnerIds[i]);
        /* string compensate_project_id = 17; */
        if (message.compensateProjectId !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.compensateProjectId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.LotteryPriceDrawingItem
 */
export const LotteryPriceDrawingItem = new LotteryPriceDrawingItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLotteryPriceDrawingsRequest$Type extends MessageType<GetLotteryPriceDrawingsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetLotteryPriceDrawingsRequest", [
            { no: 1, name: "ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetLotteryPriceDrawingsRequest>): GetLotteryPriceDrawingsRequest {
        const message = { ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetLotteryPriceDrawingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLotteryPriceDrawingsRequest): GetLotteryPriceDrawingsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string ids */ 1:
                    message.ids.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLotteryPriceDrawingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string ids = 1; */
        for (let i = 0; i < message.ids.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.ids[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetLotteryPriceDrawingsRequest
 */
export const GetLotteryPriceDrawingsRequest = new GetLotteryPriceDrawingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLotteryPriceDrawingsResponse$Type extends MessageType<GetLotteryPriceDrawingsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetLotteryPriceDrawingsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LotteryPriceDrawingItem }
        ]);
    }
    create(value?: PartialMessage<GetLotteryPriceDrawingsResponse>): GetLotteryPriceDrawingsResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetLotteryPriceDrawingsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLotteryPriceDrawingsResponse): GetLotteryPriceDrawingsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.LotteryPriceDrawingItem items */ 1:
                    message.items.push(LotteryPriceDrawingItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLotteryPriceDrawingsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.LotteryPriceDrawingItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            LotteryPriceDrawingItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetLotteryPriceDrawingsResponse
 */
export const GetLotteryPriceDrawingsResponse = new GetLotteryPriceDrawingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChallengeFeaturedTopic$Type extends MessageType<ChallengeFeaturedTopic> {
    constructor() {
        super("one.plan3t.core.model.ChallengeFeaturedTopic", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startsAt", kind: "message", T: () => Timestamp },
            { no: 3, name: "endsAt", kind: "message", T: () => Timestamp },
            { no: 4, name: "challengeIDs", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "coreIndustry", kind: "enum", T: () => ["one.plan3t.core.model.CoreIndustry", CoreIndustry] },
            { no: 7, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "backgroundPattern", kind: "message", T: () => ImageResource },
            { no: 9, name: "references", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BlogReference }
        ]);
    }
    create(value?: PartialMessage<ChallengeFeaturedTopic>): ChallengeFeaturedTopic {
        const message = { id: "", challengeIDs: [], title: "", coreIndustry: 0, category: "", references: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChallengeFeaturedTopic>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChallengeFeaturedTopic): ChallengeFeaturedTopic {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* google.protobuf.Timestamp startsAt */ 2:
                    message.startsAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.startsAt);
                    break;
                case /* google.protobuf.Timestamp endsAt */ 3:
                    message.endsAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.endsAt);
                    break;
                case /* repeated string challengeIDs */ 4:
                    message.challengeIDs.push(reader.string());
                    break;
                case /* string title */ 5:
                    message.title = reader.string();
                    break;
                case /* one.plan3t.core.model.CoreIndustry coreIndustry */ 6:
                    message.coreIndustry = reader.int32();
                    break;
                case /* string category */ 7:
                    message.category = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource backgroundPattern */ 8:
                    message.backgroundPattern = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.backgroundPattern);
                    break;
                case /* repeated one.plan3t.core.model.BlogReference references */ 9:
                    message.references.push(BlogReference.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChallengeFeaturedTopic, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* google.protobuf.Timestamp startsAt = 2; */
        if (message.startsAt)
            Timestamp.internalBinaryWrite(message.startsAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp endsAt = 3; */
        if (message.endsAt)
            Timestamp.internalBinaryWrite(message.endsAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated string challengeIDs = 4; */
        for (let i = 0; i < message.challengeIDs.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.challengeIDs[i]);
        /* string title = 5; */
        if (message.title !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.title);
        /* one.plan3t.core.model.CoreIndustry coreIndustry = 6; */
        if (message.coreIndustry !== 0)
            writer.tag(6, WireType.Varint).int32(message.coreIndustry);
        /* string category = 7; */
        if (message.category !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.category);
        /* one.plan3t.core.model.ImageResource backgroundPattern = 8; */
        if (message.backgroundPattern)
            ImageResource.internalBinaryWrite(message.backgroundPattern, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated one.plan3t.core.model.BlogReference references = 9; */
        for (let i = 0; i < message.references.length; i++)
            BlogReference.internalBinaryWrite(message.references[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ChallengeFeaturedTopic
 */
export const ChallengeFeaturedTopic = new ChallengeFeaturedTopic$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BlogReference$Type extends MessageType<BlogReference> {
    constructor() {
        super("one.plan3t.core.model.BlogReference", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "image", kind: "message", T: () => ImageResource }
        ]);
    }
    create(value?: PartialMessage<BlogReference>): BlogReference {
        const message = { id: "", url: "", label: "", description: "", title: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BlogReference>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BlogReference): BlogReference {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string url */ 2:
                    message.url = reader.string();
                    break;
                case /* string label */ 3:
                    message.label = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* string title */ 5:
                    message.title = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource image */ 6:
                    message.image = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BlogReference, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string url = 2; */
        if (message.url !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.url);
        /* string label = 3; */
        if (message.label !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.label);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* string title = 5; */
        if (message.title !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.title);
        /* one.plan3t.core.model.ImageResource image = 6; */
        if (message.image)
            ImageResource.internalBinaryWrite(message.image, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.BlogReference
 */
export const BlogReference = new BlogReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChallengeFeaturedTopicsRequest$Type extends MessageType<GetChallengeFeaturedTopicsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetChallengeFeaturedTopicsRequest", [
            { no: 1, name: "reference", kind: "message", T: () => Timestamp },
            { no: 2, name: "ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetChallengeFeaturedTopicsRequest>): GetChallengeFeaturedTopicsRequest {
        const message = { ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetChallengeFeaturedTopicsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetChallengeFeaturedTopicsRequest): GetChallengeFeaturedTopicsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp reference */ 1:
                    message.reference = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.reference);
                    break;
                case /* repeated string ids */ 2:
                    message.ids.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetChallengeFeaturedTopicsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp reference = 1; */
        if (message.reference)
            Timestamp.internalBinaryWrite(message.reference, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string ids = 2; */
        for (let i = 0; i < message.ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.ids[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetChallengeFeaturedTopicsRequest
 */
export const GetChallengeFeaturedTopicsRequest = new GetChallengeFeaturedTopicsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChallengeFeaturedTopicsResponse$Type extends MessageType<GetChallengeFeaturedTopicsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetChallengeFeaturedTopicsResponse", [
            { no: 1, name: "topics", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChallengeFeaturedTopic }
        ]);
    }
    create(value?: PartialMessage<GetChallengeFeaturedTopicsResponse>): GetChallengeFeaturedTopicsResponse {
        const message = { topics: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetChallengeFeaturedTopicsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetChallengeFeaturedTopicsResponse): GetChallengeFeaturedTopicsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.ChallengeFeaturedTopic topics */ 1:
                    message.topics.push(ChallengeFeaturedTopic.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetChallengeFeaturedTopicsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.ChallengeFeaturedTopic topics = 1; */
        for (let i = 0; i < message.topics.length; i++)
            ChallengeFeaturedTopic.internalBinaryWrite(message.topics[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetChallengeFeaturedTopicsResponse
 */
export const GetChallengeFeaturedTopicsResponse = new GetChallengeFeaturedTopicsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBlogReferenceRequest$Type extends MessageType<GetBlogReferenceRequest> {
    constructor() {
        super("one.plan3t.core.model.GetBlogReferenceRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetBlogReferenceRequest>): GetBlogReferenceRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBlogReferenceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBlogReferenceRequest): GetBlogReferenceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBlogReferenceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetBlogReferenceRequest
 */
export const GetBlogReferenceRequest = new GetBlogReferenceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBlogReferenceResponse$Type extends MessageType<GetBlogReferenceResponse> {
    constructor() {
        super("one.plan3t.core.model.GetBlogReferenceResponse", [
            { no: 1, name: "reference", kind: "message", T: () => BlogReference }
        ]);
    }
    create(value?: PartialMessage<GetBlogReferenceResponse>): GetBlogReferenceResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBlogReferenceResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBlogReferenceResponse): GetBlogReferenceResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.BlogReference reference */ 1:
                    message.reference = BlogReference.internalBinaryRead(reader, reader.uint32(), options, message.reference);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBlogReferenceResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.BlogReference reference = 1; */
        if (message.reference)
            BlogReference.internalBinaryWrite(message.reference, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetBlogReferenceResponse
 */
export const GetBlogReferenceResponse = new GetBlogReferenceResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CashbackLogEntry$Type extends MessageType<CashbackLogEntry> {
    constructor() {
        super("one.plan3t.core.model.CashbackLogEntry", [
            { no: 1, name: "transaction_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "rule_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "rate_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "transaction_amount_unscaled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "transaction_amount_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "issued_cashback_unscaled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "issued_cashback_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "fee_unscaled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "fee_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "transaction_date", kind: "message", T: () => Timestamp },
            { no: 13, name: "points", kind: "message", T: () => Plan3TPoint }
        ]);
    }
    create(value?: PartialMessage<CashbackLogEntry>): CashbackLogEntry {
        const message = { transactionId: "", partnerId: "", ruleId: "", rateId: "", transactionAmountUnscaled: 0, transactionAmountScale: 0, issuedCashbackUnscaled: 0, issuedCashbackScale: 0, feeUnscaled: 0, feeScale: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CashbackLogEntry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CashbackLogEntry): CashbackLogEntry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string transaction_id */ 1:
                    message.transactionId = reader.string();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                case /* string rule_id */ 4:
                    message.ruleId = reader.string();
                    break;
                case /* string rate_id */ 5:
                    message.rateId = reader.string();
                    break;
                case /* int32 transaction_amount_unscaled */ 6:
                    message.transactionAmountUnscaled = reader.int32();
                    break;
                case /* int32 transaction_amount_scale */ 7:
                    message.transactionAmountScale = reader.int32();
                    break;
                case /* int32 issued_cashback_unscaled */ 8:
                    message.issuedCashbackUnscaled = reader.int32();
                    break;
                case /* int32 issued_cashback_scale */ 9:
                    message.issuedCashbackScale = reader.int32();
                    break;
                case /* int32 fee_unscaled */ 10:
                    message.feeUnscaled = reader.int32();
                    break;
                case /* int32 fee_scale */ 11:
                    message.feeScale = reader.int32();
                    break;
                case /* google.protobuf.Timestamp transaction_date */ 12:
                    message.transactionDate = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.transactionDate);
                    break;
                case /* one.plan3t.core.model.Plan3TPoint points */ 13:
                    message.points = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.points);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CashbackLogEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string transaction_id = 1; */
        if (message.transactionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.transactionId);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        /* string rule_id = 4; */
        if (message.ruleId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.ruleId);
        /* string rate_id = 5; */
        if (message.rateId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.rateId);
        /* int32 transaction_amount_unscaled = 6; */
        if (message.transactionAmountUnscaled !== 0)
            writer.tag(6, WireType.Varint).int32(message.transactionAmountUnscaled);
        /* int32 transaction_amount_scale = 7; */
        if (message.transactionAmountScale !== 0)
            writer.tag(7, WireType.Varint).int32(message.transactionAmountScale);
        /* int32 issued_cashback_unscaled = 8; */
        if (message.issuedCashbackUnscaled !== 0)
            writer.tag(8, WireType.Varint).int32(message.issuedCashbackUnscaled);
        /* int32 issued_cashback_scale = 9; */
        if (message.issuedCashbackScale !== 0)
            writer.tag(9, WireType.Varint).int32(message.issuedCashbackScale);
        /* int32 fee_unscaled = 10; */
        if (message.feeUnscaled !== 0)
            writer.tag(10, WireType.Varint).int32(message.feeUnscaled);
        /* int32 fee_scale = 11; */
        if (message.feeScale !== 0)
            writer.tag(11, WireType.Varint).int32(message.feeScale);
        /* google.protobuf.Timestamp transaction_date = 12; */
        if (message.transactionDate)
            Timestamp.internalBinaryWrite(message.transactionDate, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.Plan3TPoint points = 13; */
        if (message.points)
            Plan3TPoint.internalBinaryWrite(message.points, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CashbackLogEntry
 */
export const CashbackLogEntry = new CashbackLogEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCashbackLogRequest$Type extends MessageType<GetCashbackLogRequest> {
    constructor() {
        super("one.plan3t.core.model.GetCashbackLogRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCashbackLogRequest>): GetCashbackLogRequest {
        const message = { pageSize: 0, pageToken: "", partnerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCashbackLogRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCashbackLogRequest): GetCashbackLogRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                case /* string partner_id */ 3:
                    message.partnerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCashbackLogRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        /* string partner_id = 3; */
        if (message.partnerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.partnerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCashbackLogRequest
 */
export const GetCashbackLogRequest = new GetCashbackLogRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCashbackLogResponse$Type extends MessageType<GetCashbackLogResponse> {
    constructor() {
        super("one.plan3t.core.model.GetCashbackLogResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CashbackLogEntry },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCashbackLogResponse>): GetCashbackLogResponse {
        const message = { items: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCashbackLogResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCashbackLogResponse): GetCashbackLogResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.CashbackLogEntry items */ 1:
                    message.items.push(CashbackLogEntry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCashbackLogResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.CashbackLogEntry items = 1; */
        for (let i = 0; i < message.items.length; i++)
            CashbackLogEntry.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCashbackLogResponse
 */
export const GetCashbackLogResponse = new GetCashbackLogResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCodeIDsForRewardRequest$Type extends MessageType<GetCodeIDsForRewardRequest> {
    constructor() {
        super("one.plan3t.core.model.GetCodeIDsForRewardRequest", [
            { no: 1, name: "reward_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCodeIDsForRewardRequest>): GetCodeIDsForRewardRequest {
        const message = { rewardId: "", partnerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCodeIDsForRewardRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCodeIDsForRewardRequest): GetCodeIDsForRewardRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string reward_id */ 1:
                    message.rewardId = reader.string();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCodeIDsForRewardRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string reward_id = 1; */
        if (message.rewardId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.rewardId);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCodeIDsForRewardRequest
 */
export const GetCodeIDsForRewardRequest = new GetCodeIDsForRewardRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCodeIDsForRewardResponse$Type extends MessageType<GetCodeIDsForRewardResponse> {
    constructor() {
        super("one.plan3t.core.model.GetCodeIDsForRewardResponse", [
            { no: 1, name: "code_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCodeIDsForRewardResponse>): GetCodeIDsForRewardResponse {
        const message = { codeIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCodeIDsForRewardResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCodeIDsForRewardResponse): GetCodeIDsForRewardResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string code_ids */ 1:
                    message.codeIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCodeIDsForRewardResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string code_ids = 1; */
        for (let i = 0; i < message.codeIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.codeIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCodeIDsForRewardResponse
 */
export const GetCodeIDsForRewardResponse = new GetCodeIDsForRewardResponse$Type();
