import * as React from 'react';
import Scrollable from '../common/Scrollable';
import { useAppSelector } from '../redux/react';
import { authSelectors } from '../auth/store';
import { PIXEL_3T_CODE_URL, PIXEL_CODE_ENDPOINT } from '../api';
import { useI18n } from '../i18n';

const globalSnippet = (url: string, src: string, partnerId: string) =>
    `
<script>  
!function(e,t,n,a,p,c,s,i,o){e.p3t||((s=e.p3t=function(){s.rec?s.rec.apply(s,arguments):s.q.push(arguments)}).q=[],s.u="${url}",
(i=t.createElement(n)).async=!0,i.src="${src}?t="+864e5*Math.ceil(new Date/864e5),
(o=t.getElementsByTagName(n)[0]).parentNode.insertBefore(i,o))}(window,document,"script");
p3t("init","${partnerId}");
</script>
`.trim();

const orderPageSnippet = () =>
    `
<script>  
p3t("orderId", "ORDER_ID");
p3t("amount", "AMOUNT");
p3t("currency", "CURRENCY");
p3t("send");
</script>
`.trim();

const shopifyOrderSnippet = (url: string, src: string, partnerId: string) => `
<script>  
!function(e,t,n,a,p,c,s,i,o){e.p3t||((s=e.p3t=function(){s.rec?s.rec.apply(s,arguments):s.q.push(arguments)}).q=[],s.u="${url}",
(i=t.createElement(n)).async=!0,i.src="${src}?t="+864e5*Math.ceil(new Date/864e5),
(o=t.getElementsByTagName(n)[0]).parentNode.insertBefore(i,o))}(window,document,"script");
p3t("init","${partnerId}");
p3t("orderId", "{{ order_number }}");
p3t("amount", "{{ total_price | money_without_currency }}");
p3t("currency", "{{ currency }}");
p3t("send");
</script>
`;

const IntegrationPage: React.FunctionComponent = () => {
    const { t } = useI18n();

    const partnerId = useAppSelector(authSelectors.getPartnerId);

    const snippet = globalSnippet(
        PIXEL_CODE_ENDPOINT,
        PIXEL_3T_CODE_URL,
        partnerId || '...',
    );
    const order = orderPageSnippet();
    const shopifySnippet = shopifyOrderSnippet(
        PIXEL_CODE_ENDPOINT,
        PIXEL_3T_CODE_URL,
        partnerId || '...',
    );

    return (
        <Scrollable className="pb-12">
            <div className="container mx-0 space-y-4">
                <div className="font-light">{t('integration.code.intro')}</div>

                <div>
                    <h2 className="font-medium text-xl">
                        {t('integration.code.base.headline')}
                    </h2>
                    <p className="text-gray-800 font-light">
                        {t('integration.code.base.intro')}
                    </p>
                    <pre className="bg-gray-50 text-gray-900 p-4 rounded-lg overflow-x-auto">
                        {snippet}
                    </pre>
                </div>

                <div>
                    <h2 className="font-medium text-xl">
                        {t('integration.code.conversion.headline')}
                    </h2>
                    <p className="text-gray-800 font-light">
                        {t('integration.code.conversion.intro')}
                    </p>
                    <pre className="bg-gray-50 text-gray-900 p-4 rounded-lg overflow-x-auto">
                        {order}
                    </pre>
                    <p className="text-gray-800 py-2 font-light">
                        {t('integration.code.conversion.liquid')}
                    </p>

                    <pre className="bg-gray-50 text-gray-900 p-4 rounded-lg overflow-x-auto">
                        {shopifySnippet}
                    </pre>
                    <p className="text-gray-800 py-2 font-light">
                        {t('integration.code.conversion.shopifyExtra')}
                    </p>
                </div>
            </div>
        </Scrollable>
    );
};

export default IntegrationPage;
