import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import Pica from 'pica';
import { useDropzone } from 'react-dropzone';
import { connect, ConnectedProps } from 'react-redux';

import { useHtmlId } from '../hooks';
import Thumbnails from './Thumbnails';
import Spinner from './Spinner';

import { filesActions } from '../files/store';
import { useI18n } from '../i18n';

import { ImageResource, UploadedFile } from '../api/model/core';

const actions = {
    uploadImages: filesActions.uploadImages,
};

const connector = connect(null, actions);

type Props = ConnectedProps<typeof connector> & {
    partnerId: string;
    disabled: boolean;
    label: string;
    message: string;
    file?: ImageResource | undefined;
    files?: ImageResource[];
    onSingleChanged?: (f: ImageResource | undefined) => void;
    onMultipleChanged?: (f: ImageResource[]) => void;
    thumbnailCircleCrop?: boolean;
};

const FileUpload: React.FunctionComponent<Props> = (props) => {
    const {
        disabled,
        label,
        message,
        file,
        files,
        partnerId,
        onMultipleChanged,
        onSingleChanged,
        uploadImages,
        thumbnailCircleCrop,
    } = props;
    const id = useHtmlId();
    const { t } = useI18n();
    const currentFiles = useMemo(
        () => (file ? [file] : files || []),
        [file, files],
    );

    const [isLoading, setIsLoading] = useState(false);

    const onDrop = useCallback(
        async (acceptedFiles) => {
            if (onSingleChanged && acceptedFiles.length > 1) {
                alert('Only one image is allowed.');
                return;
            }
            setIsLoading(true);
            try {
                const resizedImages: File[] = [];

                const opt = { maxWidth: 1400, quality: 0.6 };
                for (let f of acceptedFiles) {
                    resizedImages.push(await resizeImage(f, opt));
                }

                const uploads = await uploadImages({
                    partnerId,
                    files: resizedImages,
                });
                const result = uploads.payload.map(
                    (file: { upload: UploadedFile }) => {
                        return {
                            id: file.upload.id,
                            url: file.upload.path,
                        };
                    },
                );
                if (onSingleChanged) {
                    onSingleChanged(result[0]);
                } else if (onMultipleChanged) {
                    onMultipleChanged([...currentFiles, ...result]);
                }
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        },
        [
            uploadImages,
            onSingleChanged,
            onMultipleChanged,
            currentFiles,
            partnerId,
        ],
    );
    const { getRootProps, getInputProps } = useDropzone({
        disabled: disabled,
        accept: 'image/*',
        onDrop,
    });

    const onRemove = useCallback(
        (fileToRemove) => {
            if (onSingleChanged) {
                onSingleChanged(undefined);
            } else if (onMultipleChanged) {
                onMultipleChanged(
                    currentFiles.filter(
                        (tmp: ImageResource) => tmp.id !== fileToRemove.id,
                    ),
                );
            }
        },
        [currentFiles, onMultipleChanged, onSingleChanged],
    );

    return (
        <section>
            <label htmlFor={id} className="block pl-3 form-label">
                {t(label)}
            </label>
            <div className="space-y-4">
                <div
                    {...getRootProps()}
                    className="mt-1 border border-dashed border-gray-200 cursor-pointer bg-gray-50 w-100 h-32 rounded
                    flex justify-center items-center
                    hover:bg-gray-100 focus:border-green-400 focus:outline-none
                    text-center text-body1 text-semantic-primary"
                >
                    <input
                        className="focus:border-green-400"
                        id={id}
                        {...getInputProps()}
                    />
                    <p className="px-6">{t(message)}</p>
                </div>
                {isLoading ? (
                    <Spinner sizeInRem={8} />
                ) : (
                    <Thumbnails
                        images={currentFiles}
                        allowRemove={!onSingleChanged}
                        onRemove={onRemove}
                        circleCrop={!!thumbnailCircleCrop}
                    />
                )}
            </div>
        </section>
    );
};

export default connector(FileUpload);

type ResizeImageOptions = {
    maxWidth: number;
    quality: number;
};

function resizeImage(file: File, options: ResizeImageOptions): Promise<File> {
    return new Promise((resolve, reject) => {
        const p = new Pica();
        const img = new Image();

        img.onload = () => {
            // this is getting destroyed by the garbage collector, so no need to manually delete it or something.
            const c = document.createElement('canvas');
            const maxWidth = options.maxWidth;

            c.width = img.width;
            c.height = img.height;

            if (c.width > maxWidth) {
                c.height = c.height * (maxWidth / c.width);
                c.width = maxWidth;
            }

            p.resize(img, c, { alpha: file.type.includes('png') })
                .then((result) => p.toBlob(result, file.type, options.quality))
                .then((blob) => {
                    return new File([blob], file.name, {
                        type: file.type,
                        lastModified: file.lastModified,
                    });
                })
                .then(resolve)
                .catch(reject);
        };
        img.src = URL.createObjectURL(file);
    });
}
