import { SyntheticEvent, useCallback, useState } from 'react';
import * as React from 'react';
import { Button } from '../common/Button';
import { useHtmlId } from '../hooks';
import { ulid } from 'ulid';
import { useI18n } from '../i18n';

type Props = {
    onCodesReceived: (codes: string[]) => void;
    disabled: boolean;
};

const generateCodes = (num: number): string[] => {
    let codes: string[];
    let codeSet: Set<string>;

    let i = 0;
    do {
        i++;
        console.log('START');
        const rawCodes = Array(num)
            .fill(0)
            .map(() => ulid().slice(-5));

        codes = rawCodes.map((code) => `3t-` + code);
        codeSet = new Set(codes);
    } while (codes.length !== codeSet?.size && i < 1000);
    return codes;
};

const RewardCodeGenerator: React.FunctionComponent<Props> = (props) => {
    const { onCodesReceived } = props;

    const id = useHtmlId();
    const { t } = useI18n();

    const [amount, setAmount] = useState(100);

    const generate = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault();
            onCodesReceived(generateCodes(amount));
        },
        [onCodesReceived, amount],
    );

    return (
        <div className="flex flex-col space-y-6">
            <div className="text-center text-body1 text-semantic-primary font-bold">
                - {t('reward.couponCodes.generateNewTeaser')} -
            </div>
            <div className="flex flex-col space-y-1">
                <label htmlFor={id} className="block pl-3 form-label">
                    {t('reward.couponCodes.generateAmountLabel')}
                </label>
                <div className="flex space-x-2">
                    <input
                        id={id}
                        type="number"
                        min={1}
                        max={1000}
                        className="form-input w-48 sm:flex-none"
                        value={amount}
                        onChange={(e) =>
                            setAmount(parseInt(e.target.value, 10))
                        }
                    />
                    <Button
                        label="reward.couponCodes.generate"
                        className="flex-auto"
                        secondary
                        onClick={generate}
                    />
                </div>
            </div>
        </div>
    );
};

export default RewardCodeGenerator;
