import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import FormSection from '../common/form/FormSection';
import TextInput from '../common/form/TextInput';
import { useAppDispatch, useAppSelector } from '../redux/react';

import { Button } from '../common/Button';
import { accountActions, accountSelectors } from './store';
import ChangePasswordForm from './ChangePasswordForm';
import { partnerActions } from '../partner/store';
import { authSelectors } from '../auth/store';
import PartnerName from '../partner/PartnerName';

const AccountSettings: React.FunctionComponent = () => {
    const partnerId = useAppSelector(authSelectors.getPartnerId);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(accountActions.fetchMyUserAccount());
        dispatch(partnerActions.fetchPartnerBase(partnerId!));
    }, [dispatch, partnerId]);

    const account = useAppSelector(accountSelectors.getMyAccount);
    const [name, setName] = useState<string>(account?.name || '');

    const reset = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault();
            setName(account?.name || '');
        },
        [setName, account],
    );

    const submit = useCallback(
        async (e: SyntheticEvent) => {
            e.preventDefault();
            dispatch(accountActions.changeDisplayName(name));
        },
        [dispatch, name],
    );

    useEffect(() => {
        dispatch(accountActions.fetchMyUserAccount());
    }, [dispatch]);

    useEffect(() => {
        if (account) {
            setName(account.name);
        }
    }, [setName, account]);

    const writeState = useAppSelector(accountSelectors.getWriteState);

    if (!account) {
        return <>Loading...</>;
    }

    return (
        <div className="space-y-8 p-4 h-full overflow-y-auto flex flex-col">
            <div className="rounded-md max-w-2xl flex flex-col space-y-2 flex-shrink-0">
                <span className="text-sm font-medium leading-5 text-green-500 uppercase tracking-wide">
                    <PartnerName id={partnerId!} />
                </span>
                <span className="text-sm leading-5 text-gray-600">
                    {account.email}
                </span>
            </div>
            <form onSubmit={submit} className="max-w-5xl">
                <FormSection
                    label="accountsettings.form.profile.label"
                    subtitle="accountsettings.form.profile.subtitle"
                >
                    <TextInput
                        label="accountsettings.form.profile.fields.name"
                        value={name}
                        defaultValue={account?.email}
                        onChanged={setName}
                    />
                    <div className="flex justify-end">
                        <div className="inline-flex space-x-2">
                            <Button
                                secondary
                                label="common.reset"
                                onClick={reset}
                                disabled={writeState.isWriting}
                            />
                            <Button
                                primary
                                label="common.save"
                                onClick={submit}
                                disabled={writeState.isWriting}
                            />
                        </div>
                    </div>
                </FormSection>
            </form>
            <ChangePasswordForm />
        </div>
    );
};
export default AccountSettings;
