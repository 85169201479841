import { useState } from 'react';

let counter = 0;

const generate = () => {
    return 'p3t_' + counter++;
};

export default function useHtmlId(): string {
    const [value] = useState(generate());
    return value;
}
