import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { DraftState, RewardDraft } from '../../api/model/core';
import { authSelectors } from '../../auth/store';
import { SubSection } from '../../common/SubSection';
import { AppState } from '../../redux/AppStore';
import { ReviewPendingBadge } from '../rewardTile/Badges';
import { RewardTile } from '../rewardTile/RewardTile';
import { rewardActions, rewardSelectors } from '../store';

const mapStateToProps = (state: AppState) => ({
    partnerId: authSelectors.getPartnerId(state)!,
    currentDrafts: rewardSelectors.allDrafts(state)!,
});

const actions = {
    fetchAllRewardDrafts: rewardActions.fetchAllRewardDrafts,
};

const connector = connect(mapStateToProps, actions);

type Props = ConnectedProps<typeof connector>;

const AllRewardDraftsInner = (props: Props) => {
    const { currentDrafts, partnerId, fetchAllRewardDrafts } = props;
    useEffect(() => {
        fetchAllRewardDrafts({ partnerId });
    }, [partnerId, fetchAllRewardDrafts]);

    return (
        // -margin trick used here to get spacing between components when they wrap
        <SubSection title="reward.overview.allDrafts">
            <div className="flex flex-wrap -mt-6 -ml-6">
                {currentDrafts.length === 0 && (
                    <RewardTile.NoDraftsPlaceholder className="mt-6 ml-6" />
                )}
                {currentDrafts.map((draft: RewardDraft) => (
                    <RewardTile.Draft
                        className="mt-6 ml-6"
                        key={draft.rewardId}
                        draft={draft}
                        badge={
                            draft.state === DraftState.Pending && (
                                <ReviewPendingBadge />
                            )
                        }
                    />
                ))}
            </div>
        </SubSection>
    );
};

export default connector(AllRewardDraftsInner);
