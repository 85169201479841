import * as React from 'react';
import cx from 'classnames';
import { Translatable, useI18n } from '../../i18n';

type Props = {
    label: Translatable;
    subtitle: Translatable;
    className?: string;
    leftColumnChildren?: React.ReactNode;
};

const FormSection: React.FunctionComponent<Props> = ({
    label,
    subtitle,
    className,
    children,
    leftColumnChildren,
}) => {
    const { t } = useI18n();
    return (
        <div
            className={cx(
                'md:grid md:grid-cols-3 md:space-x-6 md:space-y-0',
                className,
            )}
        >
            <div className="flex flex-col pt-6 space-y-2">
                <span className="text-headline4 text-semantic-primary font-bold">
                    {t(label)}
                </span>
                <span className="text-body1 font-normal text-gray-500">
                    {t(subtitle)}
                </span>
                {leftColumnChildren}
            </div>
            <div className="col-span-2 bg-white p-6 rounded-2xl">
                {children}
            </div>
        </div>
    );
};
export default FormSection;
