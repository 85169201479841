import * as React from 'react';
import cx from 'classnames';
import { IconArrowLeft } from '../icons';

export const IconButton: React.FC<React.ComponentPropsWithoutRef<'button'>> = (
    props,
) => {
    return (
        <button
            {...props}
            className={cx(
                'relative w-full flex justify-center antialiased py-2 px-6' +
                    'focus:outline-none focus:border-green-700 focus:shadow-outline-green' +
                    'transition-default disabled:opacity-75 disabled:cursor-not-allowed',
            )}
        >
            {props.children}
        </button>
    );
};

export const BackButton: React.FC<React.ComponentPropsWithoutRef<'button'>> = (
    props,
) => {
    return (
        <IconButton onClick={() => window.history.back()} {...props}>
            <IconArrowLeft />
        </IconButton>
    );
};
