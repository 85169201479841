import * as React from 'react';
import Sidebar from './Sidebar';
import { Link, Route, Switch } from 'react-router-dom';
import DashboardHeader from '../common/DashboardHeader';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { authSelectors } from '../auth/store';
import PartnerProfileForm from '../partner_profile/PartnerProfileForm';
import Rewards from '../rewards/Rewards';
import SetInitialPasswordScreen from '../auth/SetInitialPasswordScreen';
import Reports, { AnalyticsCashbackChart } from '../reports/Reports';
import AccountSettings from '../account/AccountSettings';
import PartnerManagerPage from '../partner/PartnerManagerPage';
import IntegrationPage from '../integration/IntegrationPage';
import CashbackLogPage from '../cashback/CashbackLogPage';
import BillingPage from '../billing/BillingPage';
import { useI18n } from '../i18n';
import Scrollable from '../common/Scrollable';
import { useEffect } from 'react';
import { analyticsActions, analyticsSelectors } from '../reports/store';
import { AnalyticsReportChart } from '../reports/AnalyticsReportChart';
import BrandingPage from '../branding/BrandingPage';

const Dashboard: React.FunctionComponent = () => {
    const requireNewPassword = useAppSelector(authSelectors.requireNewPassword);
    if (requireNewPassword) {
        return <SetInitialPasswordScreen />;
    }

    return (
        <div className="h-screen flex flex-row">
            <Sidebar />
            <div className="bg-white flex-1 overflow-hidden h-full w-full flex flex-col items-stretch">
                <Switch>
                    <Route path="/" exact>
                        <DashboardContent />
                    </Route>
                    <Route path="/profile">
                        <DashboardHeader title="module.profile.name" />
                        <PartnerProfileForm />
                    </Route>
                    <Route path="/rewards">
                        <Rewards />
                    </Route>
                    <Route path="/reports">
                        <Reports />
                    </Route>
                    <Route path="/billing">
                        <BillingPage />
                    </Route>
                    <Route path="/integration">
                        <DashboardHeader title="module.integration.name" />
                        <IntegrationPage />
                    </Route>
                    <Route path="/branding">
                        <DashboardHeader title="module.branding.name" />
                        <BrandingPage />
                    </Route>
                    <Route path="/cashback">
                        <DashboardHeader title="module.cashback.name" />
                        <CashbackLogPage />
                    </Route>
                    <Route path="/account">
                        <DashboardHeader title="module.account.name" />
                        <AccountSettings />
                    </Route>
                    <Route path="/manager">
                        <PartnerManagerPage />
                    </Route>
                </Switch>
            </div>
        </div>
    );
};

export default Dashboard;

function DashboardContent() {
    const { t } = useI18n();

    const quickLinks = [
        {
            label: 'dashboard.quicklinks.rewards',
            to: '/rewards',
        },
        {
            label: 'dashboard.quicklinks.billing',
            to: '/billing',
        },
        {
            label: 'dashboard.quicklinks.analytics',
            to: '/reports',
        },
    ];

    const dispatch = useAppDispatch();
    const partnerId = useAppSelector(authSelectors.getPartnerId)!;
    const reports = useAppSelector(analyticsSelectors.reports);

    useEffect(() => {
        dispatch(analyticsActions.fetchEvents({ partnerId }));
    }, [dispatch, partnerId]);

    return (
        <Scrollable className="bg-semantic-background h-full">
            <div className="container mx-auto flex flex-col space-y-10 p-12 h-full">
                <div className="flex flex-col space-y-4">
                    <h2 className="text-semantic-primary text-headline4 font-bold px-6">
                        {t('dashboard.quicklinks.header')}
                    </h2>
                    <div className="flex flex-row space-x-4 h-24">
                        {quickLinks.map(({ label, to }) => (
                            <Link
                                key="to"
                                className="flex-1 rounded-xl bg-semantic-surface p-2 flex items-center justify-center transform hover:scale-105 transition duration-150"
                                to={to}
                            >
                                <span className="text-body2 font-bold">
                                    {t(label)}
                                </span>
                            </Link>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col space-y-4">
                    <h2 className="text-semantic-primary text-headline4 font-bold px-6">
                        {t('dashboard.stats.header')}
                    </h2>
                    <div className="flex flex-row space-x-4">
                        <div className="flex-1">
                            <AnalyticsCashbackChart />
                        </div>
                        <div className="flex-1">
                            <AnalyticsReportChart
                                title="reports.rewards.title"
                                report={reports.rewardReport}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Scrollable>
    );
}
