import { SyntheticEvent } from 'react';
import * as React from 'react';
import cx from 'classnames';

type Props = {
    onSubmit: (e: SyntheticEvent) => void;
    className?: string;
    actions?: React.ReactNode;
};

const Form: React.FunctionComponent<Props> = ({
    onSubmit,
    className,
    actions,
    children,
}) => {
    const withPreventDefault = React.useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault();
            onSubmit(e);
        },
        [onSubmit],
    );

    return (
        <form
            onSubmit={withPreventDefault}
            className={cx(
                'h-full overflow-hidden flex flex-col bg-semantic-background',
                className,
            )}
        >
            <div className="flex flex-col flex-auto items-center overflow-y-auto">
                <div className="max-w-6xl p-12 space-y-12 w-full">
                    {children}
                </div>
            </div>
            <div className="bg-semantic-surface">
                <div className="flex space-x-3 justify-end px-12 py-4">
                    {actions}
                </div>
            </div>
        </form>
    );
};
export default Form;
