type Props = {
    sizeInRem: number;
};

const Spinner = (props: Props) => (
    <div
        style={{
            width: `${props.sizeInRem}rem`,
            height: `${props.sizeInRem}rem`,
        }}
        className={`loader ease-linear rounded-full border-8 border-t-8 border-gray-200`}
    ></div>
);

export default Spinner;
