import { ButtonHTMLAttributes, useCallback } from 'react';
import * as React from 'react';
import { useAppDispatch } from '../redux/react';
import { authActions } from './store';

const LogoutButton: React.FunctionComponent<
    ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, ...props }) => {
    const dispatch = useAppDispatch();

    const onClick = useCallback(() => {
        dispatch(authActions.logout());
    }, [dispatch]);

    return (
        <button onClick={onClick} {...props}>
            {children}
        </button>
    );
};
export default LogoutButton;
