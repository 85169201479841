import * as React from 'react';
import { SyntheticEvent, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Button } from '../common/Button';
import { rewardActions, rewardSelectors } from './store';
import { authSelectors } from '../auth/store';
import TextInput from '../common/form/TextInput';
import FileUpload from '../common/FileUpload';
import { AppState } from '../redux/AppStore';
import {
    Asset,
    DraftState,
    ImageResource,
    RewardBillingType,
    TaxableCodeType,
} from '../api/model/core';
import ReviewDisplay from '../publishing/ReviewDisplay';
import { PostRewardDraftResponse } from '../api/model/b2b';
import FormSection from '../common/form/FormSection';
import RewardCodeCSVUpload, { triggerCSVDownload } from './RewardCodeCSVUpload';
import RewardCodeGenerator from './RewardCodeGenerator';
import Form from '../common/form/Form';
import DateInput from '../common/form/DateInput';
import { useValidation } from '../hooks';
import { useI18n } from '../i18n';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { Duration } from '../api/google/protobuf/duration';

type TParams = {
    rewardId: string;
    billingType?: string;
};

type Props = ConnectedProps<typeof connector> & RouteComponentProps<TParams>;

const mapStateToProps = (state: AppState) => ({
    partnerId: authSelectors.getPartnerId(state)!,
    isLoading:
        rewardSelectors.isLoading(state) || rewardSelectors.isWriting(state),
    currentDraft: rewardSelectors.currentDraft(state),
    assets: rewardSelectors.getAssets(state, {
        rewardId: state.rewards.currentDraft?.rewardId ?? '',
    }),
    isLive: rewardSelectors.hasDraftAPublishedReward(state),
});

const actions = {
    fetchRewardDraft: rewardActions.fetchRewardDraft,
    saveRewardDraft: rewardActions.saveRewardDraft,
    submitRewardDraft: rewardActions.submitRewardDraft,
    clearRewardDraft: rewardActions.clearRewardDraft,
    fetchRewardAssets: rewardActions.fetchRewardAssets,
};

const connector = connect(mapStateToProps, actions);

type State = {
    title?: string;
    description?: string;
    terms?: string;
    directLink?: string;
    couponCodes?: string[];
    images?: ImageResource[];
    validUntil?: Timestamp;
    maxClaims?: number;
    maxClaimsCooldown?: Duration;
    billingType?: RewardBillingType;
    codePriceCents?: number;
    taxableCodeType?: TaxableCodeType;
};

export const RewardFormInner: React.FunctionComponent<Props> = (props) => {
    const fetchRewardDraft = props.fetchRewardDraft;
    const fetchRewardAssets = props.fetchRewardAssets;
    const saveRewardDraft = props.saveRewardDraft;
    const submitRewardDraft = props.submitRewardDraft;
    const clearRewardDraft = props.clearRewardDraft;
    const currentDraft = props.currentDraft;
    const { history, match } = props;

    const partnerId = props.partnerId;
    const { rewardId, billingType } = match.params;
    const assets: Asset[] = props.assets;

    const { t } = useI18n();

    React.useEffect(() => {
        if (rewardId) {
            fetchRewardDraft({ partnerId, rewardId });
            fetchRewardAssets({ rewardId });
        } else {
            clearRewardDraft();
        }
        return () => {
            clearRewardDraft();
        };
    }, [
        fetchRewardDraft,
        fetchRewardAssets,
        clearRewardDraft,
        partnerId,
        rewardId,
        history,
        match,
    ]);

    const [state, setState] = useState<State | undefined>(undefined);

    const [validationMessages, onBlur] = useValidation(state, (s) => {
        if (!s) {
            return null;
        }

        if (!!s.title && s.title.length > 255) {
            return {
                title: 'reward.title.validation.tooLong',
            };
        }

        return null;
    });

    const isDirty = state !== undefined;
    const isPending = currentDraft?.state === DraftState.Pending;
    const isSavable = isDirty && !isPending;
    const isPublishable = !isDirty && !isPending && currentDraft !== undefined;

    const onPublish = React.useCallback(
        async (e: SyntheticEvent) => {
            e.preventDefault();
            // eslint-disable-next-line no-restricted-globals
            if (!confirm(t('publishing.confirm.text'))) {
                return;
            }
            await submitRewardDraft({
                rewardId,
            });
        },
        [t, submitRewardDraft, rewardId],
    );

    const matchedBillingType =
        billingType === 'discount'
            ? RewardBillingType.DISCOUNT
            : billingType === 'paid'
            ? RewardBillingType.PAID
            : undefined;

    const finalBillingType: RewardBillingType | undefined =
        state?.billingType || currentDraft?.billingType || matchedBillingType;

    const onSubmit = React.useCallback(async () => {
        const codes = state?.couponCodes || currentDraft?.couponCodes || [];

        const numDuplicates = (assets || [])
            .filter((a) => codes.includes(a.code))
            .reduce((acc) => acc + 1, 0);

        if (numDuplicates > 0) {
            if (
                // eslint-disable-next-line no-restricted-globals
                !confirm(
                    t('reward.couponCodes.duplicatesFound', { numDuplicates }),
                )
            ) {
                return;
            }
        }

        const res = await saveRewardDraft({
            partnerId,
            rewardId: currentDraft?.rewardId || '',
            title: state?.title || currentDraft?.title,
            description: state?.description || currentDraft?.description,
            terms: state?.terms || currentDraft?.terms,
            directLink: state?.directLink || currentDraft?.directLink,
            couponCodes: state?.couponCodes || currentDraft?.couponCodes,
            images: state?.images || currentDraft?.images,
            validUntil: state?.validUntil || currentDraft?.validUntil,
            maxClaims: state?.maxClaims || currentDraft?.maxClaims,
            maxClaimsCooldown:
                state?.maxClaimsCooldown || currentDraft?.maxClaimsCooldown,
            billingType: finalBillingType,
            codePriceCents:
                state?.codePriceCents || currentDraft?.codePriceCents,
            taxableCodeType:
                state?.taxableCodeType || currentDraft?.taxableCodeType,
        });
        if (res.payload) {
            const rewardId = (res.payload as PostRewardDraftResponse).draft
                ?.rewardId;
            if (rewardId) {
                history.replace(
                    `/rewards/draft/${rewardId}/${
                        finalBillingType === RewardBillingType.PAID
                            ? 'paid'
                            : 'discount'
                    }`,
                );
            }
        }
        setState(undefined);
        history.push('/rewards');
    }, [
        t,
        assets,
        saveRewardDraft,
        history,
        partnerId,
        state,
        currentDraft,
        finalBillingType,
    ]);

    const [priceRaw, setPriceRaw] = useState<string | undefined>();
    const formattedPrice = currentDraft
        ? (currentDraft.codePriceCents / 100).toFixed(2)
        : undefined;

    useEffect(() => {
        if (!priceRaw || priceRaw === formattedPrice) {
            return;
        }
        const p = Math.round(parseFloat(priceRaw.replace(',', '.')) * 100);
        setState((s) => ({
            ...s,
            codePriceCents: Number.isNaN(p) ? 0 : p,
        }));
    }, [priceRaw, formattedPrice, setState]);

    if (props.isLoading) {
        return <>Loading...</>;
    }

    return (
        <Form
            onSubmit={onSubmit}
            actions={
                <>
                    <div>
                        <Button
                            secondary
                            label="reward.publishDraft"
                            onClick={onPublish}
                            disabled={!isPublishable}
                        />
                    </div>
                    <div>
                        <Button
                            primary
                            label="reward.saveDraft"
                            onClick={onSubmit}
                            disabled={!isSavable}
                        />
                    </div>
                </>
            }
        >
            <ReviewDisplay
                state={currentDraft?.state}
                reason={currentDraft?.rejectionReason}
            />

            <FormSection
                label="reward.general.title"
                subtitle="reward.general.subtitle"
            >
                <TextInput
                    label="reward.title.label"
                    defaultValue={currentDraft?.title}
                    value={state?.title}
                    onChanged={(title) => setState({ ...state, title })}
                    disabled={isPending}
                    onBlur={onBlur}
                    errorMessage={validationMessages?.title}
                />
                <TextInput
                    label="reward.description.label"
                    defaultValue={currentDraft?.description}
                    value={state?.description}
                    onChanged={(description) =>
                        setState({ ...state, description })
                    }
                    rows={5}
                    disabled={isPending}
                />
                <TextInput
                    label="reward.terms.label"
                    defaultValue={currentDraft?.terms}
                    value={state?.terms}
                    onChanged={(terms) => setState({ ...state, terms })}
                    rows={5}
                    disabled={isPending}
                />
                <TextInput
                    label="reward.directLink.label"
                    defaultValue={currentDraft?.directLink}
                    value={state?.directLink}
                    onChanged={(directLink) =>
                        setState({ ...state, directLink })
                    }
                    disabled={isPending}
                    placeholder="https://"
                />
                <DateInput
                    label="reward.validUntil.label"
                    placeholder="reward.validUntil.placeholder"
                    value={
                        state?.validUntil && Timestamp.toDate(state?.validUntil)
                    }
                    defaultValue={
                        currentDraft?.validUntil &&
                        Timestamp.toDate(currentDraft?.validUntil)
                    }
                    onChanged={(validUntil) =>
                        setState({
                            ...state,
                            validUntil:
                                validUntil && Timestamp.fromDate(validUntil),
                        })
                    }
                />
            </FormSection>
            <FormSection
                label="reward.images.label"
                subtitle="reward.images.explainer"
            >
                <FileUpload
                    partnerId={partnerId}
                    label="reward.images.label"
                    message="reward.images.message"
                    files={state?.images || currentDraft?.images || []}
                    onSingleChanged={(image) =>
                        setState({
                            ...state,
                            images: !!image ? [image] : undefined,
                        })
                    }
                    disabled={isPending}
                />
            </FormSection>
            {!props.isLive && (
                <>
                    <FormSection
                        label="reward.couponCodes.label"
                        subtitle="reward.couponCodes.explainer"
                        leftColumnChildren={
                            <Button
                                secondary
                                className="mt-2"
                                label="reward.couponCodes.downloadTemplate"
                                onClick={(e) => {
                                    e.preventDefault();
                                    triggerCSVDownload([
                                        ['code'],
                                        ['your-code-1'],
                                        ['your-code-2'],
                                        ['...'],
                                    ]);
                                }}
                            />
                        }
                    >
                        <div className="space-y-6">
                            <RewardCodeCSVUpload
                                label="reward.couponCodes.label"
                                current={
                                    state?.couponCodes ||
                                    currentDraft?.couponCodes ||
                                    []
                                }
                                onCodesReceived={(couponCodes) => {
                                    setState({
                                        ...state,
                                        couponCodes,
                                    });
                                }}
                                disabled={isPending}
                            />
                            <RewardCodeGenerator
                                onCodesReceived={(couponCodes) => {
                                    setState({
                                        ...state,
                                        couponCodes,
                                    });
                                }}
                                disabled={isPending}
                            />
                        </div>
                    </FormSection>
                    {finalBillingType === RewardBillingType.PAID && (
                        <FormSection
                            label="reward.billing.label"
                            subtitle="reward.billing.explainer"
                        >
                            <TextInput
                                label="reward.codePrice.label"
                                value={priceRaw}
                                defaultValue={formattedPrice}
                                onChanged={setPriceRaw}
                                prefix="€"
                            />
                        </FormSection>
                    )}
                </>
            )}
        </Form>
    );
};

export default withRouter(connector(RewardFormInner));
