import * as React from 'react';
import { useAppSelector } from '../redux/react';
import { partnerSelectors } from './store';

type Props = {
    id: string;
};

const PartnerName: React.FunctionComponent<Props> = (props) => {
    const partner = useAppSelector((state) =>
        partnerSelectors.byId(state, { id: props.id }),
    );

    if (!partner) {
        return null;
    }

    return <>{partner.legalName}</>;
};

export default PartnerName;
