import { BaseSyntheticEvent, useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import cx from 'classnames';
import { Button } from '../common/Button';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { authActions, authSelectors } from './store';
import { Redirect, useHistory } from 'react-router-dom';
import { app } from './firebase';
import { useI18n } from '../i18n';
import logo from '../images/logo.png';

const Login: React.FunctionComponent = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [isResetView, setIsResetView] = useState(false);

    const href = useMemo(() => window.location.href, []);

    const isSigninLink = useMemo(() => {
        return app.auth().isSignInWithEmailLink(href);
    }, [href]);

    const isLoginInProgress = useAppSelector(authSelectors.isLoginInProgress);
    const errorMessage = useAppSelector(authSelectors.getLoginError);
    const isSignedIn = useAppSelector(authSelectors.isSignedIn);
    // const requiresNewInvite = useAppSelector(authSelectors.requiresNewInvite);

    const dispatch = useAppDispatch();
    const onSubmit = useCallback(
        (e: BaseSyntheticEvent) => {
            e.preventDefault();

            if (isSigninLink) {
                dispatch(authActions.loginWithLink({ email, href }));
            } else if (isResetView) {
                dispatch(authActions.resetPassword({ email }));
            } else {
                dispatch(authActions.login({ email, password }));
            }
        },
        [dispatch, email, password, isSigninLink, isResetView, href],
    );

    const onRequestPasswordReset = () => setIsResetView(true);

    const history = useHistory();

    const onUsePassword = useCallback(() => {
        history.replace('/login');
    }, [history]);

    const { t } = useI18n();

    if (isSignedIn) {
        return <Redirect to="/" />;
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full">
                <h2 className="text-center text-lg leading-9 text-primary flex flex-col items-center">
                    <img src={logo} alt="PLAN3T logo" className="h-6" />
                    <span>{t('auth.login.title')}</span>
                </h2>
                <form className="mt-8" onSubmit={onSubmit}>
                    <div className="rounded-md shadow-sm">
                        <div>
                            <input
                                aria-label={t('common.email')}
                                name="email"
                                type="email"
                                required
                                className={cx(
                                    'appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-green-500 focus:z-10 sm:text-sm sm:leading-5',
                                    {
                                        'rounded-t-md':
                                            !isSigninLink && !isResetView,
                                        'rounded-md':
                                            isSigninLink || isResetView,
                                    },
                                )}
                                placeholder={t('common.email')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        {!isSigninLink && !isResetView && (
                            <div className="-mt-px">
                                <input
                                    aria-label={t('common.password')}
                                    name="password"
                                    type="password"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-green-500 focus:z-10 sm:text-sm sm:leading-5"
                                    placeholder={t('common.password')}
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                            </div>
                        )}
                    </div>

                    {errorMessage && (
                        <p className="text-red-600 px-3 py-1">
                            {t(errorMessage)}
                        </p>
                    )}

                    <div className="mt-6 flex items-center justify-end">
                        {!isSigninLink && (
                            <div className="text-sm leading-5">
                                <button
                                    type="button"
                                    onClick={onRequestPasswordReset}
                                    className="text-primary hover:opacity-0 focus:outline-none focus:underline transition-default"
                                >
                                    {t('auth.login.forgotLinkLabel')}
                                </button>
                            </div>
                        )}
                        {isSigninLink && (
                            <div className="text-sm leading-5">
                                <button
                                    onClick={onUsePassword}
                                    className="text-primary hover:opacity-75 focus:outline-none focus:underline transition-default"
                                >
                                    {t('auth.login.usePassword')}
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="mt-6">
                        {isLoginInProgress ? (
                            '...'
                        ) : (
                            <Button
                                primary
                                label={
                                    !isResetView
                                        ? 'auth.login.buttonLabel'
                                        : 'auth.reset.buttonLabel'
                                }
                            />
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
