import { useState } from 'react';
import * as React from 'react';
import cx from 'classnames';
import { useHtmlId } from '../../hooks';
import { Translatable, useI18n } from '../../i18n';

type Props = {
    label: Translatable;
    placeholder?: string;
    value: string | undefined;
    defaultValue?: string;
    onChanged: (v: string) => void;
    errorMessage?: string | null;
    prefix?: string;
    type?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    rows?: number;
    onBlur?: () => void;
};

const TextInput: React.FunctionComponent<Props> = (props) => {
    const id = useHtmlId();
    const { t } = useI18n();
    const [isDirty, setDirty] = useState(false);

    const changed = (val: string) => {
        setDirty(true);
        props.onChanged(val);
    };
    let input;

    if (!props.rows) {
        input = (
            <input
                id={id}
                type={props.type}
                className={cx(
                    'form-input block w-full sm:text-sm sm:leading-5',
                    {
                        'pl-6': !!props.prefix,
                    },
                )}
                placeholder={props.placeholder}
                value={isDirty ? props.value || '' : props.defaultValue || ''}
                onChange={(e) => changed(e.target.value)}
                disabled={props.disabled}
                autoFocus={!!props.autoFocus}
                onBlur={props.onBlur}
            />
        );
    } else {
        input = (
            <textarea
                id={id}
                className="form-textarea block w-full sm:text-sm sm:leading-5"
                placeholder={props.placeholder}
                value={isDirty ? props.value || '' : props.defaultValue || ''}
                onChange={(e) => changed(e.target.value)}
                disabled={props.disabled}
                autoFocus={!!props.autoFocus}
                rows={props.rows}
                onBlur={props.onBlur}
            />
        );
    }

    return (
        <>
            <label htmlFor={id} className="block pl-3 form-label">
                {t(props.label)}
            </label>
            <div
                className={cx(
                    'mt-1 relative rounded-md text-semantic-primary',
                    {
                        'opacity-75': !!props.disabled,
                    },
                )}
            >
                {props.prefix && !props.rows && (
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">
                            {' '}
                            {props.prefix}{' '}
                        </span>
                    </div>
                )}
                {input}
            </div>
            {!!props.errorMessage ? (
                <p className="font-medium text-sm text-red-400 leading-5 py-1">
                    {t(props.errorMessage)}
                </p>
            ) : (
                <div className="py-1 leading-5">&nbsp;</div>
            )}
        </>
    );
};
export default TextInput;
