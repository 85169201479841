// @generated by protobuf-ts 2.1.0 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "billing/rpc/billing.proto" (package "one.plan3t.core.billing.rpc", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../google/protobuf/timestamp";
import { Date } from "../../model/common";
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.BillingPeriod
 */
export interface BillingPeriod {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string code = 2;
     */
    code: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Date starts_at = 3;
     */
    startsAt?: Date;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Date ends_at = 4;
     */
    endsAt?: Date;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetBillingPeriodsRequest
 */
export interface GetBillingPeriodsRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetBillingPeriodsResponse
 */
export interface GetBillingPeriodsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.billing.rpc.BillingPeriod periods = 1;
     */
    periods: BillingPeriod[];
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetBillingPeriodStateRequest
 */
export interface GetBillingPeriodStateRequest {
    /**
     * @generated from protobuf field: int64 period = 1;
     */
    period: number;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetBillingPeriodStateResponse
 */
export interface GetBillingPeriodStateResponse {
    /**
     * @generated from protobuf field: string state_code = 1;
     */
    stateCode: string;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.SetBillingPeriodStateRequest
 */
export interface SetBillingPeriodStateRequest {
    /**
     * @generated from protobuf field: int64 period = 1;
     */
    period: number;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string state_code = 3;
     */
    stateCode: string;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.SetBillingPeriodStateResponse
 */
export interface SetBillingPeriodStateResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.AddPostingsRequest
 */
export interface AddPostingsRequest {
    /**
     * @generated from protobuf field: int64 period = 1;
     */
    period: number;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.billing.rpc.AddPostingsRequest.PostingCreationData postings = 3;
     */
    postings: AddPostingsRequest_PostingCreationData[];
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.AddPostingsRequest.PostingCreationData
 */
export interface AddPostingsRequest_PostingCreationData {
    /**
     * @generated from protobuf field: string type = 3;
     */
    type: string;
    /**
     * @generated from protobuf field: int32 amount_unscaled = 4;
     */
    amountUnscaled: number;
    /**
     * @generated from protobuf field: int32 amount_scale = 5;
     */
    amountScale: number;
    /**
     * @generated from protobuf field: string currency = 6;
     */
    currency: string;
    /**
     * @generated from protobuf field: string document_id = 9;
     */
    documentId: string;
    /**
     * @generated from protobuf field: map<string, string> metadata = 10;
     */
    metadata: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: string idempotency_key = 11;
     */
    idempotencyKey: string;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.AddPostingsResponse
 */
export interface AddPostingsResponse {
    /**
     * @generated from protobuf field: repeated int64 created_ids = 1;
     */
    createdIds: number[];
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.Posting
 */
export interface Posting {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: int64 period_id = 2;
     */
    periodId: number;
    /**
     * @generated from protobuf field: string type = 3;
     */
    type: string;
    /**
     * @generated from protobuf field: int32 amount_unscaled = 4;
     */
    amountUnscaled: number;
    /**
     * @generated from protobuf field: int32 amount_scale = 5;
     */
    amountScale: number;
    /**
     * @generated from protobuf field: string currency = 6;
     */
    currency: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 7;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string created_by = 8;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: string document_id = 9;
     */
    documentId: string;
    /**
     * @generated from protobuf field: map<string, string> metadata = 10;
     */
    metadata: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetPostingsRequest
 */
export interface GetPostingsRequest {
    /**
     * @generated from protobuf field: int64 period = 1;
     */
    period: number;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetPostingsResponse
 */
export interface GetPostingsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.billing.rpc.Posting items = 1;
     */
    items: Posting[];
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.AddDocumentRequest
 */
export interface AddDocumentRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: int64 period = 2;
     */
    period: number;
    /**
     * @generated from protobuf field: string type = 3;
     */
    type: string;
    /**
     * @generated from protobuf field: string partner_id = 4;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: map<string, string> metadata = 5;
     */
    metadata: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: bytes content = 6;
     */
    content: Uint8Array;
    /**
     * @generated from protobuf field: string mime_type = 7;
     */
    mimeType: string;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.AddDocumentResponse
 */
export interface AddDocumentResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.Document
 */
export interface Document {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: int64 period = 2;
     */
    period: number;
    /**
     * @generated from protobuf field: string type = 3;
     */
    type: string;
    /**
     * @generated from protobuf field: string partner_id = 4;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 5;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string created_by = 6;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: map<string, string> metadata = 7;
     */
    metadata: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: bytes content = 8;
     */
    content: Uint8Array;
    /**
     * @generated from protobuf field: string mime_type = 9;
     */
    mimeType: string;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetDocumentsRequest
 */
export interface GetDocumentsRequest {
    /**
     * @generated from protobuf field: int64 period = 1;
     */
    period: number;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetDocumentsResponse
 */
export interface GetDocumentsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.billing.rpc.Document documents = 1;
     */
    documents: Document[];
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GeneratePostingsForAccountingCommandsRequest
 */
export interface GeneratePostingsForAccountingCommandsRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GeneratePostingsForAccountingCommandsResponse
 */
export interface GeneratePostingsForAccountingCommandsResponse {
}
/**
 * An AggregationRule describes how postings are supposed to be aggregated.
 *
 * These rules are defined using the following fields
 * 1. round_first
 *    if this is `true`, postings are rounded and all rounded posting amounts are summed up.
 *    otherwise, all amounts are summed and the final aggregated amount is rounded.
 * 2. rounding_type
 *      0 => nearest  (2.4=2,2.5=3)
 *      1 => down     (2.4=2,2.5=2)
 *      2 => up       (2.4=3,2.5=3)
 * 3. precision
 *    How many decimals are supposed to be kept after rounding
 *      0:    2.4235 => 2
 *      1:    2.4235 => 2.1
 *    The precision is applied while rounding, so round_first also affects what this fields influences.
 *
 * @generated from protobuf message one.plan3t.core.billing.rpc.AggregationRule
 */
export interface AggregationRule {
    /**
     * @generated from protobuf field: bool round_first = 1;
     */
    roundFirst: boolean;
    /**
     * @generated from protobuf field: int32 rounding_type = 2;
     */
    roundingType: number;
    /**
     * @generated from protobuf field: int32 precision = 3;
     */
    precision: number;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetAggregationRulesRequest
 */
export interface GetAggregationRulesRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetAggregationRulesResponse
 */
export interface GetAggregationRulesResponse {
    /**
     * @generated from protobuf field: map<string, one.plan3t.core.billing.rpc.AggregationRule> by_posting_type = 1;
     */
    byPostingType: {
        [key: string]: AggregationRule;
    };
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetResubmissionsRequest
 */
export interface GetResubmissionsRequest {
    /**
     * @generated from protobuf field: int64 period = 1;
     */
    period: number;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetResubmissionsResponse
 */
export interface GetResubmissionsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.billing.rpc.Document documents = 1;
     */
    documents: Document[];
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.CreateInvoiceRequest
 */
export interface CreateInvoiceRequest {
    /**
     * @generated from protobuf field: int64 period = 1;
     */
    period: number;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.CreateInvoiceResponse
 */
export interface CreateInvoiceResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.Invoice
 */
export interface Invoice {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: int64 period_id = 3;
     */
    periodId: number;
    /**
     * @generated from protobuf field: int64 external_id = 4;
     */
    externalId: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 5;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string created_by = 6;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: string external_link = 7;
     */
    externalLink: string;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetInvoicesRequest
 */
export interface GetInvoicesRequest {
    /**
     * @generated from protobuf field: int64 period = 1;
     */
    period: number;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetInvoicesResponse
 */
export interface GetInvoicesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.billing.rpc.Invoice invoices = 1;
     */
    invoices: Invoice[];
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetPeriodTotalsRequest
 */
export interface GetPeriodTotalsRequest {
    /**
     * @generated from protobuf field: int64 period = 1;
     */
    period: number;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetPeriodTotalsResponse
 */
export interface GetPeriodTotalsResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.billing.rpc.GetPeriodTotalsResponse.Totals totals = 1;
     */
    totals?: GetPeriodTotalsResponse_Totals;
    /**
     * @generated from protobuf field: map<string, one.plan3t.core.billing.rpc.GetPeriodTotalsResponse.Totals> by_type = 2;
     */
    byType: {
        [key: string]: GetPeriodTotalsResponse_Totals;
    };
    /**
     * @generated from protobuf field: int64 num_postings = 3;
     */
    numPostings: number;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetPeriodTotalsResponse.Total
 */
export interface GetPeriodTotalsResponse_Total {
    /**
     * @generated from protobuf field: int32 amount_unscaled = 1;
     */
    amountUnscaled: number;
    /**
     * @generated from protobuf field: int32 amount_scale = 2;
     */
    amountScale: number;
    /**
     * @generated from protobuf field: string currency = 3;
     */
    currency: string;
}
/**
 * @generated from protobuf message one.plan3t.core.billing.rpc.GetPeriodTotalsResponse.Totals
 */
export interface GetPeriodTotalsResponse_Totals {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.billing.rpc.GetPeriodTotalsResponse.Total totals = 1;
     */
    totals: GetPeriodTotalsResponse_Total[];
}
// @generated message type with reflection information, may provide speed optimized methods
class BillingPeriod$Type extends MessageType<BillingPeriod> {
    constructor() {
        super("one.plan3t.core.billing.rpc.BillingPeriod", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "starts_at", kind: "message", T: () => Date },
            { no: 4, name: "ends_at", kind: "message", T: () => Date }
        ]);
    }
    create(value?: PartialMessage<BillingPeriod>): BillingPeriod {
        const message = { id: 0, code: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BillingPeriod>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BillingPeriod): BillingPeriod {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* string code */ 2:
                    message.code = reader.string();
                    break;
                case /* one.plan3t.core.model.Date starts_at */ 3:
                    message.startsAt = Date.internalBinaryRead(reader, reader.uint32(), options, message.startsAt);
                    break;
                case /* one.plan3t.core.model.Date ends_at */ 4:
                    message.endsAt = Date.internalBinaryRead(reader, reader.uint32(), options, message.endsAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BillingPeriod, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* string code = 2; */
        if (message.code !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.code);
        /* one.plan3t.core.model.Date starts_at = 3; */
        if (message.startsAt)
            Date.internalBinaryWrite(message.startsAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.Date ends_at = 4; */
        if (message.endsAt)
            Date.internalBinaryWrite(message.endsAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.BillingPeriod
 */
export const BillingPeriod = new BillingPeriod$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBillingPeriodsRequest$Type extends MessageType<GetBillingPeriodsRequest> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetBillingPeriodsRequest", []);
    }
    create(value?: PartialMessage<GetBillingPeriodsRequest>): GetBillingPeriodsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBillingPeriodsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBillingPeriodsRequest): GetBillingPeriodsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetBillingPeriodsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetBillingPeriodsRequest
 */
export const GetBillingPeriodsRequest = new GetBillingPeriodsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBillingPeriodsResponse$Type extends MessageType<GetBillingPeriodsResponse> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetBillingPeriodsResponse", [
            { no: 1, name: "periods", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BillingPeriod }
        ]);
    }
    create(value?: PartialMessage<GetBillingPeriodsResponse>): GetBillingPeriodsResponse {
        const message = { periods: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBillingPeriodsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBillingPeriodsResponse): GetBillingPeriodsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.billing.rpc.BillingPeriod periods */ 1:
                    message.periods.push(BillingPeriod.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBillingPeriodsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.billing.rpc.BillingPeriod periods = 1; */
        for (let i = 0; i < message.periods.length; i++)
            BillingPeriod.internalBinaryWrite(message.periods[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetBillingPeriodsResponse
 */
export const GetBillingPeriodsResponse = new GetBillingPeriodsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBillingPeriodStateRequest$Type extends MessageType<GetBillingPeriodStateRequest> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetBillingPeriodStateRequest", [
            { no: 1, name: "period", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetBillingPeriodStateRequest>): GetBillingPeriodStateRequest {
        const message = { period: 0, partnerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBillingPeriodStateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBillingPeriodStateRequest): GetBillingPeriodStateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 period */ 1:
                    message.period = reader.int64().toNumber();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBillingPeriodStateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 period = 1; */
        if (message.period !== 0)
            writer.tag(1, WireType.Varint).int64(message.period);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetBillingPeriodStateRequest
 */
export const GetBillingPeriodStateRequest = new GetBillingPeriodStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBillingPeriodStateResponse$Type extends MessageType<GetBillingPeriodStateResponse> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetBillingPeriodStateResponse", [
            { no: 1, name: "state_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetBillingPeriodStateResponse>): GetBillingPeriodStateResponse {
        const message = { stateCode: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBillingPeriodStateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBillingPeriodStateResponse): GetBillingPeriodStateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string state_code */ 1:
                    message.stateCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBillingPeriodStateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string state_code = 1; */
        if (message.stateCode !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stateCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetBillingPeriodStateResponse
 */
export const GetBillingPeriodStateResponse = new GetBillingPeriodStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetBillingPeriodStateRequest$Type extends MessageType<SetBillingPeriodStateRequest> {
    constructor() {
        super("one.plan3t.core.billing.rpc.SetBillingPeriodStateRequest", [
            { no: 1, name: "period", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "state_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SetBillingPeriodStateRequest>): SetBillingPeriodStateRequest {
        const message = { period: 0, partnerId: "", stateCode: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetBillingPeriodStateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetBillingPeriodStateRequest): SetBillingPeriodStateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 period */ 1:
                    message.period = reader.int64().toNumber();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                case /* string state_code */ 3:
                    message.stateCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetBillingPeriodStateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 period = 1; */
        if (message.period !== 0)
            writer.tag(1, WireType.Varint).int64(message.period);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        /* string state_code = 3; */
        if (message.stateCode !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.stateCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.SetBillingPeriodStateRequest
 */
export const SetBillingPeriodStateRequest = new SetBillingPeriodStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetBillingPeriodStateResponse$Type extends MessageType<SetBillingPeriodStateResponse> {
    constructor() {
        super("one.plan3t.core.billing.rpc.SetBillingPeriodStateResponse", []);
    }
    create(value?: PartialMessage<SetBillingPeriodStateResponse>): SetBillingPeriodStateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetBillingPeriodStateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetBillingPeriodStateResponse): SetBillingPeriodStateResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetBillingPeriodStateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.SetBillingPeriodStateResponse
 */
export const SetBillingPeriodStateResponse = new SetBillingPeriodStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddPostingsRequest$Type extends MessageType<AddPostingsRequest> {
    constructor() {
        super("one.plan3t.core.billing.rpc.AddPostingsRequest", [
            { no: 1, name: "period", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "postings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AddPostingsRequest_PostingCreationData }
        ]);
    }
    create(value?: PartialMessage<AddPostingsRequest>): AddPostingsRequest {
        const message = { period: 0, partnerId: "", postings: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddPostingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddPostingsRequest): AddPostingsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 period */ 1:
                    message.period = reader.int64().toNumber();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                case /* repeated one.plan3t.core.billing.rpc.AddPostingsRequest.PostingCreationData postings */ 3:
                    message.postings.push(AddPostingsRequest_PostingCreationData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddPostingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 period = 1; */
        if (message.period !== 0)
            writer.tag(1, WireType.Varint).int64(message.period);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        /* repeated one.plan3t.core.billing.rpc.AddPostingsRequest.PostingCreationData postings = 3; */
        for (let i = 0; i < message.postings.length; i++)
            AddPostingsRequest_PostingCreationData.internalBinaryWrite(message.postings[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.AddPostingsRequest
 */
export const AddPostingsRequest = new AddPostingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddPostingsRequest_PostingCreationData$Type extends MessageType<AddPostingsRequest_PostingCreationData> {
    constructor() {
        super("one.plan3t.core.billing.rpc.AddPostingsRequest.PostingCreationData", [
            { no: 3, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "amount_unscaled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "amount_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "document_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "metadata", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 11, name: "idempotency_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddPostingsRequest_PostingCreationData>): AddPostingsRequest_PostingCreationData {
        const message = { type: "", amountUnscaled: 0, amountScale: 0, currency: "", documentId: "", metadata: {}, idempotencyKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddPostingsRequest_PostingCreationData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddPostingsRequest_PostingCreationData): AddPostingsRequest_PostingCreationData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 3:
                    message.type = reader.string();
                    break;
                case /* int32 amount_unscaled */ 4:
                    message.amountUnscaled = reader.int32();
                    break;
                case /* int32 amount_scale */ 5:
                    message.amountScale = reader.int32();
                    break;
                case /* string currency */ 6:
                    message.currency = reader.string();
                    break;
                case /* string document_id */ 9:
                    message.documentId = reader.string();
                    break;
                case /* map<string, string> metadata */ 10:
                    this.binaryReadMap10(message.metadata, reader, options);
                    break;
                case /* string idempotency_key */ 11:
                    message.idempotencyKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap10(map: AddPostingsRequest_PostingCreationData["metadata"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof AddPostingsRequest_PostingCreationData["metadata"] | undefined, val: AddPostingsRequest_PostingCreationData["metadata"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.billing.rpc.AddPostingsRequest.PostingCreationData.metadata");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: AddPostingsRequest_PostingCreationData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type = 3; */
        if (message.type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.type);
        /* int32 amount_unscaled = 4; */
        if (message.amountUnscaled !== 0)
            writer.tag(4, WireType.Varint).int32(message.amountUnscaled);
        /* int32 amount_scale = 5; */
        if (message.amountScale !== 0)
            writer.tag(5, WireType.Varint).int32(message.amountScale);
        /* string currency = 6; */
        if (message.currency !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.currency);
        /* string document_id = 9; */
        if (message.documentId !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.documentId);
        /* map<string, string> metadata = 10; */
        for (let k of Object.keys(message.metadata))
            writer.tag(10, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.metadata[k]).join();
        /* string idempotency_key = 11; */
        if (message.idempotencyKey !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.idempotencyKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.AddPostingsRequest.PostingCreationData
 */
export const AddPostingsRequest_PostingCreationData = new AddPostingsRequest_PostingCreationData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddPostingsResponse$Type extends MessageType<AddPostingsResponse> {
    constructor() {
        super("one.plan3t.core.billing.rpc.AddPostingsResponse", [
            { no: 1, name: "created_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<AddPostingsResponse>): AddPostingsResponse {
        const message = { createdIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddPostingsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddPostingsResponse): AddPostingsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 created_ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.createdIds.push(reader.int64().toNumber());
                    else
                        message.createdIds.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddPostingsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 created_ids = 1; */
        if (message.createdIds.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.createdIds.length; i++)
                writer.int64(message.createdIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.AddPostingsResponse
 */
export const AddPostingsResponse = new AddPostingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Posting$Type extends MessageType<Posting> {
    constructor() {
        super("one.plan3t.core.billing.rpc.Posting", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "period_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "amount_unscaled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "amount_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 8, name: "created_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "document_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "metadata", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<Posting>): Posting {
        const message = { id: 0, periodId: 0, type: "", amountUnscaled: 0, amountScale: 0, currency: "", createdBy: "", documentId: "", metadata: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Posting>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Posting): Posting {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* int64 period_id */ 2:
                    message.periodId = reader.int64().toNumber();
                    break;
                case /* string type */ 3:
                    message.type = reader.string();
                    break;
                case /* int32 amount_unscaled */ 4:
                    message.amountUnscaled = reader.int32();
                    break;
                case /* int32 amount_scale */ 5:
                    message.amountScale = reader.int32();
                    break;
                case /* string currency */ 6:
                    message.currency = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 7:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string created_by */ 8:
                    message.createdBy = reader.string();
                    break;
                case /* string document_id */ 9:
                    message.documentId = reader.string();
                    break;
                case /* map<string, string> metadata */ 10:
                    this.binaryReadMap10(message.metadata, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap10(map: Posting["metadata"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Posting["metadata"] | undefined, val: Posting["metadata"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.billing.rpc.Posting.metadata");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: Posting, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* int64 period_id = 2; */
        if (message.periodId !== 0)
            writer.tag(2, WireType.Varint).int64(message.periodId);
        /* string type = 3; */
        if (message.type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.type);
        /* int32 amount_unscaled = 4; */
        if (message.amountUnscaled !== 0)
            writer.tag(4, WireType.Varint).int32(message.amountUnscaled);
        /* int32 amount_scale = 5; */
        if (message.amountScale !== 0)
            writer.tag(5, WireType.Varint).int32(message.amountScale);
        /* string currency = 6; */
        if (message.currency !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.currency);
        /* google.protobuf.Timestamp created_at = 7; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string created_by = 8; */
        if (message.createdBy !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.createdBy);
        /* string document_id = 9; */
        if (message.documentId !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.documentId);
        /* map<string, string> metadata = 10; */
        for (let k of Object.keys(message.metadata))
            writer.tag(10, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.metadata[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.Posting
 */
export const Posting = new Posting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPostingsRequest$Type extends MessageType<GetPostingsRequest> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetPostingsRequest", [
            { no: 1, name: "period", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPostingsRequest>): GetPostingsRequest {
        const message = { period: 0, partnerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPostingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPostingsRequest): GetPostingsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 period */ 1:
                    message.period = reader.int64().toNumber();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPostingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 period = 1; */
        if (message.period !== 0)
            writer.tag(1, WireType.Varint).int64(message.period);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetPostingsRequest
 */
export const GetPostingsRequest = new GetPostingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPostingsResponse$Type extends MessageType<GetPostingsResponse> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetPostingsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Posting }
        ]);
    }
    create(value?: PartialMessage<GetPostingsResponse>): GetPostingsResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPostingsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPostingsResponse): GetPostingsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.billing.rpc.Posting items */ 1:
                    message.items.push(Posting.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPostingsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.billing.rpc.Posting items = 1; */
        for (let i = 0; i < message.items.length; i++)
            Posting.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetPostingsResponse
 */
export const GetPostingsResponse = new GetPostingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddDocumentRequest$Type extends MessageType<AddDocumentRequest> {
    constructor() {
        super("one.plan3t.core.billing.rpc.AddDocumentRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "period", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "metadata", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 6, name: "content", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 7, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddDocumentRequest>): AddDocumentRequest {
        const message = { id: "", period: 0, type: "", partnerId: "", metadata: {}, content: new Uint8Array(0), mimeType: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddDocumentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddDocumentRequest): AddDocumentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* int64 period */ 2:
                    message.period = reader.int64().toNumber();
                    break;
                case /* string type */ 3:
                    message.type = reader.string();
                    break;
                case /* string partner_id */ 4:
                    message.partnerId = reader.string();
                    break;
                case /* map<string, string> metadata */ 5:
                    this.binaryReadMap5(message.metadata, reader, options);
                    break;
                case /* bytes content */ 6:
                    message.content = reader.bytes();
                    break;
                case /* string mime_type */ 7:
                    message.mimeType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: AddDocumentRequest["metadata"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof AddDocumentRequest["metadata"] | undefined, val: AddDocumentRequest["metadata"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.billing.rpc.AddDocumentRequest.metadata");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: AddDocumentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* int64 period = 2; */
        if (message.period !== 0)
            writer.tag(2, WireType.Varint).int64(message.period);
        /* string type = 3; */
        if (message.type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.type);
        /* string partner_id = 4; */
        if (message.partnerId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.partnerId);
        /* map<string, string> metadata = 5; */
        for (let k of Object.keys(message.metadata))
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.metadata[k]).join();
        /* bytes content = 6; */
        if (message.content.length)
            writer.tag(6, WireType.LengthDelimited).bytes(message.content);
        /* string mime_type = 7; */
        if (message.mimeType !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.mimeType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.AddDocumentRequest
 */
export const AddDocumentRequest = new AddDocumentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddDocumentResponse$Type extends MessageType<AddDocumentResponse> {
    constructor() {
        super("one.plan3t.core.billing.rpc.AddDocumentResponse", []);
    }
    create(value?: PartialMessage<AddDocumentResponse>): AddDocumentResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddDocumentResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddDocumentResponse): AddDocumentResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AddDocumentResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.AddDocumentResponse
 */
export const AddDocumentResponse = new AddDocumentResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Document$Type extends MessageType<Document> {
    constructor() {
        super("one.plan3t.core.billing.rpc.Document", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "period", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "created_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "metadata", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 8, name: "content", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 9, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Document>): Document {
        const message = { id: "", period: 0, type: "", partnerId: "", createdBy: "", metadata: {}, content: new Uint8Array(0), mimeType: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Document>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Document): Document {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* int64 period */ 2:
                    message.period = reader.int64().toNumber();
                    break;
                case /* string type */ 3:
                    message.type = reader.string();
                    break;
                case /* string partner_id */ 4:
                    message.partnerId = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 5:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string created_by */ 6:
                    message.createdBy = reader.string();
                    break;
                case /* map<string, string> metadata */ 7:
                    this.binaryReadMap7(message.metadata, reader, options);
                    break;
                case /* bytes content */ 8:
                    message.content = reader.bytes();
                    break;
                case /* string mime_type */ 9:
                    message.mimeType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap7(map: Document["metadata"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Document["metadata"] | undefined, val: Document["metadata"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.billing.rpc.Document.metadata");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: Document, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* int64 period = 2; */
        if (message.period !== 0)
            writer.tag(2, WireType.Varint).int64(message.period);
        /* string type = 3; */
        if (message.type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.type);
        /* string partner_id = 4; */
        if (message.partnerId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.partnerId);
        /* google.protobuf.Timestamp created_at = 5; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string created_by = 6; */
        if (message.createdBy !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.createdBy);
        /* map<string, string> metadata = 7; */
        for (let k of Object.keys(message.metadata))
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.metadata[k]).join();
        /* bytes content = 8; */
        if (message.content.length)
            writer.tag(8, WireType.LengthDelimited).bytes(message.content);
        /* string mime_type = 9; */
        if (message.mimeType !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.mimeType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.Document
 */
export const Document = new Document$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDocumentsRequest$Type extends MessageType<GetDocumentsRequest> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetDocumentsRequest", [
            { no: 1, name: "period", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetDocumentsRequest>): GetDocumentsRequest {
        const message = { period: 0, partnerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetDocumentsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDocumentsRequest): GetDocumentsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 period */ 1:
                    message.period = reader.int64().toNumber();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDocumentsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 period = 1; */
        if (message.period !== 0)
            writer.tag(1, WireType.Varint).int64(message.period);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetDocumentsRequest
 */
export const GetDocumentsRequest = new GetDocumentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDocumentsResponse$Type extends MessageType<GetDocumentsResponse> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetDocumentsResponse", [
            { no: 1, name: "documents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Document }
        ]);
    }
    create(value?: PartialMessage<GetDocumentsResponse>): GetDocumentsResponse {
        const message = { documents: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetDocumentsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDocumentsResponse): GetDocumentsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.billing.rpc.Document documents */ 1:
                    message.documents.push(Document.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDocumentsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.billing.rpc.Document documents = 1; */
        for (let i = 0; i < message.documents.length; i++)
            Document.internalBinaryWrite(message.documents[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetDocumentsResponse
 */
export const GetDocumentsResponse = new GetDocumentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeneratePostingsForAccountingCommandsRequest$Type extends MessageType<GeneratePostingsForAccountingCommandsRequest> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GeneratePostingsForAccountingCommandsRequest", []);
    }
    create(value?: PartialMessage<GeneratePostingsForAccountingCommandsRequest>): GeneratePostingsForAccountingCommandsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GeneratePostingsForAccountingCommandsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GeneratePostingsForAccountingCommandsRequest): GeneratePostingsForAccountingCommandsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GeneratePostingsForAccountingCommandsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GeneratePostingsForAccountingCommandsRequest
 */
export const GeneratePostingsForAccountingCommandsRequest = new GeneratePostingsForAccountingCommandsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeneratePostingsForAccountingCommandsResponse$Type extends MessageType<GeneratePostingsForAccountingCommandsResponse> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GeneratePostingsForAccountingCommandsResponse", []);
    }
    create(value?: PartialMessage<GeneratePostingsForAccountingCommandsResponse>): GeneratePostingsForAccountingCommandsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GeneratePostingsForAccountingCommandsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GeneratePostingsForAccountingCommandsResponse): GeneratePostingsForAccountingCommandsResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GeneratePostingsForAccountingCommandsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GeneratePostingsForAccountingCommandsResponse
 */
export const GeneratePostingsForAccountingCommandsResponse = new GeneratePostingsForAccountingCommandsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AggregationRule$Type extends MessageType<AggregationRule> {
    constructor() {
        super("one.plan3t.core.billing.rpc.AggregationRule", [
            { no: 1, name: "round_first", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "rounding_type", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "precision", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<AggregationRule>): AggregationRule {
        const message = { roundFirst: false, roundingType: 0, precision: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AggregationRule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AggregationRule): AggregationRule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool round_first */ 1:
                    message.roundFirst = reader.bool();
                    break;
                case /* int32 rounding_type */ 2:
                    message.roundingType = reader.int32();
                    break;
                case /* int32 precision */ 3:
                    message.precision = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AggregationRule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool round_first = 1; */
        if (message.roundFirst !== false)
            writer.tag(1, WireType.Varint).bool(message.roundFirst);
        /* int32 rounding_type = 2; */
        if (message.roundingType !== 0)
            writer.tag(2, WireType.Varint).int32(message.roundingType);
        /* int32 precision = 3; */
        if (message.precision !== 0)
            writer.tag(3, WireType.Varint).int32(message.precision);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.AggregationRule
 */
export const AggregationRule = new AggregationRule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAggregationRulesRequest$Type extends MessageType<GetAggregationRulesRequest> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetAggregationRulesRequest", []);
    }
    create(value?: PartialMessage<GetAggregationRulesRequest>): GetAggregationRulesRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAggregationRulesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAggregationRulesRequest): GetAggregationRulesRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetAggregationRulesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetAggregationRulesRequest
 */
export const GetAggregationRulesRequest = new GetAggregationRulesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAggregationRulesResponse$Type extends MessageType<GetAggregationRulesResponse> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetAggregationRulesResponse", [
            { no: 1, name: "by_posting_type", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => AggregationRule } }
        ]);
    }
    create(value?: PartialMessage<GetAggregationRulesResponse>): GetAggregationRulesResponse {
        const message = { byPostingType: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAggregationRulesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAggregationRulesResponse): GetAggregationRulesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, one.plan3t.core.billing.rpc.AggregationRule> by_posting_type */ 1:
                    this.binaryReadMap1(message.byPostingType, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GetAggregationRulesResponse["byPostingType"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetAggregationRulesResponse["byPostingType"] | undefined, val: GetAggregationRulesResponse["byPostingType"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = AggregationRule.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.billing.rpc.GetAggregationRulesResponse.by_posting_type");
            }
        }
        map[key ?? ""] = val ?? AggregationRule.create();
    }
    internalBinaryWrite(message: GetAggregationRulesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, one.plan3t.core.billing.rpc.AggregationRule> by_posting_type = 1; */
        for (let k of Object.keys(message.byPostingType)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            AggregationRule.internalBinaryWrite(message.byPostingType[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetAggregationRulesResponse
 */
export const GetAggregationRulesResponse = new GetAggregationRulesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetResubmissionsRequest$Type extends MessageType<GetResubmissionsRequest> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetResubmissionsRequest", [
            { no: 1, name: "period", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetResubmissionsRequest>): GetResubmissionsRequest {
        const message = { period: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetResubmissionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetResubmissionsRequest): GetResubmissionsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 period */ 1:
                    message.period = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetResubmissionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 period = 1; */
        if (message.period !== 0)
            writer.tag(1, WireType.Varint).int64(message.period);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetResubmissionsRequest
 */
export const GetResubmissionsRequest = new GetResubmissionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetResubmissionsResponse$Type extends MessageType<GetResubmissionsResponse> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetResubmissionsResponse", [
            { no: 1, name: "documents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Document }
        ]);
    }
    create(value?: PartialMessage<GetResubmissionsResponse>): GetResubmissionsResponse {
        const message = { documents: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetResubmissionsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetResubmissionsResponse): GetResubmissionsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.billing.rpc.Document documents */ 1:
                    message.documents.push(Document.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetResubmissionsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.billing.rpc.Document documents = 1; */
        for (let i = 0; i < message.documents.length; i++)
            Document.internalBinaryWrite(message.documents[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetResubmissionsResponse
 */
export const GetResubmissionsResponse = new GetResubmissionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateInvoiceRequest$Type extends MessageType<CreateInvoiceRequest> {
    constructor() {
        super("one.plan3t.core.billing.rpc.CreateInvoiceRequest", [
            { no: 1, name: "period", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateInvoiceRequest>): CreateInvoiceRequest {
        const message = { period: 0, partnerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateInvoiceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateInvoiceRequest): CreateInvoiceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 period */ 1:
                    message.period = reader.int64().toNumber();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateInvoiceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 period = 1; */
        if (message.period !== 0)
            writer.tag(1, WireType.Varint).int64(message.period);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.CreateInvoiceRequest
 */
export const CreateInvoiceRequest = new CreateInvoiceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateInvoiceResponse$Type extends MessageType<CreateInvoiceResponse> {
    constructor() {
        super("one.plan3t.core.billing.rpc.CreateInvoiceResponse", []);
    }
    create(value?: PartialMessage<CreateInvoiceResponse>): CreateInvoiceResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateInvoiceResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateInvoiceResponse): CreateInvoiceResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CreateInvoiceResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.CreateInvoiceResponse
 */
export const CreateInvoiceResponse = new CreateInvoiceResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Invoice$Type extends MessageType<Invoice> {
    constructor() {
        super("one.plan3t.core.billing.rpc.Invoice", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "period_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "external_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "created_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "external_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Invoice>): Invoice {
        const message = { id: "", partnerId: "", periodId: 0, externalId: 0, createdBy: "", externalLink: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Invoice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Invoice): Invoice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                case /* int64 period_id */ 3:
                    message.periodId = reader.int64().toNumber();
                    break;
                case /* int64 external_id */ 4:
                    message.externalId = reader.int64().toNumber();
                    break;
                case /* google.protobuf.Timestamp created_at */ 5:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string created_by */ 6:
                    message.createdBy = reader.string();
                    break;
                case /* string external_link */ 7:
                    message.externalLink = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Invoice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        /* int64 period_id = 3; */
        if (message.periodId !== 0)
            writer.tag(3, WireType.Varint).int64(message.periodId);
        /* int64 external_id = 4; */
        if (message.externalId !== 0)
            writer.tag(4, WireType.Varint).int64(message.externalId);
        /* google.protobuf.Timestamp created_at = 5; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string created_by = 6; */
        if (message.createdBy !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.createdBy);
        /* string external_link = 7; */
        if (message.externalLink !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.externalLink);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.Invoice
 */
export const Invoice = new Invoice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInvoicesRequest$Type extends MessageType<GetInvoicesRequest> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetInvoicesRequest", [
            { no: 1, name: "period", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetInvoicesRequest>): GetInvoicesRequest {
        const message = { period: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInvoicesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInvoicesRequest): GetInvoicesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 period */ 1:
                    message.period = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInvoicesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 period = 1; */
        if (message.period !== 0)
            writer.tag(1, WireType.Varint).int64(message.period);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetInvoicesRequest
 */
export const GetInvoicesRequest = new GetInvoicesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInvoicesResponse$Type extends MessageType<GetInvoicesResponse> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetInvoicesResponse", [
            { no: 1, name: "invoices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Invoice }
        ]);
    }
    create(value?: PartialMessage<GetInvoicesResponse>): GetInvoicesResponse {
        const message = { invoices: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInvoicesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInvoicesResponse): GetInvoicesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.billing.rpc.Invoice invoices */ 1:
                    message.invoices.push(Invoice.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInvoicesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.billing.rpc.Invoice invoices = 1; */
        for (let i = 0; i < message.invoices.length; i++)
            Invoice.internalBinaryWrite(message.invoices[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetInvoicesResponse
 */
export const GetInvoicesResponse = new GetInvoicesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPeriodTotalsRequest$Type extends MessageType<GetPeriodTotalsRequest> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetPeriodTotalsRequest", [
            { no: 1, name: "period", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetPeriodTotalsRequest>): GetPeriodTotalsRequest {
        const message = { period: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPeriodTotalsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPeriodTotalsRequest): GetPeriodTotalsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 period */ 1:
                    message.period = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPeriodTotalsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 period = 1; */
        if (message.period !== 0)
            writer.tag(1, WireType.Varint).int64(message.period);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetPeriodTotalsRequest
 */
export const GetPeriodTotalsRequest = new GetPeriodTotalsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPeriodTotalsResponse$Type extends MessageType<GetPeriodTotalsResponse> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetPeriodTotalsResponse", [
            { no: 1, name: "totals", kind: "message", T: () => GetPeriodTotalsResponse_Totals },
            { no: 2, name: "by_type", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => GetPeriodTotalsResponse_Totals } },
            { no: 3, name: "num_postings", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetPeriodTotalsResponse>): GetPeriodTotalsResponse {
        const message = { byType: {}, numPostings: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPeriodTotalsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPeriodTotalsResponse): GetPeriodTotalsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.billing.rpc.GetPeriodTotalsResponse.Totals totals */ 1:
                    message.totals = GetPeriodTotalsResponse_Totals.internalBinaryRead(reader, reader.uint32(), options, message.totals);
                    break;
                case /* map<string, one.plan3t.core.billing.rpc.GetPeriodTotalsResponse.Totals> by_type */ 2:
                    this.binaryReadMap2(message.byType, reader, options);
                    break;
                case /* int64 num_postings */ 3:
                    message.numPostings = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: GetPeriodTotalsResponse["byType"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetPeriodTotalsResponse["byType"] | undefined, val: GetPeriodTotalsResponse["byType"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = GetPeriodTotalsResponse_Totals.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.billing.rpc.GetPeriodTotalsResponse.by_type");
            }
        }
        map[key ?? ""] = val ?? GetPeriodTotalsResponse_Totals.create();
    }
    internalBinaryWrite(message: GetPeriodTotalsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.billing.rpc.GetPeriodTotalsResponse.Totals totals = 1; */
        if (message.totals)
            GetPeriodTotalsResponse_Totals.internalBinaryWrite(message.totals, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* map<string, one.plan3t.core.billing.rpc.GetPeriodTotalsResponse.Totals> by_type = 2; */
        for (let k of Object.keys(message.byType)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            GetPeriodTotalsResponse_Totals.internalBinaryWrite(message.byType[k], writer, options);
            writer.join().join();
        }
        /* int64 num_postings = 3; */
        if (message.numPostings !== 0)
            writer.tag(3, WireType.Varint).int64(message.numPostings);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetPeriodTotalsResponse
 */
export const GetPeriodTotalsResponse = new GetPeriodTotalsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPeriodTotalsResponse_Total$Type extends MessageType<GetPeriodTotalsResponse_Total> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetPeriodTotalsResponse.Total", [
            { no: 1, name: "amount_unscaled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "amount_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPeriodTotalsResponse_Total>): GetPeriodTotalsResponse_Total {
        const message = { amountUnscaled: 0, amountScale: 0, currency: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPeriodTotalsResponse_Total>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPeriodTotalsResponse_Total): GetPeriodTotalsResponse_Total {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 amount_unscaled */ 1:
                    message.amountUnscaled = reader.int32();
                    break;
                case /* int32 amount_scale */ 2:
                    message.amountScale = reader.int32();
                    break;
                case /* string currency */ 3:
                    message.currency = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPeriodTotalsResponse_Total, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 amount_unscaled = 1; */
        if (message.amountUnscaled !== 0)
            writer.tag(1, WireType.Varint).int32(message.amountUnscaled);
        /* int32 amount_scale = 2; */
        if (message.amountScale !== 0)
            writer.tag(2, WireType.Varint).int32(message.amountScale);
        /* string currency = 3; */
        if (message.currency !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.currency);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetPeriodTotalsResponse.Total
 */
export const GetPeriodTotalsResponse_Total = new GetPeriodTotalsResponse_Total$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPeriodTotalsResponse_Totals$Type extends MessageType<GetPeriodTotalsResponse_Totals> {
    constructor() {
        super("one.plan3t.core.billing.rpc.GetPeriodTotalsResponse.Totals", [
            { no: 1, name: "totals", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetPeriodTotalsResponse_Total }
        ]);
    }
    create(value?: PartialMessage<GetPeriodTotalsResponse_Totals>): GetPeriodTotalsResponse_Totals {
        const message = { totals: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPeriodTotalsResponse_Totals>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPeriodTotalsResponse_Totals): GetPeriodTotalsResponse_Totals {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.billing.rpc.GetPeriodTotalsResponse.Total totals */ 1:
                    message.totals.push(GetPeriodTotalsResponse_Total.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPeriodTotalsResponse_Totals, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.billing.rpc.GetPeriodTotalsResponse.Total totals = 1; */
        for (let i = 0; i < message.totals.length; i++)
            GetPeriodTotalsResponse_Total.internalBinaryWrite(message.totals[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.billing.rpc.GetPeriodTotalsResponse.Totals
 */
export const GetPeriodTotalsResponse_Totals = new GetPeriodTotalsResponse_Totals$Type();
/**
 * @generated ServiceType for protobuf service one.plan3t.core.billing.rpc.Billing
 */
export const Billing = new ServiceType("one.plan3t.core.billing.rpc.Billing", [
    { name: "GetBillingPeriods", options: {}, I: GetBillingPeriodsRequest, O: GetBillingPeriodsResponse },
    { name: "GetBillingPeriodState", options: {}, I: GetBillingPeriodStateRequest, O: GetBillingPeriodStateResponse },
    { name: "SetBillingPeriodState", options: {}, I: SetBillingPeriodStateRequest, O: SetBillingPeriodStateResponse },
    { name: "AddPostings", options: {}, I: AddPostingsRequest, O: AddPostingsResponse },
    { name: "GetPostings", options: {}, I: GetPostingsRequest, O: GetPostingsResponse },
    { name: "AddDocument", options: {}, I: AddDocumentRequest, O: AddDocumentResponse },
    { name: "GetDocuments", options: {}, I: GetDocumentsRequest, O: GetDocumentsResponse },
    { name: "GeneratePostingsForAccountingCommands", options: {}, I: GeneratePostingsForAccountingCommandsRequest, O: GeneratePostingsForAccountingCommandsResponse },
    { name: "GetAggregationRules", options: {}, I: GetAggregationRulesRequest, O: GetAggregationRulesResponse },
    { name: "GetResubmissions", options: {}, I: GetResubmissionsRequest, O: GetResubmissionsResponse },
    { name: "CreateInvoice", options: {}, I: CreateInvoiceRequest, O: CreateInvoiceResponse },
    { name: "GetInvoices", options: {}, I: GetInvoicesRequest, O: GetInvoicesResponse },
    { name: "GetPeriodTotals", options: {}, I: GetPeriodTotalsRequest, O: GetPeriodTotalsResponse }
]);
