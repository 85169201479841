import { RouteComponentProps, withRouter } from 'react-router-dom';
import AllRewardDrafts from './AllRewardDrafts';
import AllRewardsLiveActive from './AllRewardsLiveActive';
import AllRewardsLiveInactive from './AllRewardsLiveInactive';

type Props = {} & RouteComponentProps;

const RewardOverview = (props: Props) => {
    return (
        <div className="w-full h-full p-12 pb-24 bg-semantic-background overflow-y-auto">
            <div className="max-w-6xl space-y-12">
                <AllRewardDrafts />
                <AllRewardsLiveActive />
                <AllRewardsLiveInactive />
            </div>
        </div>
    );
};

export default withRouter(RewardOverview);
