import { useState } from 'react';
import { Timestamp } from '../../api/google/protobuf/timestamp';
import { Reward } from '../../api/model/core';
import { Button } from '../../common/Button';
import Dropdown from '../../common/Dropdown';
import { useToggleRewardState } from '../../hooks/useToggleRewardState';
import { useI18n } from '../../i18n';
import { IconEllipsis } from '../../icons';
import RewardCodeCSVUploadDialog from '../RewardCodeCSVUploadDialog';

export interface Props {
    reward: Reward;
    /** Determines if the activate/deactivate button is disabled. */
    disableToggle?: boolean;
}

export const RewardTileDropdownMenu = ({ reward, disableToggle }: Props) => {
    const toggleRewardState = useToggleRewardState(reward);

    const { t } = useI18n();

    const [codeDialogIsOpen, setCodeDialogIsOpen] = useState(false);
    const openCodeDialog = () => setCodeDialogIsOpen(true);
    const onCloseCodeDialog = () => setCodeDialogIsOpen(false);

    const rewardIsActive = reward.validUntil
        ? Timestamp.toDate(reward.validUntil) > new Date()
        : undefined;

    return (
        <>
            <Dropdown
                button={
                    <Button secondary onClick={() => void 0}>
                        <IconEllipsis />
                    </Button>
                }
                items={[
                    {
                        label: 'reward.couponCodes.add',
                        onClick: openCodeDialog,
                    },
                    {
                        label: rewardIsActive
                            ? t('reward.deactivate')
                            : t('reward.activate'),
                        onClick: toggleRewardState,
                        disabled: disableToggle || rewardIsActive === undefined,
                    },
                ]}
            />
            <RewardCodeCSVUploadDialog
                partnerId={reward.partnerId}
                rewardId={reward.rewardId}
                isOpen={codeDialogIsOpen}
                onClose={onCloseCodeDialog}
            />
        </>
    );
};

export default RewardTileDropdownMenu;
