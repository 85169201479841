import {
    createAsyncThunk,
    createSlice,
    PayloadAction,
    SerializedError,
} from '@reduxjs/toolkit';
import * as Billing from '../api/billing/rpc/billing';
import * as Common from '../api/model/common';
import axios from 'axios';
import { authSelectors } from '../auth/store';
import { AppState } from '../redux/AppStore';

export const PostingType = {
    Cashback: 'C',
    CashbackFee: 'CF',
    RewardPaid: 'R',
    RewardPaidFee: 'RF',
    RewardAffiliate: 'AF',
};

export const PeriodStateCode = {
    Initial: 'I',
    HalfOpen: 'H',
    Open: 'O',
    Review: 'R',
    Closed: 'C',
};

const loadPeriods = createAsyncThunk(
    'billing/periods',
    async (): Promise<Array<Billing.BillingPeriod>> => {
        const res = await axios.post(
            '/one.plan3t.core.billing.rpc.Billing/GetBillingPeriods',
            {},
        );
        const msg = Billing.GetBillingPeriodsResponse.fromJson(res.data as any);

        return msg.periods;
    },
);

const loadDocuments = createAsyncThunk(
    'billing/documents',
    async (period: number, { getState }): Promise<Array<Billing.Document>> => {
        const partnerId = authSelectors.getPartnerId(getState() as AppState)!;
        const req = Billing.GetDocumentsRequest.toJson({
            period,
            partnerId,
        });
        const res = await axios.post(
            '/one.plan3t.core.billing.rpc.Billing/GetDocuments',
            req,
        );
        const msg = Billing.GetDocumentsResponse.fromJson(res.data as any);
        return msg.documents;
    },
);

const loadPostings = createAsyncThunk(
    'billing/postings',
    async (period: number, { getState }): Promise<Array<Billing.Posting>> => {
        const partnerId = authSelectors.getPartnerId(getState() as AppState)!;
        const req = Billing.GetPostingsRequest.toJson({
            period,
            partnerId,
        });
        const res = await axios.post(
            '/one.plan3t.core.billing.rpc.Billing/GetPostings',
            req,
        );
        const msg = Billing.GetPostingsResponse.fromJson(res.data as any);
        return msg.items;
    },
);

const loadPeriodState = createAsyncThunk(
    'billing/periodState',
    async (period: number, { getState }): Promise<string> => {
        const partnerId = authSelectors.getPartnerId(getState() as AppState)!;
        const req = Billing.GetBillingPeriodStateRequest.toJson({
            period,
            partnerId,
        });

        const res = await axios.post(
            '/one.plan3t.core.billing.rpc.Billing/GetBillingPeriodState',
            req,
        );
        const msg = Billing.GetBillingPeriodStateResponse.fromJson(
            res.data as any,
        );
        return msg.stateCode;
    },
);

const loadAggregationRules = createAsyncThunk(
    'billing/aggregationRules',
    async (): Promise<Record<string, Billing.AggregationRule>> => {
        const res = await axios.post(
            '/one.plan3t.core.billing.rpc.Billing/GetAggregationRules',
            {},
        );
        return Billing.GetAggregationRulesResponse.fromJson(res.data as any)
            .byPostingType;
    },
);

export type addDocumentArgs = Omit<Billing.AddDocumentRequest, 'partnerId'>;

const addDocument = createAsyncThunk(
    'billing/document/add',
    async (r: addDocumentArgs, { getState, dispatch }) => {
        const partnerId = authSelectors.getPartnerId(getState() as AppState)!;
        const req = Billing.AddDocumentRequest.toJson({
            partnerId,
            ...r,
        });
        await axios.post(
            '/one.plan3t.core.billing.rpc.Billing/AddDocument',
            req,
        );
        await dispatch(loadDocuments(r.period));
    },
);

type LoadableData<T> =
    | { initial: true }
    | { data: T }
    | { loading: true }
    | { error: SerializedError };

type State = {
    periods: LoadableData<Array<Billing.BillingPeriod>>;
    // maps periodID -> documents/postings
    documents: Record<number, LoadableData<Array<Billing.Document>>>;
    postings: Record<number, LoadableData<Array<Billing.Posting>>>;
    periodStates: Record<number, LoadableData<string>>;
    aggregationRules: LoadableData<Record<string, Billing.AggregationRule>>;

    selectedPeriod: undefined | number;
};

const initialState: State = {
    periods: { initial: true },
    documents: {},
    postings: {},
    periodStates: {},
    aggregationRules: { initial: true },
    selectedPeriod: undefined,
};

const slice = createSlice({
    name: 'billing',
    initialState,
    reducers: {
        selectPeriod(state, action: PayloadAction<number>) {
            state.selectedPeriod = action.payload || undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadPeriods.pending, (state) => {
            state.periods = { loading: true };
        });
        builder.addCase(loadPeriods.rejected, (state, action) => {
            state.periods = { error: action.error };
        });
        builder.addCase(loadPeriods.fulfilled, (state, action) => {
            const toDT = (d: Common.Date | undefined): Date => {
                if (!d) {
                    return new Date(0);
                }
                return new Date(d.year, d.month - 1, d.day);
            };
            const sorted = [...action.payload];
            sorted.sort(
                (a, b) => toDT(a.endsAt).getTime() - toDT(b.endsAt).getTime(),
            );
            state.periods = {
                data: sorted,
            };

            // try to select the period which just ended
            for (let p of sorted) {
                if (!p.startsAt || !p.endsAt) {
                    continue;
                }
                if (toDT(p.endsAt) < new Date()) {
                    state.selectedPeriod = p.id;
                }
            }
        });
        builder.addCase(loadDocuments.pending, (state, action) => {
            state.documents[action.meta.arg] = { loading: true };
        });
        builder.addCase(loadDocuments.rejected, (state, action) => {
            state.documents[action.meta.arg] = { error: action.error };
        });
        builder.addCase(loadDocuments.fulfilled, (state, action) => {
            state.documents[action.meta.arg] = { data: action.payload };
        });
        builder.addCase(loadPostings.pending, (state, action) => {
            state.postings[action.meta.arg] = { loading: true };
        });
        builder.addCase(loadPostings.rejected, (state, action) => {
            state.postings[action.meta.arg] = { error: action.error };
        });
        builder.addCase(loadPostings.fulfilled, (state, action) => {
            state.postings[action.meta.arg] = { data: action.payload };
        });
        builder.addCase(loadPeriodState.pending, (state, action) => {
            state.periodStates[action.meta.arg] = { loading: true };
        });
        builder.addCase(loadPeriodState.rejected, (state, action) => {
            state.periodStates[action.meta.arg] = { error: action.error };
        });
        builder.addCase(loadPeriodState.fulfilled, (state, action) => {
            state.periodStates[action.meta.arg] = { data: action.payload };
        });
        builder.addCase(loadAggregationRules.pending, (state, action) => {
            state.aggregationRules = { loading: true };
        });
        builder.addCase(loadAggregationRules.rejected, (state, action) => {
            state.aggregationRules = { error: action.error };
        });
        builder.addCase(loadAggregationRules.fulfilled, (state, action) => {
            state.aggregationRules = { data: action.payload };
        });
    },
});

export const billingReducer = slice.reducer;
export const billingActions = {
    ...slice.actions,
    loadPeriods,
    loadDocuments,
    loadPostings,
    loadPeriodState,
    loadAggregationRules,
    addDocument,
};

export const billingSelectors = {
    getDocumentsForPeriodAndType(
        state: AppState,
        period: number,
        type: string,
    ) {
        const documents = state.billing.documents[period];
        if (!documents) {
            return [];
        }

        if (!('data' in documents)) {
            return [];
        }

        const { data } = documents;

        return data.filter((p) => p.type === type);
    },
    getPostingsForDocument(
        state: AppState,
        period: number,
        documentId: string,
    ) {
        const postings = state.billing.postings[period];
        if (!postings) {
            return [];
        }

        if (!('data' in postings)) {
            return [];
        }

        const { data } = postings;

        return data.filter((p) => p.documentId === documentId);
    },
    getPostingsForPeriod(
        state: AppState,
        period: number,
        filter?: Array<string>,
    ) {
        const postings = state.billing.postings[period];
        if (!postings) {
            return [];
        }

        if (!('data' in postings)) {
            return [];
        }

        const { data } = postings;

        if (!filter) {
            return data;
        }

        return data.filter(({ type }) => filter.includes(type));
    },
    getAggregationRules(state: AppState) {
        if (!('data' in state.billing.aggregationRules)) {
            return {};
        }
        return state.billing.aggregationRules.data;
    },
    sumPostings(
        rules: Record<string, Billing.AggregationRule>,
        postings: Array<Billing.Posting>,
    ) {
        const totalRule = rules[''];
        if (!totalRule) {
            throw new Error('No rule found for total aggregation');
        }
        if (postings.length === 0) {
            return { amountScale: totalRule.precision, amountUnscaled: 0 };
        }

        const byType: Record<typeof postings[number]['type'], typeof postings> =
            {};
        for (const p of postings) {
            byType[p.type] = [...(byType[p.type] ?? []), p];
        }

        const sums: Array<{ amountScale: number; amountUnscaled: number }> = [];
        for (const [typ, pstings] of Object.entries(byType)) {
            const rule = rules[typ];
            if (!rule) {
                throw new Error(`No aggregation rule for '${typ}' found.`);
            }

            sums.push(sum(rule, pstings));
        }

        return sum(rules[''], sums);
    },
    getPeriodState(state: AppState, period: number) {
        const periodState = state.billing.periodStates[period];
        if (!periodState) {
            return PeriodStateCode.Initial;
        }
        if (!('data' in periodState)) {
            return PeriodStateCode.Initial;
        }
        const { data } = periodState;

        return data;
    },
    canEdit(state: AppState, period: number) {
        return this.getPeriodState(state, period) === PeriodStateCode.Open;
    },
};

function sum(
    rule: Billing.AggregationRule,
    as: Array<{ amountUnscaled: number; amountScale: number }>,
): { amountUnscaled: number; amountScale: number } {
    function round(a: number): number {
        const s = a > 0 ? 1 : -1;
        a = Math.abs(a);
        switch (rule.roundingType) {
            case 0:
                return s * Math.round(a);
            case 1:
                return s * Math.floor(a);
            case 2:
                return s * Math.ceil(a);
            default:
                throw new Error(
                    `Unrecognized rounding type '${rule.roundingType}'.`,
                );
        }
    }

    const s = { amountUnscaled: 0, amountScale: rule.precision };

    for (let { amountUnscaled, amountScale } of as) {
        const d = rule.precision - amountScale;
        amountUnscaled *= Math.pow(10, d);

        if (rule.roundFirst) {
            amountUnscaled = round(amountUnscaled);
        }
        s.amountUnscaled += amountUnscaled;
    }

    if (!rule.roundFirst) {
        s.amountUnscaled = round(s.amountUnscaled);
    }

    return s;
}
