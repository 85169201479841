import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CashbackLogEntry, GetCashbackLogRequest } from '../api/model/core';
import { GetCashbackLogResponse } from '../api/model/core';
import { AppState } from '../redux/AppStore';
import { authSelectors } from '../auth/store';

const fetchPage = async (
    partnerId: string,
    pageToken: string,
    pageSize: number,
): Promise<GetCashbackLogResponse> => {
    const req: GetCashbackLogRequest = {
        partnerId,
        pageSize,
        pageToken,
    };
    const res = await axios.post<object>(`/Cashback/GetCashbackLog`, req);
    return GetCashbackLogResponse.fromJson(res.data as any);
};

const fetchList = createAsyncThunk(
    `cashback/fetch`,
    async (_: void, { getState }) => {
        const state = getState() as AppState;
        const s = state.cashback;
        if (!s.pagination.hasMore) {
            return {
                items: [],
                nextPageToken: '--invalid',
            };
        }
        return fetchPage(
            authSelectors.getPartnerId(state)!,
            s.pagination.nextPageToken,
            s.pagination.pageSize,
        );
    },
);

type FetchState = { isFetching: boolean; error: unknown };

type CashbackState = {
    byId: Record<string, CashbackLogEntry>;
    ids: Array<string>;
    fetchState: FetchState;
    pagination: {
        pageSize: number;
        currentPage: number;
        loadedPages: number;
        hasMore: boolean;
        nextPageToken: string;
    };
};

const initialState: CashbackState = {
    byId: {},
    ids: [],
    fetchState: { isFetching: false, error: undefined },
    pagination: {
        pageSize: 25,
        currentPage: 0,
        hasMore: true,
        loadedPages: 0,
        nextPageToken: '',
    },
};

const cashbackSlice = createSlice({
    name: 'cashback',
    initialState,
    reducers: {
        navigateToNextPage(state) {
            state.pagination.currentPage++;
        },
        navigateToPreviousPage(state) {
            if (state.pagination.currentPage === 0) {
                return;
            }
            state.pagination.currentPage--;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchList.pending, (state) => {
            state.fetchState.isFetching = true;
        });
        builder.addCase(fetchList.fulfilled, (state, action) => {
            state.fetchState.isFetching = false;
            state.fetchState.error = undefined;

            const asSet = new Set(state.ids);

            action.payload.items.forEach((item) => {
                const id = item.transactionId;
                asSet.add(id);
                // @ts-ignore
                state.byId[id] = item;
            });
            state.ids = Array.from(asSet);

            state.pagination.hasMore =
                action.payload.items.length === state.pagination.pageSize;
            state.pagination.loadedPages = Math.ceil(
                state.ids.length / state.pagination.pageSize,
            );
            state.pagination.nextPageToken = action.payload.nextPageToken;
        });
        builder.addCase(fetchList.rejected, (state, action) => {
            state.fetchState.isFetching = false;
            state.fetchState.error = action.error;
        });
    },
});

export const cashbackReducer = cashbackSlice.reducer;
export const cashbackActions = {
    ...cashbackSlice.actions,
    fetchList,
};
export const cashbackSelectors = {
    isFetching: (state: AppState) => state.cashback.fetchState.isFetching,
    getCurrentPageNumber: (state: AppState) =>
        state.cashback.pagination.currentPage,
    getCurrentPage: (state: AppState): CashbackLogEntry[] => {
        const { pagination, ids, byId } = state.cashback;

        const start = pagination.currentPage * pagination.pageSize;
        const end = start + pagination.pageSize;

        const idsForPage = ids.slice(start, end);

        return idsForPage.map((id) => byId[id]);
    },
    getPageSize: (state: AppState) => state.cashback.pagination.pageSize,
    hasMore: (state: AppState): boolean => {
        const { pagination, fetchState } = state.cashback;
        return (
            fetchState.error === undefined &&
            (pagination.hasMore ||
                pagination.currentPage + 1 < pagination.loadedPages)
        );
    },
    getError: (state: AppState): any => {
        const { fetchState } = state.cashback;
        return fetchState.error;
    },

    getItem: (state: AppState, { id }: { id: string }) =>
        state.cashback.byId[id],
};
