import { parse, ParseConfig, ParseResult } from 'papaparse';
import React, { useState } from 'react';

/** List of accepted csv header keywords */
const codeColumNames = ['code', 'codes', 'coupon'];

/** Returns a callback to be used with the `useDropzone` hook. */
export function useCSVOnDrop() {
    const [isLoading, setIsLoading] = useState(false);

    const [codes, setCodes] = useState<string[]>();

    const clearCodes = () => {
        setCodes(undefined);
    };

    const onDrop = React.useCallback(
        async (acceptedFiles: File[]) => {
            setIsLoading(true);

            try {
                const codesPerFile = await Promise.all(
                    acceptedFiles.map((file) => {
                        return parsePromise<Record<string, string>>(file, {
                            header: true,
                            transformHeader(
                                header: string,
                                index?: number,
                            ): string {
                                const lower = header.toLocaleLowerCase();

                                if (codeColumNames.includes(lower)) {
                                    return '__code';
                                }

                                return lower;
                            },
                        }).then((result) => {
                            if (
                                result.data.length > 0 &&
                                Object.entries(result.data[0]).length === 1
                            ) {
                                // there is only one row
                                return result.data.map(
                                    (row) => Object.values(row)[0],
                                );
                            }
                            return result.data.map((row) => row['__code']);
                        });
                    }),
                );

                const codes = codesPerFile
                    .reduce((acc, codes) => [...acc, ...codes], [])
                    .filter((c) => !!c);
                setCodes(codes);
            } finally {
                setIsLoading(false);
            }
        },
        [setCodes, setIsLoading],
    );
    return { onDrop, codes, isLoading, clearCodes };
}

export default useCSVOnDrop;

function parsePromise<T>(
    file: File,
    config: ParseConfig,
): Promise<ParseResult<T>> {
    return new Promise<ParseResult<T>>((resolve, reject) => {
        parse(file, {
            ...config,
            complete: (result) => resolve(result),
            error: (error) => reject(error),
        });
    });
}
