import React, { SyntheticEvent, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ulid } from 'ulid';
import { Button } from '../common/Button';
import Modal from '../common/Modal';
import Spinner from '../common/Spinner';
import useCSVOnDrop from '../hooks/useCSVOnDrop';
import { useI18n } from '../i18n';
import { useAppDispatch } from '../redux/react';
import { snackbarActions } from '../snackbar/store';
import { triggerCSVDownload } from './RewardCodeCSVUpload';
import { rewardActions } from './store';

type RewardCodeCSVUploadDialogProps = {
    partnerId: string;
    rewardId: string;
    isOpen: boolean;
    onClose: () => void;
};

const RewardCodeCSVUploadDialog = ({
    partnerId,
    rewardId,
    isOpen,
    onClose,
}: RewardCodeCSVUploadDialogProps) => {
    const { t } = useI18n();

    const dialogUlid = ulid();

    const {
        onDrop,
        isLoading: csvIsLoading,
        codes,
        clearCodes,
    } = useCSVOnDrop();

    const dispatch = useAppDispatch();
    const [isUploading, setIsUploading] = useState(false);

    const onUpload = async () => {
        try {
            setIsUploading(true);
            const res = await dispatch(
                rewardActions.uploadRewardCodes({
                    codes: codes!,
                    partnerId,
                    rewardId,
                    batchId: dialogUlid,
                }),
            );

            if (res.meta.requestStatus === 'fulfilled') {
                dispatch(rewardActions.fetchRewardAssets({ rewardId }));
                onCloseDialog();
            } else {
                dispatch(
                    snackbarActions.sendMessage({
                        title: 'reward.couponCodes.upload.error',
                        type: 'warning',
                    }),
                );
            }
        } finally {
            setIsUploading(false);
        }
    };

    const onCloseDialog = () => {
        clearCodes();
        onClose();
    };

    const onRequestDownload = React.useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault();
            triggerCSVDownload([['code'], ...codes!.map((code) => [code])]);
        },
        [codes],
    );

    return (
        <Modal
            visible={isOpen}
            headline="Gutschein hinzufügen" // TODO l10n
            onRequestClose={onCloseDialog}
        >
            <div className="flex flex-col divide-y divide-gray-200 -mx-4">
                <div className="px-6 py-6 border-t border-gray-200">
                    <RewardCodeCSVDropZone onDrop={onDrop} />
                    {csvIsLoading && <CenterSpinner />}
                    {!codes && (
                        <>
                            <div className="py-4 text-body1 text-gray-500">
                                {t('reward.couponCodes.upload.csvExplainer')}
                            </div>
                            <div className="inline-flex">
                                <Button
                                    secondary
                                    label="reward.couponCodes.downloadTemplate"
                                    onClick={onRequestDownload}
                                />
                            </div>
                        </>
                    )}
                    {codes && (
                        <>
                            <div className="py-4 text-body1 text-gray-500">
                                {t('reward.couponCodes.upload.previewHint')}
                            </div>
                            <pre className="bg-gray-100 px-6 py-2 text-body1 text-semantic-primary truncate">
                                {codes.join(' ')}
                            </pre>
                        </>
                    )}
                </div>
                {isUploading ? (
                    <CenterSpinner />
                ) : (
                    <ButtonRow
                        onDone={codes && onUpload}
                        onCancel={onCloseDialog}
                    />
                )}
            </div>
        </Modal>
    );
};

export default RewardCodeCSVUploadDialog;

type RewardCodeCSVDropZoneProps = {
    onDrop: (acceptedFiles: File[]) => Promise<void>;
};

const RewardCodeCSVDropZone = ({ onDrop }: RewardCodeCSVDropZoneProps) => {
    const { t } = useI18n();

    const { getRootProps, getInputProps } = useDropzone({
        accept: '.csv',
        maxFiles: 1,
        multiple: false,
        onDrop,
    });

    return (
        <div>
            <label className="block pl-3 form-label">
                {t('billing.document.uploadCodes.modal.fileInputLabel')}
            </label>
            <div className={'mt-1 relative rounded-md text-semantic-primary'}>
                <div
                    {...getRootProps()}
                    className="flex border border-gray-200 cursor-pointer bg-gray-50 min-w-128 h-64 rounded justify-center items-center hover:bg-gray-100 focus:border-green-400 focus:outline-none"
                >
                    <input
                        className="focus:border-green-400"
                        {...getInputProps()}
                    />
                    <p className="text-center text-body1">
                        {t(
                            'billing.document.uploadCodes.modal.dropzoneMessage',
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
};

const ButtonRow = (props: { onCancel?: () => void; onDone?: () => void }) => {
    return (
        <div className="flex justify-end">
            <div className="flex flex-row p-4 pb-0 space-x-4">
                <Button
                    secondary
                    label="reward.couponCodes.upload.cancel"
                    disabled={!props.onCancel}
                    onClick={props.onCancel}
                />
                <Button
                    primary
                    label="reward.couponCodes.upload.done"
                    disabled={!props.onDone}
                    onClick={props.onDone}
                />
            </div>
        </div>
    );
};

const CenterSpinner = () => {
    return (
        <div className="flex justify-center pt-4">
            <Spinner sizeInRem={2} />
        </div>
    );
};
