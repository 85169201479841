// @generated by protobuf-ts 2.1.0 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "model/b2b.proto" (package "one.plan3t.core.model", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { AnalyticsReport } from "./core";
import { TaxableCodeType } from "./core";
import { RewardBillingType } from "./core";
import { Duration } from "../google/protobuf/duration";
import { Timestamp } from "../google/protobuf/timestamp";
import { RewardDraft } from "./core";
import { ImageResource } from "./core";
import { CoreIndustry } from "./core";
import { PartnerProfileDraft } from "./core";
import { PartnerManager } from "./core";
import { PartnerBase } from "./core";
/**
 * @generated from protobuf message one.plan3t.core.model.GetPartnerBaseResponse
 */
export interface GetPartnerBaseResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.PartnerBase partner = 1;
     */
    partner?: PartnerBase;
}
/**
 * @generated from protobuf message one.plan3t.core.model.InvitePartnerManagerRequest
 */
export interface InvitePartnerManagerRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPartnerManagerResponse
 */
export interface GetPartnerManagerResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.PartnerManager manager = 1;
     */
    manager: PartnerManager[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostInvitePartnerManagerResponse
 */
export interface PostInvitePartnerManagerResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.PartnerManager manager = 1;
     */
    manager?: PartnerManager;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPartnerProfileDraftResponse
 */
export interface GetPartnerProfileDraftResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.PartnerProfileDraft draft = 1;
     */
    draft?: PartnerProfileDraft;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostPartnerProfileDraftRequest
 */
export interface PostPartnerProfileDraftRequest {
    /**
     * @generated from protobuf field: string partnerName = 1;
     */
    partnerName: string;
    /**
     * @generated from protobuf field: string tagLine = 2;
     */
    tagLine: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: repeated string socialLinks = 4;
     */
    socialLinks: string[];
    /**
     * @generated from protobuf field: one.plan3t.core.model.CoreIndustry coreIndustry = 5;
     */
    coreIndustry: CoreIndustry;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource coverImage = 6;
     */
    coverImage?: ImageResource;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource logo = 7;
     */
    logo?: ImageResource;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ImageResource mood_images = 8;
     */
    moodImages: ImageResource[];
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.PostPartnerProfileDraftRequest.RichSocialLink social_links_rich = 9;
     */
    socialLinksRich: PostPartnerProfileDraftRequest_RichSocialLink[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostPartnerProfileDraftRequest.RichSocialLink
 */
export interface PostPartnerProfileDraftRequest_RichSocialLink {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
    /**
     * @generated from protobuf field: string kind = 3;
     */
    kind: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostPartnerProfileDraftResponse
 */
export interface PostPartnerProfileDraftResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.PartnerProfileDraft draft = 1;
     */
    draft?: PartnerProfileDraft;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRewardDraftResponse
 */
export interface GetRewardDraftResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.RewardDraft draft = 1;
     */
    draft?: RewardDraft;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostRewardDraftRequest
 */
export interface PostRewardDraftRequest {
    /**
     * @generated from protobuf field: string rewardId = 1;
     */
    rewardId: string;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: string terms = 4;
     */
    terms: string;
    /**
     * @generated from protobuf field: string directLink = 5;
     */
    directLink: string;
    /**
     * @generated from protobuf field: repeated string couponCodes = 6;
     */
    couponCodes: string[];
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ImageResource images = 7;
     */
    images: ImageResource[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp validUntil = 8;
     */
    validUntil?: Timestamp;
    /**
     * @generated from protobuf field: int32 max_claims = 9;
     */
    maxClaims: number;
    /**
     * @generated from protobuf field: google.protobuf.Duration max_claims_cooldown = 10;
     */
    maxClaimsCooldown?: Duration;
    /**
     * @generated from protobuf field: one.plan3t.core.model.RewardBillingType billing_type = 11;
     */
    billingType: RewardBillingType;
    /**
     * @generated from protobuf field: int32 code_price_cents = 12;
     */
    codePriceCents: number;
    /**
     * @generated from protobuf field: one.plan3t.core.model.TaxableCodeType taxable_code_type = 13;
     */
    taxableCodeType: TaxableCodeType;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostRewardDraftResponse
 */
export interface PostRewardDraftResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.RewardDraft draft = 1;
     */
    draft?: RewardDraft;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRewardDraftsResponse
 */
export interface GetRewardDraftsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.RewardDraft drafts = 1;
     */
    drafts: RewardDraft[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAnalyticsRequest
 */
export interface GetAnalyticsRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAnalyticsResponse
 */
export interface GetAnalyticsResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.AnalyticsReports reports = 1;
     */
    reports?: AnalyticsReports;
}
/**
 * @generated from protobuf message one.plan3t.core.model.AnalyticsReports
 */
export interface AnalyticsReports {
    /**
     * @generated from protobuf field: one.plan3t.core.model.AnalyticsReport clickOutReport = 1;
     */
    clickOutReport?: AnalyticsReport;
    /**
     * @generated from protobuf field: one.plan3t.core.model.AnalyticsReport pageViewReport = 2;
     */
    pageViewReport?: AnalyticsReport;
    /**
     * @generated from protobuf field: one.plan3t.core.model.AnalyticsReport impressionReport = 3;
     */
    impressionReport?: AnalyticsReport;
    /**
     * @generated from protobuf field: one.plan3t.core.model.AnalyticsReport rewardReport = 4;
     */
    rewardReport?: AnalyticsReport;
    /**
     * @generated from protobuf field: one.plan3t.core.model.AnalyticsReport cashbackReport = 5;
     */
    cashbackReport?: AnalyticsReport;
    /**
     * @generated from protobuf field: one.plan3t.core.model.AnalyticsReport totalBasketSizeReport = 6;
     */
    totalBasketSizeReport?: AnalyticsReport;
}
/**
 * @generated from protobuf message one.plan3t.core.model.UploadRewardCodesRequest
 */
export interface UploadRewardCodesRequest {
    /**
     * @generated from protobuf field: string batch_id = 1;
     */
    batchId: string;
    /**
     * @generated from protobuf field: string partnerId = 2;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string rewardId = 3;
     */
    rewardId: string;
    /**
     * @generated from protobuf field: repeated string codes = 4;
     */
    codes: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.UploadRewardCodesResponse
 */
export interface UploadRewardCodesResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.AssessmentScorePartnerView
 */
export interface AssessmentScorePartnerView {
    /**
     * @generated from protobuf field: string label = 1;
     */
    label: string;
    /**
     * @generated from protobuf field: int32 value_unscaled = 2;
     */
    valueUnscaled: number;
    /**
     * @generated from protobuf field: int32 value_scale = 3;
     */
    valueScale: number;
    /**
     * @generated from protobuf field: bool applicable = 4;
     */
    applicable: boolean;
}
/**
 * @generated from protobuf message one.plan3t.core.model.AssessmentPartnerView
 */
export interface AssessmentPartnerView {
    /**
     * @generated from protobuf field: string partner_id = 1;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string scoring_word = 2;
     */
    scoringWord: string;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.AssessmentScorePartnerView scores = 3;
     */
    scores: AssessmentScorePartnerView[];
    /**
     * @generated from protobuf field: string edit_link = 4;
     */
    editLink: string;
    /**
     * @generated from protobuf field: int32 score = 5;
     */
    score: number;
    /**
     * @generated from protobuf field: int32 score_max = 6;
     */
    scoreMax: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAssessmentPartnerViewRequest
 */
export interface GetAssessmentPartnerViewRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAssessmentPartnerViewResponse
 */
export interface GetAssessmentPartnerViewResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.AssessmentPartnerView assessment = 1;
     */
    assessment?: AssessmentPartnerView;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetPartnerBaseResponse$Type extends MessageType<GetPartnerBaseResponse> {
    constructor() {
        super("one.plan3t.core.model.GetPartnerBaseResponse", [
            { no: 1, name: "partner", kind: "message", T: () => PartnerBase }
        ]);
    }
    create(value?: PartialMessage<GetPartnerBaseResponse>): GetPartnerBaseResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPartnerBaseResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPartnerBaseResponse): GetPartnerBaseResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.PartnerBase partner */ 1:
                    message.partner = PartnerBase.internalBinaryRead(reader, reader.uint32(), options, message.partner);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPartnerBaseResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.PartnerBase partner = 1; */
        if (message.partner)
            PartnerBase.internalBinaryWrite(message.partner, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPartnerBaseResponse
 */
export const GetPartnerBaseResponse = new GetPartnerBaseResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvitePartnerManagerRequest$Type extends MessageType<InvitePartnerManagerRequest> {
    constructor() {
        super("one.plan3t.core.model.InvitePartnerManagerRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InvitePartnerManagerRequest>): InvitePartnerManagerRequest {
        const message = { email: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InvitePartnerManagerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InvitePartnerManagerRequest): InvitePartnerManagerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InvitePartnerManagerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.InvitePartnerManagerRequest
 */
export const InvitePartnerManagerRequest = new InvitePartnerManagerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPartnerManagerResponse$Type extends MessageType<GetPartnerManagerResponse> {
    constructor() {
        super("one.plan3t.core.model.GetPartnerManagerResponse", [
            { no: 1, name: "manager", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PartnerManager }
        ]);
    }
    create(value?: PartialMessage<GetPartnerManagerResponse>): GetPartnerManagerResponse {
        const message = { manager: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPartnerManagerResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPartnerManagerResponse): GetPartnerManagerResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.PartnerManager manager */ 1:
                    message.manager.push(PartnerManager.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPartnerManagerResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.PartnerManager manager = 1; */
        for (let i = 0; i < message.manager.length; i++)
            PartnerManager.internalBinaryWrite(message.manager[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPartnerManagerResponse
 */
export const GetPartnerManagerResponse = new GetPartnerManagerResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostInvitePartnerManagerResponse$Type extends MessageType<PostInvitePartnerManagerResponse> {
    constructor() {
        super("one.plan3t.core.model.PostInvitePartnerManagerResponse", [
            { no: 1, name: "manager", kind: "message", T: () => PartnerManager }
        ]);
    }
    create(value?: PartialMessage<PostInvitePartnerManagerResponse>): PostInvitePartnerManagerResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostInvitePartnerManagerResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostInvitePartnerManagerResponse): PostInvitePartnerManagerResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.PartnerManager manager */ 1:
                    message.manager = PartnerManager.internalBinaryRead(reader, reader.uint32(), options, message.manager);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostInvitePartnerManagerResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.PartnerManager manager = 1; */
        if (message.manager)
            PartnerManager.internalBinaryWrite(message.manager, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostInvitePartnerManagerResponse
 */
export const PostInvitePartnerManagerResponse = new PostInvitePartnerManagerResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPartnerProfileDraftResponse$Type extends MessageType<GetPartnerProfileDraftResponse> {
    constructor() {
        super("one.plan3t.core.model.GetPartnerProfileDraftResponse", [
            { no: 1, name: "draft", kind: "message", T: () => PartnerProfileDraft }
        ]);
    }
    create(value?: PartialMessage<GetPartnerProfileDraftResponse>): GetPartnerProfileDraftResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPartnerProfileDraftResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPartnerProfileDraftResponse): GetPartnerProfileDraftResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.PartnerProfileDraft draft */ 1:
                    message.draft = PartnerProfileDraft.internalBinaryRead(reader, reader.uint32(), options, message.draft);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPartnerProfileDraftResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.PartnerProfileDraft draft = 1; */
        if (message.draft)
            PartnerProfileDraft.internalBinaryWrite(message.draft, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPartnerProfileDraftResponse
 */
export const GetPartnerProfileDraftResponse = new GetPartnerProfileDraftResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostPartnerProfileDraftRequest$Type extends MessageType<PostPartnerProfileDraftRequest> {
    constructor() {
        super("one.plan3t.core.model.PostPartnerProfileDraftRequest", [
            { no: 1, name: "partnerName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tagLine", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "socialLinks", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "coreIndustry", kind: "enum", T: () => ["one.plan3t.core.model.CoreIndustry", CoreIndustry] },
            { no: 6, name: "coverImage", kind: "message", T: () => ImageResource },
            { no: 7, name: "logo", kind: "message", T: () => ImageResource },
            { no: 8, name: "mood_images", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageResource },
            { no: 9, name: "social_links_rich", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PostPartnerProfileDraftRequest_RichSocialLink }
        ]);
    }
    create(value?: PartialMessage<PostPartnerProfileDraftRequest>): PostPartnerProfileDraftRequest {
        const message = { partnerName: "", tagLine: "", description: "", socialLinks: [], coreIndustry: 0, moodImages: [], socialLinksRich: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostPartnerProfileDraftRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostPartnerProfileDraftRequest): PostPartnerProfileDraftRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string partnerName */ 1:
                    message.partnerName = reader.string();
                    break;
                case /* string tagLine */ 2:
                    message.tagLine = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* repeated string socialLinks */ 4:
                    message.socialLinks.push(reader.string());
                    break;
                case /* one.plan3t.core.model.CoreIndustry coreIndustry */ 5:
                    message.coreIndustry = reader.int32();
                    break;
                case /* one.plan3t.core.model.ImageResource coverImage */ 6:
                    message.coverImage = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.coverImage);
                    break;
                case /* one.plan3t.core.model.ImageResource logo */ 7:
                    message.logo = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.logo);
                    break;
                case /* repeated one.plan3t.core.model.ImageResource mood_images */ 8:
                    message.moodImages.push(ImageResource.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated one.plan3t.core.model.PostPartnerProfileDraftRequest.RichSocialLink social_links_rich */ 9:
                    message.socialLinksRich.push(PostPartnerProfileDraftRequest_RichSocialLink.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostPartnerProfileDraftRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string partnerName = 1; */
        if (message.partnerName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.partnerName);
        /* string tagLine = 2; */
        if (message.tagLine !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tagLine);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* repeated string socialLinks = 4; */
        for (let i = 0; i < message.socialLinks.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.socialLinks[i]);
        /* one.plan3t.core.model.CoreIndustry coreIndustry = 5; */
        if (message.coreIndustry !== 0)
            writer.tag(5, WireType.Varint).int32(message.coreIndustry);
        /* one.plan3t.core.model.ImageResource coverImage = 6; */
        if (message.coverImage)
            ImageResource.internalBinaryWrite(message.coverImage, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.ImageResource logo = 7; */
        if (message.logo)
            ImageResource.internalBinaryWrite(message.logo, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated one.plan3t.core.model.ImageResource mood_images = 8; */
        for (let i = 0; i < message.moodImages.length; i++)
            ImageResource.internalBinaryWrite(message.moodImages[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated one.plan3t.core.model.PostPartnerProfileDraftRequest.RichSocialLink social_links_rich = 9; */
        for (let i = 0; i < message.socialLinksRich.length; i++)
            PostPartnerProfileDraftRequest_RichSocialLink.internalBinaryWrite(message.socialLinksRich[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostPartnerProfileDraftRequest
 */
export const PostPartnerProfileDraftRequest = new PostPartnerProfileDraftRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostPartnerProfileDraftRequest_RichSocialLink$Type extends MessageType<PostPartnerProfileDraftRequest_RichSocialLink> {
    constructor() {
        super("one.plan3t.core.model.PostPartnerProfileDraftRequest.RichSocialLink", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "kind", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PostPartnerProfileDraftRequest_RichSocialLink>): PostPartnerProfileDraftRequest_RichSocialLink {
        const message = { url: "", label: "", kind: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostPartnerProfileDraftRequest_RichSocialLink>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostPartnerProfileDraftRequest_RichSocialLink): PostPartnerProfileDraftRequest_RichSocialLink {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                case /* string kind */ 3:
                    message.kind = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostPartnerProfileDraftRequest_RichSocialLink, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        /* string kind = 3; */
        if (message.kind !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostPartnerProfileDraftRequest.RichSocialLink
 */
export const PostPartnerProfileDraftRequest_RichSocialLink = new PostPartnerProfileDraftRequest_RichSocialLink$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostPartnerProfileDraftResponse$Type extends MessageType<PostPartnerProfileDraftResponse> {
    constructor() {
        super("one.plan3t.core.model.PostPartnerProfileDraftResponse", [
            { no: 1, name: "draft", kind: "message", T: () => PartnerProfileDraft }
        ]);
    }
    create(value?: PartialMessage<PostPartnerProfileDraftResponse>): PostPartnerProfileDraftResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostPartnerProfileDraftResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostPartnerProfileDraftResponse): PostPartnerProfileDraftResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.PartnerProfileDraft draft */ 1:
                    message.draft = PartnerProfileDraft.internalBinaryRead(reader, reader.uint32(), options, message.draft);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostPartnerProfileDraftResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.PartnerProfileDraft draft = 1; */
        if (message.draft)
            PartnerProfileDraft.internalBinaryWrite(message.draft, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostPartnerProfileDraftResponse
 */
export const PostPartnerProfileDraftResponse = new PostPartnerProfileDraftResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRewardDraftResponse$Type extends MessageType<GetRewardDraftResponse> {
    constructor() {
        super("one.plan3t.core.model.GetRewardDraftResponse", [
            { no: 1, name: "draft", kind: "message", T: () => RewardDraft }
        ]);
    }
    create(value?: PartialMessage<GetRewardDraftResponse>): GetRewardDraftResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRewardDraftResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRewardDraftResponse): GetRewardDraftResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.RewardDraft draft */ 1:
                    message.draft = RewardDraft.internalBinaryRead(reader, reader.uint32(), options, message.draft);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRewardDraftResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.RewardDraft draft = 1; */
        if (message.draft)
            RewardDraft.internalBinaryWrite(message.draft, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRewardDraftResponse
 */
export const GetRewardDraftResponse = new GetRewardDraftResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostRewardDraftRequest$Type extends MessageType<PostRewardDraftRequest> {
    constructor() {
        super("one.plan3t.core.model.PostRewardDraftRequest", [
            { no: 1, name: "rewardId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "terms", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "directLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "couponCodes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "images", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageResource },
            { no: 8, name: "validUntil", kind: "message", T: () => Timestamp },
            { no: 9, name: "max_claims", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "max_claims_cooldown", kind: "message", T: () => Duration },
            { no: 11, name: "billing_type", kind: "enum", T: () => ["one.plan3t.core.model.RewardBillingType", RewardBillingType, "REWARD_BILLING_TYPE_"] },
            { no: 12, name: "code_price_cents", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "taxable_code_type", kind: "enum", T: () => ["one.plan3t.core.model.TaxableCodeType", TaxableCodeType, "TAXABLE_CODE_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<PostRewardDraftRequest>): PostRewardDraftRequest {
        const message = { rewardId: "", title: "", description: "", terms: "", directLink: "", couponCodes: [], images: [], maxClaims: 0, billingType: 0, codePriceCents: 0, taxableCodeType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostRewardDraftRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostRewardDraftRequest): PostRewardDraftRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string rewardId */ 1:
                    message.rewardId = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string terms */ 4:
                    message.terms = reader.string();
                    break;
                case /* string directLink */ 5:
                    message.directLink = reader.string();
                    break;
                case /* repeated string couponCodes */ 6:
                    message.couponCodes.push(reader.string());
                    break;
                case /* repeated one.plan3t.core.model.ImageResource images */ 7:
                    message.images.push(ImageResource.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp validUntil */ 8:
                    message.validUntil = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.validUntil);
                    break;
                case /* int32 max_claims */ 9:
                    message.maxClaims = reader.int32();
                    break;
                case /* google.protobuf.Duration max_claims_cooldown */ 10:
                    message.maxClaimsCooldown = Duration.internalBinaryRead(reader, reader.uint32(), options, message.maxClaimsCooldown);
                    break;
                case /* one.plan3t.core.model.RewardBillingType billing_type */ 11:
                    message.billingType = reader.int32();
                    break;
                case /* int32 code_price_cents */ 12:
                    message.codePriceCents = reader.int32();
                    break;
                case /* one.plan3t.core.model.TaxableCodeType taxable_code_type */ 13:
                    message.taxableCodeType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostRewardDraftRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string rewardId = 1; */
        if (message.rewardId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.rewardId);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string terms = 4; */
        if (message.terms !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.terms);
        /* string directLink = 5; */
        if (message.directLink !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.directLink);
        /* repeated string couponCodes = 6; */
        for (let i = 0; i < message.couponCodes.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.couponCodes[i]);
        /* repeated one.plan3t.core.model.ImageResource images = 7; */
        for (let i = 0; i < message.images.length; i++)
            ImageResource.internalBinaryWrite(message.images[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp validUntil = 8; */
        if (message.validUntil)
            Timestamp.internalBinaryWrite(message.validUntil, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* int32 max_claims = 9; */
        if (message.maxClaims !== 0)
            writer.tag(9, WireType.Varint).int32(message.maxClaims);
        /* google.protobuf.Duration max_claims_cooldown = 10; */
        if (message.maxClaimsCooldown)
            Duration.internalBinaryWrite(message.maxClaimsCooldown, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.RewardBillingType billing_type = 11; */
        if (message.billingType !== 0)
            writer.tag(11, WireType.Varint).int32(message.billingType);
        /* int32 code_price_cents = 12; */
        if (message.codePriceCents !== 0)
            writer.tag(12, WireType.Varint).int32(message.codePriceCents);
        /* one.plan3t.core.model.TaxableCodeType taxable_code_type = 13; */
        if (message.taxableCodeType !== 0)
            writer.tag(13, WireType.Varint).int32(message.taxableCodeType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostRewardDraftRequest
 */
export const PostRewardDraftRequest = new PostRewardDraftRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostRewardDraftResponse$Type extends MessageType<PostRewardDraftResponse> {
    constructor() {
        super("one.plan3t.core.model.PostRewardDraftResponse", [
            { no: 1, name: "draft", kind: "message", T: () => RewardDraft }
        ]);
    }
    create(value?: PartialMessage<PostRewardDraftResponse>): PostRewardDraftResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostRewardDraftResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostRewardDraftResponse): PostRewardDraftResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.RewardDraft draft */ 1:
                    message.draft = RewardDraft.internalBinaryRead(reader, reader.uint32(), options, message.draft);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostRewardDraftResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.RewardDraft draft = 1; */
        if (message.draft)
            RewardDraft.internalBinaryWrite(message.draft, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostRewardDraftResponse
 */
export const PostRewardDraftResponse = new PostRewardDraftResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRewardDraftsResponse$Type extends MessageType<GetRewardDraftsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetRewardDraftsResponse", [
            { no: 1, name: "drafts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RewardDraft }
        ]);
    }
    create(value?: PartialMessage<GetRewardDraftsResponse>): GetRewardDraftsResponse {
        const message = { drafts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRewardDraftsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRewardDraftsResponse): GetRewardDraftsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.RewardDraft drafts */ 1:
                    message.drafts.push(RewardDraft.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRewardDraftsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.RewardDraft drafts = 1; */
        for (let i = 0; i < message.drafts.length; i++)
            RewardDraft.internalBinaryWrite(message.drafts[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRewardDraftsResponse
 */
export const GetRewardDraftsResponse = new GetRewardDraftsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAnalyticsRequest$Type extends MessageType<GetAnalyticsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetAnalyticsRequest", []);
    }
    create(value?: PartialMessage<GetAnalyticsRequest>): GetAnalyticsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAnalyticsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAnalyticsRequest): GetAnalyticsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetAnalyticsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAnalyticsRequest
 */
export const GetAnalyticsRequest = new GetAnalyticsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAnalyticsResponse$Type extends MessageType<GetAnalyticsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetAnalyticsResponse", [
            { no: 1, name: "reports", kind: "message", T: () => AnalyticsReports }
        ]);
    }
    create(value?: PartialMessage<GetAnalyticsResponse>): GetAnalyticsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAnalyticsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAnalyticsResponse): GetAnalyticsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.AnalyticsReports reports */ 1:
                    message.reports = AnalyticsReports.internalBinaryRead(reader, reader.uint32(), options, message.reports);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAnalyticsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.AnalyticsReports reports = 1; */
        if (message.reports)
            AnalyticsReports.internalBinaryWrite(message.reports, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAnalyticsResponse
 */
export const GetAnalyticsResponse = new GetAnalyticsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnalyticsReports$Type extends MessageType<AnalyticsReports> {
    constructor() {
        super("one.plan3t.core.model.AnalyticsReports", [
            { no: 1, name: "clickOutReport", kind: "message", T: () => AnalyticsReport },
            { no: 2, name: "pageViewReport", kind: "message", T: () => AnalyticsReport },
            { no: 3, name: "impressionReport", kind: "message", T: () => AnalyticsReport },
            { no: 4, name: "rewardReport", kind: "message", T: () => AnalyticsReport },
            { no: 5, name: "cashbackReport", kind: "message", T: () => AnalyticsReport },
            { no: 6, name: "totalBasketSizeReport", kind: "message", T: () => AnalyticsReport }
        ]);
    }
    create(value?: PartialMessage<AnalyticsReports>): AnalyticsReports {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AnalyticsReports>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AnalyticsReports): AnalyticsReports {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.AnalyticsReport clickOutReport */ 1:
                    message.clickOutReport = AnalyticsReport.internalBinaryRead(reader, reader.uint32(), options, message.clickOutReport);
                    break;
                case /* one.plan3t.core.model.AnalyticsReport pageViewReport */ 2:
                    message.pageViewReport = AnalyticsReport.internalBinaryRead(reader, reader.uint32(), options, message.pageViewReport);
                    break;
                case /* one.plan3t.core.model.AnalyticsReport impressionReport */ 3:
                    message.impressionReport = AnalyticsReport.internalBinaryRead(reader, reader.uint32(), options, message.impressionReport);
                    break;
                case /* one.plan3t.core.model.AnalyticsReport rewardReport */ 4:
                    message.rewardReport = AnalyticsReport.internalBinaryRead(reader, reader.uint32(), options, message.rewardReport);
                    break;
                case /* one.plan3t.core.model.AnalyticsReport cashbackReport */ 5:
                    message.cashbackReport = AnalyticsReport.internalBinaryRead(reader, reader.uint32(), options, message.cashbackReport);
                    break;
                case /* one.plan3t.core.model.AnalyticsReport totalBasketSizeReport */ 6:
                    message.totalBasketSizeReport = AnalyticsReport.internalBinaryRead(reader, reader.uint32(), options, message.totalBasketSizeReport);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AnalyticsReports, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.AnalyticsReport clickOutReport = 1; */
        if (message.clickOutReport)
            AnalyticsReport.internalBinaryWrite(message.clickOutReport, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.AnalyticsReport pageViewReport = 2; */
        if (message.pageViewReport)
            AnalyticsReport.internalBinaryWrite(message.pageViewReport, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.AnalyticsReport impressionReport = 3; */
        if (message.impressionReport)
            AnalyticsReport.internalBinaryWrite(message.impressionReport, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.AnalyticsReport rewardReport = 4; */
        if (message.rewardReport)
            AnalyticsReport.internalBinaryWrite(message.rewardReport, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.AnalyticsReport cashbackReport = 5; */
        if (message.cashbackReport)
            AnalyticsReport.internalBinaryWrite(message.cashbackReport, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.AnalyticsReport totalBasketSizeReport = 6; */
        if (message.totalBasketSizeReport)
            AnalyticsReport.internalBinaryWrite(message.totalBasketSizeReport, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AnalyticsReports
 */
export const AnalyticsReports = new AnalyticsReports$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadRewardCodesRequest$Type extends MessageType<UploadRewardCodesRequest> {
    constructor() {
        super("one.plan3t.core.model.UploadRewardCodesRequest", [
            { no: 1, name: "batch_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "partnerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rewardId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "codes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UploadRewardCodesRequest>): UploadRewardCodesRequest {
        const message = { batchId: "", partnerId: "", rewardId: "", codes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UploadRewardCodesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadRewardCodesRequest): UploadRewardCodesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string batch_id */ 1:
                    message.batchId = reader.string();
                    break;
                case /* string partnerId */ 2:
                    message.partnerId = reader.string();
                    break;
                case /* string rewardId */ 3:
                    message.rewardId = reader.string();
                    break;
                case /* repeated string codes */ 4:
                    message.codes.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UploadRewardCodesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string batch_id = 1; */
        if (message.batchId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.batchId);
        /* string partnerId = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        /* string rewardId = 3; */
        if (message.rewardId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.rewardId);
        /* repeated string codes = 4; */
        for (let i = 0; i < message.codes.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.codes[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.UploadRewardCodesRequest
 */
export const UploadRewardCodesRequest = new UploadRewardCodesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadRewardCodesResponse$Type extends MessageType<UploadRewardCodesResponse> {
    constructor() {
        super("one.plan3t.core.model.UploadRewardCodesResponse", []);
    }
    create(value?: PartialMessage<UploadRewardCodesResponse>): UploadRewardCodesResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UploadRewardCodesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadRewardCodesResponse): UploadRewardCodesResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UploadRewardCodesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.UploadRewardCodesResponse
 */
export const UploadRewardCodesResponse = new UploadRewardCodesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentScorePartnerView$Type extends MessageType<AssessmentScorePartnerView> {
    constructor() {
        super("one.plan3t.core.model.AssessmentScorePartnerView", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value_unscaled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "value_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "applicable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<AssessmentScorePartnerView>): AssessmentScorePartnerView {
        const message = { label: "", valueUnscaled: 0, valueScale: 0, applicable: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AssessmentScorePartnerView>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssessmentScorePartnerView): AssessmentScorePartnerView {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* int32 value_unscaled */ 2:
                    message.valueUnscaled = reader.int32();
                    break;
                case /* int32 value_scale */ 3:
                    message.valueScale = reader.int32();
                    break;
                case /* bool applicable */ 4:
                    message.applicable = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AssessmentScorePartnerView, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* int32 value_unscaled = 2; */
        if (message.valueUnscaled !== 0)
            writer.tag(2, WireType.Varint).int32(message.valueUnscaled);
        /* int32 value_scale = 3; */
        if (message.valueScale !== 0)
            writer.tag(3, WireType.Varint).int32(message.valueScale);
        /* bool applicable = 4; */
        if (message.applicable !== false)
            writer.tag(4, WireType.Varint).bool(message.applicable);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AssessmentScorePartnerView
 */
export const AssessmentScorePartnerView = new AssessmentScorePartnerView$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentPartnerView$Type extends MessageType<AssessmentPartnerView> {
    constructor() {
        super("one.plan3t.core.model.AssessmentPartnerView", [
            { no: 1, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scoring_word", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "scores", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssessmentScorePartnerView },
            { no: 4, name: "edit_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "score", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "score_max", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<AssessmentPartnerView>): AssessmentPartnerView {
        const message = { partnerId: "", scoringWord: "", scores: [], editLink: "", score: 0, scoreMax: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AssessmentPartnerView>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssessmentPartnerView): AssessmentPartnerView {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string partner_id */ 1:
                    message.partnerId = reader.string();
                    break;
                case /* string scoring_word */ 2:
                    message.scoringWord = reader.string();
                    break;
                case /* repeated one.plan3t.core.model.AssessmentScorePartnerView scores */ 3:
                    message.scores.push(AssessmentScorePartnerView.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string edit_link */ 4:
                    message.editLink = reader.string();
                    break;
                case /* int32 score */ 5:
                    message.score = reader.int32();
                    break;
                case /* int32 score_max */ 6:
                    message.scoreMax = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AssessmentPartnerView, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string partner_id = 1; */
        if (message.partnerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.partnerId);
        /* string scoring_word = 2; */
        if (message.scoringWord !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scoringWord);
        /* repeated one.plan3t.core.model.AssessmentScorePartnerView scores = 3; */
        for (let i = 0; i < message.scores.length; i++)
            AssessmentScorePartnerView.internalBinaryWrite(message.scores[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string edit_link = 4; */
        if (message.editLink !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.editLink);
        /* int32 score = 5; */
        if (message.score !== 0)
            writer.tag(5, WireType.Varint).int32(message.score);
        /* int32 score_max = 6; */
        if (message.scoreMax !== 0)
            writer.tag(6, WireType.Varint).int32(message.scoreMax);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AssessmentPartnerView
 */
export const AssessmentPartnerView = new AssessmentPartnerView$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentPartnerViewRequest$Type extends MessageType<GetAssessmentPartnerViewRequest> {
    constructor() {
        super("one.plan3t.core.model.GetAssessmentPartnerViewRequest", []);
    }
    create(value?: PartialMessage<GetAssessmentPartnerViewRequest>): GetAssessmentPartnerViewRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAssessmentPartnerViewRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAssessmentPartnerViewRequest): GetAssessmentPartnerViewRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetAssessmentPartnerViewRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAssessmentPartnerViewRequest
 */
export const GetAssessmentPartnerViewRequest = new GetAssessmentPartnerViewRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentPartnerViewResponse$Type extends MessageType<GetAssessmentPartnerViewResponse> {
    constructor() {
        super("one.plan3t.core.model.GetAssessmentPartnerViewResponse", [
            { no: 1, name: "assessment", kind: "message", T: () => AssessmentPartnerView }
        ]);
    }
    create(value?: PartialMessage<GetAssessmentPartnerViewResponse>): GetAssessmentPartnerViewResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAssessmentPartnerViewResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAssessmentPartnerViewResponse): GetAssessmentPartnerViewResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.AssessmentPartnerView assessment */ 1:
                    message.assessment = AssessmentPartnerView.internalBinaryRead(reader, reader.uint32(), options, message.assessment);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAssessmentPartnerViewResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.AssessmentPartnerView assessment = 1; */
        if (message.assessment)
            AssessmentPartnerView.internalBinaryWrite(message.assessment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAssessmentPartnerViewResponse
 */
export const GetAssessmentPartnerViewResponse = new GetAssessmentPartnerViewResponse$Type();
