import { useEffect } from 'react';
import * as React from 'react';
import { app } from './firebase';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { authActions, authSelectors } from './store';

let didCheckForImpersonateToken = false;
async function checkForImpersonateToken() {
    if (didCheckForImpersonateToken) {
        return;
    }
    didCheckForImpersonateToken = true;

    // eslint-disable-next-line no-restricted-globals
    const url = new URL(location.href);
    const params = new URLSearchParams(url.search);
    const token = params.get('__plan3t_impersonate_token');

    if (!token) {
        return;
    }

    await app.auth().signOut();

    await app.auth().signInWithCustomToken(token);
}

const AuthProvider: React.FunctionComponent = ({ children }) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        app.auth().onAuthStateChanged((user) => {
            dispatch(authActions.loadUser(user));
        });

        checkForImpersonateToken();
    });

    const isInitialized = useAppSelector(authSelectors.isInitialized);

    if (!isInitialized) {
        return null;
    }

    return <>{children}</>;
};
export default AuthProvider;
