import * as React from 'react';
import { Translatable, useI18n } from '../i18n';

type Props = {
    title: Translatable;
};

const SubHeader: React.FunctionComponent<Props> = ({ title }) => {
    const { t } = useI18n();
    return (
        <h2 className="text-headline2 font-bold text-semantic-primary pl-6">
            {t(title)}
        </h2>
    );
};
export default SubHeader;
