import * as React from 'react';
import SubHeader from './SubHeader';
import cx from 'classnames';

export interface SubSectionProps {
    title: string;
    className?: string;
    children?: React.ReactNode;
}

export const SubSection = ({ title, className, children }: SubSectionProps) => {
    return (
        <div className={cx('flex flex-col', className)}>
            <SubHeader title={title} />
            <div className="h-4" />
            {children}
        </div>
    );
};
