import * as React from 'react';
import { CoreIndustry } from '../api/model/core';
import SelectInput from '../common/form/SelectInput';

type Props = {
    value?: CoreIndustry | undefined;
    onChanged: (v: CoreIndustry) => void;
    disabled?: boolean;
};

export const CoreIndustryInput: React.FunctionComponent<Props> = (props) => {
    return (
        <div className="w-48">
            <SelectInput
                value={props.value}
                label="profile.coreIndustry.label"
                options={[
                    {
                        label: 'profile.coreIndustry.options.housing',
                        value: CoreIndustry.Housing,
                    },
                    {
                        label: 'profile.coreIndustry.options.mobility',
                        value: CoreIndustry.Mobility,
                    },
                    {
                        label: 'profile.coreIndustry.options.nutrition',
                        value: CoreIndustry.Nutrition,
                    },
                    {
                        label: 'profile.coreIndustry.options.consumption',
                        value: CoreIndustry.Consumption,
                    },
                ]}
                emptyLabel="profile.coreIndustry.noSelection"
                onChange={(v) => props.onChanged(parseInt(v) as CoreIndustry)}
                disabled={props.disabled}
            />
        </div>
    );
};

export default CoreIndustryInput;
