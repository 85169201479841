import * as React from 'react';
import { Link } from 'react-router-dom';
import {
    IconBilling,
    IconPartnerProfile,
    IconReports,
    IconRewards,
    IconSpeakerPhone,
    IconViewGrid,
} from '../icons';
import LogoutButton from '../auth/LogoutButton';
import { SidebarLink, SidebarSection } from '../common/Sidebar';
import { useI18n } from '../i18n';
import logo from '../images/logo.png';

const Sidebar: React.FunctionComponent = () => {
    const modules = [
        {
            label: 'module.profile.name',
            to: '/profile',
            icon: IconPartnerProfile,
        },
        { label: 'module.rewards.name', to: '/rewards', icon: IconRewards },
        { label: 'module.reports.name', to: '/reports', icon: IconReports },
        { label: 'module.billing.name', to: '/billing', icon: IconBilling },
        // {
        //     label: 'module.integration.name',
        //     to: '/integration',
        //     icon: IconCode,
        // },
        {
            label: 'module.branding.name',
            to: '/branding',
            icon: IconSpeakerPhone,
        },
        // { label: 'module.cashback.name', to: '/cashback', icon: IconCash },
    ];

    const { t } = useI18n();

    return (
        <aside className="flex flex-col w-64 p-6 space-y-8 bg-semantic-surface border-r border-gray-200 overflow-y-auto">
            <div className="flex flex-col font-headline flex-shrink-0">
                <span>
                    <img
                        src={logo}
                        alt={t('dashboard.sidebar.title')}
                        className="h-6"
                    />
                </span>
                <span className="text-semantic-primary">
                    {t('dashboard.sidebar.subtitle')}
                </span>
            </div>
            <SidebarLink
                label="dashboard.sidebar.dashboardLink"
                to="/"
                exactMatch
                icon={IconViewGrid}
            />
            <SidebarSection label="dashboard.sidebar.subheader.modules">
                {modules.map((module) => (
                    <SidebarLink key={module.label} {...module} />
                ))}
            </SidebarSection>
            <SidebarSection label="dashboard.sidebar.subheader.account">
                <Link
                    to="/account"
                    className="text-left text-sm font-light text-gray-700 hover:text-gray-800"
                >
                    {t('dashboard.sidebar.account.settings')}
                </Link>
                <Link
                    to="/manager"
                    className="text-left text-sm font-light text-gray-700 hover:text-gray-800"
                >
                    {t('dashboard.sidebar.account.team')}
                </Link>
                <LogoutButton className="text-left text-sm font-light text-gray-700 hover:text-gray-800">
                    {t('dashboard.sidebar.account.logout')}
                </LogoutButton>
            </SidebarSection>
        </aside>
    );
};
export default Sidebar;
