import { RefObject } from 'react';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

type Props = {
    nodeRef: RefObject<HTMLElement>;
    appear?: boolean;
    show?: boolean;
    enter?: string;
    enterFrom?: string;
    enterTo?: string;
    leave?: string;
    leaveFrom?: string;
    leaveTo?: string;
};

export const Transition: React.FunctionComponent<Props> = ({
    nodeRef,
    show = true,
    appear,
    enter,
    enterFrom,
    enterTo,
    leave,
    leaveFrom,
    leaveTo,
    children,
}) => {
    const enterClasses = (enter ?? '').split(' ').filter((s) => s.length);
    const enterFromClasses = (enterFrom ?? '')
        .split(' ')
        .filter((s) => s.length);
    const enterToClasses = (enterTo ?? '').split(' ').filter((s) => s.length);
    const leaveClasses = (leave ?? '').split(' ').filter((s) => s.length);
    const leaveFromClasses = (leaveFrom ?? '')
        .split(' ')
        .filter((s) => s.length);
    const leaveToClasses = (leaveTo ?? '').split(' ').filter((s) => s.length);

    return (
        <CSSTransition
            appear={appear}
            unmountOnExit
            in={show}
            nodeRef={nodeRef}
            addEndListener={(done) => {
                nodeRef.current?.addEventListener('transitionend', done, false);
            }}
            onEnter={() => {
                nodeRef.current &&
                    nodeRef.current.classList.add(
                        ...enterClasses,
                        ...enterFromClasses,
                    );
            }}
            onEntering={() => {
                nodeRef.current &&
                    nodeRef.current.classList.remove(...enterFromClasses);
                nodeRef.current &&
                    nodeRef.current.classList.add(...enterToClasses);
            }}
            onEntered={() => {
                nodeRef.current &&
                    nodeRef.current.classList.remove(
                        ...enterToClasses,
                        ...enterClasses,
                    );
            }}
            onExit={() => {
                nodeRef.current &&
                    nodeRef.current.classList.add(
                        ...leaveClasses,
                        ...leaveFromClasses,
                    );
            }}
            onExiting={() => {
                nodeRef.current &&
                    nodeRef.current.classList.remove(...leaveFromClasses);
                nodeRef.current &&
                    nodeRef.current.classList.add(...leaveToClasses);
            }}
            onExited={() => {
                nodeRef.current &&
                    nodeRef.current.classList.remove(
                        ...leaveToClasses,
                        ...leaveClasses,
                    );
            }}
        >
            {children}
        </CSSTransition>
    );
};
