import { useState } from 'react';
import * as React from 'react';
import DashboardHeader from '../common/DashboardHeader';
import PartnerManagerList from './PartnerManagerList';
import InvitePartnerManagerModal from './InvitePartnerManagerModal';
import { Button } from '../common/Button';
import Scrollable from '../common/Scrollable';

const PartnerManagerPage: React.FunctionComponent = () => {
    const [inviteModalVisible, setShowInviteModal] = useState(false);

    return (
        <>
            <DashboardHeader title="module.manager.name">
                <Button
                    primary
                    label="manager.invite.inviteButton"
                    onClick={() => setShowInviteModal(true)}
                />
            </DashboardHeader>
            <Scrollable>
                <PartnerManagerList />
            </Scrollable>
            <InvitePartnerManagerModal
                visible={inviteModalVisible}
                onClose={() => setShowInviteModal(false)}
            />
        </>
    );
};

export default PartnerManagerPage;
