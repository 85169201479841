import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppState } from '../redux/AppStore';
import { app } from '../auth/firebase';
import firebase from 'firebase/app';
import 'firebase/auth';
import { snackbarActions } from '../snackbar/store';
import { GetUserAccountResponse } from '../api/model/core';
import { UserAccount } from '../api/model/core';

const fetchMyUserAccount = createAsyncThunk(
    'account/fetch/me',
    async (): Promise<UserAccount> => {
        const user = app.auth().currentUser;
        if (!user) {
            return Promise.reject('not_logged_in');
        }

        return transformUser(user);
    },
);

const fetchUserAccount = createAsyncThunk(
    'account/fetch',
    async (ids: string[]) => {
        const res = await axios.get<GetUserAccountResponse>(
            `/fuser?ids=${ids}`,
        );
        const users: UserAccount[] = res.data.accounts;

        return users;
    },
);

const transformUser = (user: firebase.UserInfo): UserAccount => ({
    uid: user.uid,
    name: user.displayName || '',
    email: user.email || '',
    photoUrl: user.photoURL || '',
    emailVerified: true,
});

const changeDisplayName = createAsyncThunk<UserAccount, string>(
    'account/write/displayName',
    async (name: string, { getState, dispatch }) => {
        const account = accountSelectors.getMyAccount(getState() as AppState);
        const user = app.auth().currentUser;
        if (!account || !user) {
            return Promise.reject('not_logged_in');
        }
        await user.updateProfile({
            displayName: name,
        });

        dispatch(
            snackbarActions.sendMessage({
                title: 'accountsettings.notifications.displayNameChanged.title',
            }),
        );

        return {
            ...account,
            name,
        };
    },
);

type ChangePasswordPayload =
    | {
          current: string;
          password: string;
          confirmation: string;
      }
    | {
          password: string;
          confirmation: string;
          doNotReauthenticate: true;
      };
const changePassword = createAsyncThunk(
    'account/write/password',
    async (payload: ChangePasswordPayload, { dispatch }) => {
        if (payload.password !== payload.confirmation) {
            return Promise.reject('password_mismatch');
        }

        const user = app.auth().currentUser;
        if (!user || !user.email) {
            return Promise.reject('not_logged_in');
        }

        if ('current' in payload) {
            const cred = firebase.auth.EmailAuthProvider.credential(
                user.email,
                payload.current,
            );
            try {
                await user.reauthenticateWithCredential(cred);
            } catch (e) {
                return Promise.reject((e as firebase.auth.Error).code);
            }
        }

        await user.updatePassword(payload.password);

        dispatch(
            snackbarActions.sendMessage({
                title: 'accountsettings.notifications.passwordChanged.title',
            }),
        );
    },
);

type AccountState = {
    accounts: Record<
        string,
        {
            account: UserAccount;
            isLoading: boolean;
            error?: string;
        }
    >;
    writeState: {
        isWriting: boolean;
        displayNameError?: string;
        passwordError?: string;
    };
};

const initialState: AccountState = {
    accounts: {},
    writeState: {
        isWriting: false,
    },
};

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchMyUserAccount.fulfilled, (state, action) => {
            state.accounts[action.payload.uid] = {
                ...state.accounts[action.payload.uid],
                account: action.payload,
                isLoading: false,
            };
        });
        builder.addCase(changeDisplayName.pending, (state) => {
            state.writeState.isWriting = true;
        });
        builder.addCase(changeDisplayName.fulfilled, (state, action) => {
            state.accounts[action.payload.uid] = {
                ...state.accounts[action.payload.uid],
                account: action.payload,
            };
            state.writeState.isWriting = false;
            state.writeState.displayNameError = undefined;
        });

        builder.addCase(changePassword.pending, (state) => {
            state.writeState.isWriting = true;
        });
        builder.addCase(changePassword.fulfilled, (state) => {
            state.writeState.isWriting = false;
            state.writeState.passwordError = undefined;
        });
        builder.addCase(changePassword.rejected, (state, action) => {
            state.writeState.isWriting = false;
            state.writeState.passwordError = action.error.message;
        });

        builder.addCase(fetchUserAccount.fulfilled, (state, action) => {
            const firebaseUser = action.payload;
            (firebaseUser || []).forEach((u) => {
                state.accounts[u.uid] = {
                    account: u,
                    isLoading: false,
                };
            });
        });
    },
});

export const accountActions = {
    ...accountSlice.actions,
    fetchMyUserAccount,
    fetchUserAccount,
    changeDisplayName,
    changePassword,
};

export const accountReducer = accountSlice.reducer;

const s = (state: AppState): AccountState => state.account;
export const accountSelectors = {
    getById: (
        state: AppState,
        { id }: { id: string },
    ): UserAccount | undefined => s(state).accounts[id]?.account,
    getMyAccount: (state: AppState): UserAccount | undefined =>
        state.auth.active?.uid
            ? s(state).accounts[state.auth.active.uid]?.account
            : undefined,
    getWriteState: (state: AppState) => s(state).writeState,
} as const;
