import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, ReactNode } from 'react';
import { Translatable, useI18n } from '../i18n';
import cx from 'classnames';

type Props = {
    button: ReactNode;
    items: Array<{
        label: Translatable | ReactNode;
        disabled?: boolean;
        onClick: () => void;
    }>;
};

export default function Dropdown(props: Props) {
    const { t } = useI18n();
    return (
        <Menu as="div" className="inline-block text-left relative">
            <div>
                <Menu.Button as={React.Fragment}>{props.button}</Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute z-10 right-0 mt-2 origin-top-right bg-semantic-surface rounded-lg border border-gray-200 shadow-md focus:outline-none">
                    <div className="px-2 py-2 space-y-2">
                        {props.items.map((item, idx) => (
                            <Menu.Item disabled={item.disabled}>
                                {({ active }) => (
                                    <button
                                        className={cx(
                                            'group flex rounded items-center w-full px-6 py-2 text-body1 font-bold whitespace-no-wrap',
                                            {
                                                'bg-gray-100': active,
                                                'cursor-not-allowed text-gray-400':
                                                    item.disabled,
                                                'cursor-pointer text-semantic-primary':
                                                    !item.disabled,
                                            },
                                        )}
                                        onClick={item.onClick}
                                        disabled={item.disabled}
                                    >
                                        {typeof item.label === 'string'
                                            ? t(item.label)
                                            : item}
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
