import * as React from 'react';
import { AppState } from '../redux/AppStore';
import { authSelectors } from '../auth/store';
import { analyticsSelectors, analyticsActions } from './store';
import { connect, ConnectedProps } from 'react-redux';

import Scrollable from '../common/Scrollable';
import { AnalyticsReportChart } from './AnalyticsReportChart';
import { useFormatMoney } from '../i18n/numbers';
import { Button } from '../common/Button';
import { useI18n } from '../i18n';
import { useAppSelector } from '../redux/react';

const mapStateToProps = (state: AppState) => ({
    partnerId: authSelectors.getPartnerId(state)!,
    isLoading: analyticsSelectors.isLoading(state),

    reports: analyticsSelectors.reports(state),
});

const actions = {
    fetchEvents: analyticsActions.fetchEvents,
};

const connector = connect(mapStateToProps, actions);

type Props = ConnectedProps<typeof connector>;

export const ReportsInner: React.FunctionComponent<Props> = (props) => {
    const { t } = useI18n();
    const fetchEvents = props.fetchEvents;
    const partnerId = props.partnerId;

    React.useEffect(() => {
        fetchEvents({ partnerId });
    }, [fetchEvents, partnerId]);

    return (
        <Scrollable className="pb-12 bg-semantic-background space-y-6 h-full">
            <h1 className="text-headline2 font-bold px-6 ">
                {t('module.reports.name')}
            </h1>
            <div className="overflow-hidden w-full flex flex-col space-y-6 md:flex-row md:space-y-0 md:space-x-6">
                <div className="flex-1">
                    <AnalyticsCashbackChart />
                </div>
                <div className="flex-1 rounded-xl bg-semantic-surface relative">
                    <AnalyticsReportChart
                        title="reports.rewards.title"
                        report={props.reports.rewardReport}
                    />
                </div>
            </div>
            <div className="w-full flex flex-col items-stretch">
                <AnalyticsReportChart
                    title="reports.impression.title"
                    report={props.reports.impressionReport}
                />
            </div>
            <div className="flex justify-center items-center">
                <div>
                    <Button
                        secondary
                        label="reports.requestCustomButtonLabel"
                        href="mailto:hello@plan3t.one"
                    />
                </div>
            </div>
        </Scrollable>
    );
};

export default connector(ReportsInner);

export function AnalyticsCashbackChart() {
    const reports = useAppSelector(analyticsSelectors.reports);
    const fmtMoney = useFormatMoney();
    return (
        <AnalyticsReportChart
            title="reports.cashback.title"
            report={reports.cashbackReport}
            additionalReportForTotal={reports.totalBasketSizeReport}
            formatValue={(n) =>
                fmtMoney({
                    amountUnscaled: n,
                    amountScale: 2,
                    currency: 'EUR',
                })
            }
        />
    );
}
