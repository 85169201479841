import { SyntheticEvent, useCallback, useState } from 'react';
import * as React from 'react';
import { Button } from '../common/Button';
import Modal from '../common/Modal';
import TextInput from '../common/form/TextInput';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { partnerActions, partnerSelectors } from './store';
import { authSelectors } from '../auth/store';
import { useI18n } from '../i18n';

type Props = {
    visible: boolean;
    onClose: () => void;
};

const InvitePartnerManagerModal: React.FunctionComponent<Props> = ({
    visible,
    onClose,
}) => {
    const { t } = useI18n();
    const [email, setEmail] = useState('');

    const dispatch = useAppDispatch();
    const partnerId = useAppSelector(authSelectors.getPartnerId)!;

    const onSubmit = useCallback(
        async (e: SyntheticEvent) => {
            e.preventDefault();

            if (!email.includes('@')) {
                return;
            }

            await dispatch(
                partnerActions.invitePartnerManager({ partnerId, email }),
            );
            setEmail('');
            onClose();
        },
        [dispatch, partnerId, email, setEmail, onClose],
    );

    const isWriting = useAppSelector(partnerSelectors.isWritingManager);

    return (
        <Modal
            visible={visible}
            headline="manager.invite.title"
            onRequestClose={() => {
                setEmail('');
                onClose();
            }}
        >
            <div className="space-y-2">
                <p className="text-sm leading-5 text-gray-600">
                    {t('manager.invite.helpText')}
                </p>
                <form onSubmit={onSubmit}>
                    <TextInput
                        label="E-Mail Addresse"
                        value={email}
                        onChanged={setEmail}
                        autoFocus
                    />
                    {isWriting && <span>....</span>}
                    {!isWriting && (
                        <Button secondary label="Einladen" onClick={onSubmit} />
                    )}
                </form>
            </div>
        </Modal>
    );
};

export default InvitePartnerManagerModal;
