import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../redux/AppStore';

import { GetAnalyticsResponse, AnalyticsReports } from '../api/model/b2b';

export type getAnalyticsArgs = {
    partnerId: string;
};

const fetchEvents = async (partnerId: string) => {
    const res = await axios.get<GetAnalyticsResponse>(
        `/analytics/partner/${partnerId}`,
    );

    return GetAnalyticsResponse.fromJson(res.data as any);
};

const fetchAnalyticsEventsAction = createAsyncThunk(
    'fetch/analytics/partner',
    async ({ partnerId }: getAnalyticsArgs) => {
        return await fetchEvents(partnerId);
    },
);

export type AnalyticsState = {
    isLoading: boolean;
    loadingFailed: boolean;
    reports: AnalyticsReports;
};

const initialState: AnalyticsState = {
    isLoading: false,
    loadingFailed: false,
    reports: {
        clickOutReport: undefined,
        pageViewReport: undefined,
        impressionReport: undefined,
    },
};

const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAnalyticsEventsAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(
            fetchAnalyticsEventsAction.rejected,
            (state, action) => {
                state.isLoading = false;
                state.loadingFailed = true;
            },
        );
        builder.addCase(
            fetchAnalyticsEventsAction.fulfilled,
            (state, action) => {
                state.isLoading = false;
                state.reports = action.payload?.reports ?? {
                    clickOutReport: undefined,
                    pageViewReport: undefined,
                    impressionReport: undefined,
                };
            },
        );
    },
});

export const analyticsReducer = analyticsSlice.reducer;

export const analyticsActions = {
    ...analyticsSlice.actions,
    fetchEvents: fetchAnalyticsEventsAction,
};

export const analyticsSelectors = {
    isLoading: (state: AppState) => state.analytics.isLoading,
    loadingFailed: (state: AppState) => state.analytics.loadingFailed,
    reports: (state: AppState) => state.analytics.reports,
};
