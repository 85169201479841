import * as React from 'react';
import { useHtmlId } from '../../hooks';
import { Translatable, useI18n } from '../../i18n';

type Option = {
    label: Translatable;
    value: string | ReadonlyArray<string> | number;
};

type Props = {
    label: Translatable;
    value: string | ReadonlyArray<string> | number | undefined;
    emptyLabel: Translatable | undefined;
    options: Option[];
    onChange: (value: string) => void;
    disabled?: boolean;
};

const SelectInput: React.FunctionComponent<Props> = (props) => {
    const id = useHtmlId();
    const { t } = useI18n();

    return (
        <>
            <label htmlFor={id} className="block pl-3 form-label">
                {t(props.label)}
            </label>
            <select
                id={id}
                className="form-select text-body1 text-semantic-primary mt-1 w-full"
                disabled={props.disabled}
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
            >
                {props.emptyLabel && (
                    <option value="">{t(props.emptyLabel)}</option>
                )}
                {props.options.map((option) => (
                    <option key={option.value.toString()} value={option.value}>
                        {t(option.label)}
                    </option>
                ))}
            </select>
        </>
    );
};

export default SelectInput;
