import { Duration } from '../api/google/protobuf/duration';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { Reward } from '../api/model/core';
import { useAppDispatch } from '../redux/react';
import { rewardActions } from '../rewards/store';

export function useToggleRewardState(reward: Reward) {
    const dispatch = useAppDispatch();

    async function toggleState() {
        const rewardIsActive = reward.validUntil
            ? Timestamp.toDate(reward.validUntil) > new Date()
            : undefined;

        const newValidUntil = new Date();
        if (rewardIsActive) {
            // Deactivate by setting validUntil to yesterday
            newValidUntil.setDate(newValidUntil.getDate() - 1);
        } else {
            // Activate by setting validUntil to a date one year into the future
            newValidUntil.setFullYear(newValidUntil.getFullYear() + 1);
        }

        // Save
        await dispatch(
            rewardActions.saveRewardDraft({
                partnerId: reward.partnerId,
                rewardId: reward.rewardId,
                title: reward.title,
                description: reward.description,
                terms: reward.terms,
                directLink: reward.directLink,
                couponCodes: [],
                images: reward.images,
                validUntil: Timestamp.fromDate(newValidUntil),
                maxClaims: reward.maxClaims,
                maxClaimsCooldown:
                    reward.maxClaimsCooldown ?? Duration.create(),
            }),
        );

        // Submit
        await dispatch(
            rewardActions.submitRewardDraft({
                rewardId: reward.rewardId,
            }),
        );

        // Re-Fetch
        await dispatch(
            rewardActions.fetchAllRewardDrafts({
                partnerId: reward.partnerId,
            }),
        );
        await dispatch(
            rewardActions.fetchAllLiveRewards({
                partnerId: reward.partnerId,
            }),
        );
    }
    return toggleState;
}
