import { Middleware } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';

export const sentryMiddleware: Middleware = (store) => (next) => (action) => {
    try {
        Sentry.addBreadcrumb({
            category: 'redux-action',
            message: action.type,
        });
    } finally {
        next(action);
    }
};
