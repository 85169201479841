import * as React from 'react';
import cx from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../redux/AppStore';
import { rewardActions, rewardSelectors } from './store';
import { IconChevronDown } from '../icons';

const mapStateToProps = (state: AppState, props: OwnProps) => ({
    assets: rewardSelectors.getAssets(state, props),
});

const actions = {
    fetchRewardAssets: rewardActions.fetchRewardAssets,
};

const connector = connect(mapStateToProps, actions);

type OwnProps = {
    rewardId: string;
};

type Props = ConnectedProps<typeof connector> & OwnProps;

const AvailableRewardsDisplayInner: React.FunctionComponent<Props> = ({
    rewardId,
    fetchRewardAssets,
    assets,
}) => {
    React.useEffect(() => {
        fetchRewardAssets({ rewardId });
    }, [rewardId, fetchRewardAssets]);

    const [expanded, setExpanded] = React.useState(false);

    const hasNoCodesLeft = (assets?.length || 0) <= 0;

    return (
        <>
            <button
                className="flex flex-row items-center space-x-4 hover:bg-gray-50 -my-2 px-4 py-2 rounded-md"
                onClick={() => setExpanded(!expanded)}
                disabled={hasNoCodesLeft}
            >
                <span
                    className={cx({
                        'text-red-400': hasNoCodesLeft,
                    })}
                >
                    {assets?.length || '0'}
                </span>
                <span
                    className={cx('transform transition-default', {
                        'rotate-180': expanded,
                        hidden: hasNoCodesLeft,
                    })}
                >
                    <IconChevronDown className="w-4 h-4" />
                </span>
            </button>
            {expanded && (
                <ul
                    style={{ maxHeight: '200px' }}
                    className="overflow-y-auto mt-2"
                >
                    {assets.map((asset, i) => (
                        <li
                            key={asset.code}
                            className={cx('py-2 px-4', {
                                'bg-gray-50': i % 2 === 0,
                            })}
                        >
                            {asset.code}
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
};

export default connector(AvailableRewardsDisplayInner);
