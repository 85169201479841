import { SyntheticEvent, useState } from 'react';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { Button } from '../common/Button';
import { partnerProfileActions, partnerProfileSelectors } from './store';
import { authSelectors } from '../auth/store';
import TextInput from '../common/form/TextInput';
import FileUpload from '../common/FileUpload';
import { AppState } from '../redux/AppStore';
import SocialLinksInput from './SocialLinksInput';
import { CoreIndustry, DraftState, ImageResource } from '../api/model/core';
import { CoreIndustryInput } from './CoreIndustryInput';
import ReviewDisplay from '../publishing/ReviewDisplay';
import FormSection from '../common/form/FormSection';
import Form from '../common/form/Form';
import { useValidation } from '../hooks';
import { useI18n } from '../i18n';
import AssessmentDisplay from './AssessmentDisplay';
import { PostPartnerProfileDraftRequest_RichSocialLink } from '../api/model/b2b';
import { ValidationResult } from '../hooks/useValidation';

const mapStateToProps = (state: AppState) => ({
    partnerId: authSelectors.getPartnerId(state)!,
    isLoading:
        partnerProfileSelectors.isLoading(state) ||
        partnerProfileSelectors.isWriting(state),
    currentDraft: partnerProfileSelectors.currentDraft(state),
});

const actions = {
    fetchProfileDraft: partnerProfileActions.fetchProfileDraft,
    saveProfileDraft: partnerProfileActions.saveProfileDraft,
    submitProfileDraft: partnerProfileActions.submitProfileDraft,
};

const connector = connect(mapStateToProps, actions);

type Props = ConnectedProps<typeof connector>;

type State = {
    partnerName?: string;
    tagLine?: string;
    description?: string;
    socialLinks?: string[];
    socialLinksRich?: PostPartnerProfileDraftRequest_RichSocialLink[];
    coreIndustry?: CoreIndustry;
    coverImage?: ImageResource;
    logo?: ImageResource;
    moodImages?: ImageResource[];
};

export const PartnerProfileFormInner: React.FunctionComponent<Props> = (
    props,
) => {
    const fetchProfileDraft = props.fetchProfileDraft;
    const saveProfileDraft = props.saveProfileDraft;
    const submitProfileDraft = props.submitProfileDraft;
    const currentDraft = props.currentDraft;

    const partnerId = props.partnerId;

    const { t } = useI18n();

    React.useEffect(() => {
        fetchProfileDraft({ partnerId });
    }, [fetchProfileDraft, partnerId]);

    const [state, setState] = useState<State | undefined>(undefined);

    const [validationMessages, onBlur] = useValidation(state, (s) => {
        if (!s) {
            return null;
        }

        const errors: ValidationResult<Exclude<typeof state, undefined>> = {};

        if (!!s.tagLine && s.tagLine.length > 30) {
            errors.tagLine = 'profile.tagLine.tooLong';
        }

        if ((s.socialLinksRich?.length ?? 0) > 0) {
            for (let sr of s.socialLinksRich ?? []) {
                if (sr.url.includes('</')) {
                    if (!errors.socialLinksRich) {
                        errors.socialLinksRich = [];
                    }
                    errors.socialLinksRich[s.socialLinksRich!.indexOf(sr)] = {
                        url: 'profile.socialLinks.invalid',
                    };
                }
            }
        }

        if (Object.keys(errors).length === 0) {
            return null;
        }

        return errors;
    });

    const isDirty = state !== undefined;
    const isPending = currentDraft?.state === DraftState.Pending;
    const isLive = currentDraft?.state === DraftState.Live;
    const isSavable = isDirty && !isPending;
    const isPublishable = !isDirty && !isPending && !isLive;

    const onPublish = React.useCallback(
        async (e: SyntheticEvent) => {
            e.preventDefault();
            // eslint-disable-next-line no-restricted-globals
            if (!confirm(t('publishing.confirm.text'))) {
                return;
            }
            await submitProfileDraft({
                partnerId,
            });
        },
        [t, submitProfileDraft, partnerId],
    );

    const onSubmit = React.useCallback(
        async (e: SyntheticEvent) => {
            e.preventDefault();
            await saveProfileDraft({
                partnerId,
                partnerName: state?.partnerName || currentDraft?.partnerName,
                tagLine: state?.tagLine || currentDraft?.tagLine,
                description: state?.description || currentDraft?.description,
                socialLinks: state?.socialLinks || currentDraft?.socialLinks,
                socialLinksRich:
                    state?.socialLinksRich || currentDraft?.socialLinksRich,
                coreIndustry: state?.coreIndustry || currentDraft?.coreIndustry,
                coverImage: state?.coverImage || currentDraft?.coverImage,
                logo: state?.logo || currentDraft?.logo,
                moodImages: state?.moodImages || currentDraft?.moodImages,
            });
            setState(undefined);
        },
        [saveProfileDraft, partnerId, state, currentDraft],
    );

    if (props.isLoading) {
        return <>Loading...</>;
    }

    return (
        <Form
            onSubmit={onSubmit}
            actions={
                <>
                    <div>
                        <Button
                            secondary
                            label="profile.publishDraft"
                            onClick={onPublish}
                            disabled={!isPublishable}
                        />
                    </div>
                    <div>
                        <Button
                            primary
                            label="profile.saveDraft"
                            onClick={onSubmit}
                            disabled={!isSavable}
                        />
                    </div>
                </>
            }
        >
            <ReviewDisplay
                state={currentDraft?.state}
                reason={currentDraft?.rejectionReason}
            />
            <FormSection
                label="profile.general.title"
                subtitle="profile.general.subtitle"
            >
                <TextInput
                    label="profile.partnerName.label"
                    defaultValue={currentDraft?.partnerName}
                    value={state?.partnerName}
                    onChanged={(partnerName) =>
                        setState({ ...state, partnerName })
                    }
                    disabled={isPending}
                />
                <TextInput
                    label="profile.tagLine.label"
                    defaultValue={currentDraft?.tagLine}
                    value={state?.tagLine}
                    onChanged={(tagLine) => setState({ ...state, tagLine })}
                    disabled={isPending}
                    onBlur={onBlur}
                    errorMessage={validationMessages?.tagLine}
                />
                <TextInput
                    label="profile.description.label"
                    defaultValue={currentDraft?.description}
                    value={state?.description}
                    onChanged={(description) =>
                        setState({ ...state, description })
                    }
                    rows={7}
                    disabled={isPending}
                />
                <CoreIndustryInput
                    value={state?.coreIndustry || currentDraft?.coreIndustry}
                    onChanged={(coreIndustry) =>
                        setState({ ...state, coreIndustry })
                    }
                    disabled={isPending}
                />
            </FormSection>
            <FormSection
                label="profile.assessment.label"
                subtitle="profile.assessment.explainer"
            >
                <AssessmentDisplay />
            </FormSection>
            <SocialLinksInput
                linksRich={currentDraft?.socialLinksRich}
                onChanged={(socialLinksRich) =>
                    setState({ ...state, socialLinksRich })
                }
                disabled={isPending}
                onBlur={onBlur}
                errorMessages={validationMessages?.socialLinksRich}
            />
            <FormSection
                label="profile.logo.label"
                subtitle="profile.logo.explainer"
            >
                <FileUpload
                    thumbnailCircleCrop
                    partnerId={partnerId}
                    label="profile.logo.label"
                    message="profile.logo.message"
                    file={state?.logo || currentDraft?.logo}
                    onSingleChanged={(logo) => {
                        setState({ ...state, logo });
                    }}
                    disabled={isPending}
                />
            </FormSection>
            <FormSection
                label="profile.coverImage.label"
                subtitle="profile.coverImage.explainer"
            >
                <FileUpload
                    partnerId={partnerId}
                    label="profile.coverImage.label"
                    message="profile.coverImage.message"
                    file={state?.coverImage || currentDraft?.coverImage}
                    onSingleChanged={(coverImage) => {
                        setState({ ...state, coverImage });
                    }}
                    disabled={isPending}
                />
            </FormSection>
            <FormSection
                label="profile.moodImages.label"
                subtitle="profile.moodImages.explainer"
            >
                <FileUpload
                    partnerId={partnerId}
                    label="profile.moodImages.label"
                    message="profile.moodImages.message"
                    files={state?.moodImages || currentDraft?.moodImages || []}
                    onMultipleChanged={(moodImages) =>
                        setState({ ...state, moodImages })
                    }
                    disabled={isPending}
                />
            </FormSection>
        </Form>
    );
};

export default connector(PartnerProfileFormInner);
