import firebase from 'firebase/app';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../redux/AppStore';
import { app } from './firebase';
import { accountActions } from '../account/store';

type LoginRequestPayload = {
    email: string;
    password: string;
};
const login = createAsyncThunk(
    'auth/login',
    async (payload: LoginRequestPayload) => {
        const res = await app
            .auth()
            .signInWithEmailAndPassword(payload.email, payload.password);

        if (res.user === null) {
            throw new Error('auth/user-not-found');
        }
        return transformUser(res.user);
    },
);

type LoginWithLinkRequestPayload = {
    email: string;
    href: string;
};
const loginWithLink = createAsyncThunk(
    'auth/loginWithLink',
    async (payload: LoginWithLinkRequestPayload) => {
        const res = await app
            .auth()
            .signInWithEmailLink(payload.email, payload.href);

        if (res.user === null) {
            throw new Error('auth/user-not-found');
        }
        return transformUser(res.user);
    },
);

const loadUser = createAsyncThunk(
    'auth/load',
    async (user: firebase.User | null) => {
        if (!user) {
            return null;
        }
        return transformUser(user);
    },
);

const transformUser = async (user: firebase.User) => {
    const result = await user.getIdTokenResult();

    const partnerId: string | undefined = result.claims['partnerId'];
    if (!partnerId) {
        return Promise.reject('no-partner-id');
    }

    return {
        uid: result.claims['sub'] as string,
        partnerId,
        token: result.token,
    };
};

const logout = createAsyncThunk('auth/logout', async () => {
    return app.auth().signOut();
});

const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async ({ email }: { email: string }) => {
        return app.auth().sendPasswordResetEmail(email);
    },
);

type AuthState = {
    active: null | {
        uid: string;
        partnerId: string;
        token: string;
    };
    loginError?: string;
    loginInProgress: boolean;
    initialized: boolean;
    requireNewPassword: boolean;
};

const initialState: AuthState = {
    active: null,
    loginInProgress: false,
    initialized: false,
    requireNewPassword: false,
};
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state, action) => {
            state.loginInProgress = true;
            state.loginError = undefined;
        });
        builder.addCase(login.rejected, (state, action) => {
            state.loginInProgress = false;
            state.loginError = `auth.error.${action.error.code}`;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.active = action.payload;
            state.loginInProgress = false;
            state.loginError = undefined;
            state.requireNewPassword = false;
        });

        builder.addCase(loadUser.fulfilled, (state, action) => {
            state.active = action.payload;
            state.initialized = true;
            state.loginInProgress = false;
            state.loginError = undefined;
        });

        builder.addCase(logout.fulfilled, (state) => {
            state.active = null;
        });

        builder.addCase(loginWithLink.pending, (state) => {
            state.loginInProgress = true;
            state.loginError = undefined;
        });
        builder.addCase(loginWithLink.rejected, (state, action) => {
            state.loginInProgress = false;
            state.loginError = `auth.error.${action.error.code}`;
        });
        builder.addCase(loginWithLink.fulfilled, (state, action) => {
            state.active = action.payload;
            state.loginInProgress = false;
            state.requireNewPassword = true;
            state.loginError = undefined;
        });

        builder.addCase(accountActions.changePassword.fulfilled, (state) => {
            state.requireNewPassword = false;
        });

        builder.addCase(resetPassword.pending, (state) => {
            state.loginInProgress = true;
            state.loginError = undefined;
        });

        builder.addCase(resetPassword.fulfilled, (state) => {
            state.loginInProgress = false;
            state.loginError = 'auth.reset.success';
        });

        builder.addCase(resetPassword.rejected, (state, action) => {
            state.loginInProgress = false;
            state.loginError = `auth.error.${action.error.code}`;
        });
    },
});

export const authReducer = authSlice.reducer;
export const authActions = {
    ...authSlice.actions,
    login,
    loginWithLink,
    loadUser,
    logout,
    resetPassword,
};

const s = (state: AppState): AuthState => state.auth;
export const authSelectors = {
    getUid: (state: AppState) => s(state).active?.uid,
    getPartnerId: (state: AppState) => s(state).active?.partnerId,
    isLoginInProgress: (state: AppState): boolean => s(state).loginInProgress,
    isSignedIn: (state: AppState): boolean => s(state).active !== null,
    isInitialized: (state: AppState): boolean => s(state).initialized,
    requireNewPassword: (state: AppState): boolean =>
        s(state).requireNewPassword,
    getLoginError: (state: AppState) => s(state).loginError,
    requiresNewInvite: (state: AppState) =>
        s(state).loginError?.includes('action-code') ?? false,
} as const;
