import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Timestamp } from '../../api/google/protobuf/timestamp';
import { DraftState } from '../../api/model/core';
import { authSelectors } from '../../auth/store';
import { SubSection } from '../../common/SubSection';
import { AppState } from '../../redux/AppStore';
import { useAppSelector } from '../../redux/react';
import { CodesBadge } from '../rewardTile/Badges';
import { RewardTile } from '../rewardTile/RewardTile';
import { rewardActions, rewardSelectors } from '../store';

const mapStateToProps = (state: AppState) => ({
    partnerId: authSelectors.getPartnerId(state)!,
    currentRewards: rewardSelectors.getCurrentPage(state)!,
});

const actions = {
    fetchAllLiveRewards: rewardActions.fetchAllLiveRewards,
};

const connector = connect(mapStateToProps, actions);

type Props = ConnectedProps<typeof connector>;

const AllRewardsLiveInner = (props: Props) => {
    const { currentRewards, partnerId, fetchAllLiveRewards } = props;

    useEffect(() => {
        fetchAllLiveRewards({ partnerId });
    }, [partnerId, fetchAllLiveRewards]);

    const rewardDraftsById = useAppSelector(
        (state) => state.rewards.rewardDraftById,
    );

    const activeRewards = currentRewards.filter(
        (reward) =>
            reward.validUntil &&
            Timestamp.toDate(reward.validUntil) > new Date(),
    );

    if (activeRewards.length === 0) {
        return null;
    }

    return (
        // -margin trick used here to get spacing between components when they wrap
        <SubSection title="reward.overview.allActives">
            <div className="flex flex-wrap -mt-6 -ml-6">
                {activeRewards.map((reward) => {
                    // Disable the activate/deactivate button for a reward if it already has a pending draft change.
                    const disableToggle =
                        rewardDraftsById[reward.rewardId] &&
                        rewardDraftsById[reward.rewardId].state ===
                            DraftState.Pending;

                    return (
                        <RewardTile.Live
                            className="mt-6 ml-6"
                            key={reward.rewardId}
                            reward={reward}
                            disableToggle={disableToggle}
                            badge={<CodesBadge rewardId={reward.rewardId} />}
                        />
                    );
                })}
            </div>
        </SubSection>
    );
};

export default connector(AllRewardsLiveInner);
