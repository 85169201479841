import { Route, Switch } from 'react-router-dom';
import DashboardHeader from '../common/DashboardHeader';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { useEffect } from 'react';
import { billingActions, billingSelectors } from './store';
import BillingBreakdown from './BillingBreakdown';

export default function BillingPage() {
    const dispatch = useAppDispatch();
    const selectedPeriod = useAppSelector(
        (state) => state.billing.selectedPeriod,
    );
    const rules = useAppSelector(billingSelectors.getAggregationRules);

    useEffect(() => {
        dispatch(billingActions.loadPeriods());
        dispatch(billingActions.loadAggregationRules());
    }, [dispatch]);

    return (
        <Switch>
            <Route path="/billing">
                <DashboardHeader title="module.billing.name" />
                {selectedPeriod && Object.values(rules).length > 0 && (
                    <BillingBreakdown />
                )}
            </Route>
        </Switch>
    );
}
