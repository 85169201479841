import { useEffect } from 'react';
import { Badge } from '../../common/Badge';
import { useI18n } from '../../i18n';
import { useAppDispatch, useAppSelector } from '../../redux/react';
import { rewardActions } from '../store';
import { RewardBillingType } from '../../api/model/core';

type BadgeProps = {
    rewardId: string;
};

export const ReviewPendingBadge = () => {
    const { t } = useI18n();
    return (
        <Badge className="bg-semantic-energy uppercase">
            {t('reward.reviewPending')}
        </Badge>
    );
};

export const CodesBadge = ({ rewardId }: BadgeProps) => {
    const dispatch = useAppDispatch();
    const billingType = useAppSelector(
        (state) => state.rewards.rewardLiveById[rewardId].billingType,
    );
    const assets = useAppSelector((state) =>
        billingType === RewardBillingType.PAID
            ? state.rewards.codeIdsByRewardId[rewardId]
            : state.rewards.assetsByRewardId[rewardId],
    );

    const hasLoaded = assets !== undefined;

    useEffect(() => {
        if (!hasLoaded) {
            if (billingType === RewardBillingType.PAID) {
                dispatch(rewardActions.fetchRewardCodeIDs({ rewardId }));
            } else {
                dispatch(rewardActions.fetchRewardAssets({ rewardId }));
            }
        }
    }, [dispatch, rewardId, hasLoaded, billingType]);

    if (!assets || assets.length > 5) {
        return null;
    }
    if (assets.length === 0) {
        return <NoCodesBadge />;
    }
    return <FewCodesBadge />;
};

const FewCodesBadge = () => {
    const { t } = useI18n();
    return (
        <Badge className="bg-semantic-energy uppercase">
            {t('reward.couponCodes.fewCodes')}
        </Badge>
    );
};

const NoCodesBadge = () => {
    const { t } = useI18n();
    return (
        <Badge className="bg-semantic-error uppercase">
            {t('reward.couponCodes.noCodes')}
        </Badge>
    );
};

CodesBadge.FewCodes = FewCodesBadge;
CodesBadge.NoCodes = NoCodesBadge;
