import { SyntheticEvent, useCallback, useState } from 'react';
import * as React from 'react';
import FormSection from '../common/form/FormSection';
import TextInput from '../common/form/TextInput';
import { useAppDispatch, useAppSelector } from '../redux/react';

import { Button } from '../common/Button';
import { accountActions, accountSelectors } from './store';

const ChangePasswordForm: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] =
        useState<string>('');

    const reset = useCallback(() => {
        setOldPassword('');
        setNewPassword('');
        setNewPasswordConfirmation('');
    }, [setOldPassword, setNewPassword, setNewPasswordConfirmation]);

    const submit = useCallback(
        async (e: SyntheticEvent) => {
            e.preventDefault();
            await dispatch(
                accountActions.changePassword({
                    current: oldPassword,
                    password: newPassword,
                    confirmation: newPasswordConfirmation,
                }),
            );
            reset();
        },
        [dispatch, reset, oldPassword, newPassword, newPasswordConfirmation],
    );

    const writeState = useAppSelector(accountSelectors.getWriteState);

    return (
        <form onSubmit={submit} className="max-w-5xl">
            <FormSection
                label="accountsettings.form.password.label"
                subtitle="accountsettings.form.password.subtitle"
            >
                <TextInput
                    label="accountsettings.form.password.fields.oldPassword"
                    type="password"
                    value={oldPassword}
                    onChanged={setOldPassword}
                />
                <TextInput
                    label="accountsettings.form.password.fields.newPassword"
                    type="password"
                    value={newPassword}
                    onChanged={setNewPassword}
                />
                <TextInput
                    label="accountsettings.form.password.fields.newPasswordConfirmation"
                    type="password"
                    value={newPasswordConfirmation}
                    onChanged={setNewPasswordConfirmation}
                />
                <div className="flex justify-end">
                    {JSON.stringify(writeState.passwordError)}
                    <div className="inline-flex space-x-2">
                        <Button
                            primary
                            label="common.save"
                            onClick={submit}
                            disabled={writeState.isWriting}
                        />
                    </div>
                </div>
            </FormSection>
        </form>
    );
};
export default ChangePasswordForm;
