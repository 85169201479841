import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { partnerProfileActions } from './store';
import Spinner from '../common/Spinner';
import {
    AssessmentPartnerView,
    AssessmentScorePartnerView,
} from '../api/model/b2b';
import { ProgressBar } from '../common/ProgressBar';
import { IconInfo } from '../icons';
import * as React from 'react';
import { useI18n } from '../i18n';
import { Button } from '../common/Button';

export default function AssessmentDisplay() {
    const { t, Trans } = useI18n();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(partnerProfileActions.fetchAssessment());
    }, [dispatch]);

    const { assessment, assessmentError, isLoadingAssessment } = useAppSelector(
        (state) => state.partnerProfile,
    );

    if (isLoadingAssessment) {
        return <Spinner sizeInRem={2} />;
    }

    if (assessmentError || assessment === undefined) {
        return (
            <div className="flex flex-col justify-center space-y-6">
                <AssessmentCard empty />
                <p className="text-body2 text-center text-gray-500">
                    {t('profile.assessment.empty')}
                </p>
                <div className="flex justify-center">
                    <div>
                        <Button
                            secondary
                            label="profile.assessment.surveyLinkButtonLabel"
                            href="https://docs.google.com/forms/d/e/1FAIpQLSd_ioK1WjrV5j5nNSAwL1wVRAtoVQJataFJrW-r4R-KQFCI5Q/viewform"
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col justify-center space-y-6">
            <AssessmentCard assessment={assessment} />
            <hr />
            {assessment.editLink && (
                <div className="flex justify-center">
                    <div>
                        <Button
                            secondary
                            label="profile.assessment.surveyEditLinkButtonLabel"
                            href={assessment.editLink}
                        />
                    </div>
                </div>
            )}
            <div className="rounded-xl px-6 py-4 bg-green-100 text-semantic-secondary">
                <div className="flex items-center space-x-4">
                    <IconInfo color="#10B981" />
                    <div className="space-y-1">
                        <p className="text-headline4 font-bold">
                            {t('profile.assessment.status.exists.headline')}
                        </p>
                        <p className="text-body1">
                            <Trans
                                t={t}
                                i18nKey="profile.assessment.status.exists.body"
                            >
                                <a
                                    href="mailto:hello@plan3t.one"
                                    className="underline hover:text-green-700"
                                >
                                    Contact us
                                </a>
                                if you have questions.
                            </Trans>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

type AssessmentCardProps =
    | { assessment: AssessmentPartnerView }
    | { empty: true };

function AssessmentCard(props: AssessmentCardProps) {
    const score = 'empty' in props ? 'TBD' : props.assessment.score;
    const scoreMax = 'empty' in props ? '100' : props.assessment.scoreMax;

    return (
        <div className="p-6 w-96 mx-auto bg-semantic-background rounded-lg shadow-lg">
            <div className="flex flex-col space-y-6 w-full divide-y divide-gray-200">
                <div className="flex flex-col items-center space-y-1">
                    <h1 className="text-2xl font-bold text-semantic-primary">
                        <span>{score}</span>
                        <span className="text-gray-500 text-lg">
                            /{scoreMax}
                        </span>
                    </h1>
                    <p className="text-xs text-gray-500 font-bold">
                        PLAN3T SCORE
                    </p>
                </div>
                <div className="flex flex-col pt-6 space-y-4">
                    {'assessment' in props &&
                        props.assessment.scores
                            .filter((score) => score.applicable)
                            .map((score) => (
                                <Score key={score.label} score={score} />
                            ))}
                    {!('assessment' in props) &&
                        ['Climate', 'People', 'Transparency', 'Resources']
                            .map((label) => ({
                                label,
                                valueUnscaled: 5,
                                valueScale: 1,
                                applicable: true,
                            }))
                            .map((score) => (
                                <Score
                                    key={score.label}
                                    score={score}
                                    disabled
                                />
                            ))}
                </div>
            </div>
        </div>
    );
}

interface ScoreProps {
    score: AssessmentScorePartnerView;
    disabled?: boolean;
}

const Score = ({ score, disabled }: ScoreProps) => {
    const scorePercent =
        (score.valueUnscaled / Math.pow(10, score.valueScale)) * 100;

    return (
        <div className="flex flex-row w-full justify-between items-center">
            <h6 className="flex-shrink-0 text-xs w-1/2 text-gray-500 font-bold">
                {score.label.toUpperCase()}
            </h6>
            <div className="w-1/2">
                <ProgressBar percent={scorePercent} disabled={disabled} />
            </div>
        </div>
    );
};
