import { useEffect } from 'react';
import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { partnerActions, partnerSelectors } from './store';
import { authSelectors } from '../auth/store';
import { Table, TableHeader, TableRow, TextTableCell } from '../common/Table';
import { accountSelectors } from '../account/store';
import { PartnerManager } from '../api/model/core';
import { useI18n } from '../i18n';
import ResendInvitationButton from './ResendInvitationButton';
import RemovePartnerManagerButton from './RemovePartnerManagerButton';
import { Timestamp } from '../api/google/protobuf/timestamp';

const PartnerManagerList: React.FunctionComponent = (props) => {
    const id = useAppSelector(authSelectors.getPartnerId)!;
    const manager = useAppSelector((state) =>
        partnerSelectors.managersById(state, { id }),
    );
    const isLoading = useAppSelector(partnerSelectors.isLoadingManger);

    const dispatch = useAppDispatch();

    useEffect(() => {
        // TODO handle pagination
        dispatch(
            partnerActions.fetchPartnerManager({ id, limit: 20, after: '' }),
        );
    }, [dispatch, id]);

    if (isLoading) {
        return <>Loading...</>;
    }

    if (!manager) {
        return <>No manager have been found</>;
    }

    return (
        <Table
            header={
                <TableHeader
                    labels={[
                        'manager.table.header.name',
                        'manager.table.header.createdBy',
                        'manager.table.header.role',
                        'manager.table.header.actions',
                    ]}
                />
            }
        >
            {manager.map((manager) => (
                <PartnerManagerListEntry
                    key={manager.userId}
                    manager={manager}
                />
            ))}
        </Table>
    );
};

export default PartnerManagerList;

const PartnerManagerListEntry: React.FunctionComponent<{
    manager: PartnerManager;
}> = ({ manager }) => {
    const account = useAppSelector((state) =>
        accountSelectors.getById(state, { id: manager.userId }),
    );

    const createdByAccount = useAppSelector((state) =>
        accountSelectors.getById(state, { id: manager.createdBy }),
    );

    const { t } = useI18n();

    if (!account) {
        return (
            <TableRow
                contents={[
                    <TextTableCell primary="..." />,
                    <TextTableCell primary="..." />,
                    <TextTableCell primary="..." />,
                    <TextTableCell primary="..." />,
                ]}
            />
        );
    }

    const createdByName =
        createdByAccount?.name || createdByAccount?.email || manager.createdBy;

    return (
        <TableRow
            contents={[
                <TextTableCell
                    primary={account.name || account.email}
                    secondary={account.email}
                />,
                <TextTableCell
                    primary={createdByName}
                    secondary={
                        manager.createdAt
                            ? Timestamp.toDate(manager.createdAt).toISOString()
                            : ''
                    }
                />,
                <TextTableCell primary={t('manager.role.admin')} />,
                <div className="flex flex-row space-x-2">
                    <ResendInvitationButton userId={manager.userId} />
                    <RemovePartnerManagerButton userId={manager.userId} />
                </div>,
            ]}
        />
    );
};
