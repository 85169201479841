import axios from 'axios';
import {
    createAsyncThunk,
    createSlice,
    SerializedError,
} from '@reduxjs/toolkit';
import { AppState } from '../redux/AppStore';
import {
    PostPartnerProfileDraftRequest,
    PostPartnerProfileDraftResponse,
    GetPartnerProfileDraftResponse,
    AssessmentPartnerView,
    GetAssessmentPartnerViewResponse,
    PostPartnerProfileDraftRequest_RichSocialLink,
} from '../api/model/b2b';
import {
    PartnerProfileDraft,
    CoreIndustry,
    ImageResource,
} from '../api/model/core';

type saveProfileDraftArgs = {
    partnerId: string;
    partnerName?: string;
    tagLine?: string;
    description?: string;
    socialLinks?: string[];
    socialLinksRich?: PostPartnerProfileDraftRequest_RichSocialLink[];
    coreIndustry?: CoreIndustry;
    coverImage?: ImageResource;
    logo?: ImageResource;
    moodImages?: ImageResource[];
};

const saveProfileDraftAction = createAsyncThunk(
    'save/partner/profile_draft',
    async ({
        partnerId,
        partnerName,
        tagLine,
        description,
        socialLinks,
        socialLinksRich,
        coreIndustry,
        coverImage,
        logo,
        moodImages,
    }: saveProfileDraftArgs) => {
        const req: PostPartnerProfileDraftRequest = {
            partnerName: partnerName || '',
            tagLine: tagLine || '',
            description: description || '',
            socialLinks: socialLinks || [],
            socialLinksRich: socialLinksRich || [],
            coreIndustry: coreIndustry || CoreIndustry.UnknownIndustry,
            coverImage: coverImage,
            logo: logo,
            moodImages: moodImages || [],
        };

        const res = await axios.post<PostPartnerProfileDraftResponse>(
            `/partner/${partnerId}/profile_draft`,
            req,
        );

        return PostPartnerProfileDraftResponse.fromJson(res.data as any);
    },
);

const submitProfileDraftAction = createAsyncThunk(
    'submit/partner/profile_draft',
    async ({ partnerId }: saveProfileDraftArgs, { dispatch }) => {
        // FIXME(mr): Retry if it fails!
        await axios.post<PostPartnerProfileDraftResponse>(
            `/review/type/PayloadProfile/id/${partnerId}`,
        );

        // Would have prefered to do dispatch(fetchProfileDraftAction)
        // but TS can't work out the types.
        return await fetchProfileDraft(partnerId);
    },
);

export type getProfileDraftArgs = {
    partnerId: string;
};

const fetchProfileDraft = async (partnerId: string) => {
    const res = await axios.get<GetPartnerProfileDraftResponse>(
        `/partner/${partnerId}/profile_draft`,
    );

    return GetPartnerProfileDraftResponse.fromJson(res.data as any);
};

const fetchProfileDraftAction = createAsyncThunk(
    'fetch/partner/profile_draft',
    async ({ partnerId }: getProfileDraftArgs) => {
        return await fetchProfileDraft(partnerId);
    },
);

const fetchAssessmentAction = createAsyncThunk(
    'fetch/partner/assessment',
    async (): Promise<AssessmentPartnerView> => {
        const res = await axios.get('/assessment');
        return GetAssessmentPartnerViewResponse.fromJson(res.data as any)
            .assessment!;
    },
);

export type ProfileDraftState = {
    isLoading: boolean;
    loadingFailed: boolean;
    isWriting: boolean;
    writingFailed: boolean;
    currentDraft?: PartnerProfileDraft;

    assessment: AssessmentPartnerView | undefined;
    assessmentError: SerializedError | undefined;
    isLoadingAssessment: boolean;
};

const initialState: ProfileDraftState = {
    isLoading: false,
    loadingFailed: false,

    isWriting: false,
    writingFailed: false,

    assessment: undefined,
    assessmentError: undefined,
    isLoadingAssessment: false,
};

const partnerProfileSlice = createSlice({
    name: 'partnerProfileDraft',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(submitProfileDraftAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(submitProfileDraftAction.rejected, (state, action) => {
            state.isLoading = false;
            state.loadingFailed = true;
        });
        builder.addCase(submitProfileDraftAction.fulfilled, (state, action) => {
            const draft = action.payload?.draft || null;
            state.isLoading = false;
            if (draft) {
                state.currentDraft = draft;
            }
        });

        builder.addCase(fetchProfileDraftAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchProfileDraftAction.rejected, (state, action) => {
            state.isLoading = false;
            state.loadingFailed = true;
        });
        builder.addCase(fetchProfileDraftAction.fulfilled, (state, action) => {
            const draft = action.payload?.draft || null;
            state.isLoading = false;
            if (draft) {
                state.currentDraft = draft;
            }
        });

        builder.addCase(saveProfileDraftAction.pending, (state) => {
            state.isWriting = true;
        });
        builder.addCase(saveProfileDraftAction.rejected, (state) => {
            state.isWriting = false;
            state.writingFailed = true;
        });
        builder.addCase(saveProfileDraftAction.fulfilled, (state, action) => {
            const draft = action.payload?.draft || null;
            state.isWriting = false;
            if (draft) {
                state.currentDraft = draft;
            }
        });
        builder.addCase(fetchAssessmentAction.pending, (state) => {
            state.isLoadingAssessment = true;
            state.assessmentError = undefined;
        });
        builder.addCase(fetchAssessmentAction.fulfilled, (state, action) => {
            state.isLoadingAssessment = false;
            state.assessment = action.payload;
            state.assessmentError = undefined;
        });
        builder.addCase(fetchAssessmentAction.rejected, (state, action) => {
            state.isLoadingAssessment = false;
            state.assessmentError = action.error;
        });
    },
});

export const partnerProfileReducer = partnerProfileSlice.reducer;

export const partnerProfileActions = {
    ...partnerProfileSlice.actions,
    saveProfileDraft: saveProfileDraftAction,
    fetchProfileDraft: fetchProfileDraftAction,
    submitProfileDraft: submitProfileDraftAction,
    fetchAssessment: fetchAssessmentAction,
};

export const partnerProfileSelectors = {
    isLoading: (state: AppState) => state.partnerProfile.isLoading,
    loadingFailed: (state: AppState) => state.partnerProfile.loadingFailed,

    isWriting: (state: AppState) => state.partnerProfile.isWriting,
    writingFailed: (state: AppState) => state.partnerProfile.writingFailed,

    currentDraft: (state: AppState) => state.partnerProfile.currentDraft,
};
