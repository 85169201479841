import { SyntheticEvent, useMemo, useState } from 'react';
import * as React from 'react';
import { parse, ParseConfig, ParseResult } from 'papaparse';
import { useHtmlId } from '../hooks';
import { useI18n } from '../i18n';
import { useDropzone } from 'react-dropzone';
import Spinner from '../common/Spinner';
import {
    Table,
    TableFooter,
    TableHeader,
    TableRow,
    TextTableCell,
} from '../common/Table';
import { Button } from '../common/Button';

function parsePromise<T>(
    file: File,
    config: ParseConfig,
): Promise<ParseResult<T>> {
    return new Promise<ParseResult<T>>((resolve, reject) => {
        parse(file, {
            ...config,
            complete: (result) => resolve(result),
            error: (error) => reject(error),
        });
    });
}

export function triggerCSVDownload(rows: string[][]) {
    const header = 'data:text/csv;charset=utf-8,';
    const csv = rows.reduce(
        (file, row) => file + row.join(',') + '\r\n',
        header,
    );

    const encodedUri = encodeURI(csv);

    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'plan3t_codes.csv');
    document.body.appendChild(link);

    link.click();

    requestAnimationFrame(() => {
        document.body.removeChild(link);
    });
}

const codeColumNames = ['code', 'codes', 'coupon'];

type Props = {
    label: string;
    current: string[];
    onCodesReceived: (codes: string[]) => void;
    disabled: boolean;
};

const RewardCodeCSVUpload: React.FunctionComponent<Props> = (props) => {
    const id = useHtmlId();
    const { t } = useI18n();

    const { onCodesReceived, current } = props;

    const [isLoading, setIsLoading] = useState(false);

    const onDrop = React.useCallback(
        async (acceptedFiles: File[]) => {
            setIsLoading(true);

            try {
                const codesPerFile = await Promise.all(
                    acceptedFiles.map((file) => {
                        return parsePromise<Record<string, string>>(file, {
                            header: true,
                            transformHeader(
                                header: string,
                                index?: number,
                            ): string {
                                const lower = header.toLocaleLowerCase();

                                if (codeColumNames.includes(lower)) {
                                    return '__code';
                                }

                                return lower;
                            },
                        }).then((result) => {
                            if (
                                result.data.length > 0 &&
                                Object.entries(result.data[0]).length === 1
                            ) {
                                // there is only one row
                                return result.data.map(
                                    (row) => Object.values(row)[0],
                                );
                            }
                            return result.data.map((row) => row['__code']);
                        });
                    }),
                );

                const codes = codesPerFile
                    .reduce((acc, codes) => [...acc, ...codes], [])
                    .filter((c) => !!c);

                onCodesReceived(codes);
            } finally {
                setIsLoading(false);
            }
        },
        [onCodesReceived, setIsLoading],
    );

    const onRequestDownload = React.useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault();
            triggerCSVDownload([['code'], ...current.map((code) => [code])]);
        },
        [current],
    );

    const { getRootProps, getInputProps } = useDropzone({
        disabled: props.disabled,
        accept: '.csv',
        onDrop,
    });

    const shouldShowDuplicateWarning = useMemo(() => {
        const hasDuplicates = current.some(
            (val, i) => current.indexOf(val) !== i,
        );

        const allSame = new Set(current).size === 1;

        // we do not show the warning if all codes are the same, than this is mostly intential
        if (allSame) {
            return false;
        }

        return hasDuplicates;
    }, [current]);

    return (
        <section>
            <label htmlFor={id} className="block pl-3 form-label">
                {t(props.label)}
            </label>
            <div className="flex flex-col space-y-6">
                <div
                    {...getRootProps()}
                    className="mt-1 border border-dashed border-gray-200 cursor-pointer bg-gray-50 w-100 h-32 rounded
                    flex justify-center items-center
                    hover:bg-gray-100 focus:border-green-400 focus:outline-none
                    text-center text-body1 text-semantic-primary"
                >
                    <input
                        className="focus:border-green-400"
                        id={id}
                        {...getInputProps()}
                    />
                    <p className="px-6">
                        {t('reward.couponCodes.dropzoneMessage')}
                    </p>
                </div>
                {isLoading && <Spinner sizeInRem={4} />}
                {props.current.length > 0 && (
                    <div className="mt-2">
                        {shouldShowDuplicateWarning && (
                            <div className="rounded my-2 px-4 py-3 bg-red-100 text-red-900 flex">
                                {t('reward.couponCodes.duplicatesLabel')}
                            </div>
                        )}
                        <Table
                            header={
                                <TableHeader
                                    labels={[
                                        'reward.couponCodes.previewHeader',
                                    ]}
                                />
                            }
                            footer={
                                <TableFooter>
                                    <>
                                        <span>
                                            <span className="font-medium">
                                                {props.current.length}
                                            </span>{' '}
                                            {t('reward.couponCodes.total')}
                                        </span>
                                        <div>
                                            <Button
                                                label="reward.couponCodes.download"
                                                secondary
                                                onClick={onRequestDownload}
                                            />
                                        </div>
                                    </>
                                </TableFooter>
                            }
                        >
                            {props.current.slice(0, 3).map((code) => (
                                <TableRow
                                    key={code}
                                    contents={[
                                        <TextTableCell primary={code} />,
                                    ]}
                                />
                            ))}
                            {props.current.length > 3 && (
                                <TableRow
                                    contents={[
                                        <TextTableCell
                                            primary={
                                                <span className="opacity-50">
                                                    ...
                                                </span>
                                            }
                                        />,
                                    ]}
                                />
                            )}
                        </Table>
                    </div>
                )}
            </div>
        </section>
    );
};

export default RewardCodeCSVUpload;
