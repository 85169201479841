import { useCallback, useState } from 'react';

export type ValidationResult<T> =
    | (T extends Object
          ? { [k in keyof T]?: ValidationResult<T[k]> }
          : T extends undefined
          ? null
          : string | null)
    | null;

type OnBlurCallback = () => void;

export default function useValidation<T extends Object>(
    values: T | undefined,
    validate: (values: T | undefined) => ValidationResult<T>,
): [ValidationResult<T>, OnBlurCallback] {
    const [result, setResult] = useState<ValidationResult<T> | null>(null);

    const onBlur = useCallback(() => {
        setResult(validate(values));
    }, [values, validate]);

    return [result, onBlur];
}
