import axios, { AxiosRequestConfig } from 'axios';
import { app } from './auth/firebase';
import { deploymentEnvironment } from './env';

const getPixelCodeEndpoint = () => {
    switch (deploymentEnvironment) {
        case 'production':
            return 'https://api.plan3t.one';
        case 'staging':
            return 'https://api.staging.plan3t.one';
        case 'development':
            return 'http://localhost:8080';
    }
};

export const PIXEL_CODE_ENDPOINT = getPixelCodeEndpoint();

const getPixel3tCodeUrl = () => {
    switch (deploymentEnvironment) {
        case 'production':
            return 'https://c.plan3t.one/3t.js';
        case 'staging':
            return 'https://c.plan3t.one/3t.js';
        case 'development':
            return 'http://localhost:1234/3t.js';
    }
};

export const PIXEL_3T_CODE_URL = getPixel3tCodeUrl();

export const init = () => {
    switch (deploymentEnvironment) {
        case 'development':
            axios.defaults.baseURL = 'http://localhost:8080/b2b/';
            break;
        case 'staging':
            axios.defaults.baseURL = 'https://api.staging.plan3t.one/b2b/';
            break;
        case 'production':
            axios.defaults.baseURL = 'https://api.plan3t.one/b2b/';
            break;
    }

    axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
        const token = await app.auth().currentUser?.getIdToken();
        if (config.headers === undefined) {
            config.headers = {};
        }
        config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['X-App-Version'] =
            process.env.REACT_APP_GIT_SHA || 'local';
        return config;
    });
};
