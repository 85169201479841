import * as React from 'react';
import { Translatable, useI18n } from '../i18n';
import { BackButton } from './IconButton';

type Props = {
    title: Translatable;
    backButton?: boolean;
};

const DashboardHeader: React.FunctionComponent<Props> = ({
    title,
    backButton,
    children,
}) => {
    const { t } = useI18n();
    return (
        <header className="flex flex-none h-18 items-center bg-semantic-surface">
            <div className="w-full max-w-7xl flex items-center px-12">
                <h1 className="flex items-center space-x-2 text-headline2 font-bold text-semantic-primary">
                    {backButton && (
                        <div>
                            <BackButton />
                        </div>
                    )}
                    <div>{t(title)}</div>
                </h1>
                <div className="ml-auto space-x-2 flex flex-row items-center">
                    {children}
                </div>
            </div>
        </header>
    );
};
export default DashboardHeader;
