import React, { SyntheticEvent, useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    DraftState,
    Reward,
    RewardBillingType,
    RewardDraft,
} from '../../api/model/core';
import { Button } from '../../common/Button';
import { IconClock, IconPhotograph, IconWarning } from '../../icons';
import { AppState } from '../../redux/AppStore';
import { rewardActions, rewardSelectors } from '../store';
import RewardTileDropdownMenu from './RewardTileDropdownMenu';

type OwnProps =
    | {
          reward: Reward;
          type: 'live';
          hideActions?: boolean;
          disableToggle?: boolean;
      }
    | {
          reward: RewardDraft;
          type: 'draft';
          hideActions?: boolean;
          disableToggle?: boolean;
      };

type Props = ConnectedProps<typeof connector> & OwnProps;

const mapStateToProps = (state: AppState) => ({
    currentDraft: rewardSelectors.currentDraft(state),
});

const actions = {
    submitRewardDraft: rewardActions.submitRewardDraft,
};

const connector = connect(mapStateToProps, actions);

export const RewardTileInner = (props: Props) => {
    const { submitRewardDraft } = props;
    const { rewardId } = props.reward;
    const history = useHistory();
    const onPublish = useCallback(
        async (e: SyntheticEvent) => {
            e.preventDefault();
            await submitRewardDraft({
                rewardId,
            });
        },
        [submitRewardDraft, rewardId],
    );

    const truncatedDescription = props.reward.description.substring(0, 120);

    return (
        <div className="flex flex-col flex-grow space-y-4">
            {props.reward.images.length > 0 && (
                <img
                    className="flex-shrink-0 h-60 object-cover rounded"
                    src={props.reward.images[0].url}
                    alt={props.reward.images[0].id}
                />
            )}
            {props.reward.images.length < 1 && (
                <div className="flex h-60 bg-semantic-background rounded justify-center items-center">
                    <IconPhotograph className="inline-flex text-gray-300 h-10 w-10" />
                </div>
            )}
            <div className="flex flex-col flex-grow p-2 space-y-3 text-semantic-primary">
                <div className="flex flex-col flex-grow space-y-2">
                    <span className="truncate font-bold text-headline4">
                        {props.reward.title}
                    </span>
                    <p className="text-body2">
                        {
                            // Add ellipsis if text was truncated
                            truncatedDescription.length !==
                            props.reward.description.length
                                ? `${truncatedDescription} ...`
                                : props.reward.description
                        }
                    </p>
                </div>
                {!props.hideActions && (
                    <div className="flex flex-row items-center space-x-2">
                        {props.type === 'draft' &&
                            props.reward.state === DraftState.Draft && (
                                <Button
                                    secondary
                                    label="reward.publishDraftShort"
                                    onClick={onPublish}
                                />
                            )}
                        {props.type === 'draft' &&
                            props.reward.state === DraftState.Pending && (
                                <div className="bg-yellow-100 p-2 text-yellow-800 rounded ">
                                    <IconClock
                                        color="#F59E0B"
                                        className="h-6 w-6"
                                    />
                                </div>
                            )}
                        {props.type === 'draft' &&
                            props.reward.state === DraftState.Rejected && (
                                <div className="bg-red-100 p-2 text-red-800 rounded">
                                    <IconWarning className="h-6 w-6" />
                                </div>
                            )}
                        <Button
                            className="flex-auto"
                            secondary
                            label={'reward.editReward'}
                            onClick={() =>
                                history.push(
                                    `/rewards/draft/${props.reward.rewardId}/${
                                        props.reward.billingType ===
                                        RewardBillingType.PAID
                                            ? 'paid'
                                            : 'discount'
                                    }`,
                                )
                            }
                        />
                        {props.type === 'live' && (
                            <RewardTileDropdownMenu
                                disableToggle={props.disableToggle}
                                reward={props.reward}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default connector(RewardTileInner);
