import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UploadImageResponse } from '../api/model/core';

type uploadImagesArgs = {
    partnerId: string;
    files: File[];
};

const uploadImages = createAsyncThunk(
    'partner/upload',
    async ({ partnerId, files }: uploadImagesArgs): Promise<any> => {
        const uploads = files.map(async (file) => {
            let formData = new FormData();
            formData.append('file', file);
            const res = await axios.post<UploadImageResponse>(
                `/partner/${partnerId}/upload`,
                formData,
                {
                    headers: {
                        'content-type': 'multipart/form-data', // do not forget this
                    },
                },
            );
            return UploadImageResponse.fromJson(res.data as any);
        });
        return Promise.all(uploads);
    },
);

export const filesActions = {
    uploadImages,
};
