import Scrollable from '../common/Scrollable';
import FormSection from '../common/form/FormSection';
import { Translatable, useI18n } from '../i18n';
import { Trans } from 'react-i18next';
import { lazy, ReactNode, Suspense, useState } from 'react';
import { Button } from '../common/Button';
import { IconDocumentDownload } from '../icons';
import Modal from '../common/Modal';
import Spinner from '../common/Spinner';
import { useAppSelector } from '../redux/react';
import { authSelectors } from '../auth/store';

const PreviewBadges = lazy(() =>
    import('./previews').then((m) => ({ default: m.PreviewBadges })),
);
const PreviewLandingPage = lazy(() =>
    import('./previews').then((m) => ({ default: m.PreviewLandingPage })),
);

const snippet = (partnerId: string) =>
    `
<a
  href="https://plan3t.one/download?utm_source=partner_snippet&utm_medium=snippet&utm_campaign=Cashback_ForGood&utm_id=${partnerId}"
  style="text-decoration: none; color: inherit; font-family: inherit"
>
  <h4 style="margin: 0 0 8px 0">
    Für deine Zahlung hast du PLAN3T Coins erhalten!
  </h4>
  <p style="margin: 0 0 8px 0">
    Lade jetzt die PLAN3T App, um deine Coins zu sichern und Prämien und Rabatte von uns sowie weiteren nachhaltigen Partnern zu erhalten.
  </p>
  <img src="https://cdn.plan3t.one/brandassets/PLAN3T-Download-Badge.png" width="180" height="48" alt="PLAN3T App herunterladen" />
</a>
`.trim();

export default function BrandingPage() {
    const { t } = useI18n();

    const partnerId = useAppSelector(authSelectors.getPartnerId);

    return (
        <Scrollable className="bg-semantic-background min-h-full">
            <div className="flex flex-col space-y-12">
                <FormSection
                    label="branding.badges.label"
                    subtitle="branding.badges.subtitle"
                >
                    <div className="flex flex-col space-y-8">
                        <div className="flex flex-col space-y-6">
                            <h3 className="text-semantic-primary text-headline5 font-bold">
                                {t('branding.badges.headline')}
                            </h3>
                            <p className="text-gray-500 text-body1">
                                <Trans
                                    t={t}
                                    i18nKey="branding.badges.explainer"
                                >
                                    Download the pack, for printable stuff,{' '}
                                    <a
                                        href="mailto:hello@plan3t.one"
                                        className="text-semantic-secondary hover:underline"
                                    >
                                        contact us
                                    </a>
                                    .
                                </Trans>
                            </p>
                        </div>
                        <FileDownloadCard
                            fileName="branding.badges.fileName"
                            fileSize="263 KB"
                            fileURL="https://storage.googleapis.com/plan3t-cdn/brandassets/202204-PLAN3T-Partner-Badges.zip"
                            preview={<PreviewBadges />}
                        />
                    </div>
                </FormSection>
                <FormSection
                    label="branding.landingPage.label"
                    subtitle="branding.landingPage.subtitle"
                >
                    <div className="flex flex-col space-y-8">
                        <div className="flex flex-col space-y-6">
                            <h3 className="text-semantic-primary text-headline5 font-bold">
                                {t('branding.landingPage.headline')}
                            </h3>
                            <p className="text-gray-500 text-body1">
                                <Trans
                                    t={t}
                                    i18nKey="branding.landingPage.explainer"
                                >
                                    Landing page assets.
                                    <br />
                                    If you need support.
                                    <a
                                        href="mailto:hello@plan3t.one"
                                        className="text-semantic-secondary hover:underline"
                                    >
                                        contact us
                                    </a>
                                    .
                                </Trans>
                            </p>
                        </div>
                        <FileDownloadCard
                            fileName="branding.landingPage.fileName"
                            fileSize="8.4 MB"
                            fileURL="https://storage.googleapis.com/plan3t-cdn/brandassets/202204-PLAN3T-Partner-Landingpage.zip"
                            preview={<PreviewLandingPage />}
                        />
                        <div className="flex flex-col space-y-6 text-gray-500 text-body1">
                            <div className="flex flex-col space-y-2">
                                <span>
                                    {t(
                                        'branding.landingPage.linkTypes.download',
                                    )}
                                </span>
                                <pre className="bg-semantic-background border border-gray-200 rounded px-3 py-2 overflow-y-auto text-sm">
                                    https://plan3t.one/download?utm_source=partner_LP&utm_medium=LP&utm_campaign=Cashback_ForGood&utm_id=
                                    {partnerId}
                                </pre>
                            </div>
                            <div className="flex flex-col space-y-2">
                                <span>
                                    {t(
                                        'branding.landingPage.linkTypes.homepage',
                                    )}
                                </span>
                                <pre className="bg-semantic-background border border-gray-200 rounded px-3 py-2 overflow-y-auto text-sm">
                                    https://plan3t.one/?utm_source=partner_LP&utm_medium=LP&utm_campaign=Cashback_ForGood&utm_id=
                                    {partnerId}
                                </pre>
                            </div>
                            <div className="flex flex-col space-y-2">
                                <span>
                                    {t(
                                        'branding.landingPage.linkTypes.security',
                                    )}
                                </span>
                                <pre className="bg-semantic-background border border-gray-200 rounded px-3 py-2 overflow-y-auto text-sm">
                                    https://plan3t.one/security?utm_source=partner_LP&utm_medium=LP&utm_campaign=Cashback_ForGood&utm_id=
                                    {partnerId}
                                </pre>
                            </div>
                        </div>
                    </div>
                </FormSection>
                <FormSection
                    label="branding.snippets.label"
                    subtitle="branding.snippets.subtitle"
                >
                    <div className="flex flex-col space-y-8">
                        <div className="flex flex-col space-y-6">
                            <h3 className="text-semantic-primary text-headline5 font-bold">
                                {t('branding.snippets.headline')}
                            </h3>
                            <p className="text-gray-500 text-body1">
                                <Trans
                                    t={t}
                                    i18nKey="branding.snippets.explainer"
                                >
                                    Integrate a link.
                                    <br />
                                    Using this template:
                                </Trans>
                            </p>
                        </div>
                        <pre className="bg-semantic-background border border-gray-200 rounded px-3 py-2 overflow-y-auto text-sm">
                            {snippet(partnerId || '')}
                        </pre>
                    </div>
                </FormSection>
                <FormSection
                    label="branding.socialTemplates.label"
                    subtitle="branding.socialTemplates.subtitle"
                >
                    <div className="flex flex-col space-y-8">
                        <div className="flex flex-col space-y-6">
                            <h3 className="text-semantic-primary text-headline5 font-bold">
                                {t('branding.socialTemplates.headline')}
                            </h3>
                            <p className="text-gray-500 text-body1">
                                <Trans
                                    t={t}
                                    i18nKey="branding.socialTemplates.explainer"
                                >
                                    Download the pack the assets.
                                    <br />
                                    Ready made stuff.
                                </Trans>
                            </p>
                        </div>
                        <FileDownloadCard
                            fileName="branding.socialTemplates.fileName"
                            fileSize="11.5 MB"
                            fileURL="https://storage.googleapis.com/plan3t-cdn/brandassets/202204-PLAN3T-Partner-SoMe.zip"
                        />
                    </div>
                </FormSection>
            </div>
        </Scrollable>
    );
}

function FileDownloadCard(props: {
    fileName: Translatable;
    fileSize: string;
    fileURL: string;
    preview?: ReactNode;
}) {
    const { t } = useI18n();
    const [previewOpen, setPreviewOpen] = useState(false);
    return (
        <div className="rounded border border-gray-200 flex flex-row space-x-2 p-4 items-center">
            <div>
                <IconDocumentDownload className="h-12 w-12" strokeWidth={1} />
            </div>
            <div className="flex-1 flex flex-col">
                <span className="font-bold">{t(props.fileName)}</span>
                <span>{props.fileSize}</span>
            </div>
            <div className="flex flex-row space-x-2">
                {props.preview && (
                    <Button
                        secondary
                        label="branding.common.previewButtonLabel"
                        onClick={() => setPreviewOpen(true)}
                    />
                )}
                <Button
                    primary
                    label="branding.common.downloadButtonLabel"
                    href={props.fileURL}
                />
            </div>
            <Modal
                visible={previewOpen}
                headline={props.fileName}
                onRequestClose={() => setPreviewOpen(false)}
            >
                <Suspense fallback={<Spinner sizeInRem={1} />}>
                    {props.preview}
                </Suspense>
            </Modal>
        </div>
    );
}
