import { Button } from '../common/Button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '../common/Modal';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { rewardActions, rewardSelectors } from '../rewards/store';
import { authSelectors } from '../auth/store';
import { IconClose } from '../icons';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { useHtmlId } from '../hooks';
import { useI18n } from '../i18n';
import { triggerCSVDownload } from '../rewards/RewardCodeCSVUpload';
import {
    addDocumentArgs,
    billingActions,
    billingSelectors,
    PostingType,
} from './store';
import { ulid } from 'ulid';
import Spinner from '../common/Spinner';
import { snackbarActions } from '../snackbar/store';
import { RewardBillingType } from '../api/model/core';

type Props = {
    period: number;
    postingType: string;
};

export default function BillingDocumentUpload(props: Props) {
    const [modalOpen, setModalOpen] = useState(false);

    const dispatch = useAppDispatch();
    const partnerId = useAppSelector((state) =>
        authSelectors.getPartnerId(state),
    );

    const live = useAppSelector((state) =>
        rewardSelectors.getCurrentPage(state),
    );
    const drafts = useAppSelector((state) =>
        rewardSelectors.allDrafts(state),
    ).filter((r) => {
        return !live.some((l) => l.rewardId === r.rewardId);
    });

    const rewards = [...live, ...drafts].filter((r) => {
        if (props.postingType === PostingType.RewardPaid) {
            return r.billingType === RewardBillingType.PAID;
        }
        return r.billingType === RewardBillingType.DISCOUNT;
    });

    useEffect(() => {
        dispatch(rewardActions.fetchAllLiveRewards({ partnerId }));
    }, [dispatch, partnerId]);

    const [rewardID, setRewardID] = useState('');

    const selectedReward = useMemo(
        () => rewards.find((r) => r.rewardId === rewardID),
        [rewardID, rewards],
    );

    const onClose = () => {
        setModalOpen(false);
        setRewardID('');
    };

    const canEdit = useAppSelector((state) =>
        billingSelectors.canEdit(state, props.period),
    );

    return (
        <>
            <Modal
                visible={modalOpen}
                headline="billing.document.uploadCodes.modal.headline"
                onRequestClose={onClose}
            >
                <div className="flex flex-col space-y-4 border-t -mx-6 border-b">
                    {!rewardID &&
                        rewards.map((reward) => (
                            <button
                                key={reward.rewardId}
                                className="flex flex-row items-center space-x-4 py-4 px-6 hover:bg-gray-100"
                                onClick={(e) => setRewardID(reward.rewardId)}
                            >
                                <img
                                    className="h-16 w-16 object-cover"
                                    src={reward.images[0]?.url}
                                    alt="reward preview"
                                />
                                <span className="text-headline4 font-bold">
                                    {reward.title}
                                </span>
                            </button>
                        ))}
                    {selectedReward && (
                        <div className="flex flex-row items-center space-x-4 px-6 py-4">
                            <img
                                className="h-16 w-16 object-cover"
                                src={selectedReward.images[0]?.url}
                                alt="reward preview"
                            />
                            <span className="text-headline4 font-bold">
                                {selectedReward.title}
                            </span>
                            <div className="flex-1" />
                            <button
                                onClick={() => setRewardID('')}
                                className="p-2 rounded hover:bg-gray-50"
                            >
                                <IconClose className="h-6 w-6 text-gray-800" />
                            </button>
                        </div>
                    )}
                </div>
                {rewardID && (
                    <DocumentUpload
                        {...props}
                        rewardID={rewardID}
                        onClose={onClose}
                    />
                )}
            </Modal>
            {canEdit && (
                <Button
                    primary
                    label="billing.document.uploadCodes.buttonLabel"
                    onClick={(e) => {
                        e.preventDefault();
                        setModalOpen(true);
                    }}
                />
            )}
        </>
    );
}

type DocumentPreview =
    | { _tag: 'text'; content: string }
    | { _tag: 'image'; src: string }
    | { _tag: 'file'; name: string };

function DocumentUpload(
    props: Props & { rewardID: string; onClose: () => void },
) {
    const id = useHtmlId();
    const { t } = useI18n();

    const [req, setReq] = useState<addDocumentArgs | undefined>(undefined);
    const [preview, setPreview] = useState<DocumentPreview | undefined>(
        undefined,
    );

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            if (acceptedFiles.length !== 1) {
                return;
            }

            const file = acceptedFiles[0];

            const res = await new Promise<Uint8Array>((resolve, reject) => {
                const reader = new FileReader();
                reader.addEventListener('loadend', (e) => {
                    const result = e.target?.result;
                    if (result instanceof ArrayBuffer) {
                        resolve(new Uint8Array(result));
                    } else {
                        reject(
                            `unexpected read result, expected ArrayBuffer, got ${typeof result}: '${result}'`,
                        );
                    }
                });
                reader.addEventListener('error', reject);

                reader.readAsArrayBuffer(file);
            });

            setReq({
                id: ulid(),
                type: props.postingType,
                period: props.period,
                content: res,
                metadata: {
                    reward_id: props.rewardID,
                },
                mimeType: file.type,
            });

            if (file.type.startsWith('text')) {
                const lines = new TextDecoder().decode(res).split('\n');

                const truncated = lines.slice(0, 8).join('\n');

                const preview = truncated + (lines.length > 8 ? '\n[...]' : '');
                setPreview({
                    _tag: 'text',
                    content: preview,
                });
            } else if (file.type.startsWith('image')) {
                setPreview({
                    _tag: 'image',
                    src: URL.createObjectURL(file),
                });
            } else {
                setPreview({
                    _tag: 'file',
                    name: file.name,
                });
            }
        },
        [props, setReq, setPreview],
    );

    const [isUploading, setIsUploading] = useState(false);

    const dispatch = useAppDispatch();
    const onUpload = async () => {
        try {
            setIsUploading(true);
            const res = await dispatch(billingActions.addDocument(req!));

            if (res.meta.requestStatus === 'fulfilled') {
                props.onClose();
            } else {
                dispatch(
                    snackbarActions.sendMessage({
                        title: 'billing.document.uploadCodes.error',
                        type: 'warning',
                    }),
                );
            }
        } finally {
            setIsUploading(false);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        multiple: false,
        onDrop,
    });

    return (
        <div className="flex flex-col p-6 pb-0 space-y-4">
            {req === undefined && (
                <div>
                    <label htmlFor={id} className="block pl-3 form-label">
                        {t('billing.document.uploadCodes.modal.fileInputLabel')}
                    </label>
                    <div
                        className={
                            'mt-1 relative rounded-md text-semantic-primary'
                        }
                    >
                        <div
                            {...getRootProps()}
                            className="mt-1 border border-2 border-gray-200 cursor-pointer bg-white w-100 h-32 rounded flex justify-center items-center hover:bg-gray-100 focus:border-green-400 focus:outline-none"
                        >
                            <input
                                className="focus:border-green-400"
                                id={id}
                                {...getInputProps()}
                            />
                            <p className="text-center text-body1">
                                {t(
                                    'billing.document.uploadCodes.modal.dropzoneMessage',
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {req && preview && <PreviewRenderer preview={preview} />}
            {req === undefined && (
                <div className="text-body1 text-gray-500 pt-4">
                    {t('billing.document.uploadCodes.modal.explainer')}
                </div>
            )}
            {req === undefined && (
                <div className="flex flex-row">
                    <div>
                        <Button
                            secondary
                            label="billing.document.uploadCodes.modal.downloadPresetButtonLabel"
                            onClick={(e) => {
                                e.preventDefault();
                                triggerCSVDownload([
                                    ['code', 'gross_order_cents'],
                                    ['your-code-1', '2350'],
                                    ['your-code-2', '5990'],
                                    ['...', '...'],
                                ]);
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="border-t border-gray-200 flex flex-row justify-end space-x-4 -mx-10 px-4 pt-4">
                <div>
                    <Button
                        secondary
                        label="billing.document.uploadCodes.modal.cancelLabel"
                        onClick={(e) => props.onClose()}
                    />
                </div>
                <div>
                    {!isUploading && (
                        <Button
                            primary
                            label={
                                'billing.document.uploadCodes.modal.uploadLabel'
                            }
                            onClick={onUpload}
                            disabled={req === undefined}
                        />
                    )}
                    {isUploading && <Spinner sizeInRem={2} />}
                </div>
            </div>
        </div>
    );
}

function PreviewRenderer(props: { preview: DocumentPreview }) {
    switch (props.preview._tag) {
        case 'text':
            return (
                <pre className="bg-gray-100 -mx-6 px-2 py-2">
                    {props.preview.content}
                </pre>
            );
        case 'image':
            return <img src={props.preview.src} alt="preview" />;
        case 'file':
            return <span>{props.preview.name}</span>;
    }
    return <span>Document Preview errored!</span>;
}
