import { SVGProps, useMemo } from 'react';
import * as React from 'react';
import { useLocation, matchPath, Link } from 'react-router-dom';
import cx from 'classnames';
import { Translatable, useI18n } from '../i18n';

export const SidebarSection: React.FunctionComponent<{
    label: Translatable;
}> = ({ label, children }) => {
    const { t } = useI18n();
    return (
        <div className="space-y-3">
            <Subheader>{t(label)}</Subheader>
            <div className="space-y-2 flex flex-col">{children}</div>
        </div>
    );
};

const Subheader: React.FunctionComponent = ({ children }) => (
    <h2 className="uppercase text-xs font-semibold text-gray-600 tracking-wide">
        {children}
    </h2>
);

type LinkProps = {
    label: Translatable;
    to: string;
    icon: React.FunctionComponent<SVGProps<SVGElement>>;
    exactMatch?: boolean;
};
export const SidebarLink: React.FunctionComponent<LinkProps> = ({
    label,
    to,
    icon: Icon,
    exactMatch,
}) => {
    const { t } = useI18n();

    const { pathname: path } = useLocation();

    const isActive = useMemo(() => {
        if (exactMatch) {
            return path === to;
        }
        return matchPath(path, to);
    }, [exactMatch, path, to]);

    const ariaAttributes = isActive
        ? ({
              'aria-current': 'page',
          } as const)
        : {};

    return (
        <Link
            to={to}
            className={cx(
                '-mx-3 px-3 py-2 flex items-center justify-between text-sm font-medium rounded-md hover:bg-gray-200 transition-default',
                {
                    'bg-gray-200': isActive,
                },
            )}
            {...ariaAttributes}
        >
            <span className="inline-flex space-x-2 items-center">
                <Icon
                    className={cx('w-5 h-5', {
                        'text-green-500': isActive,
                        'text-gray-500': !isActive,
                    })}
                />
                <span
                    className={cx({
                        'text-green-500': isActive,
                        'text-gray-700 font-light': !isActive,
                    })}
                >
                    {t(label)}
                </span>
            </span>
        </Link>
    );
};
