import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import AppShell from './AppShell';
import { useAppSelector } from './redux/react';
import { authSelectors } from './auth/store';
import Login from './auth/Login';
import Dashboard from './dashboard/Dashboard';
import SnackbarManager from './snackbar/SnackbarManager';

function App() {
    const isSignedIn = useAppSelector(authSelectors.isSignedIn);

    return (
        <AppShell>
            <SnackbarManager />
            <Router>
                <Switch>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="/">
                        {isSignedIn ? <Dashboard /> : <Redirect to="/login" />}
                    </Route>
                </Switch>
            </Router>
        </AppShell>
    );
}

export default App;
