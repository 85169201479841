import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../redux/AppStore';
import { accountActions } from '../account/store';
import {
    GetPartnerBaseResponse,
    GetPartnerManagerResponse,
    InvitePartnerManagerRequest,
    PostInvitePartnerManagerResponse,
} from '../api/model/b2b';
import { PartnerBase, PartnerManager } from '../api/model/core';
import { snackbarActions } from '../snackbar/store';

const fetchPartnerBase = createAsyncThunk(
    'partner/fetch',
    async (id: string): Promise<PartnerBase> => {
        const res = await axios.get<GetPartnerBaseResponse>(`/partner/${id}`);
        const msg = GetPartnerBaseResponse.fromJson(res.data as any);
        return msg.partner!;
    },
);

type fetchPartnerManagerArgs = {
    id: string;
    limit: number;
    after: string;
};

const fetchPartnerManager = createAsyncThunk(
    'partner/manager/fetch',
    async ({ id, limit, after }: fetchPartnerManagerArgs, { dispatch }) => {
        const res = await axios.get<object>(
            `/partner/${id}/manager?limit=${limit}&after=${after}`,
        );

        const msg = GetPartnerManagerResponse.fromJson(res.data as any);

        const manager: PartnerManager[] = msg.manager;

        dispatch(
            accountActions.fetchUserAccount(
                manager.map(({ userId }) => userId),
            ),
        );
        return { id, manager };
    },
);

type invitePartnerByEmailArgs = {
    partnerId: string;
    email: string;
};
const invitePartnerManager = createAsyncThunk(
    'partner/manager/invite',
    async ({ partnerId, email }: invitePartnerByEmailArgs, { dispatch }) => {
        const req: InvitePartnerManagerRequest = { email };
        const res = await axios.post<PostInvitePartnerManagerResponse>(
            `/partner/${partnerId}/manager`,
            req,
        );

        const msg = PostInvitePartnerManagerResponse.fromJson(res.data as any);

        const manager = msg.manager!;

        dispatch(accountActions.fetchUserAccount([manager.userId]));

        dispatch(
            snackbarActions.sendMessage({
                title: 'manager.notifications.invitationSend.title',
            }),
        );

        return manager;
    },
);

type removePartnerManagerArgs = {
    partnerId: string;
    userId: string;
};
const removePartnerManager = createAsyncThunk(
    'partner/manager/remove',
    async ({ partnerId, userId }: removePartnerManagerArgs, { dispatch }) => {
        await axios.delete(`/partner/${partnerId}/manager/${userId}`);

        dispatch(
            snackbarActions.sendMessage({
                title: 'manager.notifications.managerRemoved.title',
            }),
        );
    },
);

type PartnerState = {
    byId: Record<string, PartnerBase>;
    isLoading: boolean;
    managerByPartnerId: Record<string, PartnerManager[]>;
    isLoadingManger: boolean;
    isWritingManager: boolean;
};

const initialState: PartnerState = {
    byId: {},
    isLoading: false,
    managerByPartnerId: {},
    isLoadingManger: false,
    isWritingManager: false,
};

const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPartnerBase.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchPartnerBase.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(fetchPartnerBase.fulfilled, (state, action) => {
            const partner = action.payload;
            state.byId[partner.id] = partner;
            state.isLoading = false;
        });

        builder.addCase(fetchPartnerManager.pending, (state, action) => {
            state.isLoadingManger = true;
        });
        builder.addCase(fetchPartnerManager.rejected, (state, action) => {
            state.isLoadingManger = false;
        });
        builder.addCase(fetchPartnerManager.fulfilled, (state, action) => {
            const { id, manager } = action.payload;
            state.isLoadingManger = false;
            state.managerByPartnerId[id] = manager;
        });

        builder.addCase(invitePartnerManager.pending, (state) => {
            state.isWritingManager = true;
        });
        builder.addCase(invitePartnerManager.rejected, (state) => {
            state.isWritingManager = false;
        });
        builder.addCase(invitePartnerManager.fulfilled, (state, action) => {
            const manager = action.payload;
            state.isWritingManager = false;
            if (
                state.managerByPartnerId[manager.partnerId]
                    ?.map(({ userId }) => userId)
                    .includes(manager.userId)
            ) {
                // do not add twice.
                return;
            }
            state.managerByPartnerId[manager.partnerId]?.push(manager);
        });
        builder.addCase(removePartnerManager.fulfilled, (state, action) => {
            state.managerByPartnerId[action.meta.arg.partnerId] =
                state.managerByPartnerId[action.meta.arg.partnerId].filter(
                    ({ userId }) => userId !== action.meta.arg.userId,
                );
        });
    },
});

export const partnerReducer = partnerSlice.reducer;

export const partnerActions = {
    ...partnerSlice.actions,
    fetchPartnerBase,
    fetchPartnerManager,
    invitePartnerManager,
    removePartnerManager,
};

export const partnerSelectors = {
    byId: (state: AppState, { id }: { id: string }) => state.partner.byId[id],
    managersById: (state: AppState, { id }: { id: string }) =>
        state.partner.managerByPartnerId[id],
    isLoading: (state: AppState) => state.partner.isLoading,
    isLoadingManger: (state: AppState) => state.partner.isLoadingManger,
    isWritingManager: (state: AppState) => state.partner.isWritingManager,
};
