import * as React from 'react';
import cx from 'classnames';

type Props = {
    className?: string;
    outerClassName?: string;
};

const Scrollable: React.FunctionComponent<Props> = (props) => (
    <div
        className={cx(
            'h-full overflow-hidden flex flex-col',
            props.outerClassName,
        )}
    >
        <div className={cx('py-6 px-8 overflow-y-auto', props.className)}>
            {props.children}
        </div>
    </div>
);

export default Scrollable;
