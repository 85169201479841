import * as React from 'react';
import { SVGProps } from 'react';

export const IconPartnerProfile: React.FunctionComponent<SVGProps<SVGElement>> =
    ({ ref, ...props }) => (
        <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            {...props}
        >
            <path d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"></path>
        </svg>
    );

export const IconProducts: React.FunctionComponent<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
    >
        <path d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
    </svg>
);

export const IconReports: React.FunctionComponent<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
    >
        <path d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
    </svg>
);

export const IconRewards: React.FunctionComponent<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
        />
    </svg>
);

export const IconBack: React.FunctionComponent<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
    >
        <path d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
    </svg>
);

export const IconSuccess: React.FunctionComponent<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
    >
        <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
    </svg>
);

export const IconWarning: React.FunctionComponent<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
    >
        <path d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
    </svg>
);

export const IconUserGroup: React.FunctionComponent<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
    >
        <path d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
    </svg>
);

export const IconClose: React.FunctionComponent<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
    >
        <path d="M6 18L18 6M6 6l12 12"></path>
    </svg>
);

export const IconClock: React.FunctionComponent<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
    >
        <path d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
);

export const IconChevronDown: React.FunctionComponent<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
    >
        <path d="M19 9l-7 7-7-7" />
    </svg>
);

export const IconCode: React.FunctionComponent<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
    >
        <path d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
    </svg>
);

export const IconCash: React.FunctionComponent<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
    >
        <path d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
    </svg>
);

export const IconInfo: React.FC<SVGProps<SVGElement>> = ({ ref, ...props }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13 16H12V12H11M12 8H12.01M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IconArrowLeft: React.FC<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3 12H21M10 19L3 12L10 19ZM3 12L10 5L3 12Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IconEllipsis: React.FC<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5 12H5.01H5ZM12 12H12.01H12ZM19 12H19.01H19ZM6 12C6 12.2652 5.89464 12.5196 5.70711 12.7071C5.51957 12.8946 5.26522 13 5 13C4.73478 13 4.48043 12.8946 4.29289 12.7071C4.10536 12.5196 4 12.2652 4 12C4 11.7348 4.10536 11.4804 4.29289 11.2929C4.48043 11.1054 4.73478 11 5 11C5.26522 11 5.51957 11.1054 5.70711 11.2929C5.89464 11.4804 6 11.7348 6 12V12ZM13 12C13 12.2652 12.8946 12.5196 12.7071 12.7071C12.5196 12.8946 12.2652 13 12 13C11.7348 13 11.4804 12.8946 11.2929 12.7071C11.1054 12.5196 11 12.2652 11 12C11 11.7348 11.1054 11.4804 11.2929 11.2929C11.4804 11.1054 11.7348 11 12 11C12.2652 11 12.5196 11.1054 12.7071 11.2929C12.8946 11.4804 13 11.7348 13 12V12ZM20 12C20 12.2652 19.8946 12.5196 19.7071 12.7071C19.5196 12.8946 19.2652 13 19 13C18.7348 13 18.4804 12.8946 18.2929 12.7071C18.1054 12.5196 18 12.2652 18 12C18 11.7348 18.1054 11.4804 18.2929 11.2929C18.4804 11.1054 18.7348 11 19 11C19.2652 11 19.5196 11.1054 19.7071 11.2929C19.8946 11.4804 20 11.7348 20 12V12Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const IconGift: React.FC<SVGProps<SVGElement>> = ({ ref, ...props }) => (
    <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.1093 6.77799C35.6628 8.25223 36.7227 11.3199 35.7581 13.9998L38.166 13.9999C40.3521 13.9999 42.1287 15.7536 42.1654 17.931L42.166 17.9999V37.9999C42.166 40.186 40.4123 41.9625 38.2349 41.9993L38.166 41.9999H10.166C7.97989 41.9999 6.20337 40.2461 6.1666 38.0687L6.16602 37.9999V17.9999C6.16602 15.8137 7.91976 14.0372 10.0971 14.0004L10.166 13.9999L12.6739 13.9998C11.7094 11.3199 12.7693 8.25223 15.3227 6.77799C18.1638 5.13771 21.7887 6.08499 23.4686 8.88862L23.5189 8.97415L24.216 10.1815L24.9132 8.97415C26.57 6.10439 30.2396 5.12114 33.1093 6.77799ZM38.166 31.9996H10.166V37.9997H38.166V31.9996ZM10.166 17.9997V27.9996H38.166V17.9997L28.7298 17.9996L30.5189 21.0984C31.0711 22.055 30.7434 23.2781 29.7868 23.8304C28.8464 24.3733 27.6484 24.0658 27.0834 23.1465L27.0548 23.0984L24.2158 18.1816L21.3773 23.0984L21.3486 23.1465C20.7836 24.0658 19.5856 24.3733 18.6452 23.8304C17.7054 23.2878 17.3726 22.0977 17.8849 21.149L17.9132 21.0984L19.702 17.9996L10.166 17.9997ZM20.054 10.974C19.5018 10.0174 18.2786 9.68969 17.322 10.242C16.3654 10.7943 16.0377 12.0174 16.5899 12.974C17.1422 13.9306 18.3654 14.2584 19.322 13.7061C20.2786 13.1538 20.6063 11.9306 20.054 10.974ZM29.1093 13.7061C30.0659 14.2584 31.2891 13.9306 31.8413 12.974C32.3936 12.0174 32.0659 10.7943 31.1093 10.242C30.1527 9.68969 28.9295 10.0174 28.3772 10.974C27.825 11.9306 28.1527 13.1538 29.1093 13.7061Z"
            fill="#CBD5E1"
        />
    </svg>
);

export const IconEdit: React.FC<SVGProps<SVGElement>> = ({ ref, ...props }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5208 4.14916C15.9619 2.64646 18.3484 2.59654 19.8511 4.03765C19.87 4.05584 19.8888 4.07424 19.9074 4.09282C21.3796 5.56505 21.3796 7.952 19.9074 9.42423L19.8794 9.45196L19.8511 9.4794L9.69246 19.2217C9.58692 19.3229 9.46058 19.3996 9.32242 19.4466L9.28764 19.4578L5.17705 20.6909C4.38357 20.929 3.54734 20.4787 3.30929 19.6852C3.22916 19.4181 3.22516 19.1343 3.29727 18.8655L3.30929 18.8232L4.54247 14.7126C4.58449 14.5725 4.65667 14.4435 4.75376 14.3346L4.77855 14.3078L14.5208 4.14916ZM14.0818 7.49598L6.38854 15.5181L5.49134 18.5085L8.48174 17.6113L16.5037 9.91789L14.0818 7.49598ZM15.9905 5.50671L15.9643 5.53351L15.4667 6.05217L17.9478 8.53328L18.48 8.02306L18.5194 7.98325C19.1756 7.2992 19.1756 6.2179 18.5194 5.53386L18.48 5.49404L18.4397 5.4558C17.7421 4.81374 16.6608 4.83625 15.9905 5.50671Z"
            fill="#334155"
        />
    </svg>
);

export const IconPhotograph: React.FC<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
    </svg>
);

export const IconViewGrid: React.FC<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
        />
    </svg>
);

export const IconBilling: React.FC<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
        />
    </svg>
);

export const IconSpeakerPhone: React.FC<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
        />
    </svg>
);

export const IconDocumentDownload: React.FC<SVGProps<SVGElement>> = ({
    ref,
    ...props
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
        {...props}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
    </svg>
);
