import cx from 'classnames';

export interface BadgeProps {
    className?: string;
    children?: React.ReactNode;
}

export const Badge = ({ className, children }: BadgeProps) => {
    return (
        <div
            className={cx(
                className,
                'px-1.5 py-0.5 rounded text-headline5 text-semantic-onSecondary font-bold',
            )}
        >
            {children}
        </div>
    );
};
