import { BaseSyntheticEvent, useCallback, useState } from 'react';
import * as React from 'react';
import { Button } from '../common/Button';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { accountActions, accountSelectors } from '../account/store';
import { useI18n } from '../i18n';

const SetInitialPasswordScreen: React.FunctionComponent = () => {
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const { isWriting } = useAppSelector(accountSelectors.getWriteState);

    const dispatch = useAppDispatch();
    const onSubmit = useCallback(
        (e: BaseSyntheticEvent) => {
            e.preventDefault();
            dispatch(
                accountActions.changePassword({
                    password: password,
                    confirmation: passwordConfirmation,
                    doNotReauthenticate: true,
                }),
            );
        },
        [dispatch, password, passwordConfirmation],
    );

    const { t } = useI18n();

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-4">
                <h2 className="text-center text-3xl leading-9 font-extrabold text-gray-900">
                    {t('auth.login.title')}
                </h2>
                <p className="text-sm text-gray-800 text-center">
                    {t('auth.newPassword.welcome')}
                </p>
                <form className="mt-8" onSubmit={onSubmit}>
                    <div className="rounded-md shadow-sm">
                        <div>
                            <input
                                aria-label={t(
                                    'accountsettings.form.password.fields.newPassword',
                                )}
                                name="password"
                                type="password"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                                placeholder={t(
                                    'accountsettings.form.password.fields.newPassword',
                                )}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="-mt-px">
                            <input
                                aria-label={t(
                                    'accountsettings.form.password.fields.newPasswordConfirmation',
                                )}
                                name="password_confirm"
                                type="password"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                                placeholder={t(
                                    'accountsettings.form.password.fields.newPasswordConfirmation',
                                )}
                                value={passwordConfirmation}
                                onChange={(e) =>
                                    setPasswordConfirmation(e.target.value)
                                }
                            />
                        </div>
                    </div>
                    {!isWriting ? (
                        <div className="mt-6">
                            <Button
                                primary
                                label="common.save"
                                onClick={onSubmit}
                            />
                        </div>
                    ) : (
                        <span>...</span>
                    )}
                </form>
            </div>
        </div>
    );
};

export default SetInitialPasswordScreen;
