import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import './tailwind.generated.css';
import '@fontsource/ubuntu/300.css';
import '@fontsource/ubuntu/400.css';
import '@fontsource/ubuntu/500.css';
import '@fontsource/ubuntu/700.css';

import App from './App';
import AppStore from './redux/AppStore';
import AuthProvider from './auth/AuthProvider';
import * as i18n from './i18n';
import * as api from './api';
import * as sentry from './sentry';

api.init();
i18n.init();
sentry.init();

ReactDOM.render(
    <StrictMode>
        <AppStore>
            <AuthProvider>
                <App />
            </AuthProvider>
        </AppStore>
    </StrictMode>,
    document.getElementById('root'),
);
