import FormSection from '../common/form/FormSection';
import Form from '../common/form/Form';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { useEffect } from 'react';
import {
    billingActions,
    billingSelectors,
    PeriodStateCode,
    PostingType,
} from './store';
import * as Billing from '../api/billing/rpc/billing';
import SelectInput from '../common/form/SelectInput';
import cx from 'classnames';
import { useI18n } from '../i18n';
import { useFormatMoney, useNumberFormat } from '../i18n/numbers';
import BillingDocumentUpload from './BillingDocumentUpload';
import { Trans } from 'react-i18next';

export default function BillingBreakdown() {
    const dispatch = useAppDispatch();
    const { t } = useI18n();

    const periodsState = useAppSelector((state) => state.billing.periods);
    const period = useAppSelector((state) => state.billing.selectedPeriod);
    const periods = 'data' in periodsState ? periodsState.data : [];

    const periodCode = periods.find(({ id }) => id === period)?.code ?? '';

    useEffect(() => {
        if (period) {
            dispatch(billingActions.loadDocuments(period));
            dispatch(billingActions.loadPostings(period));
            dispatch(billingActions.loadPeriodState(period));
        }
    }, [dispatch, period]);

    const onPeriodChange = (pid: number) => {
        dispatch(billingActions.selectPeriod(pid));
    };

    if (!period) {
        return <span>Loading...</span>;
    }

    return (
        <Form onSubmit={() => console.log('SUBMIT?')}>
            <div className="w-full flex flex-row items-center justify-between">
                {period && (
                    <>
                        <div className="flex-0 flex flex-row space-x-4 items-center justify-center">
                            <div>
                                <SelectInput
                                    label=""
                                    emptyLabel={undefined}
                                    value={period}
                                    options={periods.map((p) => ({
                                        value: p.id,
                                        label: p.code,
                                    }))}
                                    onChange={(v) =>
                                        onPeriodChange(parseInt(v, 10))
                                    }
                                />
                            </div>
                            <div className="mt-1">
                                <PeriodStateDisplay period={period} />
                            </div>
                        </div>
                        <div className="text-primary flex justify-end items-center space-x-4 flex-1">
                            <span>{t('billing.periodTotalPrefix')}</span>
                            <span className="text-2xl font-bold">
                                <TotalForPeriod period={period} />
                            </span>
                        </div>
                    </>
                )}
            </div>
            <FormSection
                label="billing.sections.cashback.title"
                subtitle="billing.sections.cashback.subtitle"
            >
                <div className="flex flex-col space-y-6">
                    <div className="font-bold text-gray-500 text-sm px-4">
                        {t('billing.sections.cashback.header', { periodCode })}
                    </div>
                    <CashbackBreakdown period={period} />
                </div>
            </FormSection>

            <FormSection
                label="billing.sections.rewardPaid.title"
                subtitle="billing.sections.rewardPaid.subtitle"
            >
                <div className="flex flex-col space-y-6">
                    <div className="font-bold text-gray-500 text-sm px-4">
                        {t('billing.sections.rewardPaid.header', {
                            periodCode,
                        })}
                    </div>

                    <RewardDocumentBreakdown
                        period={period}
                        docType={PostingType.RewardPaid}
                        postingTypes={[
                            PostingType.RewardPaid,
                            PostingType.RewardPaidFee,
                        ]}
                    />

                    <div className="flex flex-row">
                        <div>
                            <BillingDocumentUpload
                                period={period}
                                postingType={PostingType.RewardPaid}
                            />
                        </div>
                    </div>
                </div>
            </FormSection>

            <FormSection
                label="billing.sections.rewardAffiliate.title"
                subtitle="billing.sections.rewardAffiliate.subtitle"
            >
                <div className="flex flex-col space-y-6">
                    <div className="font-bold text-gray-500 text-sm px-4">
                        {t('billing.sections.rewardAffiliate.header', {
                            periodCode,
                        })}
                    </div>

                    <RewardDocumentBreakdown
                        period={period}
                        docType={PostingType.RewardAffiliate}
                        postingTypes={[PostingType.RewardAffiliate]}
                    />

                    <div className="flex flex-row">
                        <div>
                            <BillingDocumentUpload
                                period={period}
                                postingType={PostingType.RewardAffiliate}
                            />
                        </div>
                    </div>
                </div>
            </FormSection>
        </Form>
    );
}

function RewardDocumentBreakdown(props: {
    period: number;
    docType: string;
    postingTypes: Array<string>;
}) {
    const { t } = useI18n();
    const fmt = useFormatMoney({ signDisplay: 'always' });
    const documents = useAppSelector((state) =>
        billingSelectors.getDocumentsForPeriodAndType(
            state,
            props.period,
            props.docType,
        ),
    );
    const rules = useAppSelector(billingSelectors.getAggregationRules);

    const postings = useAppSelector((state) =>
        billingSelectors.getPostingsForPeriod(
            state,
            props.period,
            props.postingTypes,
        ),
    );

    const total = billingSelectors.sumPostings(rules, postings);

    if (documents.length === 0) {
        return (
            <span className="text-body2 text-gray-700">
                {t('billing.noDocuments')}
            </span>
        );
    }

    return (
        <div className="flex flex-col border border-gray-100 rounded">
            {documents.map((doc, i) => (
                <DocumentRowContent
                    key={doc.id}
                    className={cx({ 'border-t border-gray-100': i > 0 })}
                    doc={doc}
                />
            ))}
            <div className="border-t border-gray-100 flex flex-row justify-between p-4 font-bold text-xl">
                <span>{t('billing.total')}</span>
                <span>
                    {fmt({
                        ...total,
                        currency: postings[0]?.currency ?? 'EUR',
                    })}
                </span>
            </div>
        </div>
    );
}

function DocumentRowContent(props: {
    doc: Billing.Document;
    className: string;
}) {
    const { t } = useI18n();
    const fmt = useFormatMoney({ signDisplay: 'always' });
    const postings = useAppSelector((state) =>
        billingSelectors.getPostingsForDocument(
            state,
            props.doc.period,
            props.doc.id,
        ),
    );
    const rules = useAppSelector(billingSelectors.getAggregationRules);

    const total = billingSelectors.sumPostings(rules, postings);

    const numConfirmed = postings.filter(
        ({ type }) => type === PostingType.RewardPaid,
    ).length;

    return (
        <div
            className={cx(
                'flex flex-row justify-between p-4 text-sm',
                props.className,
            )}
        >
            <div>
                <span className="font-bold">
                    <DocumentLabel document={props.doc} />
                </span>
                {numConfirmed > 0 && (
                    <span className="text-semantic-secondary">
                        &nbsp;
                        {t('billing.document.numConfirmed', { numConfirmed })}
                    </span>
                )}
            </div>
            <div>
                {postings.length === 0 ? (
                    <span className="text-yellow-500">
                        {t('billing.document.noBookings')}
                    </span>
                ) : (
                    <span>
                        {fmt({ ...total, currency: postings[0].currency })}
                    </span>
                )}
            </div>
        </div>
    );
}

function TotalForPeriod(props: { period: number }) {
    const postings = useAppSelector((state) =>
        billingSelectors.getPostingsForPeriod(state, props.period),
    );
    const fmt = useFormatMoney({ signDisplay: 'always' });

    const rules = useAppSelector(billingSelectors.getAggregationRules);
    const total = billingSelectors.sumPostings(rules, postings);

    if (postings.length === 0) {
        return <>-</>;
    }

    return <>{fmt({ ...total, currency: postings[0]?.currency ?? 'EUR' })}</>;
}

function CashbackBreakdown(props: { period: number }) {
    const { t } = useI18n();
    const fmt = useFormatMoney({ signDisplay: 'always' });
    const f = useNumberFormat();
    const postings = useAppSelector((state) =>
        billingSelectors.getPostingsForPeriod(state, props.period, [
            PostingType.Cashback,
        ]),
    );
    const fees = useAppSelector((state) =>
        billingSelectors.getPostingsForPeriod(state, props.period, [
            PostingType.CashbackFee,
        ]),
    );
    const rules = useAppSelector(billingSelectors.getAggregationRules);

    const total = billingSelectors.sumPostings(rules, postings);
    const totalFees = billingSelectors.sumPostings(rules, fees);
    const points = postings
        .map(({ metadata }) => parseInt(metadata['points'], 10))
        .filter((a) => !isNaN(a))
        .reduce((a, b) => a + b, 0);

    const revertedPoints = postings
        .filter((p) => p.metadata['type'] === 'revert')
        .map((p) => {
            const revertedPosting = postings.find(
                (p_) => p_.id === parseInt(p.metadata['posting_id'] || '0', 10),
            );
            if (!revertedPosting) {
                return 0;
            }
            return parseInt(revertedPosting.metadata['points'], 10);
        })
        .filter((a) => !isNaN(a))
        .reduce((a, b) => a + b, 0);

    const sum = billingSelectors.sumPostings(rules, [...postings, ...fees]);

    if (postings.length === 0) {
        return (
            <span className="text-body2 text-gray-700">
                {t('billing.noDocuments')}
            </span>
        );
    }

    return (
        <div className="flex flex-col border border-gray-100 rounded font-bold">
            <div className="flex justify-between p-4">
                <div>
                    <Trans
                        i18nKey={
                            revertedPoints > 0
                                ? 'billing.sections.cashback.pointsWithReverted'
                                : 'billing.sections.cashback.points'
                        }
                        values={{
                            points: f(points),
                            reverted: f(revertedPoints),
                        }}
                    >
                        <span>XX Planet Coins</span>
                        <span className="text-gray-500 font-normal">
                            (including XX reverted)
                        </span>
                    </Trans>
                </div>
                <div>{fmt({ ...total, currency: postings[0].currency })}</div>
            </div>
            <div className="flex justify-between p-4 border-t border-gray-100">
                <div>{t('billing.cashbackFees')}</div>
                <div>
                    {fmt({ ...totalFees, currency: postings[0].currency })}
                </div>
            </div>
            <div className="flex justify-between p-4 border-t text-xl border-gray-100">
                <div>{t('billing.total')}</div>
                <div>{fmt({ ...sum, currency: postings[0].currency })}</div>
            </div>
        </div>
    );
}

function DocumentLabel(props: { document: Billing.Document }) {
    const reward = useAppSelector(
        (state) =>
            state.rewards.rewardLiveById[props.document.metadata['reward_id']],
    );

    if (reward) {
        return <>{reward.title}</>;
    }

    return <>DOC-{props.document.id.slice(-4)}</>;
}

function PeriodStateDisplay(props: { period: number }) {
    const { t } = useI18n();
    const state = useAppSelector((state) =>
        billingSelectors.getPeriodState(state, props.period),
    );

    return (
        <div
            className={cx('rounded-full border px-4 text-body1', {
                'border-gray-400 bg-gray-200 text-gray-800':
                    state === PeriodStateCode.Initial ||
                    state === PeriodStateCode.HalfOpen ||
                    state === PeriodStateCode.Closed,
                'border-green-400 bg-green-200 text-green-800':
                    state === PeriodStateCode.Open,
                'border-yellow-400 bg-yellow-200 text-yellow-800':
                    state === PeriodStateCode.Review,
            })}
        >
            {t('billing.periodCode.' + state)}
        </div>
    );
}
