import { useCallback, useEffect } from 'react';
import Scrollable from '../common/Scrollable';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { cashbackActions, cashbackSelectors } from './store';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { Table, TableHeader, TableRow, TextTableCell } from '../common/Table';

export default function CashbackLogPage() {
    const dispatch = useAppDispatch();

    const hasMore = useAppSelector(cashbackSelectors.hasMore);
    const items = useAppSelector(cashbackSelectors.getCurrentPage);
    const error = useAppSelector(cashbackSelectors.getError);

    const { fetchList } = cashbackActions;
    const fetchPage = useCallback(() => {
        dispatch(fetchList());
    }, [dispatch, fetchList]);

    const isEmpty = items.length === 0;
    useEffect(() => {
        if (hasMore && isEmpty) {
            fetchPage();
        }
    }, [hasMore, fetchPage, isEmpty]);

    return (
        <Scrollable className="pb-12">
            <Table
                header={
                    <TableHeader
                        labels={[
                            'cashback.table.header.date',
                            'cashback.table.header.amount',
                            'cashback.table.header.issuedCashback',
                            'cashback.table.header.fee',
                            'cashback.table.header.points',
                        ]}
                    />
                }
            >
                {items.map((e) => (
                    <TableRow
                        key={e.transactionId}
                        contents={[
                            <TextTableCell
                                primary={
                                    e.transactionDate &&
                                    Timestamp.toDate(
                                        e.transactionDate,
                                    ).toDateString()
                                }
                            />,
                            <TextTableCell
                                primary={
                                    (
                                        e.transactionAmountUnscaled /
                                        Math.pow(10, e.transactionAmountScale)
                                    ).toFixed(2) + ' €'
                                }
                            />,
                            <TextTableCell
                                primary={
                                    (
                                        e.issuedCashbackUnscaled /
                                        Math.pow(10, e.issuedCashbackScale)
                                    ).toFixed(2) + ' €'
                                }
                            />,
                            <TextTableCell
                                primary={
                                    (
                                        e.feeUnscaled / Math.pow(10, e.feeScale)
                                    ).toFixed(2) + ' €'
                                }
                            />,
                            <TextTableCell primary={e.points?.amount ?? 0} />,
                        ]}
                    />
                ))}
            </Table>
            {error && <div>Error loading: {error.message}</div>}
        </Scrollable>
    );
}
