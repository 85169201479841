import cx from 'classnames';
import { Reward, RewardDraft } from '../../api/model/core';
import { NoDraftsBody } from './NoDraftsBody';
import RewardTileBody from './TileBody';

export interface Props {
    className?: string;
    badge?: React.ReactNode;
}

export const RewardTile = (props: React.PropsWithChildren<Props>) => {
    if (!props.children) {
        return null;
    }

    return (
        <div
            className={cx(
                'relative flex flex-shrink-0 w-88 h-110 p-4 rounded-2xl bg-semantic-surface space-y-4',
                props.className,
            )}
        >
            <div className="inline-flex flex-col flex-grow w-full">
                <div className="absolute top-0 right-0 mt-6 mr-6">
                    {props.badge}
                </div>
                {props.children}
            </div>
        </div>
    );
};

RewardTile.NoDraftsPlaceholder = (props: Props) => {
    return (
        <RewardTile {...props}>
            <NoDraftsBody />
        </RewardTile>
    );
};

export interface RewardTileLiveProps extends Props {
    reward: Reward;
    disableToggle?: boolean;
}

RewardTile.Live = ({
    reward,
    disableToggle,
    ...props
}: RewardTileLiveProps) => {
    return (
        <RewardTile {...props}>
            <RewardTileBody
                reward={reward}
                disableToggle={disableToggle}
                type="live"
            />
        </RewardTile>
    );
};

export interface RewardTileDraftProps extends Props {
    draft: RewardDraft;
}

RewardTile.Draft = ({ draft, ...props }: RewardTileDraftProps) => {
    return (
        <RewardTile {...props}>
            <RewardTileBody reward={draft} type="draft" />
        </RewardTile>
    );
};
