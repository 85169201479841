import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { rewardSelectors, rewardActions } from './store';
import { AppState } from '../redux/AppStore';

import { useI18n } from '../i18n';
import { Button } from '../common/Button';
import DashboardHeader from '../common/DashboardHeader';
import RewardTile from './rewardTile/TileBody';
import AvailableRewardsDisplay from './AvailableRewardsDisplay';
import Scrollable from '../common/Scrollable';
import { authSelectors } from '../auth/store';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { RewardBillingType } from '../api/model/core';

const mapStateToProps = (state: AppState) => ({
    partnerId: authSelectors.getPartnerId(state)!,
    currentLive: rewardSelectors.currentLive(state)!,
});

const actions = {
    fetchReward: rewardActions.fetchReward,
};

const connector = connect(mapStateToProps, actions);

type TParams = {
    rewardId: string;
};

type Props = ConnectedProps<typeof connector> & RouteComponentProps<TParams>;

const RewardViewInner = (props: Props) => {
    const { t } = useI18n();
    const { currentLive, fetchReward, match, partnerId } = props;
    const rewardId = match.params.rewardId;

    useEffect(() => {
        fetchReward({ partnerId, rewardId });
    }, [partnerId, rewardId, fetchReward]);

    if (!props.currentLive) {
        return <>Loading...</>;
    }

    return (
        <>
            <DashboardHeader title="module.rewards.detail">
                <Button
                    primary
                    label="reward.editReward"
                    onClick={() =>
                        props.history.push(
                            '/rewards/draft/' +
                                currentLive.rewardId +
                                '/' +
                                (currentLive.billingType ===
                                RewardBillingType.PAID
                                    ? 'paid'
                                    : 'discount'),
                        )
                    }
                />
            </DashboardHeader>
            <Scrollable>
                <div className="flex flex-row px-8 py-12">
                    <div className="flex flex-col space-y-6">
                        <RewardTile
                            type="live"
                            reward={currentLive}
                            hideActions
                        />

                        <div className="flex flex-col border-l-4 border-gray-200 px-2">
                            <h4 className="font-medium uppercase tracking-wide text-gray-700">
                                {t('reward.description.label')}
                            </h4>
                            <p className="max-w-lg leading-5 text-gray-900">
                                {currentLive.description || '-'}
                            </p>
                        </div>

                        <div className="flex flex-col border-l-4 border-gray-200 px-2">
                            <h4 className="font-medium uppercase tracking-wide text-gray-700">
                                {t('reward.terms.label')}
                            </h4>
                            <p className="max-w-lg leading-5 text-gray-900">
                                {currentLive.terms || '-'}
                            </p>
                        </div>
                    </div>

                    <div>
                        <div className="bg-white shadow sm:rounded-lg">
                            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    {t('reward.view.details.headerLabel')}
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                                    {t('reward.view.details.subheaderLabel')}
                                </p>
                            </div>
                            <dl>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm leading-5 font-medium text-gray-500">
                                        {t('reward.validUntil.label')}
                                    </dt>
                                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                        {currentLive.validUntil
                                            ? Timestamp.toDate(
                                                  currentLive.validUntil,
                                              ).toDateString()
                                            : '-'}
                                    </dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm leading-5 font-medium text-gray-500">
                                        {t('reward.price.label')}
                                    </dt>
                                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                        {currentLive.price?.amount || '-'}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm leading-5 font-medium text-gray-500">
                                        {t('reward.directLink.label')}
                                    </dt>
                                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                        {!!currentLive.directLink && (
                                            <a
                                                href={currentLive.directLink}
                                                rel="noopener nofollow noreferrer"
                                                target="_blank"
                                                className="text-green-600 hover:text-green-700 underline"
                                            >
                                                {currentLive.directLink}
                                            </a>
                                        )}
                                        {!currentLive.directLink && '-'}
                                    </dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm leading-5 font-medium text-gray-500">
                                        {t('reward.couponCodes.labelAvailable')}
                                    </dt>
                                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 -mr-6">
                                        <AvailableRewardsDisplay
                                            rewardId={currentLive.rewardId}
                                        />
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </Scrollable>
        </>
    );
};

export default withRouter(connector(RewardViewInner));
