type FormattingOptions = {
    style?: 'currency';
    signDisplay?: 'always';
    maximumFractionDigits?: number;
};

export function useFormatMoney(opts?: Omit<FormattingOptions, 'style'>) {
    return (args: {
        amountUnscaled: number;
        amountScale: number;
        currency: string;
    }) => {
        const fmt = Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: args.currency,
            ...opts,
        });
        return fmt.format(args.amountUnscaled / Math.pow(10, args.amountScale));
    };
}

export function useNumberFormat(opts?: FormattingOptions) {
    return (num: number) => {
        const fmt = Intl.NumberFormat(undefined, opts);
        return fmt.format(num);
    };
}
