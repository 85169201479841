import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarMessage } from './types';
import { AppState } from '../redux/AppStore';
import { ulid } from 'ulid';

type State = {
    visible: SnackbarMessage[];
    dismissed: SnackbarMessage[];
};

type RequiredSnackbarMessageProps = Partial<SnackbarMessage> &
    Pick<SnackbarMessage, 'title'>;

const initialState: State = {
    visible: [],
    dismissed: [],
};

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        sendMessage: {
            reducer(state, action: PayloadAction<SnackbarMessage>) {
                state.visible.push(action.payload);
            },
            prepare(arg: RequiredSnackbarMessageProps) {
                return {
                    payload: {
                        uuid: ulid(),
                        sendAt: Date.now(),
                        type: 'success',
                        duration: 3000,
                        subtitle: undefined,
                        ...arg,
                    } as SnackbarMessage,
                };
            },
        },
        dismissMessage(state, action: PayloadAction<string>) {
            const msg = state.visible.find(
                (msg) => msg.uuid === action.payload,
            );

            if (msg) {
                state.visible.splice(state.visible.indexOf(msg), 1);
                state.dismissed.push(msg);
            }
        },
        clearMessage(state, action: PayloadAction<string>) {
            const msg = state.dismissed.find(
                (msg) => msg.uuid === action.payload,
            );

            if (msg) {
                state.dismissed.splice(state.dismissed.indexOf(msg), 1);
            }
        },
    },
});

export const snackbarReducer = snackbarSlice.reducer;
export const snackbarActions = snackbarSlice.actions;

export const snackbarSelectors = {
    getMessages: (state: AppState) =>
        [
            ...state.snackbar.visible.map((m) => ({ ...m, visible: true })),
            ...state.snackbar.dismissed.map((m) => ({ ...m, visible: false })),
        ].sort((a, b) => b.sendAt - a.sendAt),
};
