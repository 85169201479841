import * as React from 'react';
import { useI18n } from '../i18n';
import { DraftState } from '../api/model/core';
import cx from 'classnames';
import { IconInfo } from '../icons';

type Props = {
    state: DraftState | undefined;
    reason: string | undefined;
};

export const ReviewDisplay: React.FunctionComponent<Props> = (props) => {
    const { t } = useI18n();

    if (
        props.state !== DraftState.Rejected &&
        props.state !== DraftState.Pending &&
        props.state !== DraftState.Live
    ) {
        return null;
    }

    const isPending = props.state === DraftState.Pending;
    const isRejected = props.state === DraftState.Rejected;
    const isLive = props.state === DraftState.Live;
    const className = cx('rounded-xl px-6 py-4', {
        'bg-red-100 text-red-900': isRejected,
        'bg-yellow-100 text-yellow-900': isPending,
        'bg-green-100 text-semantic-secondary': isLive,
    });
    const [titleKey, subtitleKey] = isRejected
        ? ['publishing.rejected.title', 'publishing.rejected.subtitle']
        : isPending
        ? ['publishing.pending.title', 'publishing.pending.subtitle']
        : ['publishing.live.title', 'publishing.live.subtitle'];

    return (
        <div className={className} role="alert">
            <div className="flex items-center space-x-4">
                <IconInfo
                    color={
                        isPending
                            ? '#F59E0B'
                            : isRejected
                            ? '#E11D48'
                            : '#10B981'
                    }
                />
                <div className="space-y-1">
                    <p className="text-headline4 font-bold">{t(titleKey)}</p>
                    <p className="text-body1">{t(subtitleKey)}</p>
                    {props.reason && (
                        <p className="text-red-600 font-light mt-1">
                            {props.reason}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReviewDisplay;
