import cx from 'classnames';

interface ProgressBarProps {
    /** Must be a number between (including) 0 and 100. */
    percent: number;

    disabled?: boolean;
}

export const ProgressBar = (props: ProgressBarProps) => {
    return (
        <div className="bg-gray-200 h-2 rounded-md">
            <div
                className={cx('h-2 rounded-md', {
                    'bg-green-300': !props.disabled,
                    'bg-gray-400': props.disabled,
                })}
                style={{
                    width: `${props.percent.toFixed()}%`,
                }}
            />
        </div>
    );
};
