import * as React from 'react';
import cx from 'classnames';
import { Translatable, useI18n } from '../i18n';

type ButtonProps = {
    label?: Translatable;
    href?: string;
} & React.ComponentPropsWithoutRef<'button'> &
    (
        | { primary: true; secondary?: false }
        | { primary?: false; secondary: true }
    );

export const Button: React.FunctionComponent<ButtonProps> = ({
    label,
    primary,
    secondary,
    ...props
}) => {
    const { t } = useI18n();

    if (!label && !props.children) {
        return null;
    }

    const className = cx(
        props.className,
        'relative w-full flex justify-center antialiased py-2 space-x-2 border border-gray-200 text-body1 font-bold rounded focus:outline-none focus:border-green-700 focus:shadow-outline-green transition-default disabled:opacity-75 disabled:cursor-not-allowed',
        { 'px-6': label },
        { 'px-4': !label },
        {
            'text-white bg-semantic-primary hover:bg-opacity-75 active:bg-opacity-75':
                !!primary,
            'text-gray-700 hover:bg-gray-200 hover:text-gray-900': !!secondary,
        },
    );

    if (props.href) {
        return (
            <a
                href={props.href}
                target="_blank"
                rel="noreferrer"
                className={className}
            >
                {label && t(label)}
                {props.children}
            </a>
        );
    }

    return (
        <button {...props} className={className}>
            {label && t(label)}
            {props.children}
        </button>
    );
};
