import * as React from 'react';

import { AnalyticsReport } from '../api/model/core';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { Translatable, useI18n } from '../i18n';
import { useMemo } from 'react';
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';
import moment from 'moment';
import cx from 'classnames';
import { useNumberFormat } from '../i18n/numbers';

type Props = {
    title: Translatable;
    report: AnalyticsReport | undefined;
    legendI18nPrefix?: string;
    hideLegend?: boolean;
    formatValue?: (n: number) => string;
    additionalReportForTotal?: AnalyticsReport | undefined;
};

export const AnalyticsReportChart: React.FunctionComponent<Props> = ({
    report,
    additionalReportForTotal,
    ...props
}) => {
    const chart = useMemo(() => {
        const ONE_DAY = 1000 * 60 * 60 * 24;
        const NUM_DAYS = 30;

        const eod = new Date(Date.now());
        eod.setHours(23, 59, 59, 0);

        const xs = Array(NUM_DAYS)
            .fill(0)
            .map((_, i) => new Date(eod.getTime() - i * ONE_DAY))
            .reverse();

        const xsComp = xs.map(
            (v) => new Date(v.getTime() - NUM_DAYS * ONE_DAY),
        );

        const summary = xs.map((ts) => ({
            x: ts,
            y:
                (report?.summary?.events ?? []).find(
                    ({ time }: { time?: Timestamp }) =>
                        sameDay(Timestamp.toDate(time!), ts),
                )?.value ?? 0,
        }));

        const summaryComp = xsComp.map((ts) => ({
            x: new Date(ts.getTime() + NUM_DAYS * ONE_DAY), // shift back into same timeline as main series
            y:
                (report?.summary?.events ?? []).find(
                    ({ time }: { time?: Timestamp }) =>
                        sameDay(Timestamp.toDate(time!), ts),
                )?.value ?? 0,
        }));

        const total = summary.map(({ y }) => y).reduce((a, b) => a + b, 0);
        const totalComp = summaryComp
            .map(({ y }) => y)
            .reduce((a, b) => a + b, 0);

        const change = (total - totalComp) / totalComp;

        const additionalTotal = xs
            .map(
                (ts) =>
                    (additionalReportForTotal?.summary?.events ?? []).find(
                        ({ time }: { time?: Timestamp }) =>
                            sameDay(Timestamp.toDate(time!), ts),
                    )?.value ?? 0,
            )
            .reduce((a, b) => a + b, 0);

        return {
            xs,
            total,
            additionalTotal:
                additionalReportForTotal !== undefined
                    ? additionalTotal
                    : undefined,
            change,
            summaryRange: [xs[0], xs[xs.length - 1]],
            summaryCompRange: [
                new Date(xs[0].getTime() - NUM_DAYS * ONE_DAY),
                new Date(xs[xs.length - 1].getTime() - NUM_DAYS * ONE_DAY),
            ],
            data: [
                ...xs.map((x, i) => ({
                    time: moment(x).format('DD. MMM.'),
                    summary: summary[i].y,
                    summaryComp: summaryComp[i].y,
                    max: Math.max(summary[i].y, summaryComp[i].y),
                })),
            ],
        };
    }, [report, additionalReportForTotal]);

    const { t } = useI18n();

    const totalFormatter = useNumberFormat();
    const changeFormatter = useNumberFormat({
        signDisplay: 'always',
        maximumFractionDigits: 1,
    });

    const format = props.formatValue || totalFormatter;

    return (
        <div className="rounded-xl bg-semantic-surface flex flex-col space-y-4 p-6">
            <div className="text-headline6 text-gray-500 uppercase font-bold tracking-widest">
                {t(props.title)}
            </div>
            <div className="flex flex-row space-between">
                <div className="text-headline1 font-bold text-semantic-primary flex-1">
                    {chart.additionalTotal !== undefined && (
                        <>{format(chart.additionalTotal)} / </>
                    )}
                    {format(chart.total)}
                </div>
                <div
                    className={cx('text-headline1', {
                        'text-semantic-secondary': chart.change > 0,
                        'text-gray-500': chart.change < 0,
                    })}
                >
                    {Number.isFinite(chart.change) && chart.change !== 0 && (
                        <>{changeFormatter(Math.fround(chart.change * 100))}%</>
                    )}
                </div>
            </div>
            <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        margin={{
                            top: 20,
                            left: -16,
                            bottom: 10,
                        }}
                        data={chart.data}
                    >
                        <CartesianGrid strokeDasharray="3" stroke="#E2E5E9" />
                        <XAxis
                            dataKey="time"
                            tick={{
                                fontSize: 12,
                            }}
                        />
                        <YAxis
                            dataKey="max"
                            tick={{
                                fontSize: 12,
                            }}
                            tickFormatter={
                                props.formatValue
                                    ? (v: any, _) => format(v)
                                    : undefined
                            }
                        />
                        <Line
                            dataKey="summary"
                            stroke="#469F9E"
                            dot={false}
                            strokeWidth="3"
                        />
                        <Line
                            dataKey="summaryComp"
                            stroke="#939EAA"
                            dot={false}
                            strokeWidth="3"
                            strokeDasharray="5 5"
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div className="flex flex-row space-x-4 justify-end">
                <div className="flex flex-row items-center space-x-2 text-sm text-gray-500">
                    <div
                        className="w-4 h-4 rounded"
                        style={{ backgroundColor: '#469F9E' }}
                    />
                    <span>
                        {moment(chart.summaryRange[0]).format('DD. MMM.')} -{' '}
                        {moment(chart.summaryRange[1]).format('DD. MMM. YYYY')}
                    </span>
                </div>
                <div className="flex flex-row items-center space-x-2 text-sm text-gray-500">
                    <div
                        className="w-4 h-4 rounded"
                        style={{ backgroundColor: '#939EAA' }}
                    />
                    <span>
                        {moment(chart.summaryCompRange[0]).format('DD. MMM.')} -{' '}
                        {moment(chart.summaryCompRange[1]).format(
                            'DD. MMM. YYYY',
                        )}
                    </span>
                </div>
            </div>
        </div>
    );
};

function sameDay(d1: Date, d2: Date) {
    return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
    );
}
