import axios from 'axios';
import { ulid } from 'ulid';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../redux/AppStore';
import {
    GetRewardDraftResponse,
    GetRewardDraftsResponse,
    PostRewardDraftRequest,
    PostRewardDraftResponse,
    UploadRewardCodesResponse,
    UploadRewardCodesRequest,
} from '../api/model/b2b';
import {
    Asset,
    DraftState,
    GetCodeIDsForRewardResponse,
    GetRewardAssetsResponse,
    GetRewardLiveResponse,
    GetRewardResponse,
    ImageResource,
    Reward,
    RewardBillingType,
    RewardDraft,
    TaxableCodeType,
} from '../api/model/core';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { Duration } from '../api/google/protobuf/duration';

const PAGE_SIZE = 25;

type submitRewardDraftArgs = {
    rewardId: string;
};

type saveRewardDraftArgs = {
    partnerId: string;
    rewardId?: string;
    title?: string;
    description?: string;
    terms?: string;
    directLink?: string;
    couponCodes?: string[];
    images?: ImageResource[];
    validUntil?: Timestamp;
    maxClaims?: number;
    maxClaimsCooldown?: Duration;
    billingType?: RewardBillingType;
    codePriceCents?: number;
    taxableCodeType?: TaxableCodeType;
};

const saveRewardDraftAction = createAsyncThunk(
    'save/partner/reward_draft',
    async ({
        partnerId,
        rewardId,
        title,
        description,
        terms,
        directLink,
        couponCodes,
        images,
        validUntil,
        maxClaims,
        maxClaimsCooldown,
        billingType,
        codePriceCents,
        taxableCodeType,
    }: saveRewardDraftArgs) => {
        // valid for one year by default
        const nextYear = new Date();
        nextYear.setFullYear(nextYear.getFullYear() + 1);

        const req: PostRewardDraftRequest = PostRewardDraftRequest.create({
            rewardId: rewardId || ulid(),
            title: title || '',
            description: description || '',
            terms: terms || '',
            directLink: directLink || '',
            couponCodes: couponCodes || [],
            images: images || [],
            validUntil: validUntil || Timestamp.fromDate(nextYear),
            maxClaims: maxClaims || 0,
            maxClaimsCooldown: maxClaimsCooldown || Duration.create(),
            billingType: billingType || RewardBillingType.DISCOUNT,
            codePriceCents: codePriceCents || 0,
            taxableCodeType: taxableCodeType || TaxableCodeType.UNKNOWN,
        });

        const res = await axios.post<PostRewardDraftResponse>(
            `/partner/${partnerId}/reward_draft`,
            PostRewardDraftRequest.toJson(req),
        );

        return PostRewardDraftResponse.fromJson(res.data as any);
    },
);

const submitRewardDraftAction = createAsyncThunk(
    'submit/partner/reward_draft',
    async ({ rewardId }: submitRewardDraftArgs, { dispatch }) => {
        await axios.post<PostRewardDraftResponse>(
            `/review/type/PayloadReward/id/${rewardId}`,
        );
    },
);

type uploadRewardCodesArgs = {
    partnerId: string;
    rewardId: string;
    batchId?: string;
    codes: string[];
};

const uploadRewardCodesAction = createAsyncThunk(
    'upload/partner/reward_codes',
    async ({ partnerId, rewardId, batchId, codes }: uploadRewardCodesArgs) => {
        const req: UploadRewardCodesRequest = UploadRewardCodesRequest.create({
            codes,
            partnerId,
            rewardId,
            batchId: batchId ?? ulid(),
        });

        const res = await axios.post<UploadRewardCodesResponse>(
            `/partner/${partnerId}/reward_live/${rewardId}/codes`,
            UploadRewardCodesRequest.toJson(req),
        );

        return UploadRewardCodesResponse.fromJson(res.data as any);
    },
);

export type getRewardDraftArgs = {
    partnerId: string;
    rewardId?: string;
};

export type getRewardLiveArgs = {
    partnerId: string;
    rewardId: string;
};

export type getRewardsArgs = {
    partnerId?: string;
    after?: string;
};

type getRewardAssetsArgs = {
    rewardId: string;
};

const fetchRewardDraft = async (partnerId: string, rewardId?: string) => {
    const res = await axios.get<GetRewardDraftResponse>(
        `/partner/${partnerId}/reward_draft/${rewardId}`,
    );

    const reward = GetRewardDraftResponse.fromJson(res.data as any);
    return reward;
};

const fetchRewardDraftAction = createAsyncThunk(
    'fetch/partner/reward_draft',
    async ({ partnerId, rewardId }: getRewardDraftArgs) => {
        return await fetchRewardDraft(partnerId, rewardId);
    },
);

const fetchReward = async (partnerId: string, rewardId: string) => {
    const res = await axios.get<GetRewardResponse>(
        `/partner/${partnerId}/reward_live/${rewardId}`,
    );

    const draft = GetRewardResponse.fromJson(res.data as any);
    return draft;
};

const fetchRewardAction = createAsyncThunk(
    'fetch/reward',
    async ({ partnerId, rewardId }: getRewardLiveArgs) => {
        return await fetchReward(partnerId, rewardId);
    },
);

const fetchAllRewardDrafts = async (partnerId: string) => {
    const res = await axios.get<GetRewardDraftsResponse>(
        `/partner/${partnerId}/reward_draft`,
    );

    const drafts = GetRewardDraftsResponse.fromJson(res.data as any);
    return drafts;
};

const fetchAllRewardDraftsAction = createAsyncThunk(
    'fetchAll/partner/reward_draft',
    async ({ partnerId }: getRewardDraftArgs) => {
        return await fetchAllRewardDrafts(partnerId);
    },
);

const fetchAllLiveRewards = async (params: getRewardsArgs) => {
    const res = await axios.get<GetRewardLiveResponse>(
        `/partner/${params.partnerId}/reward_live?limit=${PAGE_SIZE}`,
    );

    const rewards = GetRewardLiveResponse.fromJson(res.data as any);
    return rewards;
};

const fetchAllLiveRewardsAction = createAsyncThunk(
    'fetchAll/reward',
    async (params: getRewardsArgs) => {
        return await fetchAllLiveRewards(params);
    },
);

const clearCurrentDraftAction = createAction<void>(
    'clear/partner/reward_draft',
);

const fetchRewardAssetsAction = createAsyncThunk(
    'rewards/assets/fetch',
    async (params: getRewardAssetsArgs) => {
        const res = await axios.get<GetRewardAssetsResponse>(
            `/balance/type/reward/id/${params.rewardId}`,
        );
        return GetRewardAssetsResponse.fromJson(res.data as any);
    },
);

const fetchRewardCodeIDsAction = createAsyncThunk(
    'rewards/assets/v2/fetch',
    async (params: getRewardAssetsArgs) => {
        const res = await axios.get<GetRewardAssetsResponse>(
            `/balance/type/reward/id/${params.rewardId}/v2`,
        );
        return GetCodeIDsForRewardResponse.fromJson(res.data as any);
    },
);

export type RewardDraftState = {
    rewardDraftIds: string[];
    rewardDraftById: Record<string, RewardDraft>;
    rewardLiveIds: string[];
    rewardLiveById: Record<string, Reward>;
    isLoading: boolean;
    loadingFailed: boolean;
    isWriting: boolean;
    writingFailed: boolean;
    currentDraft?: RewardDraft;
    currentLive?: Reward;
    assetsByRewardId: Record<string, Asset[]>;
    codeIdsByRewardId: Record<string, string[]>;
};

const initialState: RewardDraftState = {
    isLoading: false,
    loadingFailed: false,
    isWriting: false,
    writingFailed: false,
    rewardDraftIds: [],
    rewardDraftById: {},
    rewardLiveIds: [],
    rewardLiveById: {},
    assetsByRewardId: {},
    codeIdsByRewardId: {},
};

const rewardsSlice = createSlice({
    name: 'rewardDraft',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(submitRewardDraftAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(submitRewardDraftAction.rejected, (state, action) => {
            state.isLoading = false;
            state.loadingFailed = true;
        });
        builder.addCase(submitRewardDraftAction.fulfilled, (state, action) => {
            const { rewardId } = action.meta.arg;
            state.isLoading = false;
            state.rewardDraftById[rewardId].state = DraftState.Pending;
            if (state.currentDraft?.rewardId === rewardId) {
                state.currentDraft.state = DraftState.Pending;
            }
        });

        builder.addCase(fetchRewardDraftAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchRewardDraftAction.rejected, (state, action) => {
            state.isLoading = false;
            state.loadingFailed = true;
        });
        builder.addCase(fetchRewardDraftAction.fulfilled, (state, action) => {
            const draft = action.payload?.draft || null;
            state.isLoading = false;
            if (draft) {
                state.currentDraft = draft;
            }
        });

        builder.addCase(fetchRewardAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchRewardAction.rejected, (state, action) => {
            state.isLoading = false;
            state.loadingFailed = true;
        });
        builder.addCase(fetchRewardAction.fulfilled, (state, action) => {
            const reward = action.payload?.reward || null;
            state.isLoading = false;
            if (reward) {
                state.currentLive = reward;
            }
        });

        builder.addCase(fetchAllRewardDraftsAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(
            fetchAllRewardDraftsAction.rejected,
            (state, action) => {
                state.isLoading = false;
                state.loadingFailed = true;
            },
        );
        builder.addCase(
            fetchAllRewardDraftsAction.fulfilled,
            (state, action) => {
                state.isLoading = false;
                const drafts = action.payload?.drafts || [];
                const asSet = new Set(state.rewardDraftIds);
                drafts.forEach((draft) => {
                    asSet.add(draft.rewardId);
                    state.rewardDraftById[draft.rewardId] = draft;
                });
                state.rewardDraftIds = Array.from(asSet).sort();
            },
        );

        builder.addCase(fetchAllLiveRewardsAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchAllLiveRewardsAction.rejected, (state, action) => {
            state.isLoading = false;
            state.loadingFailed = true;
        });
        builder.addCase(
            fetchAllLiveRewardsAction.fulfilled,
            (state, action) => {
                state.isLoading = false;
                const rewards = action.payload?.rewards || [];
                const asSet = new Set(state.rewardLiveIds);
                rewards.forEach((reward) => {
                    asSet.add(reward.rewardId);
                    state.rewardLiveById[reward.rewardId] = reward;
                });
                state.rewardLiveIds = Array.from(asSet).sort();
            },
        );

        builder.addCase(saveRewardDraftAction.pending, (state) => {
            state.isWriting = true;
        });
        builder.addCase(saveRewardDraftAction.rejected, (state) => {
            state.isWriting = false;
            state.writingFailed = true;
        });
        builder.addCase(saveRewardDraftAction.fulfilled, (state, action) => {
            const draft = action.payload?.draft || null;
            state.isWriting = false;
            if (draft) {
                state.currentDraft = draft;
                state.rewardDraftById[draft.rewardId] = draft;

                const asSet = new Set([
                    ...state.rewardDraftIds,
                    draft.rewardId,
                ]);
                state.rewardDraftIds = Array.from(asSet).sort();
            }
        });

        builder.addCase(uploadRewardCodesAction.pending, (state) => {
            state.isWriting = true;
        });
        builder.addCase(uploadRewardCodesAction.rejected, (state) => {
            state.isWriting = false;
            state.writingFailed = true;
        });
        builder.addCase(uploadRewardCodesAction.fulfilled, (state, action) => {
            state.isWriting = false;
        });

        builder.addCase(clearCurrentDraftAction, (state, action) => {
            state.currentDraft = undefined;
        });

        builder.addCase(fetchRewardAssetsAction.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchRewardAssetsAction.fulfilled, (state, action) => {
            state.assetsByRewardId[action.meta.arg.rewardId] =
                action.payload.available;
            state.isLoading = false;
        });
        builder.addCase(fetchRewardAssetsAction.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(fetchRewardCodeIDsAction.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchRewardCodeIDsAction.fulfilled, (state, action) => {
            state.codeIdsByRewardId[action.meta.arg.rewardId] =
                action.payload.codeIds;
            state.isLoading = false;
        });
        builder.addCase(fetchRewardCodeIDsAction.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const rewardsReducer = rewardsSlice.reducer;

export const rewardActions = {
    ...rewardsSlice.actions,
    saveRewardDraft: saveRewardDraftAction,
    fetchRewardDraft: fetchRewardDraftAction,
    submitRewardDraft: submitRewardDraftAction,
    clearRewardDraft: clearCurrentDraftAction,
    uploadRewardCodes: uploadRewardCodesAction,
    fetchAllRewardDrafts: fetchAllRewardDraftsAction,
    fetchReward: fetchRewardAction,
    fetchAllLiveRewards: fetchAllLiveRewardsAction,
    fetchRewardAssets: fetchRewardAssetsAction,
    fetchRewardCodeIDs: fetchRewardCodeIDsAction,
};

export const rewardSelectors = {
    isLoading: (state: AppState) => state.rewards.isLoading,
    loadingFailed: (state: AppState) => state.rewards.loadingFailed,

    isWriting: (state: AppState) => state.rewards.isWriting,
    writingFailed: (state: AppState) => state.rewards.writingFailed,

    hasDraftAPublishedReward: (state: AppState) =>
        state.rewards.rewardLiveIds.includes(
            state.rewards.currentDraft?.rewardId ?? '-',
        ),
    currentDraft: (state: AppState) => state.rewards.currentDraft,
    currentLive: (state: AppState) => state.rewards.currentLive,
    allDrafts: (state: AppState) =>
        state.rewards.rewardDraftIds.map(
            (id) => state.rewards.rewardDraftById[id],
        ),
    getCurrentPage: (state: AppState): Reward[] => {
        const { rewardLiveIds, rewardLiveById } = state.rewards;
        const start = 0;
        const end = PAGE_SIZE;
        const idsForPage = rewardLiveIds.slice(start, end);
        return idsForPage.map((id) => rewardLiveById[id]);
    },
    getAssets: (state: AppState, { rewardId }: { rewardId: string }) =>
        state.rewards.assetsByRewardId[rewardId],
};
