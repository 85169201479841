import { ImageResource } from '../api/model/core';
import { Button } from './Button';
import cx from 'classnames';

type Props = {
    children?: any;
    images: ImageResource[];
    allowRemove?: boolean;
    onRemove?: any;
    circleCrop: boolean;
};

const Thumbnails = (props: Props) => {
    return (
        <div className="flex flex-row flex-wrap">
            {props.images.map((file: ImageResource) => {
                return (
                    <div
                        className={cx(
                            'w-32 h-32 shadow inline-flex m-2 box-border flex-col justify-between bg-white rounded overflow-hidden',
                            {
                                'rounded-full': props.circleCrop,
                                rounded: !props.circleCrop,
                            },
                        )}
                        key={file.id}
                    >
                        <div className="w-full h-full overflow-hidden">
                            <img
                                src={file.url}
                                className={cx('w-auto block', {
                                    'object-contain h-full w-full p-4':
                                        props.circleCrop,
                                })}
                                alt={file.id}
                            />
                        </div>
                        {props.allowRemove && (
                            <Button
                                secondary
                                label="common.remove"
                                onClick={() => props.onRemove(file)}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default Thumbnails;
