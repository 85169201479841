import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from '../auth/store';
import { accountReducer } from '../account/store';
import { snackbarReducer } from '../snackbar/store';
import { partnerReducer } from '../partner/store';
import { rewardsReducer } from '../rewards/store';
import { analyticsReducer } from '../reports/store';
import { partnerProfileReducer } from '../partner_profile/store';
import { cashbackReducer } from '../cashback/store';
import { billingReducer } from '../billing/store';

const appReducer = combineReducers({
    account: accountReducer,
    auth: authReducer,
    billing: billingReducer,
    cashback: cashbackReducer,
    partner: partnerReducer,
    snackbar: snackbarReducer,
    partnerProfile: partnerProfileReducer,
    rewards: rewardsReducer,
    analytics: analyticsReducer,
});

export const rootReducer: typeof appReducer = (state, action) => {
    if (action.type === 'auth/logout/fulfilled') {
        state = undefined;
    }

    return appReducer(state, action);
};
