import firebase from 'firebase/app';
import 'firebase/auth';
import { deploymentEnvironment } from '../env';

const firebaseConfigs = {
    production: {
        apiKey: 'AIzaSyAfLRq69mJktY6lFTl7I897wIMY7L3TfA0',
        authDomain: 'plan3t-b2b.firebaseapp.com',
        databaseURL: 'https://plan3t-b2b.firebaseio.com',
        projectId: 'plan3t-b2b',
        storageBucket: 'plan3t-b2b.appspot.com',
        messagingSenderId: '625881498893',
        appId: '1:625881498893:web:769825ac351a2963da9c44',
    },
    staging: {
        apiKey: 'AIzaSyBKqGfgXHD87T0vlx3hhanFmQTFe_fg1Ug',
        authDomain: 'plan3t-staging.firebaseapp.com',
        projectId: 'plan3t-staging',
        storageBucket: 'plan3t-staging.appspot.com',
        messagingSenderId: '213063106542',
        appId: '1:213063106542:web:7564257480f165cf7e98ff',
    },
    development: {
        apiKey: 'AIzaSyDxRO9NquEteLXz8XEt4SJqASuVOiYAaqY',
        authDomain: 'plan3t-dev.firebaseapp.com',
        projectId: 'plan3t-dev',
        storageBucket: 'plan3t-dev.appspot.com',
        messagingSenderId: '469113112437',
        appId: '1:469113112437:web:63dc6f1805245a0c33753c',
    },
} as const;

const firebaseConfig = firebaseConfigs[deploymentEnvironment];

export const app = firebase.initializeApp(firebaseConfig);
